<template>
      <v-col>
          <v-card style="background-color:#F5F5F5;" :loading="clinicAddStatus===1 || clinicEditLoadStatus===1 || clinicEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Anuluj</v-btn>
              <v-btn color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row>
            <v-col class="col-6 pl-5">
                <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Dane podstawowe</v-toolbar-title>
                    <v-spacer />
                    <v-chip label color="recepta">Dane wymagane do e-recepty</v-chip></v-toolbar>
                  <v-card-text>
                          <v-row>                   
                            <v-col cols="12">
                              <v-text-field background-color="recepta" dense v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa"></v-text-field>                            
                            </v-col>
                            <v-col cols="12">
                              <v-select dense v-model="editedItem.type" :items="lookups.clinic_type" :error-messages="typeErrors" required @input="$v.editedItem.type.$touch()"  @blur="$v.editedItem.type.$touch()" label="Rodzaj"></v-select>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field background-color="recepta" dense  v-model="editedItem.post_code" :error-messages="post_codeErrors" required @input="$v.editedItem.post_code.$touch()"  @blur="$v.editedItem.post_code.$touch()" label="Kod pocztowy"></v-text-field>
                            </v-col>
                            <v-col cols="7">
                              <v-text-field background-color="recepta" dense  v-model="editedItem.city" :error-messages="cityErrors" required @input="$v.editedItem.city.$touch()"  @blur="$v.editedItem.city.$touch()" label="Miasto"></v-text-field>
                            </v-col>
                             <v-col cols="8">
                              <v-text-field background-color="recepta" dense v-model="editedItem.address" :error-messages="addressErrors" required @input="$v.editedItem.address.$touch()"  @blur="$v.editedItem.address.$touch()" label="Ulica"></v-text-field>
                            </v-col>
                             <v-col cols="4">
                              <v-text-field background-color="recepta" dense v-model="editedItem.house" :error-messages="houseErrors" required @input="$v.editedItem.house.$touch()"  @blur="$v.editedItem.house.$touch()" label="Nr domu"></v-text-field>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field background-color="recepta" dense v-model="editedItem.phone" label="Numer telefonu"></v-text-field>
                            </v-col>
                            <v-col cols="7">
                              <v-text-field dense v-model="editedItem.email" :error-messages="emailErrors" @input="$v.editedItem.email.$touch()"  @blur="$v.editedItem.email.$touch()" label="Adres email"></v-text-field>
                            </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>      
            </v-col>
            <v-col class="col-6 pl-0 pr-5">
                <v-expansion-panels :value="2">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-4 py-2">
                        <v-toolbar-title>Logo</v-toolbar-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>    
                                <v-col dense cols="12">
                                  <v-img v-if="editedItem.logo" :src="editedItem.logo" contain height="80" />
                                  <file-drop v-else :fileContent.sync="editedItem.logo" :fileAccept="accept" />
                                </v-col>
                      </v-row>
                      <v-row>
                          <v-col dense cols="12 text-right">
                              <v-btn v-if="editedItem.logo" @click="editedItem.logo = null" x-small color="secondary">Usuń</v-btn>
                          </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-4 py-2">
                        <v-toolbar-title>Dane dodatkowe</v-toolbar-title>
                        <!-- <v-spacer />
                        <v-chip label color="recepta">Dane wymagane do e-recepty</v-chip> -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>    
                              <v-col cols="4">
                                <v-text-field dense v-model="editedItem.nip" label="NIP"></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field background-color="recepta" dense v-model="editedItem.regon" label="REGON"></v-text-field>
                              </v-col>
                               <v-col cols="4">
                                <v-select background-color="recepta" dense v-model="editedItem.nfz" :items="lookups.nfz" label="Odział NFZ"></v-select>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field dense v-model="editedItem.referrals_email" :error-messages="referrals_emailErrors" @input="$v.editedItem.referrals_email.$touch()"  @blur="$v.editedItem.referrals_email.$touch()" label="Adres email do skierowań"></v-text-field>
                              </v-col>
                              <v-col cols="6"></v-col>
                              <v-col cols="6">
                                <v-text-field background-color="recepta" dense v-model="editedItem.erecepta_oid" label="Identyfikator lokalny OID"></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field background-color="recepta" dense v-model="editedItem.erecepta_rpwdl" label="Numery księgi rejestrowej RPWDL"></v-text-field>
                              </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-4 py-2">
                        <v-toolbar-title>Osoby kontaktowe</v-toolbar-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>  
                          <v-col cols="12">
                             <clinic-contacts />
                          </v-col>    
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row class="mt-0"> 
            <v-col dense class="col-12">
                <clinic-services v-if="editedItem.id" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
</template>

<script>
  import FileDrop from '../components/FileDrop.vue'
  import required from 'vuelidate/lib/validators/required'
  import email from 'vuelidate/lib/validators/email'
  import ClinicContacts from '../components/ClinicContacts.vue'
  import ClinicServices from '../components/ClinicServices.vue'

  export default {
    components: { FileDrop, ClinicContacts, ClinicServices },
    data: () => ({
      accept:"image/png, image/jpeg, image/bmp"
    }),
    validations: {
      editedItem:{
        name: {
          required,
        },
        type: {
          required,
        },
        post_code: {
          required,
        },
        city: {
          required,
        },
        address: {
          required,
        },
        house: {
          required,
        },
        email:{
          email
        },
        referrals_email:{
          email
        }
      }
    },
    watch: {
      clinicEditStatus(val){
        if(val===2){
          this.close();
        }
      },
      clinicAddStatus(val){
        if(val===2){
          this.close();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params);
      },
      editedItem(){
        return this.$store.getters.getClinicEdit;
      },
      clinicEditLoadStatus(){
        return this.$store.getters.getClinicEditLoadStatus
      },
      clinicEditStatus(){
        return this.$store.getters.getClinicEditStatus();
      },
      clinicEditText(){
        return this.$store.getters.getClinicEditText
      },
      clinicAddStatus(){
        return this.$store.getters.getClinicAddStatus();
      },
      clinicAddText(){
        return this.$store.getters.getClinicAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowa klinika/oddział' : 'Edycja kliniki/oddziału'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa kliniki jest wymagana')
        return errors
      },
      typeErrors () {
        const errors = []
        if (!this.$v.editedItem.type.$dirty) return errors
        !this.$v.editedItem.type.required && errors.push('Rodzaj kliniki jest wymagany')
        return errors
      },
      post_codeErrors () {
        const errors = []
        if (!this.$v.editedItem.post_code.$dirty) return errors
        !this.$v.editedItem.post_code.required && errors.push('Kod pocztowy jest wymagany')
        return errors
      },
      cityErrors () {
        const errors = []
        if (!this.$v.editedItem.city.$dirty) return errors
        !this.$v.editedItem.city.required && errors.push('Miasto jest wymagane')
        return errors
      },
      addressErrors () {
        const errors = []
        if (!this.$v.editedItem.address.$dirty) return errors
        !this.$v.editedItem.address.required && errors.push('Ulica jest wymagana')
        return errors
      },
      houseErrors () {
        const errors = []
        if (!this.$v.editedItem.house.$dirty) return errors
        !this.$v.editedItem.house.required && errors.push('Numer domu jest wymagany')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.email && errors.push('Adres email jest niepoprawny')
        return errors
      },
      referrals_emailErrors () {
        const errors = []
        if (!this.$v.editedItem.referrals_email.$dirty) return errors
        !this.$v.editedItem.referrals_email.email && errors.push('Adres email jest niepoprawny')
        return errors
      },
    },
    mounted(){
      if(this.itemEditing){
        this.$store.dispatch( 'loadClinicEdit', {slug:  this.$route.params.id} );
      }else{

      }
    },
    beforeRouteLeave (to, from, next) {
      this.$v.$reset();
      this.$store.dispatch( 'loadClinicEditDefault'); 
      this.$store.dispatch( 'loadClinicAddDefault');
      next();
    },
    methods: {
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadClinicEditDefault'); 
        this.$store.dispatch( 'loadClinicAddDefault');
        this.$router.push({ name: 'ustawienia.kliniki.powrot'})
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editClinic', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                type:  this.editedItem.type,
                post_code: this.editedItem.post_code,
                city: this.editedItem.city,
                address: this.editedItem.address,
                house: this.editedItem.house,
                phone:this.editedItem.phone,
                email: this.editedItem.email,
                logo: this.editedItem.logo,
                nip: this.editedItem.nip,
                regon: this.editedItem.regon,
                nfz: this.editedItem.nfz,
                erecepta_oid: this.editedItem.erecepta_oid,
                erecepta_rpwdl: this.editedItem.erecepta_rpwdl,
                referrals_email: this.editedItem.referrals_email,
                contacts: this.editedItem.contacts
              });
            } else {//new item
              this.$store.dispatch( 'addClinic', {
                  name:  this.editedItem.name,
                  type:  this.editedItem.type,
                  post_code: this.editedItem.post_code,
                  city: this.editedItem.city,
                  address: this.editedItem.address,
                  house: this.editedItem.house,
                  phone:this.editedItem.phone,
                  email: this.editedItem.email,
                  logo: this.editedItem.logo,
                  nip: this.editedItem.nip,
                  regon: this.editedItem.regon,
                  nfz: this.editedItem.nfz,
                  erecepta_oid: this.editedItem.erecepta_oid,
                  erecepta_rpwdl: this.editedItem.erecepta_rpwdl,
                  referrals_email: this.editedItem.referrals_email,
                  contacts: this.editedItem.contacts
              });

            }
        }
      },
    },
  }
</script>

