import { unset } from "lodash";
import AppointmentAPI from "../api/appointment";

export const appointments = {
    state: {
        appointments: [],
        appointmentOptions:{
            itemAdded:false,
            itemSearched:false,
            selectedCategories:[],
            selectedTypes:[2,3],
            selectedStatuses:[1,5,10,15],
            selectedUser:undefined,
            selectedService:undefined,
            fromDate:undefined,
            toDate:undefined,
            focus:'',
            type:'day'
        },
        appointmentsLoadStatus: 0,
        appointmentAddStatus: 0,
        appointmentAddText: "",

        appointmentEdit: {
            id: undefined,
            lp: undefined,
            event_ids:[],
            category_id: 1,
            category:{name:undefined},
            patient_id:undefined,
            users:[],
            user_id:undefined,
            clinic_id:undefined,
            service_id:undefined,
            room:undefined,
            date_from:null,
            date_to:undefined,
            info:undefined,
            patient_info:undefined,
            status:1,
            remainder_users:undefined,
            remainder_patient:undefined,
            user:{name:null,surname:null},
            patient:{name:'',surname:'',age:null,phone:null,text:null},
            clinic:{name:null},
            service:{name:null},
            read_only:false,
        },
        appointmentEditDefault: {
            id: undefined,
            lp: undefined,
            event_ids:[],
            category_id: 1,
            category:{name:undefined},
            patient_id:undefined,
            users:[],
            user_id:undefined,
            clinic_id:undefined,
            service_id:undefined,
            room:undefined,
            date_from:null,
            date_to:undefined,
            info:undefined,
            patient_info:undefined,
            status:1,
            remainder_users:undefined,
            remainder_patient:undefined,
            user:{name:null,surname:null},
            patient:{name:'',surname:'',age:null,phone:null,text:null},
            clinic:{name:null},
            service:{name:null},
            read_only:false,
        },
        appointmentEditLoadStatus: 0,
        appointmentEditStatus: 0,
        appointmentEditText: "",

        appointmentDeleteStatus: 0,
        appointmentDeleteText: "",

        appointmentsLookup:[],
        appointmentsLookupLoadStatus:0,

        referenceAppointments:[],
        referenceAppointmentsLoadStatus:0,

        viewStyle:'flow'
    },

    actions: {
        loadAppointments({ state, commit, dispatch }) {
            commit("setAppointmentsLoadStatus", 1);
                AppointmentAPI.getAppointments({
                    selectedCategories:state.appointmentOptions.selectedCategories,
                    selectedTypes:state.appointmentOptions.selectedTypes,
                    selectedStatuses:state.appointmentOptions.selectedStatuses,
                    selectedUser:state.appointmentOptions.selectedUser,
                    selectedService:state.appointmentOptions.selectedService,
                    fromDate:state.appointmentOptions.fromDate,
                    toDate:state.appointmentOptions.toDate,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setAppointments", response.data.appointments);
                    commit("setEvents", response.data.events);
                    commit("setAppointmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setAppointments", []);
                    commit("setAppointmentsLoadStatus", 3);
                });
         
        },
        loadReferenceAppointments({ state, commit, dispatch },data) {
            commit("setReferenceAppointmentsLoadStatus", 1);
                AppointmentAPI.getReferenceAppointments({
                    id:data.id,
                    user_id:data.user_id,
                    clinic_id:data.clinic_id,
                    room:data.room,
                    date_from:data.date_from,
                    date_to:data.date_to,
                    service_id:data.service_id
                })
                .then(function(response) {
                    commit("setReferenceAppointments", response.data);
                    commit("setReferenceAppointmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setReferenceAppointments", []);
                    commit("setReferenceAppointmentsLoadStatus", 3);
                });
        },
        // loadMoreAppointments({ state, commit, dispatch }) {
        //     commit("setAppointmentsLoadStatus", 1);
        //         AppointmentAPI.getAppointments({
        //             offset:state.appointments.length,
        //             itemsPerPage:state.appointmentOptions.itemsPerPage, 
        //             orderBy:state.appointmentOptions.orderBy,
        //             orderDirection:state.appointmentOptions.orderDirection,
        //             showDeleted:state.appointmentOptions.showDeleted,
        //             selectedType:state.appointmentOptions.selectedType,
        //             fromDate:state.appointmentOptions.fromDate,
        //             toDate:state.appointmentOptions.toDate,
        //         })
        //         .then(function(response) {
        //             commit("setAppointmentLineCount", response.data.lineCount);
        //             commit("setAppointments", _.concat(state.appointments, response.data.appointments));
        //             dispatch("applyIndexOnAppointments");
        //             commit("setAppointmentsLoadStatus", 2);
        //         })
        //         .catch(function() {
        //             commit("setAppointments", []);
        //             commit("setAppointmentsLoadStatus", 3);
        //         });
         
        // },
        // searchAppointments({ state, commit, dispatch },data) {
        //     commit("setAppointmentsLoadStatus", 1);
        //         AppointmentAPI.searchAppointments({
        //             showDeleted:state.appointmentOptions.showDeleted,
        //             filterableHeaders:data.filterableHeaders,
        //             q:data.q
        //         })
        //         .then(function(response) {
        //             commit("setOptionsItemAddedAndSearchedFalse");
        //             commit("setAppointmentSearchedTrue");
        //             commit("setAppointmentLineCount", response.data.lineCount);
        //             commit("setAppointments", response.data.appointments);
        //             dispatch("applyIndexOnAppointments");
        //             commit("setAppointmentsLoadStatus", 2);
        //         })
        //         .catch(function() {
        //             commit("setAppointments", []);
        //             commit("setAppointmentsLoadStatus", 3);
        //         });
         
        // },
        setAppointmentOptions({ state, commit, dispatch },data){
            commit("setAppointmentOptions",data.options);
            dispatch("loadAppointments");
        },
        applyIndexOnAppointments({ commit, state, dispatch }) {
            let localAppointments = state.appointments;

            var i = 1;
            localAppointments.forEach(appointment => {
                appointment.lp = i;
                i++;
            });

            commit("setAppointments",localAppointments);
        },
        addAppointment({ commit, state, dispatch }, data) {
            commit("setAppointmentAddStatus", 1);
            commit("setAppointmentAddText", "");

            AppointmentAPI.postAddNewAppointment(
                data.category_id,
                data.patient_id,
                data.clinic_id,
                data.service_id,
                data.room,
                data.date_from,
                data.date_to,
                data.info,
                data.patient_info,
                data.status,
                data.remainder_users,
                data.remainder_patient,
                data.event_ids,
                data.users

            )
                .then(function(response) {
                    commit("setAppointmentAddStatus", 2);
                    //commit("setAddedAppointments",response.data);
                    //commit("setAppointmentLineCount", state.appointmentOptions.lineCount + response.data.length);
                    //dispatch("applyIndexOnAppointments");
                })
                .catch(function(error) {
                    commit("setAppointmentAddStatus", 3);
                    commit("setAppointmentAddText", error.response.data.errors);
                });
        },

        loadAppointmentEdit({ commit }, data) {
            commit("setAppointmentEditLoadStatus", 1);
            commit("setAppointmentEditStatus", 0);
            commit("setAppointmentEditText", "");

            AppointmentAPI.getAppointmentEdit(data.slug)
                .then(function(response) {
                    commit("setReferenceEvents", response.data['references']);
                    commit("setReferenceAppointments", response.data['referenceAppointments']);
                    unset(response.data['references']);
                    unset(response.data['referenceAppointments']);
                    commit("setAppointmentEdit", response.data);
                    commit("setAppointmentEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setAppointmentEdit", {});
                    commit("setAppointmentEditLoadStatus", 3);
                });
        },
        loadAppointmentEditDefault({ commit }) {
            commit("setAppointmentEditDefault");
        },
        loadAppointmentAddDefault({ commit }) {
            commit("setAppointmentAddStatus", 0);
            commit("setAppointmentAddText", "");
        },
        editAppointment({ commit, state, dispatch }, data) {
            commit("setAppointmentEditStatus", 1);

            AppointmentAPI.putEditAppointment(
                data.slug,
                data.category_id,
                data.patient_id,
                data.clinic_id,
                data.service_id,
                data.room,
                data.date_from,
                data.date_to,
                data.info,
                data.patient_info,
                data.status,
                data.remainder_users,
                data.remainder_patient,
                data.event_ids,
                data.users
            )
                .then(function(response) {
           
                    commit("setAppointmentEditStatus", 2);
                    //commit("setEditedAppointments",response.data)
                    //dispatch("applyIndexOnAppointments");
                })
                .catch(function(error) {
                    commit("setAppointmentEditStatus", 3);
                    commit("setAppointmentEditText", error.response.data.errors);
                });
        },
        editAppointmentStatus({ commit, state, dispatch }, data) {
            commit("setAppointmentEditStatus", 1);

            AppointmentAPI.putEditAppointmentStatus(
                data.slug,
                data.status,
            )
                .then(function(response) {
                    commit("setAppointmentEditStatus", 2);
                    commit("setAppointmentEdit", response.data);
                })
                .catch(function(error) {
                    commit("setAppointmentEditStatus", 3);
                    commit("setAppointmentEditText", error.response.data.errors);
                });
        },
        editAppointmentInfo({ commit, state, dispatch }, data) {
            commit("setAppointmentEditStatus", 1);

            AppointmentAPI.putEditAppointmentInfo(
                data.slug,
                data.info,
            )
                .then(function(response) {
                    commit("setAppointmentEditStatus", 2);
                    commit("setAppointmentEdit", response.data);
                })
                .catch(function(error) {
                    commit("setAppointmentEditStatus", 3);
                    commit("setAppointmentEditText", error.response.data.errors);
                });
        },
        // editAppointmentReactivate({ commit, state, dispatch }, data) {
        //     commit("setAppointmentEditStatus", 1);
  
        //     AppointmentAPI.putReactivateAppointment(data.slug)
        //         .then(function(response) {
        //             commit("setAppointmentEditStatus", 2);
        //             commit("setEditedAppointments",response.data)
        //             dispatch("applyIndexOnAppointments");
        //         })
        //         .catch(function(error) {
        //             commit("setAppointmentEditStatus", 3);
        //         });
        // },
        // deleteAppointment({ commit, state, dispatch }, data) {
        //     commit("setAppointmentDeleteStatus", 1);

        //     AppointmentAPI.deleteAppointment(data.slug)
        //         .then(function(response) {
        //             commit("setAppointmentDeleteStatus", 2);
        //             if(state.appointmentOptions.showDeleted){
        //                 commit("setEditedAppointments",response.data)
        //             }else{
        //                 commit("setAppointmentLineCount", state.appointmentOptions.lineCount - 1);
        //                 commit("setDeletedAppointment",response.data)
        //             }
        //             dispatch("applyIndexOnAppointments");
        //         })
        //         .catch(function() {
        //             commit("setAppointmentDeleteStatus", 3);
        //         });
        // },
        // deleteAppointments({ commit, state, dispatch }, data) {
        //     commit("setAppointmentDeleteStatus", 1);

        //     AppointmentAPI.deleteAppointments(data.slug)
        //         .then(function(response) {
        //             commit("setAppointmentDeleteStatus", 2);
        //             if(state.appointmentOptions.showDeleted){
        //                 commit("setEditedAppointments",response.data)
        //             }else{
        //                 commit("setAppointmentLineCount", state.appointmentOptions.lineCount - response.data.length);
        //                 commit("setDeletedAppointments",response.data)
        //             }
        //             dispatch("applyIndexOnAppointments");
        //         })
        //         .catch(function() {
        //             commit("setAppointmentDeleteStatus", 3);
        //         });
        // },
        // forceDeleteAppointment({ commit, state, dispatch }, data) {
        //     commit("setAppointmentDeleteStatus", 1);

        //     AppointmentAPI.forceDeleteAppointment(data.slug)
        //         .then(function(response) {
        //             commit("setAppointmentDeleteStatus", 2);
        //             commit("setAppointmentLineCount", state.appointmentOptions.lineCount - 1);
        //             commit("setDeletedAppointment",response.data)
        //             dispatch("applyIndexOnAppointments");
        //         })
        //         .catch(function() {
        //             commit("setAppointmentDeleteStatus", 3);
        //         });
        // },
    },
    mutations: {
        setAppointmentOptions(state,options){
            state.appointmentOptions = options;
        },
        setAppointmentsLoadStatus(state, status) {
            state.appointmentsLoadStatus = status;
        },
        setAppointmentsLookupLoadStatus(state, status) {
            state.appointmentsLookupLoadStatus = status;
        },
        setReferenceAppointmentsLoadStatus(state, status) {
            state.referenceAppointmentsLoadStatus = status;
        },
        setAppointments(state, appointments) {
            state.appointments = appointments;
        },
        setAppointmentsLookup(state, appointments) {
            state.appointmentsLookup = appointments;
        },
        setReferenceAppointments(state, appointments) {
            state.referenceAppointments = appointments;
        },
        setAppointmentLineCount(state, lineCount) {
            state.appointmentOptions.lineCount = lineCount;
        },
        setAppointmentAddStatus(state, status) {
            state.appointmentAddStatus = status;
        },
        setAppointmentAddText(state, text) {
            state.appointmentAddText = text;
        },
        setAppointmentEdit(state, appointment) {
            state.appointmentEdit = appointment;
        },
        setPermissionListEdit(state, list) {
            state.appointmentEdit.computed_permissions = list;
        },
        setEditedAppointments(state, appointments) {
            appointments.forEach(appointment => {
                var lp = _.find(state.appointments,{'id':appointment.id}).lp;
                var index = _.findIndex(state.appointments,{'id':appointment.id});
        
                state.appointments[index] = appointment;
                state.appointments[index].lp = lp;
            });
        },
        setAddedAppointments(state, appointments) {
            appointments.forEach(appointment => {
                state.appointmentOptions.itemAdded = true;
                state.appointments.push(appointment);
            });
        },
        setAppointmentSearchedTrue(state) {
            state.appointmentOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.appointmentOptions.itemAdded = false;
            state.appointmentOptions.itemSearched = false;
        },
        setDeletedAppointment(state, appointment) {
            var index = _.findIndex(state.appointments,{'id':appointment.id});
            state.appointments.splice(index,1);
        },
        setDeletedAppointments(state, appointments) {
            appointments.forEach(e => {
                var index = _.findIndex(state.appointments,{'id':e.id});
                state.appointments.splice(index,1);      
            });
        },
        setAppointmentEditDefault(state) {
            state.appointmentEdit = JSON.parse(JSON.stringify(state.appointmentEditDefault));
        },
        setAppointmentEditStatus(state, status) {
            state.appointmentEditStatus = status;
        },
        setAppointmentEditText(state, text) {
            state.appointmentEditText = text;
        },
        setAppointmentEditLoadStatus(state, status) {
            state.appointmentEditLoadStatus = status;
        },
        setAppointmentDeleteStatus(state, status) {
            state.appointmentDeleteStatus = status;
        },
        setAppointmentDeletedText(state, text) {
            state.appointmentDeleteText = text;
        },
        setAppointmentViewStyle(state, style) {
            state.viewStyle = style;
        }
    },

    getters: {
        getAppointmentOptions(state){
            return state.appointmentOptions;
        },
        getAppointmentsLoadStatus(state) {
            return function(){
                return state.appointmentsLoadStatus;
            }
        },
        getAppointmentsLookupLoadStatus(state) {
            return state.appointmentsLookupLoadStatus;
        },
        getReferenceAppointmentsLoadStatus(state) {
            return state.referenceAppointmentsLoadStatus;
        },
        getAppointments(state) {
            return state.appointments;
        },
        getReferenceAppointments(state) {
            return state.referenceAppointments;
        },
        getAppointmentsLookup(state) {
            return state.appointmentsLookup;
        },
        getAppointmentAddStatus(state) {
            return function(){
                return state.appointmentAddStatus;
            } 
        },
        getAppointmentAddText(state) {
            return state.appointmentAddText;
        },
        getAppointmentEdit(state) {
            return state.appointmentEdit;
        },
        getAppointmentEditStatus(state) {
          return function(){
            return state.appointmentEditStatus;
          }
        },
        getAppointmentEditing(state) {
            return state.appointmentEdit.id > 0;
        },
        getAppointmentEditText(state) {
            return state.appointmentEditText;
        },
        getAppointmentEditLoadStatus(state) {
            return function(){
                return state.appointmentEditLoadStatus;
            }
        },
        getAppointmentDeleteStatus(state) {
            return function(){
                return state.appointmentDeleteStatus;
            }
        },
        getAppointmentDeleteText(state) {
            return state.appointmentDeleteText;
        },
        getAppointmentViewStyle(state) {
            return state.viewStyle ;
        }
    }
};
