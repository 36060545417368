import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/services
    */
    getServices: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/services',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/services/search
    */
   searchServices: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/services/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

    /*
		POST 	/api/services
	*/
    postAddNewService: function( name,code,category_id,referral_type_id,info,patient_info){
        return axios.post( HGO_CONFIG.API_URL + '/services',
            {
                name: name,
                code:code,
                category_id:category_id,
                referral_type_id:referral_type_id,
                info:info,
                patient_info:patient_info
            }
        );
    },

    /*
		GET 	/api/services/{slug}/edit
	*/
    getServiceEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/services/' + slug + '/edit' );
	},

    /*
        GET     /api/services/searchLookup
    */
    searchServicesLookup: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/services/searchLookup',{
        params:{
            q:options.q,
            category_id:options.category_id
    }});

    },

    /*
		PUT 	/api/services/{slug}
	*/
    putEditService: function(slug, name,code,category_id,referral_type_id,info,patient_info){
        return axios.put( HGO_CONFIG.API_URL + '/services/'+ slug,
            {
                name: name,
                code:code,
                category_id:category_id,
                referral_type_id:referral_type_id,
                info:info,
                patient_info:patient_info
            }
        );
    },

     /*
		GET 	/api/services/reactivate/{slug}
	*/
    putReactivateService: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/services/reactivate/' + slug);
    },

    /*
		DELETE /api/services/{slug}
	*/
    deleteService: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/services/' + slug );
    },
    
    /*
		DELETE /api/services/{slug}/force
	*/
    forceDeleteService: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/services/' + slug + '/force' );
	}
}