import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/events
    */
    getEvents: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/events',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
                selectedType:options.selectedType,
                fromDate:options.fromDate,
                toDate:options.toDate,
        }});
    },

    /*
        GET     /api/events/references
    */
   getReferenceEvents: function(data){
    return axios.get( HGO_CONFIG.API_URL + '/events/references',{
        params:{
            id:data.id,
            user_id:data.user_id,
            clinic_id:data.clinic_id,
            room:data.room,
            date_from:data.date_from,
            date_to:data.date_to,
            service_id:data.service_id
    }});
},

    /*
        GET     /api/events/search
    */
   searchEvents: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/events/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

  /*
        GET     /api/events/interval/{clinic_id}/{service_id}
    */
        getEventInterval: function(clinic_id,service_id){
            return axios.get( HGO_CONFIG.API_URL + '/events/interval/' + clinic_id + '/' + service_id);
        },

    /*
		POST 	/api/events
	*/
    postAddNewEvent: function( type,user_id,clinic_id,category_id,service_id,room,date_from,date_to,note,series_def,series,xpublic,interval){
        return axios.post( HGO_CONFIG.API_URL + '/events',
            {
                type: type,
                user_id:user_id,
                clinic_id:clinic_id,
                category_id:category_id,
                service_id:service_id,
                room:room,
                date_from:date_from,
                date_to:date_to,
                note:note,
                series_def:series_def,
                series:series,
                public:xpublic,
                interval:interval
            }
        );
    },

    /*
		GET 	/api/events/{slug}/edit
	*/
    getEventEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/events/' + slug + '/edit' );
	},


    /*
		PUT 	/api/events/{slug}
	*/
    putEditEvent: function(slug, type,user_id,clinic_id,category_id,service_id,room,date_from,date_to,note,series_def,series,xpublic,interval){
        return axios.put( HGO_CONFIG.API_URL + '/events/'+ slug,
            {
                type: type,
                user_id:user_id,
                clinic_id:clinic_id,
                category_id:category_id,
                service_id:service_id,
                room:room,
                date_from:date_from,
                date_to:date_to,
                note:note,
                series_def:series_def,
                series:series,
                public:xpublic,
                interval:interval
            }
        );
    },

     /*
		GET 	/api/events/reactivate/{slug}
	*/
    putReactivateEvent: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/events/reactivate/' + slug);
    },

    /*
		DELETE /api/events/{slug}
	*/
    deleteEvent: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/events/' + slug );
    },

    /*
		DELETE /api/events/
	*/
    deleteEvents: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/events' ,{
            params:{
                slug:slug,
        }});
    },
    
    /*
		DELETE /api/events/{slug}/force
	*/
    forceDeleteEvent: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/events/' + slug + '/force' );
	}
}