import store from './store';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from './pages/Layout'
import PortalLayout from './pages/PortalLayout'
import Logowanie from './pages/Logowanie'
import ZmianaHasla from './pages/ZmianaHasla'

import PortalPulpit from './pages/PortalPulpit'
import PortalWyniki from './pages/PortalWyniki'
import PortalRejestracja from './pages/PortalRejestracja'

import Pulpit from './pages/Pulpit'

import Klinika from './pages/Klinika'
import Kliniki from './pages/Kliniki'
import Uzytkownicy from './pages/Uzytkownicy'
import Uzytkownik from './pages/Uzytkownik'
import Role from './pages/Role'
import Rola from './pages/Rola'
import Dzialania from './pages/Dzialania'
import Dzialanie from './pages/Dzialanie'
import Diagnostyka from './pages/Diagnostyka'
import Diagnostyki from './pages/Diagnostyki'
import Ulotka from './pages/Ulotka'
import Ulotki from './pages/Ulotki'
import Harmonogramy from './pages/Harmonogramy'
import Harmonogram from './pages/Harmonogram'

import Wizyta from './pages/Wizyta'
import Realizacja from './pages/Realizacja'

import Pacjent from './pages/Pacjent'
import Pacjenci from './pages/Pacjenci'

import Rozliczenia from './pages/Rozliczenia'
import Billing from './pages/Billing'

import Empty from './pages/Empty'

import _isEmpty from "lodash/isEmpty"

import MenuPulpit from './components/MenuPulpit'
import MenuPacjent from './components/MenuPacjent'
import MenuUstawienia from './components/MenuUstawienia'

Vue.use(VueRouter)

function checkModules(userPermissions,modules){
  var sum = 0
  modules.forEach(m => {
    sum += userPermissions[m];
  });
  return sum >=5;
}

function requireAuth (to, from, next) {
  function proceed () {
    if ( store.getters.getUserLoadStatus() == 2 ) {

      if(!_isEmpty(store.getters.getUser)){//zalogowany
        
        if(!store.getters.getUser.oneTimePassword){//hasło jednorazowe

          //sprawdzanie uprawnien
          if(to.meta.modules.length === 0)next();
          else if(checkModules(store.getters.getUser.permissions,to.meta.modules))next();
          else next('/pulpit');

        }else{
          next('/haslo');
        }

      }else{
        next('/logowanie');
      }
    }
  }

  

  if ( store.getters.getUserLoadStatus() != 2 ) {
    store.dispatch( 'loadUser' );
    store.watch( store.getters.getUserLoadStatus, function(){
          if( store.getters.getUserLoadStatus() == 2 ){
              proceed();
          }
    });
  } else {
    proceed()
  }
}

function requireAuthOneTime (to, from, next) {
  function proceed () {
    
    if ( store.getters.getUserLoadStatus() == 2 ) {
      if( !_isEmpty(store.getters.getUser) ){//zalogowany

           //sprawdzanie uprawnien
           if(to.meta.modules.length === 0)next();
           else if( checkModules(store.getters.getUser.permissions,to.meta.modules) )next();
           else next('/pulpit');
  
      }else{
        next('/logowanie');
      }
    }
  }

  if ( store.getters.getUserLoadStatus() != 2 ) {
    store.dispatch( 'loadUser' );
    store.watch( store.getters.getUserLoadStatus, function(){
          if( store.getters.getUserLoadStatus() == 2 ){
              proceed();
          }
    });
  } else {
    proceed()
  }
}

const routes = [
  
  { 
    path: '/portal',
    redirect: { name: 'portal.pulpit' },
    name: 'portal.layout',
    component:PortalLayout,
    children:[
        {
          path: '/portal/pulpit', name: 'portal.pulpit', component: PortalPulpit
        },
        {
          path: '/portal/wyniki', name: 'portal.wyniki', component: PortalWyniki,
        },
        {
          path: '/portal/rejestracja', name: 'portal.rejestracja', component: PortalRejestracja,
        }
    ]
  },
  {
    path: '/',
    redirect: { name: 'pulpit' },
    name: 'Layout',
    component:Layout,
    children:[
      {
        path: '/logowanie', name: 'logowanie', component: Logowanie
      },
      //{path: '/jednorazowe', name: 'jednorazowe', component: PierwszaZmianaHasla, beforeEnter: requireAuthOneTime, meta: { module: ''}},
      {
        path: '/haslo',
        name: 'haslo',
        component: ZmianaHasla,
        beforeEnter: requireAuthOneTime,
        meta: {
          module: '',
          modules:[]
        }
      },
      {
        path: '/pulpit',
        name: 'pulpit',
        components: {
          default: Pulpit,
          menu:MenuPulpit
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'clinic',
          modules:['clinic']
        }
      },
      {
        path: '/ustawienia/klinika/:id?',
        name: 'ustawienia.klinika',
        params:true,
        components: {
          default: Klinika,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['clinics']
        }
      },
      {
        path: '/ustawienia/kliniki/powrot',
        name: 'ustawienia.kliniki.powrot',
        components: {
          default: Kliniki,
          menu: MenuUstawienia
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          modules:['clinics']
        }
      },
      {
        path: '/ustawienia/kliniki',
        name: 'ustawienia.kliniki',
        components: {
          default: Kliniki,
          menu: MenuUstawienia
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          modules:['clinics']
        }
      },



      {
        path: '/ustawienia/diagnostyka/:id?',
        name: 'ustawienia.diagnostyka',
        params:true,
        components: {
          default: Diagnostyka,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['service']
        }
      },
      {
        path: '/ustawienia/diagnostyki/powrot',
        name: 'ustawienia.diagnostyki.powrot',
        components: {
          default: Diagnostyki,
          menu: MenuUstawienia
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          modules:['service']
        }
      },
      {
        path: '/ustawienia/diagnostyki',
        name: 'ustawienia.diagnostyki',
        components: {
          default: Diagnostyki,
          menu: MenuUstawienia
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          modules:['service']
        }
      },
      {
        path: 'harmonogramy/powrot',
        name: 'harmonogramy.powrot',
        components: {
          default: Harmonogramy,
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          modules:['event']
        }
      },
      {
        path: 'harmonogramy',
        name: 'harmonogramy',
        components: {
          default: Harmonogramy,
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          modules:['event']
        }
      },
      {
        path: 'harmonogram/:id?',
        name: 'harmonogram',
        params:true,
        components: {
          default: Harmonogram,
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['event']
        }
      },
      {
        path: 'dzialaniePacjenta',
        name: 'dzialaniePacjenta',
        props: {default:{loadPatient:true}},
        components: {
          default: Wizyta,
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['clinic']
        }
      },
      {
        path: 'dzialanie/:id?',
        name: 'dzialanie',
        props: {default:{loadPatient:false}},
        params:true,
        components: {
          default: Wizyta,
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['clinic']
        }
      },
      {
        path: 'realizacja/:id?',
        name: 'realizacja',
        params:true,
        components: {
          default: Realizacja,
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['clinic']
        }
      },
      {
        path: 'pacjenci/powrot',
        name: 'pacjenci.powrot',
        components: {
          default: Pacjenci,
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          modules:['patient']
        }
      },
      {
        path: 'pacjenci',
        name: 'pacjenci',
        components: {
          default: Pacjenci,
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          modules:['patient']
        }
      },
      {
        path: 'pacjent/:id?',
        name: 'pacjent',
        params:true,
        components: {
          default: Pacjent,
          menu:MenuPacjent
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['patient']
        }
      },
      {
        path: '/ustawienia/dzialanie/:id?',
        name: 'ustawienia.dzialanie',
        params:true,
        components: {
          default: Dzialanie,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['category']
        }
      },
      {
        path: '/ustawienia/dzialania/powrot',
        name: 'ustawienia.dzialania.powrot',
        components: {
          default: Dzialania,
          menu: MenuUstawienia
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          modules:['category']
        }
      },
      {
        path: '/ustawienia/dzialania',
        name: 'ustawienia.dzialania',
        components: {
          default: Dzialania,
          menu: MenuUstawienia
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          modules:['category']
        }
      },
      {
        path: '/ustawienia/rola/:id?',
        name: 'ustawienia.rola',
        params:true,
        components: {
          default: Rola,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'setting',
          modules:['role']
        }
      },
      {
        path: '/ustawienia/role/powrot',
        name: 'ustawienia.role.powrot',
        components: {
          default: Role,
          menu: MenuUstawienia
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          module: 'setting',
          modules:['role']
        }
      },
      {
        path: '/ustawienia/role',
        name: 'ustawienia.role',
        components: {
          default: Role,
          menu: MenuUstawienia
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          module: 'setting',
          modules:['role']
        }
      },
      {
        path: '/ustawienia/uzytkownik/:id?',
        name: 'ustawienia.uzytkownik',
        params:true,
        components: {
          default: Uzytkownik,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'setting',
          modules:['user']
        }
      },
      {
        path: '/ustawienia/uzytkownicy',
        name: 'ustawienia.uzytkownicy',
        components: {
          default: Uzytkownicy,
          menu: MenuUstawienia
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          module: 'setting',
          modules:['user']
        }
      },
      {
        path: '/ustawienia/uzytkownicy/powrot',
        name: 'ustawienia.uzytkownicy.powrot',
        components: {
          default: Uzytkownicy,
          menu: MenuUstawienia
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          module: 'setting',
          modules:['user']
        }
      },
      {
        path: '/ustawienia/ulotka/:id?',
        name: 'ustawienia.ulotka',
        params:true,
        components: {
          default: Ulotka,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['leaflet']
        }
      },
      {
        path: '/ustawienia/ulotki/powrot',
        name: 'ustawienia.ulotki.powrot',
        components: {
          default: Ulotki,
          menu: MenuUstawienia
        },
        props: {default:{reload:false}},
        beforeEnter: requireAuth,
        meta: {
          modules:['leaflet']
        }
      },
      {
        path: '/ustawienia/ulotki',
        name: 'ustawienia.ulotki',
        components: {
          default: Ulotki,
          menu: MenuUstawienia
        },
        props: {default:{reload:true}},
        beforeEnter: requireAuth,
        meta: {
          modules:['leaflet']
        }
      },
      {
        path: '/rozliczenia/:patient_id?',
        name: 'rozliczenia',
        params:true,
        components: {
          default: Rozliczenia,
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['payments']
        }
      },
      {
        path: '/billing',
        name: 'billing',
        components: {
          default: Billing,
        },
        beforeEnter: requireAuth,
        meta: {
          modules:['billing']
        }
      },
    ]
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
