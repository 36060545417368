import ClinicAPI from "../api/clinic";

export const clinics = {
    state: {
        clinics: [],
        clinicOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:15,
            itemAdded:false,
            itemSearched:false
        },
        clinicsLoadStatus: 0,
        clinicAddStatus: 0,
        clinicAddText: "",

        clinicEdit: {
            id: undefined,
            lp: undefined,
            name: undefined,
            type:undefined,
            post_code:undefined,
            city:undefined,
            address:undefined,
            house:undefined,
            phone:undefined,
            email:undefined,
            logo:undefined,
            nip:undefined,
            regon:undefined,
            nfz:undefined,
            erecepta_oid:undefined,
            erecepta_rpwdl:undefined,
            referrals_email:undefined,
            contacts:[]
        },
        clinicEditDefault: {
            id: undefined,
            lp: undefined,
            name: undefined,
            type:undefined,
            post_code:undefined,
            city:undefined,
            address:undefined,
            house:undefined,
            phone:undefined,
            email:undefined,
            logo:undefined,
            nip:undefined,
            regon:undefined,
            nfz:undefined,
            erecepta_oid:undefined,
            erecepta_rpwdl:undefined,
            referrals_email:undefined,
            contacts:[]
        },
        clinicEditLoadStatus: 0,
        clinicEditStatus: 0,
        clinicEditText: "",

        clinicDeleteStatus: 0,
        clinicDeleteText: "",

        clinicsLookup:[],
        clinicsLookupLoadStatus:0,
    },

    actions: {
        loadClinics({ state, commit, dispatch }) {
            commit("setClinicsLoadStatus", 1);
                ClinicAPI.getClinics({
                    offset:0,
                    itemsPerPage:state.clinicOptions.itemsPerPage, 
                    orderBy:state.clinicOptions.orderBy,
                    orderDirection:state.clinicOptions.orderDirection,
                    showDeleted:state.clinicOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setClinicLineCount", response.data.lineCount);
                    commit("setClinics", response.data.clinics);
                    dispatch("applyIndexOnClinics");
                    commit("setClinicsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinics", []);
                    commit("setClinicsLoadStatus", 3);
                });
         
        },
        loadMoreClinics({ state, commit, dispatch }) {
            commit("setClinicsLoadStatus", 1);
                ClinicAPI.getClinics({
                    offset:state.clinics.length,
                    itemsPerPage:state.clinicOptions.itemsPerPage, 
                    orderBy:state.clinicOptions.orderBy,
                    orderDirection:state.clinicOptions.orderDirection,
                    showDeleted:state.clinicOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setClinicLineCount", response.data.lineCount);
                    commit("setClinics", _.concat(state.clinics, response.data.clinics));
                    dispatch("applyIndexOnClinics");
                    commit("setClinicsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinics", []);
                    commit("setClinicsLoadStatus", 3);
                });
         
        },
        searchClinics({ state, commit, dispatch },data) {
            commit("setClinicsLoadStatus", 1);
                ClinicAPI.searchClinics({
                    showDeleted:state.clinicOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setClinicSearchedTrue");
                    commit("setClinicLineCount", response.data.lineCount);
                    commit("setClinics", response.data.clinics);
                    dispatch("applyIndexOnClinics");
                    commit("setClinicsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinics", []);
                    commit("setClinicsLoadStatus", 3);
                });
         
        },
        searchClinicsLookup({ state, commit, dispatch },data) {
            commit("setClinicsLookupLoadStatus", 1);
                ClinicAPI.searchClinicsLookup({
                    q:data.q,
                    type:data.type
                })
                .then(function(response) {
                    commit("setClinicsLookup", response.data);
                    commit("setClinicsLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinicsLookup", []);
                    commit("setClinicsLookupLoadStatus", 3);
                });
         
        },
        searchClinicsByServiceLookup({ state, commit, dispatch },data) {
            commit("setClinicsLookupLoadStatus", 1);
                ClinicAPI.searchClinicsByServiceLookup({
                    service_id:data.service_id
                })
                .then(function(response) {
                    commit("setClinicsLookup", response.data);
                    commit("setClinicsLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinicsLookup", []);
                    commit("setClinicsLookupLoadStatus", 3);
                });
         
        },
        setClinicOptions({ state, commit, dispatch },data){
            commit("setClinicOptions",data.options);
            dispatch("loadClinics");
        },
        applyIndexOnClinics({ commit, state, dispatch }) {
            let localClinics = state.clinics;

            var i = 1;
            localClinics.forEach(clinic => {
                clinic.lp = i;
                i++;
            });

            commit("setClinics",localClinics);
        },
        addClinic({ commit, state, dispatch }, data) {
            commit("setClinicAddStatus", 1);
            commit("setClinicAddText", "");

            ClinicAPI.postAddNewClinic(
                data.name,
                data.type,
                data.post_code,
                data.city,
                data.address,
                data.house,
                data.phone,
                data.email,
                data.logo,
                data.nip,
                data.regon,
                data.nfz,
                data.erecepta_oid,
                data.erecepta_rpwdl,
                data.referrals_email,
                data.contacts
            )
                .then(function(response) {
                    commit("setClinicAddStatus", 2);
                    commit("setAddedClinic",response.data);
                    commit("setClinicLineCount", state.clinicOptions.lineCount + 1);
                    dispatch("applyIndexOnClinics");
                })
                .catch(function(error) {
                    commit("setClinicAddStatus", 3);
                    commit("setClinicAddText", error.response.data.errors);
                });
        },

        loadClinicEdit({ commit }, data) {
            commit("setClinicEditLoadStatus", 1);
            commit("setClinicEditStatus", 0);
            commit("setClinicEditText", "");

            ClinicAPI.getClinicEdit(data.slug)
                .then(function(response) {
                    commit("setClinicEdit", response.data);
                    commit("setClinicEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinicEdit", {});
                    commit("setClinicEditLoadStatus", 3);
                });
        },
        loadPermissionList({ commit }, data) {
            commit("setClinicEditLoadStatus", 1);
            commit("setClinicEditStatus", 0);
            commit("setClinicEditText", "");

            ClinicAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setPermissionListEdit", response.data);
                    commit("setClinicEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinicEdit", {});
                    commit("setClinicEditLoadStatus", 3);
                });
        },
        loadClinicEditDefault({ commit }) {
            commit("setClinicEditDefault");
        },
        loadClinicAddDefault({ commit }) {
            commit("setClinicAddStatus", 0);
            commit("setClinicAddText", "");
        },
        editClinic({ commit, state, dispatch }, data) {
            commit("setClinicEditStatus", 1);

            ClinicAPI.putEditClinic(
                data.slug,
                data.name,
                data.type,
                data.post_code,
                data.city,
                data.address,
                data.house,
                data.phone,
                data.email,
                data.logo,
                data.nip,
                data.regon,
                data.nfz,
                data.erecepta_oid,
                data.erecepta_rpwdl,
                data.referrals_email,
                data.contacts
            )
                .then(function(response) {
           
                    commit("setClinicEditStatus", 2);
                    commit("setEditedClinic",response.data)
                    dispatch("applyIndexOnClinics");
                })
                .catch(function(error) {
                    commit("setClinicEditStatus", 3);
                    commit("setClinicEditText", error.response.data.errors);
                });
        },
        editClinicReactivate({ commit, state, dispatch }, data) {
            commit("setClinicEditStatus", 1);
  
            ClinicAPI.putReactivateClinic(data.slug)
                .then(function(response) {
                    commit("setClinicEditStatus", 2);
                    commit("setEditedClinic",response.data)
                    dispatch("applyIndexOnClinics");
                })
                .catch(function(error) {
                    commit("setClinicEditStatus", 3);
                });
        },
        deleteClinic({ commit, state, dispatch }, data) {
            commit("setClinicDeleteStatus", 1);

            ClinicAPI.deleteClinic(data.slug)
                .then(function(response) {
                    commit("setClinicDeleteStatus", 2);
                    if(state.clinicOptions.showDeleted){
                        commit("setEditedClinic",response.data)
                    }else{
                        commit("setClinicLineCount", state.clinicOptions.lineCount - 1);
                        commit("setDeletedClinic",response.data)
                    }
                    dispatch("applyIndexOnClinics");
                })
                .catch(function() {
                    commit("setClinicDeleteStatus", 3);
                });
        },
        forceDeleteClinic({ commit, state, dispatch }, data) {
            commit("setClinicDeleteStatus", 1);

            ClinicAPI.forceDeleteClinic(data.slug)
                .then(function(response) {
                    commit("setClinicDeleteStatus", 2);
                    commit("setClinicLineCount", state.clinicOptions.lineCount - 1);
                    commit("setDeletedClinic",response.data)
                    dispatch("applyIndexOnClinics");
                })
                .catch(function() {
                    commit("setClinicDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setClinicOptions(state,options){
            state.clinicOptions = options;
        },
        setClinicsLoadStatus(state, status) {
            state.clinicsLoadStatus = status;
        },
        setClinics(state, clinics) {
            state.clinics = clinics;
        },
        setClinicsLookup(state, clinics) {
            state.clinicsLookup = clinics;
        },
        setClinicsLookupLoadStatus(state, status) {
            state.clinicsLookupLoadStatus = status;
        },
        setClinicLineCount(state, lineCount) {
            state.clinicOptions.lineCount = lineCount;
        },
        setClinicAddStatus(state, status) {
            state.clinicAddStatus = status;
        },
        setClinicAddText(state, text) {
            state.clinicAddText = text;
        },
        setClinicEdit(state, clinic) {
            state.clinicEdit = clinic;
        },
        setPermissionListEdit(state, list) {
            state.clinicEdit.computed_permissions = list;
        },
        setEditedClinic(state, clinic) {
            var lp = _.find(state.clinics,{'id':clinic.id}).lp;
            var index = _.findIndex(state.clinics,{'id':clinic.id});
       
            state.clinics[index] = clinic;
            state.clinics[index].lp = lp;
        },
        setAddedClinic(state, clinic) {
            state.clinicOptions.itemAdded = true;
            state.clinics.push(clinic);
        },
        setClinicSearchedTrue(state) {
            state.clinicOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.clinicOptions.itemAdded = false;
            state.clinicOptions.itemSearched = false;
        },
        setDeletedClinic(state, clinic) {
            var index = _.findIndex(state.clinics,{'id':clinic.id});
            state.clinics.splice(index,1);
        },
        setClinicEditDefault(state) {
            state.clinicEdit = { ...state.clinicEditDefault };
        },
        setClinicEditStatus(state, status) {
            state.clinicEditStatus = status;
        },
        setClinicEditText(state, text) {
            state.clinicEditText = text;
        },
        setClinicEditLoadStatus(state, status) {
            state.clinicEditLoadStatus = status;
        },
        setClinicDeleteStatus(state, status) {
            state.clinicDeleteStatus = status;
        },
        setClinicDeletedText(state, text) {
            state.clinicDeleteText = text;
        }
    },

    getters: {
        getClinicOptions(state){
            return state.clinicOptions;
        },
        getClinicsLoadStatus(state) {
            return state.clinicsLoadStatus;
        },
        getClinics(state) {
            return state.clinics;
        },
        getClinicAddStatus(state) {
            return function(){
                return state.clinicAddStatus;
            } 
        },
        getClinicAddText(state) {
            return state.clinicAddText;
        },
        getClinicEdit(state) {
            return state.clinicEdit;
        },
        getClinicEditStatus(state) {
          return function(){
            return state.clinicEditStatus;
          }
        },
        getClinicEditing(state) {
            return state.clinicEdit.id > 0;
        },
        getClinicEditText(state) {
            return state.clinicEditText;
        },
        getClinicEditLoadStatus(state) {
            return state.clinicEditLoadStatus;
            
        },
        getClinicDeleteStatus(state) {
            return function(){
                return state.clinicDeleteStatus;
            }
        },
        getClinicDeleteText(state) {
            return state.clinicDeleteText;
        },
        getClinicsLookupLoadStatus(state) {
            return state.clinicsLookupLoadStatus;
        },
        getClinicsLookup(state) {
            return state.clinicsLookup;
        },
    }
};
