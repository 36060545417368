<template>
      <v-col>
          <v-card style="background-color:#F5F5F5;" :loading="serviceAddStatus===1 || serviceEditLoadStatus===1 || serviceEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Anuluj</v-btn>
              <v-btn color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row>
            <v-col class="col-6 pl-5">
                <v-card>
                  <v-toolbar dense flat><v-toolbar-title>Dane podstawowe</v-toolbar-title></v-toolbar>
                  <v-card-text>
                          <v-row>                   
                            <v-col cols="12">
                              <v-text-field dense v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa usługi"></v-text-field>                            
                            </v-col>
                            <v-col cols="6">
                              <v-text-field dense v-model="editedItem.code" :error-messages="codeErrors" required @input="$v.editedItem.code.$touch()"  @blur="$v.editedItem.code.$touch()" label="Kod"></v-text-field>                            
                            </v-col>
                            <v-col cols="6">
                              <v-select dense  v-model="editedItem.category_id" :items="lookups.categories" :error-messages="category_idErrors" required @input="$v.editedItem.category_id.$touch()"  @blur="$v.editedItem.category_id.$touch()" label="Działanie"></v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select clearable dense v-model="editedItem.referral_type_id" :items="lookups.referral_type"  label="Skierowanie"></v-select>
                            </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>      
            </v-col>
            <v-col class="col-6 pl-0 pr-5">
                    
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 pl-5 pr-5">
              <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Informacje dodatkowe</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pt-0">
                          <v-row>    
                                <v-col dense cols="12">
                                  <v-textarea hide-details solo v-model="editedItem.info" rows="5" />
                                </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-5">
                  <v-toolbar dense flat>
                    <v-toolbar-title>Informacje dla pacjenta</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pt-0">
                          <v-row>    
                                <v-col dense cols="12">
                                  <v-textarea hide-details solo v-model="editedItem.patient_info" rows="5" />
                                </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>    
            </v-col>
          </v-row>
        </v-card>
      </v-col>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'

  export default {
    data: () => ({

    }),
    validations: {
      editedItem:{
        name: {
          required,
        },
        code: {
          required,
        },
        category_id: {
          required,
        },
      }
    },
    watch: {
      serviceEditStatus(val){
        if(val===2){
          this.close();
        }
      },
      serviceAddStatus(val){
        if(val===2){
          this.close();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params);
      },
      editedItem(){
        return this.$store.getters.getServiceEdit;
      },
      serviceEditLoadStatus(){
        return this.$store.getters.getServiceEditLoadStatus
      },
      serviceEditStatus(){
        return this.$store.getters.getServiceEditStatus();
      },
      serviceEditText(){
        return this.$store.getters.getServiceEditText
      },
      serviceAddStatus(){
        return this.$store.getters.getServiceAddStatus();
      },
      serviceAddText(){
        return this.$store.getters.getServiceAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowa diagnostyka' : 'Edycja diagnostyki'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa diagnostyki jest wymagana')
        return errors
      },
      codeErrors () {
        const errors = []
        if (!this.$v.editedItem.code.$dirty) return errors
        !this.$v.editedItem.code.required && errors.push('Kod diagnostyki jest wymagana')
        return errors
      },
      category_idErrors () {
        const errors = []
        if (!this.$v.editedItem.category_id.$dirty) return errors
        !this.$v.editedItem.category_id.required && errors.push('Kategoria diagnostyki jest wymagana')
        return errors
      },
    },
    mounted(){
      if(this.itemEditing){
        this.$store.dispatch( 'loadServiceEdit', {slug:  this.$route.params.id} );
      }else{

      }
    },
    methods: {
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadServiceEditDefault'); 
        this.$store.dispatch( 'loadServiceAddDefault');
        this.$router.push({ name: 'ustawienia.diagnostyki.powrot'})
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editService', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                code:  this.editedItem.code,
                category_id: this.editedItem.category_id,
                referral_type_id: this.editedItem.referral_type_id,
                info: this.editedItem.info,
                patient_info:this.editedItem.patient_info
              });
            } else {//new item
              this.$store.dispatch( 'addService', {
                  name:  this.editedItem.name,
                  code:  this.editedItem.code,
                  category_id: this.editedItem.category_id,
                  referral_type_id: this.editedItem.referral_type_id,
                  info: this.editedItem.info,
                  patient_info:this.editedItem.patient_info
              });

            }
        }
      },
    },
  }
</script>

