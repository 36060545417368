import AppointmentAPI from "../api/appointment";

export const flows = {
    state: {
        flows: [],
        flowsAttachmentFileContent:{
            file_name:null,
            file_content:null,
            file_type:null,
            file_path:null
        },
        flowOptions:{
            itemAdded:false,
            itemSearched:false,
            selectedPatient:undefined,
            selectedAppointment:undefined,
            selectedCategories:[],
            selectedAttachments:[],
            selectedStatuses:[1,5,10,15],
            fromDate:undefined,
            toDate:undefined,
            topPixels:0,
            expandAll:false
        },
        flowsLoadStatus: 0,
        flowsAttachmentFileContentLoadStatus: 0,
        //flowsAttachmentFileContentSigningStatus: 0,
        
    },

    actions: {
        loadFlows({ state, commit, dispatch }) {
            commit("setFlowsLoadStatus", 1);
                AppointmentAPI.getFlows({
                    selectedPatient:state.flowOptions.selectedPatient,
                    selectedAppointment:state.flowOptions.selectedAppointment,
                    selectedCategories:state.flowOptions.selectedCategories,
                    selectedAttachments:state.flowOptions.selectedAttachments,
                    selectedStatuses:state.flowOptions.selectedStatuses,
                    fromDate:state.flowOptions.fromDate,
                    toDate:state.flowOptions.toDate,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setFlows", response.data);
                    commit("setFlowsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setFlows", []);
                    commit("setFlowsLoadStatus", 3);
                });
         
        },
        loadAttachmentFileContent({ state, commit, dispatch },data) {
            commit("setFlowsAttachmentFileContentLoadStatus", 1);
                AppointmentAPI.getAttachmentFileContent(data.slug,data.file_path)
                .then(function(response) {
                    commit("setFlowsAttachmentFileContent", response.data);
                    commit("setFlowsAttachmentFileContentLoadStatus", 2);
                })
                .catch(function() {
                    commit("setFlowsAttachmentFileContent", {file_name:null,
                        file_content:null,
                        file_type:null,
                        file_path:null});
                    commit("setFlowsAttachmentFileContentLoadStatus", 3);
                });
         
        },
        signZLAAttachmentFileContent({ state, commit, dispatch },data) {
            commit("setFlowsAttachmentFileContentLoadStatus", 1);
                AppointmentAPI.getAttachmentSignZLAFileContent(data.slug,data.file_path,data.password)
                .then(function(response) {
                    commit("setFlowsAttachmentFileContent", response.data.file);
                    commit("setEditedFlow", response.data.attachment);
                    commit("setFlowsAttachmentFileContentLoadStatus", 4);
                })
                .catch(function(error) {
                    if(error.response.data.error==='invalid_credentials'){//nieporawidłowe hasło
                        commit("setFlowsAttachmentFileContentLoadStatus", 5);
                    }else{
                        commit("setFlowsAttachmentFileContentLoadStatus", 6);
                    }
                });
         
        },
        unsignZLAAttachmentFileContent({ state, commit, dispatch },data) {
            commit("setFlowsAttachmentFileContentLoadStatus", 1);
                AppointmentAPI.getAttachmentUnsignZLAFileContent(data.slug,data.file_path,data.password)
                .then(function(response) {
                    commit("setFlowsAttachmentFileContent", response.data.file);
                    commit("setEditedFlow", response.data.attachment);
                    commit("setFlowsAttachmentFileContentLoadStatus", 4);
                })
                .catch(function() {
                    commit("setFlowsAttachmentFileContentLoadStatus", 5);
                });
         
        },
        editFileContentDesc({ state, commit, dispatch },data) {
            commit("setFlowsAttachmentFileContentLoadStatus", 1);
                AppointmentAPI.putEditFileContentDesc(data.slug,data.file_path,data.desc)
                .then(function(response) {
                    commit("setFlowsAttachmentFileContent", response.data.file);
                    commit("setEditedFlow", response.data.attachment);
                    commit("setFlowsAttachmentFileContentLoadStatus", 7);
                })
                .catch(function(error) {
                  
                });
         
        },
        editDeleteFile({ state, commit, dispatch },data) {
            commit("setAttachmentEditStatus", 1);
                AppointmentAPI.putDeleteFile(data.slug,data.file_path)
                .then(function(response) {
                    commit("setEditedFlow", response.data);
                    commit("setAttachmentEditStatus", 2);
                })
                .catch(function(error) {
                    commit("setAttachmentEditStatus", 3);
                });
         
        },
        searchFlows({ state, commit, dispatch },data) {
            commit("setFlowsLoadStatus", 1);
                AppointmentAPI.searchFlows({
                    selectedPatient:state.flowOptions.selectedPatient,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setFlowSearchedTrue");
                    //commit("setFlowLineCount", response.data.lineCount);
                    commit("setFlows", response.data);
                    commit("setFlowsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setFlows", []);
                    commit("setFlowsLoadStatus", 3);
                });
         
        },
        setFlowOptions({ state, commit, dispatch },data){
            commit("setFlowOptions",data.options);
            dispatch("loadFlows");
        },
    },
    mutations: {
        setFlowsAttachmentFileContent(state,data){
            state.flowsAttachmentFileContent = data;
        },
        setFlowOptions(state,options){
            state.flowOptions = options;
        },
        setFlowsLoadStatus(state, status) {
            state.flowsLoadStatus = status;
        },
        setFlowsAttachmentFileContentLoadStatus(state, status) {
            state.flowsAttachmentFileContentLoadStatus = status;
        },
        setFlows(state, flows) {
            state.flows = flows;
            state.flows.forEach(app => {
                app.attachments.forEach(att => {
                    att.expanded = state.flowOptions.expandAll;
                });
            });
        },
        setFlowLineCount(state, lineCount) {
            state.flowOptions.lineCount = lineCount;
        },
        setEditedFlow(state, attachment) {
            var appIndex = _.findIndex(state.flows,{'id':attachment.appointment_id});
            var attIndex = _.findIndex(state.flows[appIndex].attachments,{'id':attachment.id});
            var att = attachment
            att.expanded = true
            state.flows[appIndex].attachments[attIndex] = att;
        },
        setAddedFlow(state, attachment) {
            state.flowOptions.itemAdded = true;
            var appIndex = _.findIndex(state.flows,{'id':attachment.appointment_id});
            state.flows[appIndex].attachments.push(attachment);
        },
        setFlowSearchedTrue(state) {
            state.flowOptions.itemSearched = true;
        },
        setFlowExpandAll(state,value) {
            state.flows.forEach(app => {
                app.attachments.forEach(att => {
                  att.expanded = value;
                });
            });
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.flowOptions.itemAdded = false;
            state.flowOptions.itemSearched = false;
        },
        setDeletedFlow(state, attachment) {
            var appIndex = _.findIndex(state.flows,{'id':attachment.appointment_id});
            var attIndex = _.findIndex(state.flows[appIndex].attachments,{'id':attachment.id});
            state.flows[appIndex].attachments[attIndex] = attachment;

            state.flows[appIndex].attachments.splice(attIndex,1);
        },
        setDeletedFlows(state, flows) {
            flows.forEach(e => {
                var index = _.findIndex(state.flows,{'id':e.id});
                state.flows.splice(index,1);      
            });
        },
    },

    getters: {
        getFlowOptions(state){
            return state.flowOptions;
        },
        getFlowsLoadStatus(state) {
            return function(){
                return state.flowsLoadStatus;
            }
        },
        getFlowsAttachmentFileContentLoadStatus(state){
            return function(){
                return state.flowsAttachmentFileContentLoadStatus;
            }
        },
        getFlows(state) {
            return state.flows;
        },
        getFlowsAttachmentFileContent(state){
            return state.flowsAttachmentFileContent;
        }
    }
};
