<template>
                  <v-card  :loading="patientAddStatus===1 || patientEditLoadStatus===1 || patientEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                      <span class="headline">{{ formTitle }}</span>
                      <v-spacer />
                      <v-chip label color="recepta">Dane wymagane do e-recepty</v-chip>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>  
                            <v-col :cols="itemEditing || patientEditing?3:6">
                              <v-text-field dense v-model="editedItem.name" label="Imię" background-color="recepta"></v-text-field>
                            </v-col>                 
                            <v-col :cols="itemEditing || patientEditing?4:6">
                              <v-text-field dense v-model="editedItem.surname"  label="Nazwisko" background-color="recepta"></v-text-field>                            
                            </v-col>
                            <v-col :cols="itemEditing || patientEditing?1:2" class="text-right pb-0 pt-1" align-self="start">
                              <country-code
                                                      :defaultDialCode="editedItem.phone_prefix"
                                                      @onSelect="onSelectPatient"
                                                      :preferredCountries="['pl', 'de', 'gb', 'cz', 'sk']"
                                ></country-code>
                            </v-col>
                            <v-col :cols="itemEditing || patientEditing?4:10">
                              <v-text-field :prefix="'+'+editedItem.phone_prefix" dense v-model="editedItem.phone" :error-messages="phoneErrors"  @input="$v.editedItem.phone.$touch()" @blur="$v.editedItem.phone.$touch()" label="Numer telefonu"></v-text-field>
                            </v-col>
                            <v-col :cols="itemEditing || patientEditing?3:6" >
                              <v-text-field dense hide-details="auto" v-model="editedItem.pesel" :error-messages="peselErrors" @input="peselChanged"  background-color="recepta"  @blur="$v.editedItem.pesel.$touch()" label="PESEL"></v-text-field>
                            </v-col>
                            <v-col :cols="itemEditing?3:6">
                              <v-text-field
                                              v-model="editedItem.birth_date"
                                              label="Data urodzenia"
                                              dense
                                              clearable
                                              :error-messages="birth_dateErrors"
                                              @blur="birth_dateChanged"
                                              hint="RRRR-MM-DD"
                                              background-color="recepta"
                              >
                               <template v-slot:append-outer>
                                 <v-menu
                                          v-model="birthDayMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="150px"
                                        >
                                        <template v-slot:activator="{ on }">
                                          <v-icon :disabled="!$v.editedItem.birth_date.validDate" v-on="on" class="mb-1">mdi-calendar</v-icon>
                                        </template>
                                        <v-date-picker no-title first-day-of-week="1" v-model="birthDate" @input="birthDayMenu = false;birth_dateChanged()"></v-date-picker>
                                </v-menu>
                               </template>
                              </v-text-field>
                            </v-col>
                            <v-col v-if="itemEditing || patientEditing" :cols="itemEditing || patientEditing?2:0">
                              <v-text-field dense :readonly="editedItem.birth_date!==null" v-model="editedItem.age" :error-messages="ageErrors" @input="$v.editedItem.age.$touch()"   @blur="$v.editedItem.age.$touch()" label="Wiek"></v-text-field>
                            </v-col>
                            
                            <v-col :cols="itemEditing || patientEditing?4:12">
                              <v-text-field dense hide-details="auto" v-model="editedItem.email" :error-messages="emailErrors" @input="$v.editedItem.email.$touch()"  @blur="$v.editedItem.email.$touch()"  label="Adres e-mail"></v-text-field>
                            </v-col>
                            
                            
                            <v-col cols="12" v-if="!itemEditing &&  !patientEditing">
                               <v-switch v-model="detailes" label="Dodatkowe informacje" dense />
                            </v-col>
                            <v-col :cols="2" v-if="itemEditing || patientEditing">
                              <v-text-field dense v-if="itemEditing" v-model="editedItem.post_code" label="Kod pocztowy" background-color="recepta"></v-text-field>
                            </v-col>
                            <v-col :cols="itemEditing || patientEditing?3:12" v-if="detailes || itemEditing || patientEditing">
                              <v-text-field dense v-if="detailes || itemEditing" v-model="editedItem.city"  label="Miasto" background-color="recepta"></v-text-field>
                            </v-col>
                            <v-col cols="3" v-if="itemEditing || patientEditing">
                              <v-text-field dense v-if="itemEditing || patientEditing" v-model="editedItem.address"  label="Ulica" background-color="recepta"></v-text-field>
                            </v-col>
                            <v-col cols="2" v-if="itemEditing || patientEditing">
                              <v-text-field dense v-if="itemEditing || patientEditing" v-model="editedItem.house"  label="Numer domu" background-color="recepta"></v-text-field>
                            </v-col>
                             <v-col cols="2" v-if="itemEditing || patientEditing">
                              <v-text-field dense v-if="itemEditing || patientEditing" v-model="editedItem.apartment"  label="Numer mieszkania"></v-text-field>
                            </v-col>
                            <v-col :cols="itemEditing || patientEditing?4:12" v-if="detailes || itemEditing || patientEditing">
                              <recommendation-live-search dense v-if="detailes || itemEditing || patientEditing" label="Lekarz polecający" :selectedValue="editedItem.recommendation"  @valueSelected="editedItem.recommendation = $event" />
                            </v-col>
                            <v-col cols="4" v-if="itemEditing || patientEditing">
                               <v-text-field dense  v-if="itemEditing || patientEditing" v-model="editedItem.first_contact"  label="Pierwszy kontakt"></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="itemEditing || patientEditing">
                               <v-text-field dense v-if="itemEditing || patientEditing" v-model="editedItem.source"  label="Wiedza o Hifu"></v-text-field>
                            </v-col>
                            <v-col cols="3" v-if="itemEditing || patientEditing">
                               <v-select :items="lookups.currencies" dense v-if="itemEditing || patientEditing" v-model="editedItem.currency"  label="Waluta rozliczeniowa"></v-select>
                            </v-col>
                            <v-col cols="3" v-if="itemEditing || patientEditing">
                               <v-select :items="lookups.patient_sex" dense v-if="itemEditing || patientEditing" v-model="editedItem.sex"  label="Płeć" background-color="recepta"></v-select>
                            </v-col>
                            <v-col cols="3" v-if="itemEditing || patientEditing">
                               <v-select :items="lookups.patient_insurance" dense v-if="itemEditing || patientEditing" v-model="editedItem.insurance"  label="Ubezpieczenie" background-color="recepta"></v-select>
                            </v-col>
                            <v-col cols="3" v-if="itemEditing || patientEditing">
                               <v-text-field dense hide-details="auto" v-if="itemEditing || patientEditing" v-model="editedItem.gus" :error-messages="gusErrors"  @input="$v.editedItem.gus.$touch()" @blur="$v.editedItem.gus.$touch()" label="GUS TERC" background-color="recepta">
                                 <template v-slot:prepend-inner>
                                    <v-menu
                                      offset-y
                                      close-on-content-click
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          icon small
                                          @click="searchGUS"
                                          :disabled="!editedItem.city"
                                        >
                                          <v-icon>
                                            mdi-map-search
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list dense v-if="patientsGUSLookup.length===0 && patientsGUSLookupLoadStatus===2 ">
                                        <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title>Brak danych</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>
                                     </v-list>
                                     <v-list dense v-else-if="patientsGUSLookup.length===0 && patientsGUSLookupLoadStatus===1 ">
                                        <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title>Pobieranie danych</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>
                                     </v-list>
                                     <v-list dense v-else>
                                        <v-list-item v-for="(gus) in patientsGUSLookup" :key="gus.value" @click="editedItem.gus = gus.value;">
                                              <v-list-item-content @click="">
                                                <v-list-item-title>{{ gus.text }}</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>
                                     </v-list>
                                    </v-menu>
                                  </template>
                               </v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="itemEditing || patientEditing">
                              <v-data-table
                                  :key="componentKey"
                                  :headers="headers"
                                  :items="tableItems"
                                  class="elevation-1"
                                  hide-default-footer
                                  must-sort
                                  dense
                                >
                                  <template v-slot:top>
                                    <v-toolbar flat color="white">
                                      <v-toolbar-title>Kontakty</v-toolbar-title>
                                      <v-spacer></v-spacer>
                                      <v-dialog v-if="user.permissions['patient'] >= 10" v-model="dialogContact" max-width="500px">
                                          <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                                              <v-icon dark>mdi-plus</v-icon>
                                            </v-btn>
                                          </template>
                                          
                                          <v-card  :loading="contactAddStatus===1 || contactEditLoadStatus===1 || contactEditStatus===1">
                                          <v-card-title>
                                            <span class="headline">{{ itemContactEditing?'Edycja kontaktu':'Nowy kontakt' }}</span>
                                          </v-card-title>

                                          <v-card-text>
                                            <v-container>
                                              
                                                <v-row>                   
                                                  <v-col cols="6">
                                                    <v-text-field hide-details="auto" dense v-model="editedContactItem.name"  label="Imię"></v-text-field>                            
                                                  </v-col>
                                                  <v-col cols="6">
                                                    <v-text-field hide-details="auto" dense v-model="editedContactItem.surname"  label="Nazwisko"></v-text-field>                            
                                                  </v-col>
                                                  <v-col cols="6">
                                                    <v-select v-model="editedContactItem.type" :items="lookups.patient_contact_type" label="Rola" />
                                                  </v-col>
                                                  <v-col cols="6">
                                                    <v-checkbox
                                                      :disabled="itemContactEditing && itemWasMain"
                                                      v-model="editedContactItem.default"
                                                      label="Domyślny"
                                                    ></v-checkbox>
                                                  </v-col>
                                                  <v-col cols="2"  class="text-right" align-self="end">
                                                    <country-code
                                                      :defaultDialCode="editedContactItem.phone_prefix"
                                                      @onSelect="onSelectContact"
                                                      :preferredCountries="['pl', 'de', 'gb', 'cz', 'sk']"
                                                    ></country-code>
                                                    <!-- <v-text-field hide-details="auto"  v-model="editedContactItem.phone_prefix"></v-text-field>  -->
                                                  </v-col>
                                                  <v-col cols="10">
                                                    <v-text-field :prefix="'+'+editedContactItem.phone_prefix" hide-details="auto"  v-model="editedContactItem.phone" :error-messages="contactPhoneErrors" @input="$v.editedContactItem.phone.$touch()"  @blur="$v.editedContactItem.phone.$touch()" label="Telefon"></v-text-field> 
                                                  </v-col>
                                                  <v-col cols="12">
                                                    <v-text-field hide-details="auto"  v-model="editedContactItem.email" :error-messages="contactEmailErrors" @input="$v.editedContactItem.email.$touch()"  @blur="$v.editedContactItem.email.$touch()"  label="E-mail"></v-text-field> 
                                                  </v-col>
                                                </v-row>
                                            </v-container>
                                          </v-card-text>

                                          <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="gray" text @click="closeContact">Anuluj</v-btn>
                                            <v-btn color="secondary darken-1" text @click="handleSubmitContact" >Zapisz</v-btn>
                                          </v-card-actions>
                                        </v-card>
                                          
                                      </v-dialog>

                                    </v-toolbar>
                                  </template>
                                  <!-- <template v-slot:item.type="{ item }">
                                    <span v-if="item.type">{{_.find(lookups.patient_type,{'value':item.type}).text}}</span>
                                  </template> -->
                                   <template v-slot:item.phone="{ item }">
                                      {{item.phone_prefix=='48'?item.phone:('+'+item.phone_prefix+item.phone)}}
                                    </template>
                                  <template v-slot:item.actions="{ item }">

                                      <v-tooltip v-if="user.permissions['patient'] >= 10" right>
                                        <template v-slot:activator="{ on }">
                                          <v-btn v-on="on" @click="editItem(item)" icon><v-icon>mdi-pencil</v-icon></v-btn>
                                        </template>
                                          <span>Edytuj</span>
                                      </v-tooltip>

                                      <v-tooltip v-if="item.can_delete  && user.permissions['patient'] >= 10" right>
                                        <template v-slot:activator="{ on }">
                                          <v-btn v-on="on" @click="forceDeleteItem(item)" icon><v-icon color="error">mdi-delete-forever</v-icon></v-btn>
                                        </template>
                                        <span>Usuń na stałe</span>
                                      </v-tooltip>

                                      
                                  </template>

                                  <template v-slot:item.type="{ item }">
                                    {{_.find(lookups.patient_contact_type,{'value':item.type}).text}}
                                  </template>
                                  <template v-slot:item.default="{ item }">
                                    <v-simple-checkbox v-model="item.default" disabled></v-simple-checkbox>
                                  </template>

                                </v-data-table>
                            
                            </v-col>
                           
                          </v-row>
                          <v-row v-if="(patientAddStatus===3 && patientAddText=='pesel_exists') || patientEditStatus===3 && patientEditText=='pesel_exists'">
                                <v-col class="d-flex flex-column align-center">
                                      <v-alert dense outlined type="error">
                                          Jest już pacjent o takim numerze PESEL (kartoteka może być wyłączona)
                                      </v-alert>
                                </v-col>
                          </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="itemEditing || patientEditing" color="gray" text @click="close()">Zamknij</v-btn>
                      <v-btn v-else color="gray" text @click="close()">Anuluj</v-btn>

                      <v-btn v-if="itemEditing || patientEditing" color="secondary darken-1" text @click="handleSubmit">Zapisz</v-btn>
                      <v-btn v-else color="secondary darken-1" text @click="handleSubmit" >Dodaj</v-btn>
                    </v-card-actions>
                    <confirm ref="confirm"></confirm>
                  </v-card>
</template>

<script>
  import confirm from '../components/Confirm'
  import CountryCode from '../components/CountryCode.vue'
  import required from 'vuelidate/lib/validators/required'
  import requiredUnless from 'vuelidate/lib/validators/requiredUnless'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'
  import email from 'vuelidate/lib/validators/email'
  import RecommendationLiveSearch from './RecommendationLiveSearch.vue'
  import moment from 'moment';
  import {Helper} from '../mixins/helper'
  const validDate = (value) => !value || moment(value).isValid()

  export default {
    mixins:[
      Helper
    ],
    components:{RecommendationLiveSearch,confirm,CountryCode},
    data: () => ({
      componentKey:0,
      detailes:false,
      birthDayMenu:false,
      itemContactEditing:false,
      dialog:false,
      dialogContact:false,
      itemWasMain:false,
      headers: [
        { text: 'Imię', value: 'name',filterable:true,filterColumn:"name",visible:true},
        { text: 'Nazwisko', value: 'surname',filterable:true,filterColumn:"surname",visible:true},
        { text: 'Rola', value: 'type',filterable:true,filterColumn:"type",visible:true},
        { text: 'Telefon', value: 'phone',filterable:false,filterColumn:"phone",visible:true},
        { text: 'E-mail', value: 'email',filterable:false,filterColumn:"email",visible:true},
        { text: 'Domyślny', value: 'default',filterable:false,filterColumn:"default",visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    props:['itemEditing'],
    validations: {
      editedItem:{
        age:{
          numeric
        },
        pesel:{
          numeric,
          minLength:minLength(11),
          maxLength:maxLength(11)
        },
        phone:{
          numeric
        },
        gus:{
          numeric
        },
        email:{
          email
        },
        birth_date:{
          minLength:minLength(10),
          maxLength:maxLength(10),
          validDate
        }
      },
      editedContactItem:{
        email:{
          email,
          required: requiredUnless(vm => {
            return vm.phone
          }),
        },
        phone:{
          required: requiredUnless(vm => {
            return vm.email
          }),
        }
      }
    },
    watch: {
      contactEditStatus(val){
        if(val===2){
          this.closeContact();
          this.componentKey += 1;
        }
      },
     contactAddStatus(val){
        if(val===2){
          this.closeContact();
          this.componentKey += 1;
        }
      },
     contactDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getPatientEdit;
      },
      editedContactItem(){
        return this.$store.getters.getPatientContactEdit;
      },
      patientEditLoadStatus(){
        return this.$store.getters.getPatientEditLoadStatus
      },
      patientEditStatus(){
        return this.$store.getters.getPatientEditStatus();
      },
      patientEditText(){
        return this.$store.getters.getPatientEditText
      },
      patientAddStatus(){
        return this.$store.getters.getPatientAddStatus();
      },
      patientAddText(){
        return this.$store.getters.getPatientAddText;
      },
      patientEditing(){
        return this.$store.getters.getPatientEditing;
      },
      formTitle () {
        return !this.itemEditing && !this.patientEditing ? 'Nowy pacjent' : 'Edycja pacjenta'
      },
      ageErrors () {
        const errors = []
        if (!this.$v.editedItem.age.$dirty) return errors
        !this.$v.editedItem.age.numeric && errors.push('Wiek musi być liczbą')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.email && errors.push('E-mail jest niepoprawny')
        
        return errors
      },
      birthDate:{
        get(){
          if(this.editedItem.birth_date && this.$v.editedItem.birth_date.validDate && this.$v.editedItem.birth_date.minLength && this.$v.editedItem.birth_date.maxLength){
            return this.editedItem.birth_date;
          }else{
            return null
          }
        },
        set(val){
          this.editedItem.birth_date = val
        }
      },
      birth_dateErrors () {
        const errors = []
        if (!this.$v.editedItem.birth_date.$dirty) return errors
        !this.$v.editedItem.birth_date.minLength && errors.push('Format daty to RRRR-MM-DD')
        !this.$v.editedItem.birth_date.maxLength && errors.push('Format daty to RRRR-MM-DD')
        !this.$v.editedItem.birth_date.validDate && errors.push('Format daty to RRRR-MM-DD')
        return errors
      },
      peselErrors () {
        const errors = []
        if (!this.$v.editedItem.pesel.$dirty) return errors
        !this.$v.editedItem.pesel.numeric && errors.push('PESEL może zawierać tylko cyfry')
        !this.$v.editedItem.pesel.minLength && errors.push('PESEL musi mieć 11 cyfr')
        !this.$v.editedItem.pesel.maxLength && errors.push('PESEL musi mieć 11 cyfr')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.editedItem.phone.$dirty) return errors
        !this.$v.editedItem.phone.numeric && errors.push('Numer telfonu może zawierać tylko cyfry')
        return errors
      },
      gusErrors () {
        const errors = []
        if (!this.$v.editedItem.gus.$dirty) return errors
        !this.$v.editedItem.gus.numeric && errors.push('GUS TERC musi być liczbą')
        return errors
      },
      tableItems(){
        return this.editedItem.contacts;
      },
      contactEditLoadStatus(){
        return this.$store.getters.getPatientContactEditLoadStatus
      },
      contactEditStatus(){
        return this.$store.getters.getPatientContactEditStatus();
      },
      contactAddStatus(){
        return this.$store.getters.getPatientContactAddStatus();
      },
      contactDeleteStatus(){
        return this.$store.getters.getPatientContactDeleteStatus();
      },
      contactEmailErrors () {
        const errors = []
        if (!this.$v.editedContactItem.email.$dirty) return errors
        !this.$v.editedContactItem.email.email && errors.push('E-mail jest niepoprawny')
        !this.$v.editedContactItem.email.required && errors.push('E-mail lub numer telefonu jest wymagany')
        return errors
      },
      contactPhoneErrors () {
        const errors = []
        if (!this.$v.editedContactItem.phone.$dirty) return errors
        !this.$v.editedContactItem.phone.required && errors.push('E-mail lub numer telefonu jest wymagany')
        return errors
      },
      patientsGUSLookupLoadStatus(){
        return this.$store.getters.getPatientsGUSLookupLoadStatus;
      },
      patientsGUSLookup(){
        return this.$store.getters.getPatientsGUSLookup;
      },
    },
 
    methods: {
      onSelectContact({name, iso2, dialCode}) {
        this.editedContactItem.phone_prefix = dialCode
      },
      onSelectPatient({name, iso2, dialCode}) {
        this.editedItem.phone_prefix = dialCode
      },
      searchGUS(){
        this.$store.dispatch( 'searchPatientsGUSLookup', {q:  this.editedItem.city});
      },
      peselChanged(){
        if(this.editedItem.pesel && this.editedItem.pesel.length>4){
          const p = this.peselDecode(this.editedItem.pesel);
          if(p.valid){
            this.editedItem.birth_date = p.date.toISOString().substring(0,10)
            this.editedItem.age = this.getAge(p.date);
          }else{

          }
        }
      },
      birth_dateChanged(){
        this.$v.editedItem.birth_date.$touch()
        if(this.editedItem.birth_date && this.$v.editedItem.birth_date.validDate && this.$v.editedItem.birth_date.minLength && this.$v.editedItem.birth_date.maxLength){
            this.editedItem.age = this.getAge(new Date(this.editedItem.birth_date));
        }
      },
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
      closeContact () {
        this.$v.editedContactItem.$reset();
        this.dialogContact = false
        setTimeout(() => {
          this.$store.commit( 'setPatientContactEditDefault'); 
          this.itemContactEditing = false
        }, 300)
      },
      editItem (item) {
        this.itemContactEditing = true
        this.itemWasMain = item.default
        this.$store.dispatch( 'loadPatientContactEdit', {slug:  item.id});  
        this.dialogContact = true
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać kontakt?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeletePatientContact', {slug:  item.id});
            }
        })
      },
       handleSubmit () {
        this.$v.editedItem.$touch()
        if (this.$v.editedItem.$invalid) {

        } else {
         
            if (this.itemEditing || this.patientEditing) {
              //edycja
              this.$store.dispatch( 'editPatient', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                surname:  this.editedItem.surname,
                age:this.editedItem.age,
                birth_date:this.editedItem.birth_date,
                pesel:this.editedItem.pesel,
                country_code:this.editedItem.country_code,
                post_code: this.editedItem.post_code,
                city:  this.editedItem.city,
                address:  this.editedItem.address,
                house:  this.editedItem.house,
                apartment:  this.editedItem.apartment,
                gus:  this.editedItem.gus,
                sex:  this.editedItem.sex,
                insurance:  this.editedItem.insurance,
                email: this.editedItem.email,
                phone_prefix:  this.editedItem.phone_prefix,
                phone:  this.editedItem.phone,
                recommendation:  this.editedItem.recommendation,
                first_contact:  this.editedItem.first_contact,
                source:  this.editedItem.source,
                currency: this.editedItem.currency
              });
            } else {//new item
              this.$store.dispatch( 'addPatient', {
                  name:  this.editedItem.name,
                  surname:  this.editedItem.surname,
                  age:this.editedItem.age,
                  birth_date:this.editedItem.birth_date,
                  pesel:this.editedItem.pesel,
                  city:  this.editedItem.city,
                  email: this.editedItem.email,
                  phone_prefix:  this.editedItem.phone_prefix,
                  phone:  this.editedItem.phone,
                  recommendation:  this.editedItem.recommendation,
              });

            }
        }
      },
      handleSubmitContact () {
        this.$v.editedContactItem.$touch()
        if (this.$v.editedContactItem.$invalid) {

        } else {
         
            if (this.itemContactEditing) {
              //edycja
              this.$store.dispatch( 'editPatientContact', {
                  slug: this.editedContactItem.id,
                  name:  this.editedContactItem.name,
                  surname:  this.editedContactItem.surname,
                  type: this.editedContactItem.type,
                  email: this.editedContactItem.email,
                  phone_prefix:  this.editedContactItem.phone_prefix,
                  phone:  this.editedContactItem.phone,
                  default:  this.editedContactItem.default,
              });
            } else {//new item
              this.$store.dispatch( 'addPatientContact', {
                  patient_id:this.editedItem.id,
                  name:  this.editedContactItem.name,
                  surname:  this.editedContactItem.surname,
                  type:this.editedContactItem.type,
                  email: this.editedContactItem.email,
                  phone_prefix:  this.editedContactItem.phone_prefix,
                  phone:  this.editedContactItem.phone,
                  default:  this.editedContactItem.default,
              });

            }
        }
      },
    },
  }
</script>

