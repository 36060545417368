import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/templates
    */
    getTemplates: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/templates',{
            params:{
                selectedType:options.selectedType,
        }});
    },


    /*
		POST 	/api/templates
	*/
    postAddNewTemplate: function(type,name,body,files,content){
        return axios.post( HGO_CONFIG.API_URL + '/templates',
            {
                type:type,
                name:name,
                body:body,
                my_files:files,
                content:content,
            }
        );
    },

    /*
		PUT 	/api/templates/{slug}
	*/
    putEditTemplate: function(slug, body,files,content){
        return axios.put( HGO_CONFIG.API_URL + '/templates/'+ slug,
            {
                body:body,
                my_files:files,
                content:content,
            }
        );
    },

    /*
		DELETE /api/templates/{slug}/force
	*/
    forceDeleteTemplate: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/templates/' + slug + '/force' );
	},

}