<template>
  <v-col>
      <v-card flat>
     <v-data-table
        dense
        :key="componentKey"
        :headers="computedHeaders"
        :items="tableItems"
        class="elevation-1"
        :loading="paymentsLoadStatus===1 || paymentEditStatus===1"
        :options.sync="tableOptions"
        :server-items-length="options.lineCount"
        hide-default-footer
        must-sort
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-btn v-if="$route.params.patient_id" color="primary" small @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon>Wróć</v-btn>
            <v-toolbar-title v-else>Rozliczenia</v-toolbar-title>
            
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>

            <v-menu
                                  v-model="fromDateMenu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="150px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      hide-details
                                      clearable
                                      v-model="fromDate"
                                      label="Od"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-on="on"
                                      class="choose_date"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker  first-day-of-week="1" no-title v-model="fromDate" @input="fromDateMenu = false"></v-date-picker>
                  </v-menu>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-menu
                                  v-model="toDateMenu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="150px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      hide-details
                                      clearable
                                      v-model="toDate"
                                      label="Do"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-on="on"
                                      class="choose_date"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker  first-day-of-week="1" no-title v-model="toDate" @input="toDateMenu = false"></v-date-picker>
                  </v-menu>
            <v-divider
              v-if="!$route.params.patient_id"
              class="mx-4"
              inset
              vertical
            ></v-divider>

            <patients-live-search 
              v-if="!$route.params.patient_id"
              dense
              class="choose_user" 
              clearable 
              @patientSelected="selectedPatient=$event.value" 
              :selectedPatient="selectedPatient" 
              label="Pacjent" />
            <!-- <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Wyszukaj"
                  single-line
                  hide-details
                  class="search"
                  @keydown.enter="searchPayments"
              ></v-text-field>
              </template>
                <span>Wpisz min 3 znaki i naciśnij Enter</span>
            </v-tooltip>
              -->

            <v-spacer></v-spacer>

            <services-live-search 
              dense
              class="choose_service" 
              clearable 
              @serviceSelected="selectedService=$event" 
              :selectedService="selectedService" 
              label="Usługa medyczna" />


            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>

            <clinics-live-search 
              dense
              class="choose_user" 
              clearable 
              @clinicSelected="selectedClinic=$event" 
              :selectedClinic="selectedClinic" 
              label="Klinika" />

            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            
            <users-live-search 
              dense
              class="choose_user" 
              clearable 
              @userSelected="selectedUser=$event" 
              :selectedUser="selectedUser" 
              :userType="1"
              label="Lekarz" />

              <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>

            <v-select 
              clearable
              class="choose_date mt-4" 
              dense 
              v-model="selectedStatus" 
              :items="lookups.payment_status"
              label="Status zapłaty"
              ></v-select>
<v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            
              <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="28"
                          class="mr-4"
                          :disabled="tableItems.length==0"
                          @click="downloadXlsFile"
                        >
                          mdi-file-excel
                        </v-icon>
                      </template>
                      <span>Pobierz jako Excel</span>
                    </v-tooltip>

          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">

            <v-tooltip v-if="user.permissions['payments'] >= 10" right>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="savePayment(item)" color="secondary" icon><v-icon>mdi-content-save</v-icon></v-btn>
              </template>
                <span>Zapisz</span>
            </v-tooltip>

        </template>

      <template v-slot:item.price="{ item }">
          {{priceText(item.price,item.patient.currency)}}
      </template>
        <template v-slot:item.cost="{ item }">
          {{priceText(item.cost)}}
        </template>
       <template v-slot:item.invoice_number="{ item }">
        <v-text-field 
            class="smaller_font"
            dense
            flat
            v-model="item.invoice_number"
            single-line
            hide-details
        ></v-text-field>  
       </template>

       <template v-slot:item.invoice_date="{ item }">
         <v-menu
                                  v-model="item.invoice_menu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="150px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      dense
                                      flat
                                      single-line
                                      hide-details
                                      v-model="item.invoice_date"
                                      readonly
                                      v-on="on"
                                      class="choose_date smaller_font"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker  first-day-of-week="1" no-title v-model="item.invoice_date" @input="item.invoice_menu = false"></v-date-picker>
                  </v-menu>
       </template>
        <template v-slot:item.payment_amount="{ item }">
        <v-text-field 
            dense
            v-model="item.payment_amount"
            single-line
            hide-details
            @input="changeAmount(item)"
            class="smaller_font"
            :suffix ="_.find(lookups.currencies,{'value':item.payment_currency?item.payment_currency:item.patient.currency}).text"
        ></v-text-field>  
       </template>

        <template v-slot:item.payment_status="{ item }">
          <v-select 
              dense
              flat
              :items="lookups.payment_status"
              v-model="item.payment_status"
              single-line
              hide-details
              @change="changeStatus(item)"
          ></v-select>  
       </template>

      </v-data-table> 
      <div class="text-center pt-2">
        <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && paymentsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
        <v-btn @click="loadAll(options.lineCount-tableItems.length)" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && paymentsLoadStatus===2" small color="error" class="ml-4"><v-icon left>mdi-download-lock</v-icon>Załaduj wszystkie ({{ options.lineCount-tableItems.length }})</v-btn>
        
        <v-btn @click="loadPayments" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
        <v-btn @click="loadPayments" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
      
      </div>

      </v-card>
  
  <confirm ref="confirm"></confirm>
  </v-col>
  

</template>

<script>
import XLSX from "xlsx"
import confirm from '../components/Confirm'
import UsersLiveSearch from '../components/UsersLiveSearch.vue';
import ClinicsLiveSearch from '../components/ClinicsLiveSearch.vue';
import ServicesLiveSearch from '../components/ServicesLiveSearch.vue';
import PatientsLiveSearch from '../components/PatientsLiveSearch.vue';

export default {
components:{
    confirm,
    UsersLiveSearch,
    ClinicsLiveSearch,
    ServicesLiveSearch,
    PatientsLiveSearch
},
data: () => ({
  componentKey:0,
  tableOptions: {},
  search:'',
  toDateMenu:false,
  fromDateMenu:false,
  headers: [
    { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
    { text: 'Data', value: 'date_from',filterable:true,filterColumn:"date_from",visible:true },
    { text: 'Pacjent', value: 'short_desc',filterable:true,filterColumn:"short_desc",visible:true },
    { text: 'Usługa', value: 'service.name',sortable: false,filterable:true,filterColumn:"service.name",visible:true },
    { text: 'Klinika', value: 'clinic.name',filterable:true,filterColumn:"clinic.name",visible:true,cellClass:'cost_background',class:'cost_background'},
    { text: 'Koszt', value: 'cost',sortable: false,filterable:true,filterColumn:"cost",visible:true,cellClass:'cost_background',class:'cost_background' },
    { text: 'Numer faktury',width:'150', value: 'invoice_number',sortable: false,filterable:true,filterColumn:"invoice_number",visible:true,cellClass:'cost_background',class:'cost_background' },
    { text: 'Data faktury',width:'150', value: 'invoice_date',sortable: false,filterable:true,filterColumn:"invoice_date",visible:true,cellClass:'cost_background',class:'cost_background' },
    { text: 'Cena', value: 'price',sortable: false,filterable:true,filterColumn:"price",visible:true,cellClass:'price_background',class:'price_background' },
    { text: 'Wpłata',width:'130', value: 'payment_amount',sortable: false,filterable:true,filterColumn:"payment_amount",visible:true,cellClass:'price_background',class:'price_background' },
    { text: 'Status zapłaty',width:'150', value: 'payment_status', sortable: false,filterable:false ,visible:true,cellClass:'price_background',class:'price_background' },
    { text: '', value: 'actions', sortable: false,filterable:false,visible:true },
  ],
}),
watch: {
  tableOptions: {
    handler () {
      var currentOptions = {...this.options};
      const { sortBy, sortDesc } = this.tableOptions
    
      //zmiana sortowania
      if (sortBy.length === 1 && sortDesc.length === 1) {
        currentOptions.orderBy = sortBy[0];
        currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
        this.options = currentOptions;
      } 
    },
    deep: true,
  },
  paymentEditStatus(val){
    if(val===2){
      this.componentKey += 1;
    }
  },
},
computed: {
  user(){
    return this.$store.getters.getUser;
  },
  lookups(){
    return this.$store.getters.getLookups;
  },
  options:{
    get () {  
      return this.$store.getters.getPaymentOptions;
    },
    set (value) {
      this.search='';
      this.$store.dispatch( 'setPaymentOptions', {options:value} )
    }
  },
  fromDate:{
    get () {
      return this.$store.getters.getPaymentOptions.fromDate;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.fromDate = value;
      this.options = currentOptions;
      //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
    }
  },
  toDate:{
    get () {
      return this.$store.getters.getPaymentOptions.toDate;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.toDate = value;
      this.options = currentOptions;
      //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
    }
  },
  selectedUser:{
    get () {
      return this.$store.getters.getPaymentOptions.selectedUser;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.selectedUser = value;
      this.options = currentOptions;
    }
  },
  selectedClinic:{
    get () {
      return this.$store.getters.getPaymentOptions.selectedClinic;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.selectedClinic = value;
      this.options = currentOptions;
    }
  },
  selectedService:{
    get () {
      return this.$store.getters.getPaymentOptions.selectedService;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.selectedService = value;
      this.options = currentOptions;
    }
  },
  selectedPatient:{
    get () {
      return this.$store.getters.getPaymentOptions.selectedPatient;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.selectedPatient = value;
      this.options = currentOptions;
    }
  },
  selectedStatus:{
    get () {
      return this.$store.getters.getPaymentOptions.selectedStatus;
    },
    set (value) {
      var currentOptions = {...this.options};
      currentOptions.selectedStatus = value;
      this.options = currentOptions;
    }
  },
  tableItems(){
    return this.$store.getters.getPayments;
  },
  paymentsLoadStatus(){
    return this.$store.getters.getPaymentsLoadStatus;
  },
  paymentEditStatus(){
    return this.$store.getters.getPaymentEditStatus();
  },
  computedHeaders () {
    var h = _.filter(this.headers,function(o) { return o.visible; })

    //if(!this.showDeleted && this.payment.permissions['payments'] == 0) return h.filter(h => h.value!=='status' && h.value!=='actions')
    //else if(this.showDeleted && this.payment.permissions['payments'] <= 5) return h.filter(h => h.value!=='actions')
    //else 
    if(!this.showDeleted) return h.filter(h => h.value!=='status')
    else return h
  },
},
mounted(){
  if(this.lookups.roles.length>0){
    if(this.$route.params.patient_id){
      this.selectedPatient = parseInt(this.$route.params.patient_id)
    }else this.loadPayments();
  }else{
    this.$store.dispatch( 'loadLookups' );
    setTimeout(() => {
      if(this.$route.params.patient_id){
        this.selectedPatient = parseInt(this.$route.params.patient_id)
    }else this.loadPayments();
    }, 2000)
  }
},
beforeRouteLeave (to, from, next) {
  var currentOptions = {...this.options};
  currentOptions.selectedPatient = null;
  this.$store.commit('setPaymentOptions',currentOptions);
  next();
},
methods: {
  downloadXlsFile(){
    //przygotowanie danych
    var items = []
    function p_name(p) {
      return p.name + ' ' + p.surname;
    }
    this.tableItems.forEach(item => {
      items.push({
        date_from_date:item.date_from.slice(0,10),
        date_from_hour:item.date_from.slice(11,16),
        patient_surname:item.patient.surname,
        patient_name:item.patient.name,
        pesel:item.patient.pesel,
        email:item.patient.email,
        mobile:item.patient.phone,
        team: _.join(_.map(item.team,p_name)),
        category:item.category?item.category.name:'',
        service:item.service?item.service.name:'',
        info:item.info,
        clinic:item.clinic?item.clinic.name:'', 
        cost:this.priceText(item.cost), 
        invoice_number:item.invoice_number, 
        invoice_date:item.invoice_date,
        price: item.price?(_.find(item.price,{'currency_id':item.patient.currency})?_.find(item.price,{'currency_id':item.patient.currency})['amount']:null):null,
        payment_amount:item.payment_amount,
        payment_currency:_.find(this.lookups.currencies,{'value':item.payment_currency?item.payment_currency:item.patient.currency}).text,
        payment_status:_.find(this.lookups.payment_status,{'value':item.payment_status}).text,
        created_by:item.created_by.length>0?item.created_by[0].user:'',
        created_date:item.created_by.length>0?item.created_by[0].created_at.split(' ')[0]:'',
        created_time:item.created_by.length>0?item.created_by[0].created_at.split(' ')[1]:''
        })
    });

    var wb = XLSX.utils.book_new()
    var ws_name = "Zestawienie";
    var ws = XLSX.utils.aoa_to_sheet([["Data","Godzina","Nazwisko","Imię","PESEL","Adres e-mail","Telefon","Lekarz","Działanie","Usługa","Opis","Klinika","Koszt","Numer faktury","Data faktury","Cena","Wpłata","Waluta","Status zapłaty","Rejestrujący","Data rejestracji","Godzina rejestracji"]]);
    XLSX.utils.sheet_add_json(ws,items,{header:["date_from_date","date_from_hour","patient_surname","patient_name","pesel","email","mobile","team","category","service","info","clinic","cost","invoice_number","invoice_date","price","payment_amount","payment_currency","payment_status","created_by","created_date","created_time"], skipHeader:true, origin:1})
    
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
  
    var rightNow = new Date();
    rightNow.setMinutes(rightNow.getMinutes() - rightNow.getTimezoneOffset());
    var filename = 'Zestawienie_' + rightNow.toISOString().replace(/-/g,'').replace(/:/g,'').replace('T','_').slice(0,15) +'.xlsx';
    XLSX.writeFile(wb, filename, {})
  },
  loadPayments(){
    this.search='';
    this.$store.dispatch( 'loadPayments' );
  },
  loadMore(){
    this.$store.dispatch( 'loadMorePayments' );
  },
  loadAll(len){
    this.$refs.confirm.open('Uwaga', 'Czy na pewno chcesz pobrać resztę rekordów? ('+len+')', { color: 'warning' }).then((confirm) => {
        if(confirm){
           this.$store.dispatch( 'loadAllPayments' );
        }
    })
    
  },
  searchPayments(){
    if(this.search.length>2){
      const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
      this.$store.dispatch( 'searchPayments',{filterableHeaders:filterableHeaders,q:this.search});
    }
  },
  changeStatus(item){
    if(item.payment_status==5){//opłacone
      item.payment_amount = _.find(item.price,{'currency_id':item.patient.currency})['amount'];
      item.payment_currency = item.patient.currency;
    }if(item.payment_status==10){//opłacone czesciowo
      item.payment_currency = item.patient.currency;
    }else if(!item.payment_amount){
      item.payment_amount = null;
      item.payment_currency = null;
    }
  },
  changeAmount(item){
    var p = this.priceValue(item.price,item.patient.currency);
    if(item.payment_amount == p){
      this.tableItems[this.tableItems.indexOf(item)].payment_status = 5;
    }else if(item.payment_amount>0){
      this.tableItems[this.tableItems.indexOf(item)].payment_status = 10;
    }else{
      this.tableItems[this.tableItems.indexOf(item)].payment_status = 1;
    }
  },
  savePayment(item){
    this.$store.dispatch( 'editPayment', {
            slug: item.id,
            payment_status:  item.payment_status,
            payment_amount:  item.payment_amount,
            payment_currency: item.payment_currency,
            invoice_number: item.invoice_number,
            invoice_date: item.invoice_date
          });
  },
  priceText(value,userCurrency = null){
    if(value){
      var output = [];
      this.lookups.currencies.forEach(c => {
        var val = _.find(value,{'currency_id':c.value});
        if(val && (!userCurrency || val['currency_id']==userCurrency))
          output.push(val['amount'] + ' ' + c.text );
      });
      return output.join(', ');

    }else return "BEZPŁATNE";
  },
  priceValue(value,userCurrency){
    if(value){
      var val = _.find(value,{'currency_id':userCurrency});
      return val['amount'];
    }else return null;
  },
},
}
</script>

<style scoped>
.search{
  max-width: 250px;
}
.choose_date{
max-width: 150px;
}
.choose_user{
max-width: 200px;
}
.choose_clinic{
max-width: 200px;
}
.choose_service{
max-width: 350px;
}

</style>

<style>
.cost_background{
background: rgb(237, 244, 255);
}
.price_background{
background: rgb(250, 229, 229);
}
.smaller_font input {
font-size: 1em;
background:rgba(255, 255, 255, 0.555);
}
</style>