<template>
    <div class="mx-1">
      <v-row class="mb-1">
                                <v-col class="col-8">
                                    <service-search label="Wyszukaj badanie" @serviceSelected="addService($event)" :serviceCategory="1" />
                                </v-col>
                                <v-spacer />
                                <v-col>
                                    <v-btn :disabled="false" @click="addEmpty" small color="primary" class="mt-4">lub Dodaj własne</v-btn>
                                </v-col>
      </v-row>
      <v-row>
        <v-col>
        <v-responsive
                                      class="overflow-y-auto fill-height"
                                      :height="(height-100)"
                                  >
                                  <v-simple-table>
                                      <template v-slot:default>
                                        <tbody>
                                          <tr
                                            v-for="(item, index) in editedItem.content.results"
                                            :key="index"
                                          >
                                          <!-- <td class="px-0"><v-icon size="30">{{item['file_type']=='pdf'?'mdi-file-pdf-box':item['file_type']=='png'?'mdi-file-image-outline':item['file_type']=='jpg'?'mdi-file-image-outline':item['file_type']=='jpeg'?'mdi-file-image-outline':'mdi-file'}}</v-icon></td> -->
                                          <td class="px-0" width="60%"><v-text-field @input="inputChange()" :readonly="item.id>0" :error-messages="resultNameErrors(index)" flat single-line dense v-model="item['name']" label="Badanie" required  hide-details="auto"></v-text-field></td>
                                          <td class="px-1" width="30%"><v-text-field @input="inputChange()" flat dense v-model="item['value']" hide-details="auto" single-line label="Wynik"></v-text-field></td>
                                         
                                          <td><v-icon @click="deleteResult(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>
                                            <!-- <td><v-avatar size="35" :color="item.category.color"><v-icon size="20">{{item.category.icon}}</v-icon></v-avatar></td>
                                            <td><b>{{ item.date_from }}</b></td>
                                            <td><b>{{ item.service.name }}</b><br />{{item.clinic?item.clinic.name:''}}</td>
                                            <td><i>{{ _.find(lookups.appointment_status,{'value':item.status}).text }}</i></td>
                                            <td><v-btn :to="{ name: 'dzialanie', params: { id: item.id } }" color="primary" x-small>Przejdź</v-btn></td> -->
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-responsive>
        </v-col>
      </v-row>
    </div>
</template>

<script>
  import ServiceSearch from '../../components/ServicesSearch.vue'
  import required from 'vuelidate/lib/validators/required'
  export default {
    components:{
      ServiceSearch
    },
    props: {
      height:{
        type:Number,
        default:0
      },
    },
    data: () => ({
      
    }),
    validations() {
       return {
          editedItem:{
            content:{
              results:{
                $each:{
                  name:{required}
                }
              }
            },
          },
        }
    },
    watch: {
     
    },
    computed: {
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
    },
    methods: {
      resultNameErrors (index) {
        const errors = []
        if (!this.$v.editedItem.content.results || !this.$v.editedItem.content.results.$each[index] || !this.$v.editedItem.content.results.$each[index].name.$dirty) return errors
        !this.$v.editedItem.content.results.$each[index].name.required && errors.push('Nazwa jest wymagana')
        return errors
      },
      addService(service){
         this.editedItem.content.results.push({
          id:service.value,
          name:service.code,
          desc:service.text,
          value:undefined
        })
      },
      addEmpty(){
        this.editedItem.content.results.push({
          id:null,
          name:undefined,
          desc:undefined,
          value:undefined
        })
      },
      deleteResult(index){
        this.editedItem.content.results.splice(index,1);
        this.$emit('changed');
      },
      inputChange(){
        this.$v.editedItem.$touch();
        if(!this.$v.editedItem.$invalid){
          this.$emit('changed');
        }
      }
    },
  }
</script>