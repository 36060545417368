<template>
  <v-app id="inspire">
    <v-main>
      <v-container style="max-width: 1000px;">
        <v-row no-gutters justify="center" >
          <v-col>
            <v-img src="/hifuclinic-logo-pl.svg" height="68"></v-img> 
            <router-view  :key="$route.path"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import _isEmpty from "lodash/isEmpty"

  export default {
    components: {

    },
    computed:{
     
    },
    watch:{
      
    },
    mounted(){
      
    },
    methods:{
      
    },
  }
</script>