import AttachmentAPI from "../api/attachment";

export const attachments = {
    state: {
        attachmentAddStatus: 0,
        attachmentAddText: "",

        attachmentEdit: {
            id: null,
            appointment_id: null,
            type:null,
            header:null,
            body:null,
            bodyHTML:false,
            files:[],
            content:{
                header:null,
                footer:true,
                recipient:null,
                send_date:null,
                password:null,
                assigned_users:[],
                deleted_at:null,
                results:[],
                referral:{
                    type_id:null,
                    patient:{name:null,phone:null,email:null,address:null},
                    data:{diagnosis:null,idc:null,purpose:null,dre:null,psa:null,other:null},
                    results:{score:null,mri:null,pet_mri:null,mdx:null,pet_ct:null,mpMri:null,pca:null}
                },
                prescription:{
                    patient:{
                        id:null,sex:null,name:null,surname:null,address:null,house:null,apartment:null,post_code:null,city:null,country:'Polska',pesel:null,gus:null,birth_date:null,insurance:null,phone:null,passport:null
                    },
                    author:{name:null,surname:null,pwz:null},
                    clinic:{name:null,address:null,house:null,post_code:null,city:null,nfz:null,phone:null,regon:null,krs:null},
                    medicines:[],
                    oid:null,
                    rpwdl:null,
                    id:null,
                    code:null,
                    status:0,
                    updated:null
                }
            },
            ref_id:null,
            tab:0
        },
        attachmentEditDefault: {
            id: null,
            appointment_id: null,
            type:null,
            password:null,
            header:null,
            body:null,
            bodyHTML:false,
            files:[],
            content:{
                header:null,
                footer:true,
                recipient:null,
                send_date:null,
                assigned_users:[],
                deleted_at:null,
                results:[],
                referral:{
                    type_id:null,
                    patient:{name:null,phone:null,email:null,address:null},
                    data:{diagnosis:null,idc:null,purpose:null,dre:null,psa:null,other:null},
                    results:{score:null,mri:null,pet_mri:null,mdx:null,pet_ct:null,mpMri:null,pca:null}
                },
                prescription:{
                    patient:{
                        id:null,sex:null,name:null,surname:null,address:null,house:null,apartment:null,post_code:null,city:null,country:'Polska',pesel:null,gus:null,birth_date:null,insurance:null,phone:null,passport:null
                    },
                    author:{name:null,surname:null,pwz:null},
                    clinic:{name:null,address:null,house:null,post_code:null,city:null,nfz:null,phone:null,regon:null,krs:null},
                    medicines:[],
                    oid:null,
                    rpwdl:null,
                    id:null,
                    code:null,
                    status:0,
                    updated:null
                }
            },
            ref_id:null,
            tab:0
        },

        newFile:{
            file_name:null,
            file_content:null,
            file_type:'pdf',
            elements:[]
        },

        newFileDefault:{
            file_name:null,
            file_content:null,
            file_type:'pdf',
            elements:[]
        },
        
        attachmentEditLoadStatus: 0,
        attachmentEditStatus: 0,
        attachmentEditText: "",

        attachmentDeleteStatus: 0,
        attachmentDeleteText: "",

        filesAddStatus:0,
    },

    actions: {
        addAttachment({ commit, state, dispatch }, data) {
            commit("setAttachmentAddStatus", 1);
            commit("setAttachmentAddText", "");

            AttachmentAPI.postAddNewAttachment(
                data.appointment_id,
                data.type,
                data.header,
                data.body,
                data.files,
                data.content,
                data.ref_id
            )
                .then(function(response) {
                    commit("setAttachmentAddStatus", 2);
                    commit("setAttachmentEditId", response.data.id);
                    commit("setAttachmentEditOrig", response.data);
                    commit("setAddedFlow", response.data);
                })
                .catch(function(error) {
                    commit("setAttachmentAddStatus", 3);
                    commit("setAttachmentAddText", error.response.data.errors);
                });
        },
        addFiles({ commit, state, dispatch }, data) {
            commit("setFilesAddStatus", 1);
            AttachmentAPI.postAddNewFiles(
                data.patient_id,
                data.files,
            )
                .then(function(response) {
                    commit("setFilesAddStatus", 2);
                    dispatch("loadFlows");
                })
                .catch(function(error) {
                    commit("setFilesAddStatus", 3);
                });
        },
        loadAttachmentEdit({ commit }, data) {
            commit("setAttachmentEditLoadStatus", 1);
            commit("setAttachmentEditStatus", 0);
            commit("setAttachmentEditText", "");

            AttachmentAPI.getAttachmentEdit(data.slug)
                .then(function(response) {
                    commit("setAttachmentEdit", response.data);
                    commit("setAttachmentEditOrig", response.data);
                    commit("setAttachmentEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setAttachmentEdit", {});
                    commit("setAttachmentEditLoadStatus", 3);
                });
        },
        loadAttachmentEditDefault({ commit }) {
            commit("setAttachmentEditDefault");
        },
        loadAttachmentAddDefault({ commit }) {
            commit("setAttachmentAddStatus", 0);
            commit("setAttachmentAddText", "");
        },
        editAttachment({ commit, state, dispatch }, data) {
            commit("setAttachmentEditStatus", 1);

            AttachmentAPI.putEditAttachment(
                data.slug,
                data.header,
                data.body,
                data.files,
                data.content
            )
                .then(function(response) {
                    commit("setAttachmentEditOrig", response.data);
                    commit("setEditedFlow", response.data);
                    commit("setAttachmentEditStatus", 2);
                })
                .catch(function(error) {
                    commit("setAttachmentEditStatus", 3);
                    commit("setAttachmentEditText", error.response.data.errors);
                });
        },
        sendAttachment({ commit, state, dispatch }, data) {
            commit("setAttachmentEditStatus", 1);

            AttachmentAPI.putSendAttachment(
                data.slug,
            )
                .then(function(response) {
                    commit("setAttachmentEditOrig", response.data);
                    commit("setEditedFlow", response.data);
                    commit("setAttachmentEditStatus", 4);
                })
                .catch(function(error) {
                    commit("setAttachmentEditStatus", 3);
                    commit("setAttachmentEditText", error.response.data.errors);
                });
        },
        forceDeleteAttachment({ commit, state, dispatch }, data) {
            commit("setAttachmentDeleteStatus", 1);

            AttachmentAPI.forceDeleteAttachment(data.slug)
                .then(function(response) {
                    commit("setAttachmentDeleteStatus", 2);
                    commit("setDeletedFlow", response.data);
                })
                .catch(function() {
                    commit("setAttachmentDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setAttachmentAddStatus(state, status) {
            state.attachmentAddStatus = status;
        },
        setAttachmentAddText(state, text) {
            state.attachmentAddText = text;
        },
        setAttachmentEdit(state, attachment) {
            state.attachmentEdit = attachment;
            if(attachment.body && (attachment.body !== attachment.body.replace(/<\/?[^>]+(>|$)/g, ""))){
                state.attachmentEdit.bodyHTML = true
            }else state.attachmentEdit.bodyHTML = false
        },
        setAttachmentEditBodyHTML(state, value) {
            state.attachmentEdit.bodyHTML = value
        },
        setAttachmentEditOrig(state, attachment) {
            state.attachmentEdit.orig = JSON.parse(JSON.stringify(attachment));
            state.attachmentEdit.files = JSON.parse(JSON.stringify(attachment.files));

            //state.attachmentEdit.content.send_date = attachment.content.send_date;
        },
        setAttachmentEditId(state, id) {
            state.attachmentEdit.id = id;
        },
        setAttachmentEditDefault(state) {
            state.attachmentEdit = JSON.parse(JSON.stringify(state.attachmentEditDefault));
        },
        setNewFileDefault(state) {
            state.newFile = JSON.parse(JSON.stringify(state.newFileDefault));
        },
        setAttachmentEditStatus(state, status) {
            state.attachmentEditStatus = status;
        },
        setAttachmentEditText(state, text) {
            state.attachmentEditText = text;
        },
        setAttachmentEditLoadStatus(state, status) {
            state.attachmentEditLoadStatus = status;
        },
        setAttachmentDeleteStatus(state, status) {
            state.attachmentDeleteStatus = status;
        },
        setAttachmentDeletedText(state, text) {
            state.attachmentDeleteText = text;
        },
        setFilesAddStatus(state, status) {
            state.filesAddStatus = status;
        },
    },

    getters: {
        getAttachmentAddStatus(state) {
            return function(){
                return state.attachmentAddStatus;
            } 
        },
        getAttachmentAddText(state) {
            return state.attachmentAddText;
        },
        getAttachmentEdit(state) {
            return state.attachmentEdit;
        },
        getAttachmentNewFile(state) {
            return state.newFile;
        },
        getAttachmentEditStatus(state) {
          return function(){
            return state.attachmentEditStatus;
          }
        },
        getAttachmentEditing(state) {
            return (state.attachmentEdit.id && state.attachmentEdit.id > 0)?true:false;
        },
        getAttachmentEditText(state) {
            return state.attachmentEditText;
        },
        getAttachmentEditLoadStatus(state) {
            return state.attachmentEditLoadStatus;
            
        },
        getAttachmentDeleteStatus(state) {
            return function(){
                return state.attachmentDeleteStatus;
            }
        },
        getAttachmentDeleteText(state) {
            return state.attachmentDeleteText;
        },
        getFilesAddStatus(state) {
            return function(){
                return state.filesAddStatus;
            } 
        },
    }
};
