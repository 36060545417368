import AppointmentAPI from "../api/appointment";

export const payments = {
    state: {
        payments: [],
        paymentOptions:{
            lineCount:0,
            orderBy:'date_from',
            orderDirection:'desc',
            itemsPerPage:25,
            fromDate:undefined,
            toDate:undefined,
            selectedStatus:null,
            selectedUser:null,
            selectedPatient:null,
            selectedClinic:null,
            selectedService:null,
        },
        paymentsLoadStatus: 0,

        paymentEditStatus: 0,
        paymentEditText: "",
    },

    actions: {
        loadPayments({ state, commit, dispatch }) {
            commit("setPaymentsLoadStatus", 1);
                AppointmentAPI.getPayments({
                    offset:0,
                    itemsPerPage:state.paymentOptions.itemsPerPage, 
                    orderBy:state.paymentOptions.orderBy,
                    orderDirection:state.paymentOptions.orderDirection,
                    fromDate:state.paymentOptions.fromDate,
                    toDate:state.paymentOptions.toDate,
                    selectedStatus:state.paymentOptions.selectedStatus,
                    selectedUser:state.paymentOptions.selectedUser,
                    selectedPatient:state.paymentOptions.selectedPatient,
                    selectedClinic:state.paymentOptions.selectedClinic,
                    selectedService:state.paymentOptions.selectedService,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setPaymentLineCount", response.data.lineCount);
                    commit("setPayments", response.data.payments);
                    dispatch("applyIndexOnPayments");
                    commit("setPaymentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPayments", []);
                    commit("setPaymentsLoadStatus", 3);
                });
         
        },
        loadMorePayments({ state, commit, dispatch }) {
            commit("setPaymentsLoadStatus", 1);
                AppointmentAPI.getPayments({
                    offset:state.payments.length,
                    itemsPerPage:state.paymentOptions.itemsPerPage, 
                    orderBy:state.paymentOptions.orderBy,
                    orderDirection:state.paymentOptions.orderDirection,
                    fromDate:state.paymentOptions.fromDate,
                    toDate:state.paymentOptions.toDate,
                    selectedStatus:state.paymentOptions.selectedStatus,
                    selectedUser:state.paymentOptions.selectedUser,
                    selectedPatient:state.paymentOptions.selectedPatient,
                    selectedClinic:state.paymentOptions.selectedClinic,
                    selectedService:state.paymentOptions.selectedService,
                })
                .then(function(response) {
                    commit("setPaymentLineCount", response.data.lineCount);
                    commit("setPayments", _.concat(state.payments, response.data.payments));
                    dispatch("applyIndexOnPayments");
                    commit("setPaymentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPayments", []);
                    commit("setPaymentsLoadStatus", 3);
                });
         
        },
        loadAllPayments({ state, commit, dispatch }) {
            commit("setPaymentsLoadStatus", 1);
                AppointmentAPI.getPayments({
                    offset:state.payments.length,
                    itemsPerPage:999999, 
                    orderBy:state.paymentOptions.orderBy,
                    orderDirection:state.paymentOptions.orderDirection,
                    fromDate:state.paymentOptions.fromDate,
                    toDate:state.paymentOptions.toDate,
                    selectedStatus:state.paymentOptions.selectedStatus,
                    selectedUser:state.paymentOptions.selectedUser,
                    selectedPatient:state.paymentOptions.selectedPatient,
                    selectedClinic:state.paymentOptions.selectedClinic,
                    selectedService:state.paymentOptions.selectedService,
                })
                .then(function(response) {
                    commit("setPaymentLineCount", response.data.lineCount);
                    commit("setPayments", _.concat(state.payments, response.data.payments));
                    dispatch("applyIndexOnPayments");
                    commit("setPaymentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPayments", []);
                    commit("setPaymentsLoadStatus", 3);
                });
         
        },
        searchPayments({ state, commit, dispatch },data) {
            commit("setPaymentsLoadStatus", 1);
                AppointmentAPI.searchPayments({
                    showDeleted:state.paymentOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setPaymentSearchedTrue");
                    commit("setPaymentLineCount", response.data.lineCount);
                    commit("setPayments", response.data.payments);
                    dispatch("applyIndexOnPayments");
                    commit("setPaymentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPayments", []);
                    commit("setPaymentsLoadStatus", 3);
                });
         
        },
        setPaymentOptions({ state, commit, dispatch },data){
            commit("setPaymentOptions",data.options);
            dispatch("loadPayments");
        },
        applyIndexOnPayments({ commit, state, dispatch }) {
            let localPayments = state.payments;

            var i = 1;
            localPayments.forEach(payment => {
                payment.lp = i;
                i++;
            });

            commit("setPayments",localPayments);
        },
       
        editPayment({ commit, state, dispatch }, data) {
            commit("setPaymentEditStatus", 1);

            AppointmentAPI.putEditPayment(
                data.slug,
                data.payment_status,
                data.payment_amount,
                data.payment_currency,
                data.invoice_number,
                data.invoice_date,
            )
                .then(function(response) {
           
                    commit("setPaymentEditStatus", 2);
                    commit("setEditedPayment",response.data)
                    dispatch("applyIndexOnPayments");
                })
                .catch(function(error) {
                    commit("setPaymentEditStatus", 3);
                    commit("setPaymentEditText", error.response.data.errors);
                });
        },
    },
    mutations: {
        setPaymentOptions(state,options){
            state.paymentOptions = options;
        },
        setPaymentsLoadStatus(state, status) {
            state.paymentsLoadStatus = status;
        },
        setPaymentEditStatus(state, status) {
            state.paymentEditStatus = status;
        },
        setPayments(state, payments) {
            state.payments = payments;
        },
        setPaymentLineCount(state, lineCount) {
            state.paymentOptions.lineCount = lineCount;
        },
        setEditedPayment(state, payment) {
            var lp = _.find(state.payments,{'id':payment.id}).lp;
            var index = _.findIndex(state.payments,{'id':payment.id});
       
            state.payments[index] = payment;
            state.payments[index].lp = lp;
        },
        setPaymentSearchedTrue(state) {
            state.paymentOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.paymentOptions.itemAdded = false;
            state.paymentOptions.itemSearched = false;
        },
    },
    getters: {
        getPaymentOptions(state){
            return state.paymentOptions;
        },
        getPaymentsLoadStatus(state) {
            return state.paymentsLoadStatus;
        },
        getPayments(state) {
            return state.payments;
        },
        getPaymentEditStatus(state) {
          return function(){
            return state.paymentEditStatus;
          }
        },
        getPaymentEditText(state) {
            return state.paymentEditText;
        },
    }
};
