import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    getCookie: function(){
        //console.log(HGO_CONFIG.API_URL);
        return axios.get( HGO_CONFIG.WEB_URL + '/sanctum/csrf-cookie');
    },

    loginUser: function( login, password, code){
        //console.log(HGO_CONFIG.API_URL);
        return axios.post( HGO_CONFIG.WEB_URL + '/login',
            {
                login: login,
                password: password,
                code: code
            }
        );
    },

    loginUserMobile: function( login, password,device_name, code){
        //console.log(HGO_CONFIG.WEB_URL);
        return axios.post( HGO_CONFIG.WEB_URL + '/token',
            {
                login: login,
                password: password,
                device_name:device_name,
                code: code
            }
        );
    },

    logoutUser: function(){
        return axios.get( HGO_CONFIG.WEB_URL + '/logout');
    },

    logoutUserMobile: function(){
        return axios.get( HGO_CONFIG.WEB_URL + '/revoke');
    },

    /*
        GET     /api/user
    */
    getUser: function(){
        return axios.get( HGO_CONFIG.API_URL + '/user' );
    },

    /*
        GET     /api/users/searchLookup
    */
    searchUsersLookup: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/users/searchLookup',{
            params:{
                q:options.q,
                type:options.type
        }});
    },

    /*
        GET     /api/users
    */
    getUsers: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/users',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/users/search
    */
    searchUsers: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/users/search',{
            params:{
                showDeleted:options.showDeleted,
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },

    /*
        POST 	/api/users
    */
    postAddNewUser: function( name, surname, mobile, login,color,signature,zla,info, password,oneTimePassword,type,session_length,pwz,role_id,permissions ){
        return axios.post( HGO_CONFIG.API_URL + '/users',
            {
                name: name,
                surname: surname,
                mobile: mobile,
                login: login,
                color:color,
                signature:signature,
                zla:zla,
                info:info,
                password: password,
                oneTimePassword:oneTimePassword,
                type:type,
                session_length:session_length,
                pwz:pwz,
                role_id:role_id,
                permissions:permissions
            }
        );
    },

    /*
        GET 	/api/users/{slug}/edit
    */
    getUserEdit: function( slug ){
        return axios.get( HGO_CONFIG.API_URL + '/users/' + slug + '/edit' );
    },

    /*
        PUT 	/api/users/{slug}
    */
    putEditUser: function(slug, name, surname, mobile, login,color,signature,zla,info,type,session_length,pwz,role_id,permissions){
        return axios.put( HGO_CONFIG.API_URL + '/users/'+ slug,
            {
                name: name,
                surname: surname,
                mobile: mobile,
                login: login,
                color:color,
                signature:signature,
                zla:zla,
                info:info,
                type:type,
                session_length:session_length,
                pwz:pwz,
                role_id:role_id,
                permissions:permissions
            }
        );
    },

    /*
        PUT 	/api/users/notification
    */
        putNotificationAsRead: function(id){
            return axios.put( HGO_CONFIG.API_URL + '/users/notification',
                {
                    id: id,
                }
            );
        },

    /*
        GET 	/api/users/reactivate/{slug}
    */
    putReactivateUser: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/users/reactivate/' + slug);
    },

    /*
        DELETE /api/users/{slug}
    */
    deleteUser: function( slug ){
        return axios.delete( HGO_CONFIG.API_URL + '/users/' + slug );
    },

    /*
        DELETE /api/users/{slug}/force
    */
    forceDeleteUser: function( slug ){
        return axios.delete( HGO_CONFIG.API_URL + '/users/' + slug + '/force' );
    },


     /*
		PUT 	/api/user/{slug}/reset
	*/
    putEditUserPassword: function(slug, password, oneTimePassword){
        return axios.put( HGO_CONFIG.API_URL + '/user/'+ slug + '/reset',
            {
                password: password,
                oneTimePassword: oneTimePassword
            }
        );
    },

    // /*
	// 	PUT 	/api/user/{slug}/resetOneTime
	// */
    // putEditUserOneTimePassword: function(slug, password, oldPassword){
    //     return axios.put( HGO_CONFIG.API_URL + '/user/'+ slug + '/resetOneTime',
    //         {
    //             password: password,
    //             oldPassword: oldPassword
    //         }
    //     );
    // },
    

}