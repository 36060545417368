<template>
                  <v-card>
                    <v-card-title>
                      <img class="mr-3" src="/icon.png" height="40" />
                      <span class="headline">Zmiana hasła</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                            <v-row>     
                             
                                <v-col cols="12">
                                <v-text-field 
                                  append-icon="mdi-refresh"
                                  @click:append="generatePassword"
                                  v-model="editedItem.password" 
                                  :error-messages="passwordErrors" 
                                  hide-details="auto"
                                  required 
                                  @input="$v.editedItem.password.$touch()"  
                                  @blur="$v.editedItem.password.$touch()"  
                                  label="Nowe hasło"></v-text-field>                            
                                </v-col>

                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="editedItem.oneTimePassword"
                                    hide-details="auto"
                                    label="Wymagana zmiana hasła przy pierwszym logowaniu"
                                  ></v-checkbox> 

                            </v-col>

                            </v-row>     
                           
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <p class="text--secondary caption ml-5">Minimalne wymagania hasła <br />Minimum 8 znaków, 1 duża litera, 1 cyfra</p>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close()">Anuluj</v-btn>
                      <v-btn color="secondary" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import passwordComplexity from '../../plugins/passwordComplexity'
  import {Helper} from '../../mixins/helper'

  export default {
    mixins:[
      Helper
    ],
    data: () => ({
    
    }),
    validations: {
      editedItem:{
        password:{
            required,
            minLength: minLength(8),
            passwordComplexity
          },
      }
    },
    watch: {
    
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      editedItem(){
        return this.$store.getters.getUserEdit;
      },
      passwordErrors () {
        const errors = []
        if (this.itemEditing || !this.$v.editedItem.password.$dirty) return errors
        !this.itemEditing && !this.$v.editedItem.password.required && errors.push('Hasło jest wymagane')
        !this.itemEditing && !this.$v.editedItem.password.minLength && errors.push('Hasło jest za krótkie')
        !this.itemEditing && !this.$v.editedItem.password.passwordComplexity && errors.push('Hasło jest za proste')
        return errors
      },
    },
 
    methods: {
      generatePassword(){
        this.editedItem.password = this.randPasswordString()
      },
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
            this.$store.dispatch( 'editUserPassword', {
                slug: this.editedItem.id,
                password:  this.editedItem.password,
                oneTimePassword: this.editedItem.oneTimePassword
            });
        }

        this.close();
      },
    },
  }
</script>

