<template>
                  <v-card height="480" :loading="filesAddStatus===1">
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                      <span class="headline">Pliki od pacjenta</span>
                    </v-card-title>

                    <v-card-text>
                        <v-row class="px-2" dense>
                          <v-col :class="editedItem.files.length>0?'col-3':'col-12'">
                                            <file-drop  :fileAccept="accept" height="320"
                                              @newFile="addNewFile($event)"
                                              /> 
                          </v-col>
                          <v-col class="col-9 pl-0" v-if="editedItem.files.length>0">
                                            <v-responsive
                                                class="overflow-y-auto fill-height"
                                                height="325"
                                            >
                                            <v-simple-table>
                                                <template v-slot:default>
                                                  <tbody>
                                                    <tr
                                                      v-for="(item, index) in editedItem.files"
                                                      :key="index"
                                                    >
                                                    <td class="px-0" width="35"><v-icon size="30">{{item['file_type']=='pdf'?'mdi-file-pdf-box':item['file_type']=='png'?'mdi-file-image-outline':item['file_type']=='jpg'?'mdi-file-image-outline':item['file_type']=='jpeg'?'mdi-file-image-outline':'mdi-file'}}</v-icon></td>
                                                    <td class="px-1" width="360" style="text-align: left;">{{_.truncate(item['file_name']+'.'+item['file_type'],{'length': 45})}}</td>
                                                    <td class="px-0" width="120"><v-chip v-if="fileSizeErrors(index)" color="error" label small outlined>Pusty plik</v-chip></td>
                                                    <td class="px-0"><v-icon @click="deleteFile(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>
                                                    </tr>
                                                  </tbody>
                                                </template>
                                              </v-simple-table>
                                            </v-responsive>
                          </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer> 
                      <v-btn :disabled="filesAddStatus===1" color="gray" text @click="close()">Anuluj</v-btn>
                      <v-btn :disabled="filesAddStatus===1" color="secondary darken-1" text @click="handleSubmit" >Dodaj</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import minValue from 'vuelidate/lib/validators/minValue'
  import FileDrop from '../components/FileDrop'

  //const fileNotEmpty = (value) => value.includes('cool')

  export default {
    components:{FileDrop},
    props:['patientId'],
    data: () => ({
      accept:null,
      componentKey:0,
      editedItem:{
        files:[
          
        ]
      }
    }),
    validations: {
      editedItem:{
        files:{
                $each:{
                  file_name:{required},
                  file_size:{minValue:minValue(100)}
                }
        }
      },
    },
    watch: {
     filesAddStatus(val){
        if(val===2){
          this.editedItem.files = []
          this.close();
          //this.$store.dispatch('loadTasks');
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      filesAddStatus(){
        return this.$store.getters.getFilesAddStatus();
      },
      
    },
    methods: {
      fileSizeErrors(index){
        return this.editedItem.files[index] && !this.$v.editedItem.files.$each[index].file_size.minValue;
      },
      addNewFile(file){
        var f = file;
        f.file_size = file['file_content'].length/1.37;
        this.editedItem.files.push(file);
      },
      deleteFile(index){
          this.editedItem.files.splice(index,1);
      },
      close(){
        this.editedItem.files = []
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.editedItem.$touch()
        if (this.$v.editedItem.$invalid) {

        } else {
         
           this.$store.dispatch( 'addFiles', {
                  patient_id:  this.patientId,
                  files:this.editedItem.files,
             });

            
        }
      },
    },
  }
</script>

