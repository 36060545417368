<template>
  <div id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      :mini-variant="variant"
      :style="`margin-top: ${$vuetify.application.top}px`"
      width="180"
      app 
      dark
      permanent
    >
    <!-- <v-row>
      <v-col>
        <v-img :src="myLogo"></v-img>
      </v-col>
    </v-row> -->

      <v-list dense flat>
        <template v-for="item in items">         
          <v-list-item
            class="pl-3"
            :key="item.path"
            :to="{ name: item.path }"
            link
            :class="item.path.split('.')[0] === $route.name.split('.')[0] ? 'v-list-item--active' : ''"
            v-if="checkModules(user.permissions,item.modules)"
            color="primary"
          >
            <v-list-item-action>
              <v-icon size="30" :color="item.path.split('.')[0] === $route.name.split('.')[0] ? 'secondary' : 'white'">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <h5 style="color:grey; position: absolute;bottom:65px;left:5px">{{appVersion}}</h5>

    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      clipped-left
    >
      <v-app-bar-nav-icon dark @click.stop="variant = !variant" />
      <v-toolbar-title
        style="width: 200px"
        class="ml-0"
      >
      <v-img src="/logo.png"></v-img>   
      </v-toolbar-title>
      
      <v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer />
      <patients-search dense label="Wyszukaj pacjenta" @patientSelected="openPatient($event)" class="mr-4" />

      <v-menu
            bottom
            left
            :close-on-content-click="false" 
            v-model="notificationMenu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                  color="secondary"
                  :content="user.unread_notifications.length"
                  overlap
                  :value="user.unread_notifications.length>0"
                >
                  <v-icon v-if="user.unread_notifications.length>0" v-on="on" size="28" color="white">mdi-bell</v-icon>
                  <v-icon v-else size="28" color="grey">mdi-bell</v-icon>
              </v-badge>
            </template>

            <v-list>
              
              <v-list-item
                two-line
                v-for="(item, i) in user.unread_notifications"
                :key="i"
              >
              <v-list-item-content>
                <v-list-item-title>{{ item.data.header }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.data.body }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-hover v-slot="{ hover }">
                  <v-icon @click="markAsRead(item.id)" :color="hover?'secondary':''">mdi-delete {{notificationAsReadStatus===1 && hover?'mdi-spin':''}}</v-icon>
                </v-hover>
              </v-list-item-icon>
              </v-list-item>
            </v-list>
      </v-menu>

      
      <v-menu offset-y >
        <template v-slot:activator="{ on }">
          <v-btn
            outlined
            color="white"
            v-on="on"
            class="text-none ml-6"
          >
           <v-icon left>mdi-account</v-icon> {{user.name[0].toUpperCase()+user.surname[0].toUpperCase()}}
          </v-btn>
        </template>
        <v-list>

          <v-list-item
            key="logout"
            @click="logout"
          >
            <v-list-item-title >Wyloguj</v-list-item-title>
          </v-list-item>

          <v-list-item
            key="passchange"
            :to="{ name: 'haslo' }"
          >
            <v-list-item-title>Zmień hasło</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <confirm ref="confirm"></confirm>
    </v-app-bar>
    
  </div>

</template>

<script>
  import {Permissions} from "../../mixins/permissions"
  import PatientsSearch from '../PatientsSearch.vue';
  import { useSound } from '@vueuse/sound'
  import notificationSfx from '../../plugins/definite-555.mp3'
  import confirm from '../../components/Confirm'

  export default {
    components: { PatientsSearch,confirm },
    mixins:[
      Permissions
    ],
    props: {
      source: String,
    },
    data: () => ({
      notificationMenu:false,
      prevNotificaitonCount:9999,
      dialog: false,
      drawer: null,
      variant: true,
      items: [
        { icon: 'mdi-monitor-dashboard', text: 'Pulpit', path: 'pulpit',modules:['desktop'] },
        { icon: 'mdi-clipboard-account-outline', text: 'Dziennik', path: 'dziennik',modules:['journal']},
        { icon: 'mdi-calculator', text: 'Rozliczenia', path: 'rozliczenia',modules:['settlement']},
        { icon: 'mdi-cash-register', text: 'Kasa', path:'kasa.dokumenty', modules:['payment']},
        { icon: 'mdi-chart-bar', text: 'Raporty', path: 'raporty',modules:['report']},
        { icon: 'mdi-human-male', text: 'Pacjent', path: 'pacjenci',modules:['patient']},
        { icon: 'mdi-calendar-clock', text: 'Harmonogram', path: 'harmonogramy',modules:['event']},
        { icon: 'mdi-cash-register', text: 'Rozliczenia', path: 'rozliczenia',modules:['payments']},
        { icon: 'mdi-phone-log', text: 'Billing', path: 'billing',modules:['billing']},
        { icon: 'mdi-cog-outline', text: 'Ustawienia', path: 'ustawienia.kliniki',modules:['role','user']},
        
      ],
    }),
    setup(){
      const { play } = useSound(notificationSfx)
      return {
        play,
      }
    },
    watch:{
      notificationAsReadStatus(val){
        if(val==2){
          if(this.user.unread_notifications.length===0)this.notificationMenu = false;
        }
      },
      userLoadStatus(val){
        if(val==1){
          this.prevNotificaitonCount = this.user.unread_notifications.length;
          //sprawdzamy czy jest więcej powiadomień
        }else if(val==2 && this.user.unread_notifications.length>this.prevNotificaitonCount){
          this.play()
        }
       
        if(val==2 && process.env.VERSION !== this.user.version){//czy nie ma nowej wersji aplikacji
          this.$refs.confirm.open('Odśwież', 'Używasz starej wersji aplikacji. Czy chcesz odświeżyć stronę?', { color: 'error' }).then((confirm) => {
            if(confirm){
               location.reload();
            }
        })
        }
      },
      variant(val){
        this.$store.commit('setMiniVariant',val);
      }
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
      notificationAsReadStatus(){
        return this.$store.getters.getNotificationAsReadStatus();
      },
      userLoadStatus(){
        return this.$store.getters.getUserLoadStatus();
      },
      appVersion(){
        return process.env.VERSION;
      }
    },
    mounted(){
      //console.log(process.env.VERSION);
    },
    methods:{
      logout(){
        this.$store.dispatch( 'loadEmptyUser' );
      },
      openPatient(id){
        this.$store.commit("setFlowsAttachmentFileContent", {
          file_name:null,
          file_content:null,
          file_type:null,
          file_path:null});
        this.$router.push({name:'pacjent',params:{id:id}});
      },
      markAsRead(id){
        this.$store.dispatch('setNotificationAsRead',{id:id})
      }
    }
  }
</script>
<style scoped>
.v-list-item--active{
  background-color: white;
  color:#2b2838;
}
</style>
