<template>
      <v-col>
          <v-card flat >
         <v-data-table
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-1"
            :loading="eventsLoadStatus===1"
            :options.sync="tableOptions"
            :server-items-length="options.lineCount"
            hide-default-footer
            must-sort
            dense
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Harmonogram</v-toolbar-title>
                
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                

                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Wyszukaj"
                      single-line
                      hide-details
                      class="search"
                      @keydown.enter="searchEvents"
                  ></v-text-field>
                  </template>
                    <span>Wpisz min 3 znaki i naciśnij Enter</span>
                </v-tooltip>
                 

                <v-spacer></v-spacer>

                <v-menu
                                      v-model="fromDateMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          hide-details
                                          clearable
                                          v-model="fromDate"
                                          label="Od"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="fromDate" @input="fromDateMenu = false"></v-date-picker>
                      </v-menu>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-menu
                                      v-model="toDateMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          hide-details
                                          clearable
                                          v-model="toDate"
                                          label="Do"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="toDate" @input="toDateMenu = false"></v-date-picker>
                      </v-menu>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-select v-model="selectedType" clearable  class="choose_type" :items="lookups.event_type" hide-details label="Rodzaj"></v-select>
                
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>

                <v-divider
                  v-if="user.permissions['event'] >= 10"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-tooltip v-if="user.permissions['event'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="{name:'harmonogram',params:{}}" fab dark small><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                    <span>Dodaj rekord</span>
                </v-tooltip>

              </v-toolbar>
            </template>

            <template v-slot:item.date="{ item }">
              {{item.all_day?item.date_from+' - '+item.date_to:item.date_from.split(' ')[0]}}
            </template>

            <template v-slot:item.time="{ item }">
              {{item.all_day?'':item.date_from.split(' ')[1]+' - '+item.date_to.split(' ')[1]}}
            </template>

            <template v-slot:item.type="{ item }">
              {{_.find(lookups.event_type,{'value':item.type}).text}}
            </template>

            <template v-slot:item.public="{ item }">
              <v-simple-checkbox v-model="item.public" disabled></v-simple-checkbox>
            </template>
            
             <template v-slot:item.interval="{ item }">
              {{item.interval?_.find(lookups.interval,{'value':item.interval}).text:null}}
            </template>

            <template v-slot:item.actions="{ item }">

                <v-btn v-if="item.status && user.permissions['event'] >= 10" :to="{name:'harmonogram',params:{id:item.id}}" x-small color="secondary" >Detale</v-btn>

                <!-- <v-tooltip v-if="item.status && user.permissions['event'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="{name:'harmonogram',params:{id:item.id}}" icon><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip> -->

                <!-- <v-tooltip v-if="item.status && item.can_delete && user.permissions['event'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="deleteItem(item)" icon><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip> -->

                <v-tooltip v-if="!item.status  && user.permissions['event'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="reactivateItem(item)" icon><v-icon>mdi-delete-restore</v-icon></v-btn>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['event'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="forceDeleteItem(item)" icon><v-icon color="error">mdi-delete-forever</v-icon></v-btn>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                
            </template>

            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>

          </v-data-table> 
          <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && eventsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadEvents" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadEvents" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>

          </v-card>
      
      <confirm ref="confirm"></confirm>
      </v-col>
      

</template>

<script>
  import confirm from '../components/Confirm'

  export default {
    props:{
      reload: Boolean
    },
    components:{
      confirm,
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      search:'',
      fromDateMenu:false,
      toDateMenu:false,
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Data', value: 'date',filterable:true,filterColumn:"date_from",visible:true},
        { text: 'Godzina', value: 'time',filterable:true,filterColumn:"date_from",visible:true, sortable: false},
        { text: 'Rodzaj', value: 'type',filterable:true,filterColumn:"type",visible:true},
        { text: 'Publiczny', value: 'public',filterable:false,visible:true},
        { text: 'Czas wizyty', value: 'interval',filterable:false,visible:true},
        { text: 'Opis', value: 'desc',filterable:false,visible:true, sortable: false},
        { text: 'Powtarzaj', value: 'series_desc',filterable:true,filterColumn:"series_def",visible:true, sortable: false},
        { text: 'Status', value: 'status',filterable:false,filterColumn:"status",visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
        { text: 'Lekarz name', value: 'user.name',filterable:true,filterColumn:"user.name",visible:false},
        { text: 'Lekarz surname', value: 'user.surname',filterable:true,filterColumn:"user.surname",visible:false},
        { text: 'Klinika name', value: 'clinic.name',filterable:true,filterColumn:"clinic.name",visible:false},
        { text: 'Działanie name', value: 'category.name',filterable:true,filterColumn:"category.name",visible:false},
        { text: 'Diagnostyka name', value: 'service.name',filterable:true,filterColumn:"service.name",visible:false},
        { text: 'Notatka', value: 'note',filterable:true,filterColumn:"note",visible:false},
        { text: 'Sala', value: 'room',filterable:true,filterColumn:"room",visible:false},
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
    
      dialog (val) {
        val || this.close()
      },
      eventEditStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      },
      eventAddStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      },
      eventDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getEventOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setEventOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getEventOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      selectedType:{
        get () {
          return this.$store.getters.getEventOptions.selectedType;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.selectedType = value;
          this.options = currentOptions;
          //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
        }
      },
      fromDate:{
        get () {
          return this.$store.getters.getEventOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.fromDate = value;
          this.options = currentOptions;
          //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
        }
      },
      toDate:{
        get () {
          return this.$store.getters.getEventOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.toDate = value;
          this.options = currentOptions;
          //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
        }
      },
      editedItem(){
        return this.$store.getters.getEventEdit;
      },
      tableItems(){
        return this.$store.getters.getEvents;
      },
      eventsLoadStatus(){
        return this.$store.getters.getEventsLoadStatus;
      },
      eventEditLoadStatus(){
        return this.$store.getters.getEventEditLoadStatus
      },
      eventEditStatus(){
        return this.$store.getters.getEventEditStatus();
      },
      eventAddStatus(){
        return this.$store.getters.getEventAddStatus();
      },
      eventDeleteStatus(){
        return this.$store.getters.getEventDeleteStatus();
      },
      computedHeaders () {
        var h = _.filter(this.headers,function(o) { return o.visible; })

        // if(!this.showDeleted && this.user.permissions['event'] <= 5) return h.filter(h => h.value!=='status' && h.value!=='actions')
        // else if(this.showDeleted && this.user.permissions['event'] <= 5) return h.filter(h => h.value!=='actions')
        // else 
        if(!this.showDeleted) return h.filter(h => h.value!=='status')
        else return h
      },
    },
    mounted(){
      if(this.reload)this.loadEvents();
    },
    beforeRouteLeave (to, from, next) {
      if(to.name!=="harmonogram"){
        this.$store.commit('setEvents',[])
      }
      next();
    },
    methods: {
      loadEvents(){
        this.search='';
        var date = new Date(); 
        this.fromDate = date.toISOString().substr(0,10);
        //this.$store.dispatch( 'loadEvents' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreEvents' );
      },
      reactivateItem(item){
        this.$store.dispatch( 'editEventReactivate',{slug:item.id});
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz ukryć wpis?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteEvent', {slug:  item.id});
            }
        })
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać wpis na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteEvent', {slug:  item.id});
            }
        })
      },
      searchEvents(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchEvents',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
.choose_type{
      max-width: 150px;
}

.choose_date{
  max-width: 200px;
}
</style>