var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{key:_vm.componentKey,attrs:{"dense":"","headers":_vm.headers,"items":_vm.tableItems,"hide-default-footer":"","must-sort":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),(_vm.user.permissions['clinics'] >= 10)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mt-2",attrs:{"x-small":"","color":"secondary"}},on),[_vm._v(" Dodaj ")])]}}],null,false,3340798866),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.selectedItemNameErrors,"required":"","label":"Nazwa"},on:{"input":function($event){return _vm.$v.selectedItemName.$touch()},"blur":function($event){return _vm.$v.selectedItemName.$touch()}},model:{value:(_vm.selectedItemName),callback:function ($$v) {_vm.selectedItemName=$$v},expression:"selectedItemName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Telefon"},model:{value:(_vm.selectedItemPhone),callback:function ($$v) {_vm.selectedItemPhone=$$v},expression:"selectedItemPhone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.selectedItemEmailErrors,"label":"Email"},on:{"input":function($event){return _vm.$v.selectedItemEmail.$touch()},"blur":function($event){return _vm.$v.selectedItemEmail.$touch()}},model:{value:(_vm.selectedItemEmail),callback:function ($$v) {_vm.selectedItemEmail=$$v},expression:"selectedItemEmail"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.close}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"color":"secondary darken-1","text":""},on:{"click":_vm.handleSubmit}},[_vm._v("Zapisz")])],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.user.permissions['clinics'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"size":"18"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]):_vm._e(),(_vm.user.permissions['clinics'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"18"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Usuń")])]):_vm._e(),_c('confirm',{ref:"confirm"})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }