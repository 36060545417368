<template>
  <v-autocomplete
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedPatient"
                         @change="patientChange"
                        :items="patientsLookup"
                        @update:search-input="changeSelection"
                        dark
                        filled
                        rounded
                        hide-no-data
                        prepend-inner-icon='mdi-magnify'
                        append-icon=''
                        hide-details
                        single-line
                        color="white"
                        :label="label"
                        :dense="dense"
                        :disabled="disabled"
                        auto-select-first
                      ></v-autocomplete>

</template>
<script>
import moment from 'moment';
export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj klinikę"
   },
   disabled:{
     type:Boolean,
     default:false
   },
  
  },
  data: () => ({
    selectedPatient:null
  }),
  watch:{

  },
  computed: {
    patientsLookupLoadStatus(){
      return this.$store.getters.getPatientsLookupLoadStatus;
    },
    patientsLookup(){
      //jeżeli jest juz ustawiony patient ale lookup jest pusty to dajemy tego patienta
      return _.map(this.$store.getters.getPatientsLookup,'text');
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        true//this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    patientChange(val){
      if(val){
        this.$emit('patientSelected', _.find(this.$store.getters.getPatientsLookup,{'text':val}).value)
        this.$store.commit('setPatientsLookup',[]);
        this.selectedPatient = moment().valueOf().toString();
      }
    },
    changeSelection (val) {
      val && val !== this.selectedPatient && val.length>2 && this.querySelections(val)
    },
    querySelections (v) {
        this.$store.dispatch( 'searchPatientsLookup',{q:v}); 
      },
  }
};
</script>
<style scoped>

</style>