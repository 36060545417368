import RoleAPI from "../api/role";

export const roles = {
    state: {
        roles: [],
        roleOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:10,
            itemAdded:false,
            itemSearched:false
        },
        rolesLoadStatus: 0,
        roleAddStatus: 0,
        roleAddText: "",

        roleEdit: {
            id: undefined,
            lp: undefined,
            name: undefined,
            description:undefined,
            computed_permissions:[]
        },
        roleEditDefault: {
            id: undefined,
            lp: undefined,
            name: undefined,
            description:undefined,
            computed_permissions:[]
        },
        roleEditLoadStatus: 0,
        roleEditStatus: 0,
        roleEditText: "",

        roleDeleteStatus: 0,
        roleDeleteText: "",

    },

    actions: {
        loadRoles({ state, commit, dispatch }) {
            commit("setRolesLoadStatus", 1);
                RoleAPI.getRoles({
                    offset:0,
                    itemsPerPage:state.roleOptions.itemsPerPage, 
                    orderBy:state.roleOptions.orderBy,
                    orderDirection:state.roleOptions.orderDirection,
                    showDeleted:state.roleOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setRoleLineCount", response.data.lineCount);
                    commit("setRoles", response.data.roles);
                    dispatch("applyIndexOnRoles");
                    commit("setRolesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setRoles", []);
                    commit("setRolesLoadStatus", 3);
                });
         
        },
        loadMoreRoles({ state, commit, dispatch }) {
            commit("setRolesLoadStatus", 1);
                RoleAPI.getRoles({
                    offset:state.roles.length,
                    itemsPerPage:state.roleOptions.itemsPerPage, 
                    orderBy:state.roleOptions.orderBy,
                    orderDirection:state.roleOptions.orderDirection,
                    showDeleted:state.roleOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setRoleLineCount", response.data.lineCount);
                    commit("setRoles", _.concat(state.roles, response.data.roles));
                    dispatch("applyIndexOnRoles");
                    commit("setRolesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setRoles", []);
                    commit("setRolesLoadStatus", 3);
                });
         
        },
        searchRoles({ state, commit, dispatch },data) {
            commit("setRolesLoadStatus", 1);
                RoleAPI.searchRoles({
                    showDeleted:state.roleOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setRoleSearchedTrue");
                    commit("setRoleLineCount", response.data.lineCount);
                    commit("setRoles", response.data.roles);
                    dispatch("applyIndexOnRoles");
                    commit("setRolesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setRoles", []);
                    commit("setRolesLoadStatus", 3);
                });
         
        },
        setRoleOptions({ state, commit, dispatch },data){
            commit("setRoleOptions",data.options);
            dispatch("loadRoles");
        },
        applyIndexOnRoles({ commit, state, dispatch }) {
            let localRoles = state.roles;

            var i = 1;
            localRoles.forEach(role => {
                role.lp = i;
                i++;
            });

            commit("setRoles",localRoles);
        },
        addRole({ commit, state, dispatch }, data) {
            commit("setRoleAddStatus", 1);
            commit("setRoleAddText", "");

            RoleAPI.postAddNewRole(
                data.name,
                data.description,
                data.permissions
            )
                .then(function(response) {
                    commit("setRoleAddStatus", 2);
                    commit("setAddedRole",response.data);
                    commit("setRoleLineCount", state.roleOptions.lineCount + 1);
                    dispatch("applyIndexOnRoles");
                    dispatch("loadRoleLookup");
                })
                .catch(function(error) {
                    commit("setRoleAddStatus", 3);
                    commit("setRoleAddText", error.response.data.errors);
                });
        },

        loadRoleEdit({ commit }, data) {
            commit("setRoleEditLoadStatus", 1);
            commit("setRoleEditStatus", 0);
            commit("setRoleEditText", "");

            RoleAPI.getRoleEdit(data.slug)
                .then(function(response) {
                    commit("setRoleEdit", response.data);
                    commit("setRoleEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setRoleEdit", {});
                    commit("setRoleEditLoadStatus", 3);
                });
        },
        loadPermissionList({ commit }, data) {
            commit("setRoleEditLoadStatus", 1);
            commit("setRoleEditStatus", 0);
            commit("setRoleEditText", "");

            RoleAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setPermissionListEdit", response.data);
                    commit("setRoleEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setRoleEdit", {});
                    commit("setRoleEditLoadStatus", 3);
                });
        },
        loadRoleEditDefault({ commit }) {
            commit("setRoleEditDefault");
        },
        loadRoleAddDefault({ commit }) {
            commit("setRoleAddStatus", 0);
            commit("setRoleAddText", "");
        },
        editRole({ commit, state, dispatch }, data) {
            commit("setRoleEditStatus", 1);

            RoleAPI.putEditRole(
                data.slug,
                data.name,
                data.description,
                data.permissions
            )
                .then(function(response) {
           
                    commit("setRoleEditStatus", 2);
                    commit("setEditedRole",response.data)
                    dispatch("applyIndexOnRoles");
                    dispatch("loadRoleLookup");
                })
                .catch(function(error) {
                    commit("setRoleEditStatus", 3);
                    commit("setRoleEditText", error.response.data.errors);
                });
        },
        editRoleReactivate({ commit, state, dispatch }, data) {
            commit("setRoleEditStatus", 1);
  
            RoleAPI.putReactivateRole(data.slug)
                .then(function(response) {
                    commit("setRoleEditStatus", 2);
                    commit("setEditedRole",response.data)
                    dispatch("applyIndexOnRoles");
                    dispatch("loadRoleLookup");
                })
                .catch(function(error) {
                    commit("setRoleEditStatus", 3);
                });
        },
        deleteRole({ commit, state, dispatch }, data) {
            commit("setRoleDeleteStatus", 1);

            RoleAPI.deleteRole(data.slug)
                .then(function(response) {
                    commit("setRoleDeleteStatus", 2);
                    if(state.roleOptions.showDeleted){
                        commit("setEditedRole",response.data)
                    }else{
                        commit("setRoleLineCount", state.roleOptions.lineCount - 1);
                        commit("setDeletedRole",response.data)
                    }
                    dispatch("loadRoleLookup");
                    dispatch("applyIndexOnRoles");
                })
                .catch(function() {
                    commit("setRoleDeleteStatus", 3);
                });
        },
        forceDeleteRole({ commit, state, dispatch }, data) {
            commit("setRoleDeleteStatus", 1);

            RoleAPI.forceDeleteRole(data.slug)
                .then(function(response) {
                    commit("setRoleDeleteStatus", 2);
                    commit("setRoleLineCount", state.roleOptions.lineCount - 1);
                    commit("setDeletedRole",response.data)
                    dispatch("applyIndexOnRoles");
                })
                .catch(function() {
                    commit("setRoleDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setRoleOptions(state,options){
            state.roleOptions = options;
        },
        setRolesLoadStatus(state, status) {
            state.rolesLoadStatus = status;
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        setRoleLineCount(state, lineCount) {
            state.roleOptions.lineCount = lineCount;
        },
        setRoleAddStatus(state, status) {
            state.roleAddStatus = status;
        },
        setRoleAddText(state, text) {
            state.roleAddText = text;
        },
        setRoleEdit(state, role) {
            state.roleEdit = role;
        },
        setPermissionListEdit(state, list) {
            state.roleEdit.computed_permissions = list;
        },
        setEditedRole(state, role) {
            var lp = _.find(state.roles,{'id':role.id}).lp;
            var index = _.findIndex(state.roles,{'id':role.id});
       
            state.roles[index] = role;
            state.roles[index].lp = lp;
        },
        setAddedRole(state, role) {
            state.roleOptions.itemAdded = true;
            state.roles.push(role);
        },
        setRoleSearchedTrue(state) {
            state.roleOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.roleOptions.itemAdded = false;
            state.roleOptions.itemSearched = false;
        },
        setDeletedRole(state, role) {
            var index = _.findIndex(state.roles,{'id':role.id});
            state.roles.splice(index,1);
        },
        setRoleEditDefault(state) {
            state.roleEdit = { ...state.roleEditDefault };
        },
        setRoleEditStatus(state, status) {
            state.roleEditStatus = status;
        },
        setRoleEditText(state, text) {
            state.roleEditText = text;
        },
        setRoleEditLoadStatus(state, status) {
            state.roleEditLoadStatus = status;
        },
        setRoleDeleteStatus(state, status) {
            state.roleDeleteStatus = status;
        },
        setRoleDeletedText(state, text) {
            state.roleDeleteText = text;
        }
    },

    getters: {
        getRoleOptions(state){
            return state.roleOptions;
        },
        getRolesLoadStatus(state) {
            return state.rolesLoadStatus;
        },
        getRoles(state) {
            return state.roles;
        },
        getRoleAddStatus(state) {
            return function(){
                return state.roleAddStatus;
            } 
        },
        getRoleAddText(state) {
            return state.roleAddText;
        },
        getRoleEdit(state) {
            return state.roleEdit;
        },
        getRoleEditStatus(state) {
          return function(){
            return state.roleEditStatus;
          }
        },
        getRoleEditing(state) {
            return state.roleEdit.id > 0;
        },
        getRoleEditText(state) {
            return state.roleEditText;
        },
        getRoleEditLoadStatus(state) {
            return state.roleEditLoadStatus;
            
        },
        getRoleDeleteStatus(state) {
            return function(){
                return state.roleDeleteStatus;
            }
        },
        getRoleDeleteText(state) {
            return state.roleDeleteText;
        }
    }
};
