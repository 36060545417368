var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Historia operacji")]),_c('v-spacer'),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5",attrs:{"x-small":"","color":"secondary"},on:{"click":_vm.loadLog}},on),[_vm._v("Szczegóły")])]}}]),model:{value:(_vm.dialogLog),callback:function ($$v) {_vm.dialogLog=$$v},expression:"dialogLog"}},[_c('v-card',{staticClass:"pl-1 pr-1"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersLog,"items":_vm.tableLog,"dense":"","hide-default-footer":"","disable-pagination":"","disable-sort":"","loading":_vm.logLoadStatus == 1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Historia Operacji")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date mt-6 mr-5",attrs:{"label":"Od","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.logDateFrom),callback:function ($$v) {_vm.logDateFrom=$$v},expression:"logDateFrom"}},on))]}}]),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.dateFromMenu = false}},model:{value:(_vm.logDateFrom),callback:function ($$v) {_vm.logDateFrom=$$v},expression:"logDateFrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date mt-6 mr-5",attrs:{"label":"Do","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.logDateTo),callback:function ($$v) {_vm.logDateTo=$$v},expression:"logDateTo"}},on))]}}]),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.dateToMenu = false}},model:{value:(_vm.logDateTo),callback:function ($$v) {_vm.logDateTo=$$v},expression:"logDateTo"}})],1),_c('v-btn',{staticClass:"mb-2 mt-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.dialogLog = false}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split(' ')[0])+" ")]}},{key:"item.hour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split(' ')[1])+" ")]}}])})],1)],1)],1),_c('v-simple-table',{staticClass:"mx-3 pb-5",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.logItems),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"nowrap":""}},[_c('b',[_vm._v(_vm._s(item.created_at))])]),(item.param)?_c('td',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm._.find(_vm.lookups.log,{'value':item.action}).text)+" ("+_vm._s(_vm._.find(_vm.lookups.logParams,{'value':item.param}).text)+")")]):_c('td',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm._.find(_vm.lookups.log,{'value':item.action}).text))]),_c('td',[_c('i',[_vm._v(_vm._s(item.user))])])])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }