<template>
  <v-autocomplete
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedClinic"
                         @change="clinicChange"
                        :loading="clinicsLookupLoadStatus==1"
                        :items="clinicsLookup"
                        @update:search-input="changeSelection"
                        flat
                        hide-no-data
                        hide-details="auto"
                        :label="fixed?'Wybierz klinikę':label"
                        :dense="dense"
                        :clearable="clearable"
                        @click:clear="clearContent"
                        :disabled="disabled"
                        auto-select-first
                      ></v-autocomplete>

</template>
<script>

export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   excludeClinicId:{
     type:Number,
     default:0
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj klinikę"
   },
   clearable:{
     type:Boolean,
     default:false
   },
   disabled:{
     type:Boolean,
     default:false
   },
   fixed:{
     type:Boolean,
     default:false
   },
   selectedClinic:{
     type:Number
   },
   selectedClinicText:{
     type:String,
     default:null
   },
  },
  data: () => ({
    searchClinic: null,
    clinicId:undefined
  }),
  watch:{
    // selectedClinic(val){
    //   if(!val){
    //     this.searchClinic = null;
    //   }
    // }
  },
  computed: {
    clinicsLookupLoadStatus(){
      return this.$store.getters.getClinicsLookupLoadStatus;
    },
    clinicsLookup(){
      if(this.fixed){//dodajemy ceny
        var list = [];
        this.$store.getters.getClinicsLookup.forEach(element => {
          list.push({'value':element.value,'short':element.text,'text':element.text+' ('+this.priceText(element.price)+')'})
        });
        return list;
      }else{
        //jeżeli jest juz ustawiony clinic ale lookup jest pusty to dajemy tego clinica
        if(this.selectedClinic && this.selectedClinicText && _.filter(this.$store.getters.getClinicsLookup,{'value':this.selectedClinic}).length===0)
        return [{value:this.selectedClinic,text:this.selectedClinicText}];
        else return this.$store.getters.getClinicsLookup;
      }
    },
  },
  methods: {
    priceText(value){
        if(value){
          var output = [];
          this.$store.getters.getLookups.currencies.forEach(c => {
            var val = _.find(value,{'currency_id':c.value});
            if(val)output.push(val['amount'] + ' ' + c.text );
          });
          return output.join(', ');

        }else return "BEZPŁATNE";
    },
    filterObject(item, queryText, itemText) {
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    clearContent(){
      this.$emit('clinicSelected', null)
      this.$emit('clinicNameSelected', null)
      //this.searchClinic = null;
    },
    clinicChange(val){
      if(val){
        var el = _.find(this.clinicsLookup,{'value':val});
        this.$emit('clinicSelected', val)
        this.$emit('clinicNameSelected', el.hasOwnProperty('short')?el.short:el.text)
      }
    },
    changeSelection (val) {
      if(this.selectedClinic){
        val && val !== _.find(this.clinicsLookup,{'value':this.selectedClinic}).text && val.length>2 && this.querySelections(val)
      }else{
        val && val.length>2 && this.querySelections(val)
      }
    },
    querySelections (v) {
      if(!this.fixed)this.$store.dispatch( 'searchClinicsLookup',{q:v}); 
    },
  }
};
</script>
<style scoped>

</style>