<template>
      <v-col>
          <v-card class="fill-height" style="background-color:#F5F5F5;">
          <v-row class="mt-0">
            <v-col class="col-8 pl-5 pt-3">
              <desktop-calendar />
            </v-col>
            <v-col class="col-4 pl-0 pr-5 pt-3" v-resize="onResize">
              <v-card class="fill-height" :loading="tasksLoadStatus==1" style="background-color:#D8D8D8;">
                <v-toolbar flat style="background-color:#D8D8D8;">
                  <v-toolbar-title>Zadania</v-toolbar-title>
                  <!-- <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>{{ showCreated?'Zadania utworzone':'Zadania do wykonania' }}</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="showCreated = false">
                              <v-list-item-title>Zadania do wykonania</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="showCreated = true">
                              <v-list-item-title>Zadania utworzone</v-list-item-title>
                            </v-list-item>
                          </v-list>
                  </v-menu> -->
                  <v-spacer />
                  <v-checkbox v-model="showDone" class="mt-4" label="Zakończone"></v-checkbox>
                </v-toolbar>
                <v-responsive
                                      class="overflow-y-auto fill-height"
                                      :height="windowSize.y"
                                  >
                  <tasks-list  />
                </v-responsive>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
</template>

<script>
import confirm from '../components/Confirm';
import DesktopCalendar from '../components/DesktopCalendar';
import TasksList from '../components/TasksList.vue';

  export default {
   components: { confirm,DesktopCalendar,TasksList },
   data: () => ({
     windowSize: {
        x: 0,
        y: 0,
      },
   
    }),
    validations: {

    },
    watch: {
     

    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      showDone: {
        get () {
          return this.$store.getters.getTaskOptions.showDone;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getTaskOptions};
          currentOptions.showDone = value;
          this.$store.dispatch( 'setTaskOptions', {options:  currentOptions} );
        }
      },
      showCreated: {
        get () {
          return this.$store.getters.getTaskOptions.showCreated;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getTaskOptions};
          currentOptions.showCreated = value;
          this.$store.dispatch( 'setTaskOptions', {options:  currentOptions} );
        }
      },
      tasksLoadStatus(){
        return this.$store.getters.getTasksLoadStatus;
      },
     
    },
    mounted(){
     if(this.lookups.roles.length>0){
          this.$store.dispatch( 'loadTasks');
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.$store.dispatch( 'loadTasks');
        }, 2000)
      }
    },
    beforeRouteLeave(to, from, next) {
      var options = this.$store.getters.getTaskOptions;
      options.showCreated = false;
      this.$store.commit( 'setTaskOptions', options );
      next();
    },
    methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight - 175 }
      },
    },
  }
</script>

<style scoped>

</style>