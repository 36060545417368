import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {
    lastGetAppointmentsRequest:null,
    lastReferenceAppointmentsRequest:null,
    /*
        GET     /api/appointments
    */
    getAppointments: function(options){
        if(this.lastGetAppointmentsRequest){
            this.lastGetAppointmentsRequest.cancel();
            this.lastGetAppointmentsRequest = null;
        }
        
        const cancelTokenSource = axios.CancelToken.source();
        this.lastGetAppointmentsRequest = cancelTokenSource
        
        return axios.get( HGO_CONFIG.API_URL + '/appointments',{
            cancelToken:cancelTokenSource.token,
            params:{
                selectedTypes:options.selectedTypes,
                selectedStatuses:options.selectedStatuses,
                selectedCategories:options.selectedCategories,
                selectedUser:options.selectedUser,
                selectedService:options.selectedService,
                fromDate:options.fromDate,
                toDate:options.toDate,
        }});
    },

    /*
        GET     /api/appointments/references
    */
   getReferenceAppointments: function(data){
    // if(this.lastReferenceAppointmentsRequest){
    //     this.lastReferenceAppointmentsRequest.cancel();
    //     this.lastReferenceAppointmentsRequest = null;
    // }
    
    // const cancelTokenSource = axios.CancelToken.source();
    // this.lastReferenceAppointmentsRequest = cancelTokenSource
    return axios.get( HGO_CONFIG.API_URL + '/appointments/references',{
        //cancelToken:cancelTokenSource.token,
        params:{
            id:data.id,
            user_id:data.user_id,
            clinic_id:data.clinic_id,
            room:data.room,
            date_from:data.date_from,
            date_to:data.date_to,
            service_id:data.service_id
    }});
},



    /*
		POST 	/api/appointments
	*/
    postAddNewAppointment: function( category_id,patient_id,clinic_id,service_id,room,date_from,date_to,info,patient_info,status,remainder_users,remainder_patient,event_ids,users){
        return axios.post( HGO_CONFIG.API_URL + '/appointments',
            {
                category_id: category_id,
                patient_id:patient_id,
                clinic_id:clinic_id,
                service_id:service_id,
                room:room,
                date_from:date_from,
                date_to:date_to,
                info:info,
                patient_info:patient_info,
                status:status,
                remainder_users:remainder_users,
                remainder_patient:remainder_patient,
                event_ids:event_ids,
                users:users
            }
        );
    },

    /*
		GET 	/api/appointments/{slug}/edit
	*/
    getAppointmentEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/appointments/' + slug + '/edit' );
	},


    /*
		PUT 	/api/appointments/{slug}
	*/
    putEditAppointment: function(slug, category_id,patient_id,clinic_id,service_id,room,date_from,date_to,info,patient_info,status,remainder_users,remainder_patient,event_ids,users){
        return axios.put( HGO_CONFIG.API_URL + '/appointments/'+ slug,
            {
                category_id: category_id,
                patient_id:patient_id,
                clinic_id:clinic_id,
                service_id:service_id,
                room:room,
                date_from:date_from,
                date_to:date_to,
                info:info,
                patient_info:patient_info,
                status:status,
                remainder_users:remainder_users,
                remainder_patient:remainder_patient,
                event_ids:event_ids,
                users:users
            }
        );
    },

     /*
		PUT 	/api/appointments/{slug}/staus
	*/
    putEditAppointmentStatus: function(slug, status){
        return axios.put( HGO_CONFIG.API_URL + '/appointments/'+ slug + '/status',
            {
                status: status,
            }
        );
    },

     /*
		PUT 	/api/appointments/{slug}/info
	*/
    putEditAppointmentInfo: function(slug, info){
        return axios.put( HGO_CONFIG.API_URL + '/appointments/'+ slug + '/info',
            {
                info: info,
            }
        );
    },

    //  /*
	// 	GET 	/api/appointments/reactivate/{slug}
	// */
    // putReactivateAppointment: function(slug){
    //     return axios.get( HGO_CONFIG.API_URL + '/appointments/reactivate/' + slug);
    // },

    // /*
	// 	DELETE /api/appointments/{slug}
	// */
    // deleteAppointment: function( slug ){
	// 	return axios.delete( HGO_CONFIG.API_URL + '/appointments/' + slug );
    // },

    // /*
	// 	DELETE /api/appointments/
	// */
    // deleteAppointments: function( slug ){
	// 	return axios.delete( HGO_CONFIG.API_URL + '/appointments' ,{
    //         params:{
    //             slug:slug,
    //     }});
    // },
    
    // /*
	// 	DELETE /api/appointments/{slug}/force
	// */
    // forceDeleteAppointment: function( slug ){
	// 	return axios.delete( HGO_CONFIG.API_URL + '/appointments/' + slug + '/force' );
	// }

     /*
        GET     /api/flows
    */
        getFlows: function(options){
            
            
            return axios.get( HGO_CONFIG.API_URL + '/flows',{
                params:{
                    selectedPatient:options.selectedPatient,
                    selectedAppointment:options.selectedAppointment,
                    selectedCategories:options.selectedCategories,
                    selectedAttachments:options.selectedAttachments,
                    selectedStatuses:options.selectedStatuses,
                    fromDate:options.fromDate,
                    toDate:options.toDate,
            }});
        },

        /*
        GET     /api/flows/attachmentFileContent
    */
        getAttachmentFileContent: function(slug,file_path){
            return axios.get( HGO_CONFIG.API_URL + '/flows/'+slug +'/attachmentFileContent',{
                params:{
                    file_path:file_path,
            }});
        },

         /*
        GET     /api/flows/{slug}/signZLAFileContent
    */
        getAttachmentSignZLAFileContent: function(slug,file_path,password){
            return axios.get( HGO_CONFIG.API_URL + '/flows/'+slug +'/signZLAFileContent',{
                params:{
                    file_path:file_path,
                    password:password
            }});
        },

           /*
        GET     /api/flows/{slug}/unsignZLAFileContent
    */
        getAttachmentUnsignZLAFileContent: function(slug,file_path,password){
            return axios.get( HGO_CONFIG.API_URL + '/flows/'+slug +'/unsignZLAFileContent',{
                params:{
                    file_path:file_path,
                    password:password
            }});
        },

           /*
        GET     /api/flows/{slug}/desc
    */
        putEditFileContentDesc: function(slug,file_path,desc){
            return axios.put( HGO_CONFIG.API_URL + '/flows/'+slug +'/desc',{
                    file_path:file_path,
                    file_desc:desc
            });
        },


              /*
        PUT     /api/flows/{slug}/desc
    */
        putDeleteFile: function(slug,file_path,desc){
            return axios.put( HGO_CONFIG.API_URL + '/flows/'+slug +'/delete',{
                    file_path:file_path
            });
        },

        /*
        GET     /api/appointments/search
        */
        searchFlows: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/flows/search',{
                params:{
                    selectedPatient:options.selectedPatient,
                    filterableHeaders:options.filterableHeaders,
                    q:options.q
            }});
        },

         /*
        GET     /api/payments
    */
        getPayments: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/payments',{
                params:{
                    offset:options.offset,
                    itemsPerPage:options.itemsPerPage,
                    orderBy:options.orderBy,
                    orderDirection:options.orderDirection,
                    fromDate:options.fromDate,
                    toDate:options.toDate,
                    selectedStatus:options.selectedStatus,
                    selectedUser:options.selectedUser,
                    selectedPatient:options.selectedPatient,
                    selectedClinic:options.selectedClinic,
                    selectedService:options.selectedService,
            }});
        },

         /*
		PUT 	/api/payments/{slug}
	*/
    putEditPayment: function(slug, payment_status,payment_amount,payment_currency,invoice_number,invoice_date){
        return axios.put( HGO_CONFIG.API_URL + '/payments/'+ slug,
            {
                payment_status: payment_status,
                payment_amount:payment_amount,
                payment_currency:payment_currency,
                invoice_number:invoice_number,
                invoice_date:invoice_date,
            }
        );
    },

}