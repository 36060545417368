<template>
      <v-col>
          <v-card flat>
         <v-data-table
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-1"
            :loading="patientsLoadStatus===1"
            :options.sync="tableOptions"
            :server-items-length="options.lineCount"
            hide-default-footer
            must-sort
            dense
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Pacjenci</v-toolbar-title>
                
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                

                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Wyszukaj"
                      single-line
                      hide-details
                      class="search"
                      @keydown.enter="searchPatients"
                  ></v-text-field>
                  </template>
                    <span>Wpisz min 3 znaki i naciśnij Enter</span>
                </v-tooltip>
                 

                <v-spacer></v-spacer>

                <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż zamknięte"></v-checkbox>

                <v-divider
                  v-if="user.permissions['patient'] >= 10"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-dialog v-if="user.permissions['patient'] >= 10" v-model="dialog" :max-width="itemEditing || patientEditing?'1000px':'500px'">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <patient-editor :item-editing="itemEditing" @close="close"></patient-editor>
                </v-dialog>

              </v-toolbar>
            </template>
            <!-- <template v-slot:item.type="{ item }">
              <span v-if="item.type">{{_.find(lookups.patient_type,{'value':item.type}).text}}</span>
            </template> -->
            <template v-slot:item.actions="{ item }">

                <v-tooltip v-if="item.status && user.permissions['patient'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="editItem(item)" icon><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && item.can_delete && user.permissions['patient'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="deleteItem(item)" icon><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status  && user.permissions['patient'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="reactivateItem(item)" icon><v-icon>mdi-delete-restore</v-icon></v-btn>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['patient'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="forceDeleteItem(item)" icon><v-icon color="error">mdi-delete-forever</v-icon></v-btn>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && user.permissions['patient'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="{name:'pacjent',params:{id:item.id}}" x-small color="secondary" class="ml-3" >Karta</v-btn>
                  </template>
                    <span>Karta pacjenta</span>
                </v-tooltip>
                
            </template>

             <template v-slot:item.phone="{ item }">
              {{item.phone_prefix=='48'?item.phone:('+'+item.phone_prefix+item.phone)}}
            </template>
            
            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="!item.status" disabled></v-simple-checkbox>
            </template>

          </v-data-table> 
          <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && patientsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadPatients" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadPatients" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>

          </v-card>
      
      <confirm ref="confirm"></confirm>
      </v-col>
      

</template>

<script>
  import confirm from '../components/Confirm'
  import PatientEditor from '../components/PatientEditor'
  

  export default {
    props:{
      reload: Boolean
    },
    
    components:{
      confirm,PatientEditor
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      search:'',
      itemEditing:false,
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Imię', value: 'name',filterable:true,filterColumn:"name",visible:true},
        { text: 'Nazwisko', value: 'surname',filterable:true,filterColumn:"surname",visible:true},
        { text: 'Wiek', value: 'age',filterable:true,filterColumn:"age",visible:true},
        { text: 'Pesel', value: 'pesel',filterable:true,filterColumn:"pesel",visible:true},
        { text: 'Miasto', value: 'city',filterable:false,filterColumn:"city",visible:true},
        { text: 'Telefon', value: 'phone',filterable:false,filterColumn:"phone",visible:true},
        { text: 'E-mail', value: 'email',filterable:false,filterColumn:"email",visible:true},
        { text: 'Zamknięte', value: 'status',filterable:false,filterColumn:"status",visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        val || this.close()
      },
      patientEditStatus(val){
        if(val===2){
          //this.close();
          //this.componentKey += 1;
        }
      },
      patientAddStatus(val){
        if(val===2){
          //this.close();
          //this.componentKey += 1;
        }
      },
      patientDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      },
     
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getPatientOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setPatientOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getPatientOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      editedItem(){
        return this.$store.getters.getPatientEdit;
      },
      tableItems(){
        return this.$store.getters.getPatients;
      },
      patientsLoadStatus(){
        return this.$store.getters.getPatientsLoadStatus;
      },
      patientEditLoadStatus(){
        return this.$store.getters.getPatientEditLoadStatus
      },
      patientEditStatus(){
        return this.$store.getters.getPatientEditStatus();
      },
      patientAddStatus(){
        return this.$store.getters.getPatientAddStatus();
      },
      patientDeleteStatus(){
        return this.$store.getters.getPatientDeleteStatus();
      },
      patientEditing(){
        return this.$store.getters.getPatientEditing;
      },
      computedHeaders () {
        var h = _.filter(this.headers,function(o) { return o.visible; })

        // if(!this.showDeleted && this.user.permissions['patient'] <= 5) return h.filter(h => h.value!=='status' && h.value!=='actions')
        // else if(this.showDeleted && this.user.permissions['patient'] <= 5) return h.filter(h => h.value!=='actions')
        // else 
        if(!this.showDeleted) return h.filter(h => h.value!=='status')
        else return h
      },
    },
    mounted(){
      if(this.reload)this.loadPatients();
    },
    methods: {
      loadPatients(){
        this.search='';
        this.$store.dispatch( 'loadPatients' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMorePatients' );
      },
      editItem (item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadPatientEdit', {slug:  item.id});  
        this.dialog = true
      },
      reactivateItem(item){
        this.$store.dispatch( 'editPatientReactivate',{slug:item.id});
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz zamknąć kartę pacjenta?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deletePatient', {slug:  item.id});
            }
        })
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać pacjenta na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeletePatient', {slug:  item.id});
            }
        })
      },
      searchPatients(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchPatients',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
      close () {
        this.dialog = false
        this.componentKey += 1
        setTimeout(() => {
          this.$store.dispatch( 'loadPatientEditDefault'); 
          this.$store.dispatch( 'loadPatientAddDefault');
          this.$store.commit('setPatientsRecommendationLookup',[]);
          this.itemEditing = false
        }, 300)
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>