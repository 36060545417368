<template>
    <div>
      <v-row dense>
                                <v-col cols="8">
                                    <v-text-field @input="checkChange()" class="mt-2 mb-0" outlined dense v-model="editedItem.content.recipient" required label="Adres e-mail" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-spacer />
                                <v-col>
                                    <v-switch dense class="mt-3 mb-0" v-model="editedItem.content.footer" label="Stopka" hide-details="auto" @change="checkChange()" />
                                </v-col>
                              </v-row>
                                 
                              <v-row  class="mb-0" dense>
                                <v-col cols="8" >
                                  <v-text-field v-if="editedItem.type==40" @input="checkChange()" class="my-2" outlined dense v-model="editedItem.content.header" required label="Tytuł wiadomości" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="4" >
                                  <v-text-field v-if="editedItem.type==40" @input="checkChange()" class="my-2" outlined dense v-model="editedItem.content.password" required label="Hasło" hide-details="auto"></v-text-field>
                                </v-col>
                              </v-row>
    </div>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  export default {
    components:{
    },
    props: {
      height:{
        type:Number,
        default:0
      },
    },
    data: () => ({
      
    }),
    validations() {
      
    },
    watch: {
     
    },
    computed: {
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
    },
    methods: {
      sendAttachment(id){
        this.$store.dispatch( 'sendAttachment', {slug:  id} );
      },
      checkChange(){
        this.$emit('changed');
      }
    },
  }
</script>