<template>
    <v-container class="d-flex flex-column pa-0 ml-3">

        <v-container v-if="user.permissions.clinics >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'ustawienia.kliniki' }" color="secondary" icon><v-icon size="35">{{ $route.name === 'ustawienia.kliniki' || $route.name === 'ustawienia.klinika' || $route.name === 'ustawienia.kliniki.powrot' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Klinika</span>
        </v-container>

        <v-container v-if="user.permissions.category >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'ustawienia.dzialania' }" color="secondary" icon><v-icon size="35">{{ $route.name === 'ustawienia.dzialania' || $route.name === 'ustawienia.dzialanie' || $route.name === 'ustawienia.dzialania.powrot' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Działania</span>
        </v-container>

        <v-container v-if="user.permissions.service >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'ustawienia.diagnostyki' }" color="secondary" icon><v-icon size="35">{{ $route.name === 'ustawienia.diagnostyki' || $route.name === 'ustawienia.diagnostyka' || $route.name === 'ustawienia.diagnostyki.powrot' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Diagnostyka</span>
        </v-container>

        <v-container v-if="user.permissions.role >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'ustawienia.role' }" color="secondary" icon><v-icon size="35">{{ $route.name === 'ustawienia.role' || $route.name === 'ustawienia.rola' || $route.name === 'ustawienia.role.powrot' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Role</span>
        </v-container>
         
         <v-container v-if="user.permissions.user >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'ustawienia.uzytkownicy' }" color="secondary" icon><v-icon size="35">{{ $route.name === 'ustawienia.uzytkownicy' || $route.name === 'ustawienia.uzytkownik' || $route.name === 'ustawienia.uzytkownicy.powrot' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Użytkownicy</span>
        </v-container>

         <v-container v-if="user.permissions.leaflet >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'ustawienia.ulotki' }" color="secondary" icon><v-icon size="35">{{ $route.name === 'ustawienia.ulotki' || $route.name === 'ustawienia.ulotka' || $route.name === 'ustawienia.ulotki.powrot' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Ulotki</span>
        </v-container>

    </v-container>
</template>

<script>
export default {
    data(){
        return{
            defaultColor:'secondary', 
            selectedColor:'primary'
        }
    },
    mounted(){
      //pobierabie liczników
      //this.$store.dispatch( 'loadMenuSettingsCount' );
      //this.$store.dispatch( 'loadLookups' );
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
    },
}
</script>

<style scoped>
.menu{
  margin-bottom: 1em;
  padding-top:0px;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}
</style>