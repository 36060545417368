import CategoryAPI from "../api/category";

export const categories = {
    state: {
        categories: [],
        categoryOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:10,
            itemAdded:false,
            itemSearched:false
        },
        categoriesLoadStatus: 0,
        categoryAddStatus: 0,
        categoryAddText: "",

        categoryEdit: {
            id: undefined,
            lp: undefined,
            name: undefined,
            description:undefined,
            icon:undefined
        },
        categoryEditDefault: {
            id: undefined,
            lp: undefined,
            name: undefined,
            description:undefined,
            icon:undefined
        },
        categoryEditLoadStatus: 0,
        categoryEditStatus: 0,
        categoryEditText: "",

        categoryDeleteStatus: 0,
        categoryDeleteText: "",

    },

    actions: {
        loadCategories({ state, commit, dispatch }) {
            commit("setCategoriesLoadStatus", 1);
                CategoryAPI.getCategories({
                    offset:0,
                    itemsPerPage:state.categoryOptions.itemsPerPage, 
                    orderBy:state.categoryOptions.orderBy,
                    orderDirection:state.categoryOptions.orderDirection,
                    showDeleted:state.categoryOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setCategoryLineCount", response.data.lineCount);
                    commit("setCategories", response.data.categories);
                    dispatch("applyIndexOnCategories");
                    commit("setCategoriesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setCategories", []);
                    commit("setCategoriesLoadStatus", 3);
                });
         
        },
        loadMoreCategories({ state, commit, dispatch }) {
            commit("setCategoriesLoadStatus", 1);
                CategoryAPI.getCategories({
                    offset:state.categories.length,
                    itemsPerPage:state.categoryOptions.itemsPerPage, 
                    orderBy:state.categoryOptions.orderBy,
                    orderDirection:state.categoryOptions.orderDirection,
                    showDeleted:state.categoryOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setCategoryLineCount", response.data.lineCount);
                    commit("setCategories", _.concat(state.categories, response.data.categories));
                    dispatch("applyIndexOnCategories");
                    commit("setCategoriesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setCategories", []);
                    commit("setCategoriesLoadStatus", 3);
                });
         
        },
        searchCategories({ state, commit, dispatch },data) {
            commit("setCategoriesLoadStatus", 1);
                CategoryAPI.searchCategories({
                    showDeleted:state.categoryOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setCategorySearchedTrue");
                    commit("setCategoryLineCount", response.data.lineCount);
                    commit("setCategories", response.data.categories);
                    dispatch("applyIndexOnCategories");
                    commit("setCategoriesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setCategories", []);
                    commit("setCategoriesLoadStatus", 3);
                });
         
        },
        setCategoryOptions({ state, commit, dispatch },data){
            commit("setCategoryOptions",data.options);
            dispatch("loadCategories");
        },
        applyIndexOnCategories({ commit, state, dispatch }) {
            let localCategories = state.categories;

            var i = 1;
            localCategories.forEach(category => {
                category.lp = i;
                i++;
            });

            commit("setCategories",localCategories);
        },
        addCategory({ commit, state, dispatch }, data) {
            commit("setCategoryAddStatus", 1);
            commit("setCategoryAddText", "");

            CategoryAPI.postAddNewCategory(
                data.name,
                data.description,
                data.icon
            )
                .then(function(response) {
                    commit("setCategoryAddStatus", 2);
                    commit("setAddedCategory",response.data);
                    commit("setCategoryLineCount", state.categoryOptions.lineCount + 1);
                    dispatch("applyIndexOnCategories");
                    dispatch("loadCategoryLookup");
                })
                .catch(function(error) {
                    commit("setCategoryAddStatus", 3);
                    commit("setCategoryAddText", error.response.data.errors);
                });
        },

        loadCategoryEdit({ commit }, data) {
            commit("setCategoryEditLoadStatus", 1);
            commit("setCategoryEditStatus", 0);
            commit("setCategoryEditText", "");

            CategoryAPI.getCategoryEdit(data.slug)
                .then(function(response) {
                    commit("setCategoryEdit", response.data);
                    commit("setCategoryEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setCategoryEdit", {});
                    commit("setCategoryEditLoadStatus", 3);
                });
        },
        loadPermissionList({ commit }, data) {
            commit("setCategoryEditLoadStatus", 1);
            commit("setCategoryEditStatus", 0);
            commit("setCategoryEditText", "");

            CategoryAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setPermissionListEdit", response.data);
                    commit("setCategoryEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setCategoryEdit", {});
                    commit("setCategoryEditLoadStatus", 3);
                });
        },
        loadCategoryEditDefault({ commit }) {
            commit("setCategoryEditDefault");
        },
        loadCategoryAddDefault({ commit }) {
            commit("setCategoryAddStatus", 0);
            commit("setCategoryAddText", "");
        },
        editCategory({ commit, state, dispatch }, data) {
            commit("setCategoryEditStatus", 1);

            CategoryAPI.putEditCategory(
                data.slug,
                data.name,
                data.description,
                data.icon
            )
                .then(function(response) {
           
                    commit("setCategoryEditStatus", 2);
                    commit("setEditedCategory",response.data)
                    dispatch("applyIndexOnCategories");
                    dispatch("loadCategoryLookup");
                })
                .catch(function(error) {
                    commit("setCategoryEditStatus", 3);
                    commit("setCategoryEditText", error.response.data.errors);
                });
        },
        editCategoryReactivate({ commit, state, dispatch }, data) {
            commit("setCategoryEditStatus", 1);
  
            CategoryAPI.putReactivateCategory(data.slug)
                .then(function(response) {
                    commit("setCategoryEditStatus", 2);
                    commit("setEditedCategory",response.data)
                    dispatch("applyIndexOnCategories");
                    dispatch("loadCategoryLookup");
                })
                .catch(function(error) {
                    commit("setCategoryEditStatus", 3);
                });
        },
        deleteCategory({ commit, state, dispatch }, data) {
            commit("setCategoryDeleteStatus", 1);

            CategoryAPI.deleteCategory(data.slug)
                .then(function(response) {
                    commit("setCategoryDeleteStatus", 2);
                    if(state.categoryOptions.showDeleted){
                        commit("setEditedCategory",response.data)
                    }else{
                        commit("setCategoryLineCount", state.categoryOptions.lineCount - 1);
                        commit("setDeletedCategory",response.data)
                    }
                    dispatch("loadCategoryLookup");
                    dispatch("applyIndexOnCategories");
                })
                .catch(function() {
                    commit("setCategoryDeleteStatus", 3);
                });
        },
        forceDeleteCategory({ commit, state, dispatch }, data) {
            commit("setCategoryDeleteStatus", 1);

            CategoryAPI.forceDeleteCategory(data.slug)
                .then(function(response) {
                    commit("setCategoryDeleteStatus", 2);
                    commit("setCategoryLineCount", state.categoryOptions.lineCount - 1);
                    commit("setDeletedCategory",response.data)
                    dispatch("applyIndexOnCategories");
                })
                .catch(function() {
                    commit("setCategoryDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setCategoryOptions(state,options){
            state.categoryOptions = options;
        },
        setCategoriesLoadStatus(state, status) {
            state.categoriesLoadStatus = status;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        setCategoryLineCount(state, lineCount) {
            state.categoryOptions.lineCount = lineCount;
        },
        setCategoryAddStatus(state, status) {
            state.categoryAddStatus = status;
        },
        setCategoryAddText(state, text) {
            state.categoryAddText = text;
        },
        setCategoryEdit(state, category) {
            state.categoryEdit = category;
        },
        setPermissionListEdit(state, list) {
            state.categoryEdit.computed_permissions = list;
        },
        setEditedCategory(state, category) {
            var lp = _.find(state.categories,{'id':category.id}).lp;
            var index = _.findIndex(state.categories,{'id':category.id});
       
            state.categories[index] = category;
            state.categories[index].lp = lp;
        },
        setAddedCategory(state, category) {
            state.categoryOptions.itemAdded = true;
            state.categories.push(category);
        },
        setCategorySearchedTrue(state) {
            state.categoryOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.categoryOptions.itemAdded = false;
            state.categoryOptions.itemSearched = false;
        },
        setDeletedCategory(state, category) {
            var index = _.findIndex(state.categories,{'id':category.id});
            state.categories.splice(index,1);
        },
        setCategoryEditDefault(state) {
            state.categoryEdit = { ...state.categoryEditDefault };
        },
        setCategoryEditStatus(state, status) {
            state.categoryEditStatus = status;
        },
        setCategoryEditText(state, text) {
            state.categoryEditText = text;
        },
        setCategoryEditLoadStatus(state, status) {
            state.categoryEditLoadStatus = status;
        },
        setCategoryDeleteStatus(state, status) {
            state.categoryDeleteStatus = status;
        },
        setCategoryDeletedText(state, text) {
            state.categoryDeleteText = text;
        }
    },

    getters: {
        getCategoryOptions(state){
            return state.categoryOptions;
        },
        getCategoriesLoadStatus(state) {
            return state.categoriesLoadStatus;
        },
        getCategories(state) {
            return state.categories;
        },
        getCategoryAddStatus(state) {
            return function(){
                return state.categoryAddStatus;
            } 
        },
        getCategoryAddText(state) {
            return state.categoryAddText;
        },
        getCategoryEdit(state) {
            return state.categoryEdit;
        },
        getCategoryEditStatus(state) {
          return function(){
            return state.categoryEditStatus;
          }
        },
        getCategoryEditing(state) {
            return state.categoryEdit.id > 0;
        },
        getCategoryEditText(state) {
            return state.categoryEditText;
        },
        getCategoryEditLoadStatus(state) {
            return state.categoryEditLoadStatus;
            
        },
        getCategoryDeleteStatus(state) {
            return function(){
                return state.categoryDeleteStatus;
            }
        },
        getCategoryDeleteText(state) {
            return state.categoryDeleteText;
        }
    }
};
