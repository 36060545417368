<template>
  <v-autocomplete
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedValue"
                         @change="valueChange"
                        :items="lookup"
                        @update:search-input="changeSelection"
                        hide-no-data
                        hide-details
                        single-line
                        :label="label"
                        :dense="dense"
                        :readonly="selectedValue!==null || readonly"
                        :loading="lookupLoadStatus===1"
                      >
                       <template v-slot:append-outer>
                         <v-btn x-small color="primary" @click="clearContent">Wyczyść</v-btn>
                       </template>
                      </v-autocomplete>

</template>
<script>
export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj lek"
   },
   disabled:{
     type:Boolean,
     default:false
   },
   readonly:{
     type:Boolean,
     default:false
   },
   selectedText:{
     type:String,
     default:null
   },
  },
  data: () => ({
    selectedValue:null
  }),
  watch:{
  
  },
  computed: {
    lookupLoadStatus(){
      return this.$store.getters.getPrescriptionProductsLoadStatus();
    },
    lookup(){
      if(this.selectedText && _.filter(this.$store.getters.getPrescriptionProducts,{'value':this.selectedValue}).length===0)
        return [{value:this.selectedText,text:this.selectedText}];
      else return _.map(this.$store.getters.getPrescriptionProducts,'text')
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    valueChange(val){
      if(val){
        this.selectedValue = val;
        this.$emit('valueSelected', val)
        this.$store.commit('setPrescriptionProducts',[]);
        this.$store.dispatch( 'getPrescriptionPackages',{q:val});
      }
    },
    clearContent(){
      this.selectedValue = null;
      this.$emit('valueSelected', null)
      this.$store.commit('setPrescriptionProducts',[]);
      this.$store.commit('setPrescriptionPackages',[]);
    },
    changeSelection (val) {
      if(this.selectedText){//edycja
        val && val !== this.selectedText && val.length>2 && this.querySelections(val)
      }else{
        val && val !== this.selectedValue && val.length>2 && this.querySelections(val)
      }
      
    },
    querySelections (v) {
        this.$store.dispatch( 'getPrescriptionProducts',{q:v}); 
      },
  }
};
</script>
<style scoped>

</style>