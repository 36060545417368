/*
  Adds the promise polyfill for IE 11
*/
require('es6-promise').polyfill();

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { clinics } from './modules/clinics'
import { clinicServices } from './modules/clinicServices'
import { users } from './modules/users'
import { roles } from './modules/roles'
import { categories } from './modules/categories'
import { services } from './modules/services'
import { leaflets } from './modules/leaflets'
import { events } from './modules/events'
import { patients } from './modules/patients'
import { appointments } from './modules/appointments'
import { attachments } from './modules/attachments'
import { flows } from './modules/flows'
import { tasks } from './modules/tasks'
import { templates } from './modules/templates'
import { payments } from './modules/payments'
import { billings } from './modules/billings'
import { prescriptions } from './modules/prescriptions'

import SharedAPI from "./api/shared";

export default new Vuex.Store({
  modules: {
    clinics,
    clinicServices,
    users,
    roles,
    categories,
    services,
    leaflets,
    events,
    patients,
    appointments,
    attachments,
    flows,
    tasks,
    templates,
    payments,
    billings,
    prescriptions
  },
  state: {
    lookups:{
        permissions: [
            { value: 0, text: "Brak" },
            { value: 5, text: "Swoje" },
            { value: 10, text: "Pełne" },
        ],
        user_types: [
          { value: 1, text: "Lekarz" },
          { value: 2, text: "Konsultant medyczny" },
          { value: 3, text: "Administrator" },
        ],
        referral_type: [
          { value: 1, text: "Detection mpMRI" },
          { value: 2, text: "Scyntygrafia kości + SPECT" },
          { value: 3, text: "68Ga-PSMA PET MRI" },
          { value: 4, text: "68Ga-PSMA PET CT" },
          { value: 5, text: "PET/CT z choliną" },
          { value: 6, text: "PET/MRI z choliną" },
          { value: 7, text: "Staging mpMRI prostaty z podaniem kontrastu" },
        ],
        patient_contact_type: [
          { value: 1, text: "Partner" },
          { value: 2, text: "Partnerka" },
          { value: 3, text: "Syn" },
          { value: 4, text: "Córka" },
          { value: 5, text: "Znajomy" },
        ],
        patient_sex: [
          { value: "M", text: "Mężczyzna" },
          { value: "F", text: "Kobieta" },
        ],
        patient_insurance: [
          { value: 1, text: "ZUS" },
          { value: 2, text: "KRUS" },
          { value: 3, text: "Inny w PL" },
          { value: 4, text: "Inny poza PL" },
        ],
        prescription_status:[
          { value: 0, text: "Nowa" },
          { value: 1, text: "Wystawiona" },
          { value: 2, text: "Anulowana" },
          { value: 3, text: "Zrealizowana" },
          { value: 4, text: "Częściowo zrealizowana" },
        ],
        nfz:[
          { value: "01", text: "Dolnośląski" },
          { value: "02", text: "Kujawsko-Pomorski" },
          { value: "03", text: "Lubelski" },
          { value: "04", text: "Lubuski" },
          { value: "05", text: "Łódzki" },
          { value: "06", text: "Małopolski" },
          { value: "07", text: "Mazowiecki" },
          { value: "08", text: "Opolski" },
          { value: "09", text: "Podkarpacki" },
          { value: "10", text: "Podlaski" },
          { value: "11", text: "Pomorski" },
          { value: "12", text: "Śląski" },
          { value: "13", text: "Świętokrzyski" },
          { value: "14", text: "Warmińsko-Mazurski" },
          { value: "15", text: "Wielkopolski" },
          { value: "16", text: "Zachodniopomorski" },
        ],
        clinic_type: [
          { value: 1, text: "Centralna" },
          { value: 2, text: "Oddział" },
          { value: 3, text: "Partner/Inna Placówka medyczna" },
        ],
        interval:[
          { value: 5, text: "5 minut" },
          { value: 10, text: "10 minut" },
          { value: 15, text: "15 minut" },
          { value: 20, text: "20 minut" },
          { value: 25, text: "25 minut" },
          { value: 30, text: "30 minut" },
          { value: 35, text: "35 minut" },
          { value: 40, text: "40 minut" },
          { value: 45, text: "45 minut" },
          { value: 50, text: "50 minut" },
          { value: 55, text: "55 minut" },
          { value: 60, text: "60 minut" },
        ],
        remainders:[
          { value: 5,    text: "5min przed"},
          { value: 15,   text: "15min przed"},
          { value: 30,   text: "30min przed"},
          { value: 60,   text: "1h przed"},
          { value: 180,  text: "3h przed"},
          { value: 360,  text: "6h przed"},
          { value: 720,  text: "12h przed"},
          { value: 1440, text: "24h przed"},
        ],
        event_type: [
          { value: 1, text: "Lekarz", color:"blue darken-3", icon:"mdi-stethoscope" },
          { value: 2, text: "Urlop", color:"orange darken-3", icon:"mdi-beach" },
          { value: 3, text: "Dni wolne", color:"brown darken-3", icon:"mdi-calendar-outline" },
          { value: 4, text: "Gabinet", color:"green darken-3", icon:"mdi-medical-bag" },
          { value: 5, text: "Blok", color:"purple darken-3", icon:"mdi-hospital-box-outline" },
          { value: 6, text: "Inny", color:"grey darken-2", icon:"mdi-calendar-question" },
        ],
        appointment_status:[
          { value: 1, text: "Zaplanowane" },
          { value: 5, text: "Przyjęte" },
          { value: 10, text: "Potwierdzone" },
          { value: 15, text: "Zrealizowane" },
          { value: 20, text: "Anulowane" },
        ],
        payment_status:[
          { value: 1, text: "Niepłacone" },
          { value: 5, text: "Opłacone" },
          { value: 10, text: "Opłacone częściowo" },
          { value: 15, text: "Bezpłatne" },
        ],
        billing_type:[
          {value: 1,text:"Przychodzące"},
          {value: 2,text:"Wychodzące"},
        ],
        billing_status:[
          {value: 1,text:"Nawiązane"},
          {value: 2,text:"Odebrane"},
          {value: 3,text:"Nieodebrane"},
          {value: 4,text:"Przekierowane"},
        ],
        billing_action:[
          {value: 1,text:"Obsłużone"},
          {value: 2,text:"Nieobsłużone"},
        ],
        attachment_type:[
          { value:10, name:"Opis", header:"Opis"},
          { value:20, name:"Zalecenia", header:"Zalecenia"},
          { value:30, name:"Link", header:"Link do spotkania"},
          { value:40, name:"E-Mail", header:"E-Mail"},
          { value:50, name:"Zadanie", header:"Zadanie"},
          { value:60, name:"Skierowanie", header:"Skierowanie"},
          { value:70, name:"e-Recepta", header:"e-Recepta"},
          { value:80, name:"Wyniki", header:"Wyniki"},
        ],
        currencies:[
          { value: 1, text: "PLN" },
          { value: 2, text: "EUR" },
          { value: 3, text: "CHF" },
        ],
        months:[
          { value: 1, text: "Styczeń" },
          { value: 2, text: "Luty" },
          { value: 3, text: "Marzec" },
          { value: 4, text: "Kwiecień" },
          { value: 5, text: "Maj" },
          { value: 6, text: "Czerwiec" },
          { value: 7, text: "Lipiec" },
          { value: 8, text: "Sierpień" },
          { value: 9, text: "Wrzesień" },
          { value: 10, text: "Pażdziernik" },
          { value: 11, text: "Listopad" },
          { value: 12, text: "Grudzień" },
        ],
        days:[
          { value: 0, short: "Pon.", variant:'poniedziałek' },
          { value: 1, short: "Wt.", variant:'wtorek' },
          { value: 2, short: "Śr." , variant:'środę'},
          { value: 3, short: "Czw.", variant:'czwartek' },
          { value: 4, short: "Pt.", variant:'piątek' },
          { value: 5, short: "Sob.", variant:'sobotę' },
          { value: 6, short: "Niedz.", variant:'niedzielę' },
        ],
        log:[
          { value: 1, text: "Utworzenie kartoteki" },
          { value: 2, text: "Edycja danych pacjenta" },
          { value: 3, text: "Wyłączenie pacjenta" },
          { value: 4, text: "Włączenie pacjenta" },
          { value: 5, text: "Usunięcie pacjenta" },
          
          { value: 21, text: "Utworzenie osoby kontaktowej" },
          { value: 22, text: "Edycja danych osoby kontaktowej" },
          { value: 25, text: "Usunięcie osoby kontaktowej" },

          { value: 101, text: "Zaplanowanie działania" },
          { value: 102, text: "Edycja danych działania" },
          { value: 103, text: "Zmiana statusu działania" },
          { value: 110, text: "Utworzenie załącznika" },
          { value: 112, text: "Edycja danych załącznika" },
          { value: 115, text: "Usunięcie załącznika" },
          { value: 116, text: "Akcja załącznika" },
        ],
        logParams:[
          { value:'patient.name',text: 'Imię'},
          { value:'patient.surname',text: 'Nazwisko'},
          { value:'patient.phone',text: 'Numer telefonu'},
          { value:'patient.pesel',text: 'PESEL'},
          { value:'patient.birth_date',text: 'Data urodzenia'},
          { value:'patient.age',text: 'Wiek'},
          { value:'patient.email',text: 'Adres e-mail'},
          { value:'patient.post_code',text: 'Kod pocztowy'},
          { value:'patient.city',text: 'Miasto'},
          { value:'patient.address',text: 'Adres'},
          { value:'patient.house',text: 'Nr domu'},
          { value:'patient.apartment',text: 'Nr mieszkania'},
          { value:'patient.currency',text: 'Waluta rozliczeniowa'},

          { value:'contact.name',text: 'Imię'},
          { value:'contact.surname',text: 'Nazwisko'},
          { value:'contact.phone',text: 'Numer telefonu'},
          { value:'contact.email',text: 'Adres e-mail'},
          { value:'contact.role',text: 'Rola'},

          { value:'appointment.patient',text: 'Pacjent'},
          { value:'appointment.date_from',text: 'Data rozpoczęcia'},
          { value:'appointment.date_to',text: 'Data zakończenia'},

        ],
        roles:[],
        users:[],
        leaflets:[],
        categories:[{ value: 1, text: "Badanie", icon:"mdi-stethoscope" },],
    },
    lookupsLoadStatus: 0,
    resultsAddStatus:0,
    reservationAddStatus:0,
    miniVariant:false,
    publicEvents:[],
    publicEventsMinTime:null,
    publicEventsMaxTime:null,
    publicEventsLoadStatus:0,
  },
  actions: {
    loadLookups({ commit }) {
        commit("setLookupsLoadStatus", 1);

        SharedAPI.getLookups()
            .then(function(response) {
                commit("setRolesLookup", response.data.roles);
                commit("setLeafletsLookup", response.data.leaflets);
                commit("setCategoriesLookup", response.data.categories);
                commit("setLookupsLoadStatus", 2);
            })
            .catch(function() {
                commit("setRolesLookup", []);
                commit("setLookupsLoadStatus", 3);
            });
    },
    loadCategoryLookup({ commit }) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getCategoryLookup()
          .then(function(response) {
              commit("setCategoriesLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setCategoriesLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    loadRoleLookup({ commit }) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getRoleLookup()
          .then(function(response) {
              commit("setRolesLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setRolesLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    loadUserLookup({ commit }) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getUserLookup()
          .then(function(response) {
              commit("setUsersLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setUsersLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    addResults({ commit, state, dispatch }, data) {
      commit("setResultsAddStatus", 1);

      SharedAPI.postNewResults(
          data.name,
          data.surname,
          data.email,
          data.phone,
          data.body,
          data.files,
          data.code,
      )
          .then(function(response) {
            if(response.status===202){//pin request
                commit( 'setResultsAddStatus', 4 );
            }else if(response.status===204){//wszytko ok
                commit( 'setResultsAddStatus', 2 );
            }
          })
          .catch(function(error) {
            if(error.response.data.error==='invalid_token'){//nieprawidłowy PIN
                commit( 'setResultsAddStatus', 5 );
            }
          });
    },
    loadPublicEvents({ state, commit, dispatch },data) {
      commit("setPublicEventsLoadStatus", 1);
          SharedAPI.getPublicEvents({
              date_from:data.date_from,
              date_to:data.date_to,
          })
          .then(function(response) {
              commit("setPublicEvents", response.data.events);
              commit("setPublicEventsMinTime", response.data.minTime);
              commit("setPublicEventsMaxTime", response.data.maxTime);
              commit("setPublicEventsLoadStatus", 2);
          })
          .catch(function() {
              commit("setPublicEvents", []);
              commit("setPublicEventsMinTime", null);
              commit("setPublicEventsMaxTime", null);
              commit("setPublicEventsLoadStatus", 3);
          });
   
    },
    
    addReservation({ commit, state, dispatch }, data) {
      commit("setReservationAddStatus", 1);

      SharedAPI.postNewReservation(
          data.name,
          data.surname,
          data.email,
          data.phone,
          data.date_from,
          data.date_to,
          data.event_id,
          data.code,
      )
          .then(function(response) {
            if(response.status===202){//pin request
                commit( 'setReservationAddStatus', 4 );
            }else if(response.status===204){//wszytko ok
                commit( 'setReservationAddStatus', 2 );
            }
          })
          .catch(function(error) {
            if(error.response.data.error==='invalid_token'){//nieprawidłowy PIN
                commit( 'setReservationAddStatus', 5 );
            }
          });
    },
  },
  mutations: {
    setLookupsLoadStatus(state,status){
      state.lookupsLoadStatus = status;
    },
    setResultsAddStatus(state,status){
      state.resultsAddStatus = status;
    },
    setReservationAddStatus(state,status){
      state.reservationAddStatus = status;
    },
    setCategoriesLookup(state,data){
      state.lookups.categories = data;
      state.appointments.appointmentOptions.selectedCategories = _.map(_.filter(data,{visible:1}),'value');
      state.flows.flowOptions.selectedCategories = _.map(data,'value');
      state.flows.flowOptions.selectedAttachments = _.map(state.lookups.attachment_type,'value');
    },
    setRolesLookup(state,data){
      state.lookups.roles = data;
    },
    setUsersLookup(state,data){
      state.lookups.users = data;
    },
    setLeafletsLookup(state,data){
      state.lookups.leaflets = data;
    },
    setPublicEvents(state,events){
      state.publicEvents = events;
    },
    setPublicEventsLoadStatus(state,status){
      state.publicEventsLoadStatus = status;
    },
    setPublicEventsMinTime(state,time){
      state.publicEventsMinTime = time;
    },
    setPublicEventsMaxTime(state,time){
      state.publicEventsMaxTime = time;
    },
    setMiniVariant(state,data){
      state.miniVariant = data;
    }
  },
  getters: {
    getLookups(state){
      return state.lookups;
    },
    getLookupsLoadStatus(state){
      return state.lookupsLoadStatus;
    },
    getPublicEventsLoadStatus(state){
      return state.publicEventsLoadStatus;
    },
    getPublicEvents(state){
      return state.publicEvents;
    },
    getPublicEventsMinTime(state){
      return state.publicEventsMinTime;
    },
    getPublicEventsMaxTime(state){
      return state.publicEventsMaxTime;
    },
    getResultsAddStatus(state){
      return function(){
          return state.resultsAddStatus;
      }
    },
    getReservationAddStatus(state){
      return function(){
          return state.reservationAddStatus;
      }
    },
    getMiniVariant(state){
      return state.miniVariant;
    }
  }
})
