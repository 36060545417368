import PatientAPI from "../api/patient";

export const patients = {
    state: {
        patients: [],
        patientOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'surname',
            orderDirection:'asc',
            itemsPerPage:15,
            itemAdded:false,
            itemSearched:false
        },
        patientsLoadStatus: 0,
        patientAddStatus: 0,
        patientAddText: "",

        patientEdit: {
            id: undefined,
            lp: undefined,
            name: undefined,
            surname:undefined,
            age:undefined,
            birth_date:undefined,
            pesel:undefined,
            country_code:'PL',
            post_code:undefined,
            city:undefined,
            address:undefined,
            house:undefined,
            apartment:undefined,
            gus:undefined,
            sex:undefined,
            insurance:undefined,
            phone_prefix:'48',
            phone:undefined,
            email:undefined,
            recommendation:undefined,
            first_contact:undefined,
            source:undefined,
            contacts:[],
            currency:1,
        },
        patientEditDefault: {
            id: undefined,
            lp: undefined,
            name: undefined,
            name: undefined,
            surname:undefined,
            age:undefined,
            birth_date:undefined,
            pesel:undefined,
            country_code:'PL',
            post_code:undefined,
            city:undefined,
            address:undefined,
            phone_prefix:'48',
            phone:undefined,
            email:undefined,
            recommendation:undefined,
            first_contact:undefined,
            source:undefined,
            contacts:[],
            currency:1
        },
        patientEditLoadStatus: 0,
        patientEditStatus: 0,
        patientEditText: "",

        patientDeleteStatus: 0,
        patientDeleteText: "",

        patientsLookup:[],
        patientsLookupLoadStatus:0,

        patientsGUSLookup:[],
        patientsGUSLookupLoadStatus:0,

        patientsRecommendationLookup:[],
        patientsRecommendationLookupLoadStatus:0,

        contactEdit:{
            name: undefined,
            surname:undefined,
            type:1,
            phone_prefix:'48',
            phone:undefined,
            email:undefined,
            default:false
        },
        contactEditDefault:{
            name: undefined,
            surname:undefined,
            type:1,
            phone_prefix:'48',
            phone:undefined,
            email:undefined,
            default:false
        },
        patientContactEditLoadStatus: 0,
        patientContactAddStatus: 0,
        patientContactEditStatus: 0,
        patientContactDeleteStatus: 0,

        logLoadStatus:0,
        log:[],
        logOptions:{
            type:1,
            ref_id:undefined,
            fromDate:undefined,
            toDate:undefined
        },

    },

    actions: {
        loadPatients({ state, commit, dispatch }) {
            commit("setPatientsLoadStatus", 1);
                PatientAPI.getPatients({
                    offset:0,
                    itemsPerPage:state.patientOptions.itemsPerPage, 
                    orderBy:state.patientOptions.orderBy,
                    orderDirection:state.patientOptions.orderDirection,
                    showDeleted:state.patientOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setPatientLineCount", response.data.lineCount);
                    commit("setPatients", response.data.patients);
                    dispatch("applyIndexOnPatients");
                    commit("setPatientsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatients", []);
                    commit("setPatientsLoadStatus", 3);
                });
         
        },
        loadMorePatients({ state, commit, dispatch }) {
            commit("setPatientsLoadStatus", 1);
                PatientAPI.getPatients({
                    offset:state.patients.length,
                    itemsPerPage:state.patientOptions.itemsPerPage, 
                    orderBy:state.patientOptions.orderBy,
                    orderDirection:state.patientOptions.orderDirection,
                    showDeleted:state.patientOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setPatientLineCount", response.data.lineCount);
                    commit("setPatients", _.concat(state.patients, response.data.patients));
                    dispatch("applyIndexOnPatients");
                    commit("setPatientsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatients", []);
                    commit("setPatientsLoadStatus", 3);
                });
         
        },
        searchPatients({ state, commit, dispatch },data) {
            commit("setPatientsLoadStatus", 1);
                PatientAPI.searchPatients({
                    showDeleted:state.patientOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setPatientSearchedTrue");
                    commit("setPatientLineCount", response.data.lineCount);
                    commit("setPatients", response.data.patients);
                    dispatch("applyIndexOnPatients");
                    commit("setPatientsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatients", []);
                    commit("setPatientsLoadStatus", 3);
                });
         
        },
        searchPatientsLookup({ state, commit, dispatch },data) {
            commit("setPatientsLookupLoadStatus", 1);
                PatientAPI.searchPatientsLookup({
                    q:data.q,
                    type:data.type
                })
                .then(function(response) {
                    commit("setPatientsLookup", response.data);
                    commit("setPatientsLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatientsLookup", []);
                    commit("setPatientsLookupLoadStatus", 3);
                });
         
        },
        searchPatientsGUSLookup({ state, commit, dispatch },data) {
            commit("setPatientsGUSLookup", []);
            commit("setPatientsGUSLookupLoadStatus", 1);
                PatientAPI.searchPatientsGUSLookup({
                    q:data.q
                })
                .then(function(response) {
                    commit("setPatientsGUSLookup", response.data);
                    commit("setPatientsGUSLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatientsGUSLookup", []);
                    commit("setPatientsGUSLookupLoadStatus", 3);
                });
         
        },
        searchPatientsRecommendationLookup({ state, commit, dispatch },data) {
            commit("setPatientsRecommendationLookupLoadStatus", 1);
                PatientAPI.searchPatientsRecommendationLookup({
                    q:data.q,
                    type:data.type
                })
                .then(function(response) {
                    commit("setPatientsRecommendationLookup", response.data);
                    commit("setPatientsRecommendationLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatientsRecommendationLookup", []);
                    commit("setPatientsRecommendationLookupLoadStatus", 3);
                });
         
        },
        setPatientOptions({ state, commit, dispatch },data){
            commit("setPatientOptions",data.options);
            dispatch("loadPatients");
        },
        applyIndexOnPatients({ commit, state, dispatch }) {
            let localPatients = state.patients;

            var i = 1;
            localPatients.forEach(patient => {
                patient.lp = i;
                i++;
            });

            commit("setPatients",localPatients);
        },
        addPatient({ commit, state, dispatch }, data) {
            commit("setPatientAddStatus", 1);
            commit("setPatientAddText", "");

            PatientAPI.postAddNewPatient(
                data.name,
                data.surname,
                data.age,
                data.birth_date,
                data.pesel,
                data.city,
                data.phone_prefix,
                data.phone,
                data.email,
                data.recommendation
            )
                .then(function(response) {
                    commit("setPatientAddStatus", 2);
                    commit("setAddedPatient",response.data);
                    commit("setPatientEdit", response.data);
                    commit("setPatientLineCount", state.patientOptions.lineCount + 1);
                    dispatch("applyIndexOnPatients");
                })
                .catch(function(error) {
                    commit("setPatientAddStatus", 3);
                    commit("setPatientAddText", error.response.data.errors);
                });
        },

        loadPatientEdit({ commit }, data) {
            commit("setPatientEditLoadStatus", 1);
            commit("setPatientEditStatus", 0);
            commit("setPatientEditText", "");

            PatientAPI.getPatientEdit(data.slug)
                .then(function(response) {
                    commit("setPatientEdit", response.data);
                    commit("setPatientEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatientEdit", {});
                    commit("setPatientEditLoadStatus", 3);
                });
        },
        loadPatientEditDefault({ commit }) {
            commit("setPatientEditDefault");
        },
        loadPatientAddDefault({ commit }) {
            commit("setPatientAddStatus", 0);
            commit("setPatientAddText", "");
        },
        editPatient({ commit, state, dispatch }, data) {
            commit("setPatientEditStatus", 1);

            PatientAPI.putEditPatient(
                data.slug,
                data.name,
                data.surname,
                data.age,
                data.birth_date,
                data.pesel,
                data.country_code,
                data.post_code,
                data.city,
                data.address,
                data.house,
                data.apartment,
                data.gus,
                data.sex,
                data.insurance,
                data.phone_prefix,
                data.phone,
                data.email,
                data.recommendation,
                data.first_contact,
                data.source,
                data.currency
            )
                .then(function(response) {
           
                    commit("setPatientEditStatus", 2);
                    commit("setEditedPatient",response.data)
                    dispatch("applyIndexOnPatients");
                })
                .catch(function(error) {
                    commit("setPatientEditStatus", 3);
                    commit("setPatientEditText", error.response.data.errors);
                });
        },
        editPatientReactivate({ commit, state, dispatch }, data) {
            commit("setPatientDeleteStatus", 1);
  
            PatientAPI.putReactivatePatient(data.slug)
                .then(function(response) {
                    commit("setPatientDeleteStatus", 2);
                    commit("setEditedPatient",response.data)
                    dispatch("applyIndexOnPatients");
                })
                .catch(function(error) {
                    commit("setPatientDeleteStatus", 3);
                });
        },
        deletePatient({ commit, state, dispatch }, data) {
            commit("setPatientDeleteStatus", 1);

            PatientAPI.deletePatient(data.slug)
                .then(function(response) {
                    commit("setPatientDeleteStatus", 2);
                    if(state.patientOptions.showDeleted){
                        commit("setEditedPatient",response.data)
                    }else{
                        commit("setPatientLineCount", state.patientOptions.lineCount - 1);
                        commit("setDeletedPatient",response.data)
                    }
                    dispatch("applyIndexOnPatients");
                })
                .catch(function() {
                    commit("setPatientDeleteStatus", 3);
                });
        },
        forceDeletePatient({ commit, state, dispatch }, data) {
            commit("setPatientDeleteStatus", 1);

            PatientAPI.forceDeletePatient(data.slug)
                .then(function(response) {
                    commit("setPatientDeleteStatus", 2);
                    commit("setPatientLineCount", state.patientOptions.lineCount - 1);
                    commit("setDeletedPatient",response.data)
                    dispatch("applyIndexOnPatients");
                })
                .catch(function() {
                    commit("setPatientDeleteStatus", 3);
                });
        },
        //##################
        addPatientContact({ commit, state, dispatch }, data) {
            commit("setPatientContactAddStatus", 1);
            commit("setPatientAddText", "");

            PatientAPI.postAddNewContact(
                data.patient_id,
                data.name,
                data.surname,
                data.type,
                data.phone_prefix,
                data.phone,
                data.email,
                data.default
            )
                .then(function(response) {
                    commit("setPatientContactAddStatus", 2);
                    commit("setAddedPatientContact",response.data);
                })
                .catch(function(error) {
                    commit("setPatientContactAddStatus", 3);
                    //commit("setPatientAddText", error.response.data.errors);
                });
        },

        loadPatientContactEdit({ commit }, data) {
            commit("setPatientContactEditLoadStatus", 1);
            commit("setPatientContactEditStatus", 0);

            PatientAPI.getContactEdit(data.slug)
                .then(function(response) {
                    commit("setPatientContactEdit", response.data);
                    commit("setPatientContactEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPatientContactEdit", {});
                    commit("setPatientContactEditLoadStatus", 3);
                });
        },
        editPatientContact({ commit, state, dispatch }, data) {
            commit("setPatientContactEditStatus", 1);

            PatientAPI.putEditContact(
                data.slug,
                data.name,
                data.surname,
                data.type,
                data.phone_prefix,
                data.phone,
                data.email,
                data.default
            )
                .then(function(response) {
           
                    commit("setPatientContactEditStatus", 2);
                    commit("setEditedPatientContact",response.data)
                })
                .catch(function(error) {
                    commit("setPatientContactEditStatus", 3);
                    //commit("setPatientEditText", error.response.data.errors);
                });
        },
        forceDeletePatientContact({ commit, state, dispatch }, data) {
            commit("setPatientContactDeleteStatus", 1);

            PatientAPI.forceDeleteContact(data.slug)
                .then(function(response) {
                    commit("setPatientContactDeleteStatus", 2);
                    commit("setDeletedPatientContact",response.data)
                })
                .catch(function() {
                    commit("setPatientContactDeleteStatus", 3);
                });
        },


        //##################### LOG
        setLogOptions({ state, commit, dispatch },data){
            commit("setLogOptions",data.options);
            dispatch("loadLog");
        },
        loadLog({ commit, dispatch, state }, data) {
            commit("setLog", []);
            commit("setLogLoadStatus", 1);
            PatientAPI.getLog({
                    type:state.logOptions.type,
                    ref_id:state.logOptions.ref_id,
                    fromDate:state.logOptions.fromDate,
                    toDate:state.logOptions.toDate
            })
                .then(function(response) {
                    commit("setLog", response.data);
                    dispatch("applyIndexOnLog");
                    commit("setLogLoadStatus", 2);
                })
                .catch(function() {
                    commit("setLog", {});
                    commit("setLogLoadStatus", 3);
                });
        },
        applyIndexOnLog({ commit, state, rootState }) {
            let localLog = state.log;
            
            //var i = 1;
            localLog.forEach(u => {
                if(_.isNumber(u.action)){
                    if(u.action==103){
                        u.before = _.find(rootState.lookups.appointment_status, { 'value': _.toNumber(u.before) }).text;
                        u.after = _.find(rootState.lookups.appointment_status, { 'value': _.toNumber(u.after) }).text;
                    }
                    u.action = _.find(rootState.lookups.log, { 'value': u.action }).text;
                }
                if(u.param && u.param!=''){
                    if(u.param=="patient.currency"){
                        u.before = _.find(rootState.lookups.currencies, { 'value': _.toNumber(u.before) }).text;
                        u.after = _.find(rootState.lookups.currencies, { 'value': _.toNumber(u.after) }).text;
                    }
                    u.param = _.find(rootState.lookups.logParams, { 'value': u.param }).text;
                }
            });

            commit("setLog",localLog);
        },
    },
    mutations: {
        setPatientOptions(state,options){
            state.patientOptions = options;
        },
        setPatientsLoadStatus(state, status) {
            state.patientsLoadStatus = status;
        },
        setPatients(state, patients) {
            state.patients = patients;
        },
        setPatientsLookup(state, patients) {
            state.patientsLookup = patients;
        },
        setPatientsLookupLoadStatus(state, status) {
            state.patientsLookupLoadStatus = status;
        },
        setPatientsRecommendationLookup(state, patientsRecommendation) {
            state.patientsRecommendationLookup = patientsRecommendation;
        },
        setPatientsRecommendationLookupLoadStatus(state, status) {
            state.patientsRecommendationLookupLoadStatus = status;
        },
        setPatientLineCount(state, lineCount) {
            state.patientOptions.lineCount = lineCount;
        },
        setPatientAddStatus(state, status) {
            state.patientAddStatus = status;
        },
        setPatientContactAddStatus(state, status) {
            state.patientContactAddStatus = status;
        },
        setPatientAddText(state, text) {
            state.patientAddText = text;
        },
        setPatientEdit(state, patient) {
            state.patientEdit = patient;
        },
        setPatientContactEdit(state, patientContact) {
            state.contactEdit = patientContact;
        },
        setPermissionListEdit(state, list) {
            state.patientEdit.computed_permissions = list;
        },
        setEditedPatient(state, patient) {
            var lp = _.find(state.patients,{'id':patient.id}).lp;
            var index = _.findIndex(state.patients,{'id':patient.id});
       
            state.patients[index] = patient;
            state.patients[index].lp = lp;
        },
        setEditedPatientContact(state, patientContact) {
            if(patientContact.default){//default change
                state.patientEdit.contacts.forEach(element => {
                    element.can_delete = true;
                    element.default = false;
                });
            }
            var index = _.findIndex(state.patientEdit.contacts,{'id':patientContact.id});
            state.patientEdit.contacts[index] = patientContact;
        },
        setAddedPatient(state, patient) {
            state.patientOptions.itemAdded = true;
            state.patients.push(patient);
        },
        setAddedPatientContact(state, patientContact) {
            if(patientContact.default){//default change
                state.patientEdit.contacts.forEach(element => {
                    element.can_delete = true;
                    element.default = false;
                });
            }
            //state.patientOptions.itemAdded = true;
            state.patientEdit.contacts.push(patientContact);
        },
        setPatientSearchedTrue(state) {
            state.patientOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.patientOptions.itemAdded = false;
            state.patientOptions.itemSearched = false;
        },
        setDeletedPatient(state, patient) {
            var index = _.findIndex(state.patients,{'id':patient.id});
            state.patients.splice(index,1);
        },
        setDeletedPatientContact(state, patientContact) {
            var index = _.findIndex(state.patientEdit.contacts,{'id':patientContact.id});
            state.patientEdit.contacts.splice(index,1);
        },
        setPatientEditDefault(state) {
            state.patientEdit = { ...state.patientEditDefault };
        },
        setPatientContactEditDefault(state) {
            state.contactEdit = { ...state.contactEditDefault };
        },
        setPatientEditStatus(state, status) {
            state.patientEditStatus = status;
        },
        setPatientContactEditStatus(state, status) {
            state.patientContactEditStatus = status;
        },
        setPatientEditText(state, text) {
            state.patientEditText = text;
        },
        setPatientEditLoadStatus(state, status) {
            state.patientEditLoadStatus = status;
        },
        setPatientContactEditLoadStatus(state, status) {
            state.patientContactEditLoadStatus = status;
        },
        setPatientDeleteStatus(state, status) {
            state.patientDeleteStatus = status;
        },
        setPatientContactDeleteStatus(state, status) {
            state.patientContactDeleteStatus = status;
        },
        setPatientDeletedText(state, text) {
            state.patientDeleteText = text;
        },
        //###### LOG
        setLog(state, log) {
            state.log = log;
        },
        setLogLoadStatus(state, status) {
            state.logLoadStatus = status;
        },
        setLogOptions(state,options){
            state.logOptions = options;
        },
        //###### GUS
        setPatientsGUSLookup(state, patients) {
            state.patientsGUSLookup = patients;
        },
        setPatientsGUSLookupLoadStatus(state, status) {
            state.patientsGUSLookupLoadStatus = status;
        },
    },

    getters: {
        getPatientOptions(state){
            return state.patientOptions;
        },
        getPatientsLoadStatus(state) {
            return state.patientsLoadStatus;
        },
        getPatients(state) {
            return state.patients;
        },
        getPatientAddStatus(state) {
            return function(){
                return state.patientAddStatus;
            } 
        },
        getPatientContactAddStatus(state) {
            return function(){
                return state.patientContactAddStatus;
            } 
        },
        getPatientAddText(state) {
            return state.patientAddText;
        },
        getPatientEdit(state) {
            return state.patientEdit;
        },
        getPatientContactEdit(state) {
            return state.contactEdit;
        },
        getPatientEditStatus(state) {
          return function(){
            return state.patientEditStatus;
          }
        },
        getPatientContactEditStatus(state) {
            return function(){
              return state.patientContactEditStatus;
            }
          },
        getPatientEditing(state) {
            return state.patientEdit.id && state.patientEdit.id > 0;
        },
        getPatientEditText(state) {
            return state.patientEditText;
        },
        getPatientEditLoadStatus(state) {
            return function(){
                return state.patientEditLoadStatus;
            }
        },
        getPatientContactEditLoadStatus(state) {
            return state.patientContactEditLoadStatus;
        },
        getPatientDeleteStatus(state) {
            return function(){
                return state.patientDeleteStatus;
            }
        },
        getPatientContactDeleteStatus(state) {
            return function(){
                return state.patientContactDeleteStatus;
            }
        },
        getPatientDeleteText(state) {
            return state.patientDeleteText;
        },
        getPatientsLookupLoadStatus(state) {
            return state.patientsLookupLoadStatus;
        },
        getPatientsLookup(state) {
            return state.patientsLookup;
        },
        getPatientsLookupRecommendationLoadStatus(state) {
            return state.patientsRecommendationLookupLoadStatus;
        },
        getPatientsRecommendationLookup(state) {
            return state.patientsRecommendationLookup;
        },
        //#### LOG
        getLogOptions(state){
            return state.logOptions;
        },
        getLogLoadStatus(state) {
            return state.logLoadStatus;
        },
        getLog(state) {
            return state.log;
        },
        //#### GUS
        getPatientsGUSLookupLoadStatus(state) {
            return state.patientsGUSLookupLoadStatus;
        },
        getPatientsGUSLookup(state) {
            return state.patientsGUSLookup;
        },
    }
};
