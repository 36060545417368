import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/tasks
    */
        getTasks: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/tasks',{
                params:{
                    selectedPatient:options.selectedPatient,
                    showDone:options.showDone,
                    showCreated:options.showCreated,
            }});
        },
        
    /*
		POST 	/api/tasks
	*/
    postAddNewTask: function( patient_id,appointment_id,date_to,body,content,assigned_to){
        return axios.post( HGO_CONFIG.API_URL + '/tasks',
            {
                patient_id: patient_id,
                appointment_id:appointment_id,
                date_to:date_to,
                body:body,
                content:content,
                assigned_to:assigned_to
            }
        );
    },

    /*
		GET 	/api/tasks/{slug}/edit
	*/
    getTaskEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/tasks/' + slug + '/edit' );
	},


    /*
		PUT 	/api/tasks/{slug}
	*/
    putEditTask: function(slug,patient_id, date_to,body,content,assigned_to){
        return axios.put( HGO_CONFIG.API_URL + '/tasks/'+ slug,
            {
                patient_id: patient_id,
                date_to: date_to,
                body:body,
                content:content,
                assigned_to:assigned_to
            }
        );
    },

     /*
		PUT 	/api/tasks/{slug}/done
	*/
    putTaskDone: function(slug, date_to,body,content){
        return axios.put( HGO_CONFIG.API_URL + '/tasks/'+ slug + '/done');
    },

    /*
		PUT 	/api/tasks/{slug}/undone
	*/
    putTaskUndone: function(slug, date_to,body,content){
        return axios.put( HGO_CONFIG.API_URL + '/tasks/'+ slug + '/undone');
    },
    
    /*
		DELETE /api/tasks/{slug}/force
	*/
    forceDeleteTask: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/tasks/' + slug + '/force' );
	}
}