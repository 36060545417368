<template>
      <v-col>
        
          <v-card class="fill-height" style="background-color:#F5F5F5;" :loading="appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1">
            
                <v-toolbar style="background-color:#F5F5F5;" flat>
                  <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Wróć</v-btn>
                  
                  <v-spacer></v-spacer>
                  <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
                  <v-btn v-if="itemEditing" color="primary" small :to="{ name: 'realizacja', params: { id: editedItem.id } }" class="ml-2" ><v-icon>mdi-menu</v-icon></v-btn>
                  <v-btn :disabled="!mainEventChanged" color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
                </v-toolbar>
              
          <v-row class="mt-0">
            <v-col class="col-7 pl-5 pt-0" v-resize="onResize">
                 <v-card  class="fill-height">
                    <v-sheet height="64">
                      <v-toolbar flat>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Dziś</v-btn>
                          <v-btn fab text small color="grey darken-2" @click="prev"><v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-alert v-if="date_fromErrors" class="mr-5 mt-4" dense outlined type="error">{{date_fromErrors}}</v-alert>
                        <v-switch v-if="type=='week' || type=='month'" class="mt-5" v-model="withWeekend" label="Sb Nd"></v-switch>
                        <v-menu bottom right v-if="type!='category'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              color="grey darken-2"
                              class="ml-5"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>{{ typeToLabel[type] }}</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="type = 'day'">
                              <v-list-item-title>Dzień</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                              <v-list-item-title>Tydzień</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                              <v-list-item-title>Miesiąc</v-list-item-title>
                            </v-list-item>
                            
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet  :height="windowSize.y+'px'">
                      <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="secondary"
                        :events="events"
                        :type="type"
                        :category-show-all="calendarCategories.length>0"
                        :categories="calendarCategories"
                        @change="updateRange"
                        @click:event="goToEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                        :weekdays="withWeekend?weekDaysWeekend:weekDays"
                        first-time="08:00"
                        interval-minutes="30"
                        interval-count="28"
                        :short-intervals="false"
                        :short-weekdays="false"
                        event-overlap-mode="column"
                      >
                      
                      <template v-slot:event="{  event, timed, eventSummary }">
                        <v-tooltip right v-for="ap in event.appointments" :key="ap.id" >
                          <template  v-slot:activator="{ on }" >
                            <div  
                              v-on="on"
                              :style="'top:'+(ap.start-event.start)/1000/60*pixelsPerMinute+'px;height:'+((ap.end-ap.start)/1000/60)*pixelsPerMinute+'px'"
                              class="v-event-sub-appointment"
                              style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                              >
                              <v-icon size="20" style="position:absolute;z-index:10000;">{{_.find(lookups.categories,{'value':ap.category_id}).icon}}</v-icon>
                              <p v-if="type=='category' || type=='day'" style="position:absolute;left:25px;top:2px;">{{_.find(lookups.categories,{'value':ap.category_id}).text}}</p>
                              <p class="mb-0"></p><p class="mb-0 mr-1" style="z-index:100000;"><strong :style="'background-color:' + ap.color">{{ap.name}}</strong></p>
                            </div>        
                          </template>
                          <span v-html="ap.tooltip"></span>
                        </v-tooltip>
                        <div  
                          v-if="mainEvent && mainEvent.event_ids.length>0 && _.includes(mainEvent.event_ids,event.id)" 
                          :style="'top:'+(mainEvent.start-event.start)/1000/60*pixelsPerMinute+'px;height:'+duration*pixelsPerMinute+'px'"
                          class="v-event-appointment"
                          style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                          >
                          <p class="mb-0"></p><p class="mb-0 mr-1"><strong>{{mainEventDesc}}</strong></p>
                        </div>
                        <div
                          v-if="event.main" 
                          class="v-event-draggable pr-1"
                          style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                        ><p class="mb-0"></p><p class="mb-0"><strong>{{mainEventDesc}}</strong></p></div>
                        <div
                          v-if="!event.main && !event.appointment" 
                          class="v-event-draggable"
                        ><strong>{{event.name}}</strong></div>
                        <div
                          v-if="timed && event.main && !event.readonly"
                          class="v-event-drag-bottom"
                          @mousedown.stop="extendBottom(event)"
                        ></div>
                        <v-tooltip right v-if="event.appointment" >
                          <template  v-slot:activator="{ on }" >
                            <div  
                              v-on="on"
                              class="v-event-ref-appointment"
                              style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                              >
                              <v-icon size="20" color="white" style="position:absolute;z-index:10000;">{{_.find(lookups.categories,{'value':event.category_id}).icon}}</v-icon>
                              <p v-if="type=='category' || type=='day'" style="position:absolute;left:25px;top:2px;">{{_.find(lookups.categories,{'value':event.category_id}).text}}</p>
                              <p class="mb-0"></p><p class="mb-0 mr-1" style="z-index:100000;"><strong :style="'background-color:' + event.color">{{type=='category' || type=='day'?event.name:event.name.split(" ").pop()}}</strong></p>
                            </div>           
                          </template>
                          <span v-html="event.tooltip"></span>
                        </v-tooltip>
                        
                      </template>

                      </v-calendar>
                    </v-sheet>
                 </v-card>   
            </v-col>
            <v-col class="col-5 pl-0 pr-5 pt-0">
                <v-card>
                  <v-container fluid>
                  <v-card-text>
                          <v-row class="child-flex" >
                             <v-col cols="8" >
                              <patients-live-search @change="updateReferenceEvents" :disabled="editedItem.read_only" clearable  :error-messages="patient_idErrors" :selectedPatientText="(itemEditing || loadPatient)&&editedItem.patient_id?editedItem.patient.text:null" @patientSelected="editedItem.patient_id = $event.value;editedItem.patient.text = $event.text" label="Wyszukaj pacjenta" />                        
                            </v-col>
                            <v-col cols="1" class="text-start pl-0"  align-self="end">
                              <v-btn v-if="editedItem.id && editedItem.patient_id" color="primary" small :to="{ name: 'pacjent', params: { id: editedItem.patient_id } }" class="ml-0" >Karta</v-btn>
                            </v-col>
                            <v-col cols="12">
                              <service-live-search @change="serviceChange" :disabled="editedItem.read_only" clearable :serviceCategory="editedItem.category_id"  :error-messages="service_idErrors" :selectedService="editedItem.service_id" :selectedServiceText="editedItem.service?editedItem.service.name:null" @serviceSelected="editedItem.service_id = $event" />                        
                            </v-col>
                            
                            <v-col cols="8">
                              <clinic-live-search :disabled="editedItem.read_only" :fixed="clinicFixed" clearable :selectedClinic="editedItem.clinic_id"   :selectedClinicText="editedItem.clinic?editedItem.clinic.name:null" @clinicSelected="editedItem.clinic_id = $event" @clinicNameSelected="editedItem.clinic.name = $event" />                        
                            </v-col>
                            <v-col cols="4">
                              <v-text-field @change="updateReferenceEvents" :disabled="editedItem.read_only" hide-details v-model="editedItem.room" :error-messages="roomErrors" :label="'Gabinet'"></v-text-field>                            
                            </v-col>
                            <v-col cols="4" >
                              <user-search label="Wyszukaj lekarza" @userSelected="addUser($event)"  :userType="1" />
                            </v-col>
                            <v-col cols="8" >
                              <v-select @change="updateReferenceEvents" v-model="selectedUsers" :items="editedItem.users" deletable-chips multiple chips small-chips hide-details="auto" label="Lekarz / Zespół lekarzy" />
                            </v-col>
                            
                            
                            <v-col cols="12">
                              <v-text-field :disabled="editedItem.read_only || editedItem.read_only" hide-details v-model="editedItem.info" label="Notatka"></v-text-field>                            
                            </v-col>
                            
                            <v-col cols="4">
                              <v-menu
                                          v-model="selectedDayMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="150px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="startingDate"
                                              readonly
                                              v-on="on"
                                              label="Data"
                                              hide-details
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker no-title first-day-of-week="1" v-model="startingDate" @input="selectedDayMenu = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                             <v-col cols="4">
                               <v-text-field
                                              v-model="startingTime"
                                              label="Godzina"
                                              type="time"
                                              :error-messages="date_fromErrors"
                                              hide-details="auto"
                                            ></v-text-field>
                              <!-- <v-menu
                                          ref="startTimeMenu"
                                          v-model="selectedTimeMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          max-width="290px"
                                          min-width="290px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="startingTime"
                                              readonly
                                              v-on="on"
                                              label="Godzina"
                                              hide-details
                                            ></v-text-field>
                                          </template>
                                          <v-time-picker full-width format="24hr" @click:minute="$refs.startTimeMenu.save(startingTime)" v-model="startingTime"></v-time-picker>
                              </v-menu> -->
                            </v-col>
                            <v-col cols="4">
                              <v-text-field :disabled="!startingTime" hide-details v-model="duration" type="number" min="5" label="Czas trwania (minut)" ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-alert type="warning" v-if="calendarConflictExists" dense class="mb-0">Termin się nakłada</v-alert>
                            </v-col>
                            <v-col cols="4" class="text-end mt-2">
                              <span class="text-subtitle-1">Przypomnienie dla zespołu:</span>
                            </v-col>
                            <v-col cols="4">
                              <v-select filled dense clearable v-model="editedItem.remainder_users" :items="lookups.remainders" hide-details="auto" />
                            </v-col>
                            <v-col cols="8" class="text-end mt-2">
                              <span class="text-subtitle-1">Przypomnienie dla pacjenta</span>
                            </v-col>
                            <v-col cols="4">
                              <v-select filled dense clearable v-model="editedItem.remainder_patient" :items="lookups.remainders" hide-details="auto" />
                            </v-col>
                          </v-row>
                          
                  </v-card-text>
                  
                  </v-container>
                  <v-speed-dial
                            v-if="editedItem.read_only || itemEditing"
                            v-model="fab" absolute
                            top right direction="bottom"
                            transition="scale-transition"
                          >
                            <template v-slot:activator>
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" fab outlined large color="blue darken-4" height="63" class="px-0" style="border:2px solid;"><v-icon size="43">{{_.find(lookups.categories,{'value':editedItem.category_id}).icon}}</v-icon></v-btn>
                                </template>
                                  <span>{{_.find(lookups.categories,{'value':editedItem.category_id}).text}}</span>
                              </v-tooltip>
                            </template>
                    </v-speed-dial>
                  <v-speed-dial
                            v-else
                            v-model="fab" absolute
                            top right direction="bottom"
                            transition="scale-transition"
                          >
                            <template v-slot:activator>
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" fab outlined large color="blue darken-4" height="63" class="px-0" style="border:2px solid;"><v-icon size="43">{{_.find(lookups.categories,{'value':editedItem.category_id}).icon}}</v-icon></v-btn>
                                </template>
                                  <span>{{_.find(lookups.categories,{'value':editedItem.category_id}).text}}</span>
                              </v-tooltip>
                            </template>
                                <v-tooltip left v-for="t in _.filter(lookups.categories,function(o) { return o.visible && o.value!=editedItem.category_id; })" :key="t.value">
                                    <template v-slot:activator="{ on }" >
                                      <v-btn
                                        v-on="on"
                                        outlined
                                        fab
                                        small
                                        color="grey darken-3"
                                        class="px-0" 
                                        style="border:2px solid;"
                                        @click="changeCategory(t.value)"
                                      >
                                        <v-icon size="28">{{t.icon}}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{t.text}}</span>
                                </v-tooltip>
                    </v-speed-dial>
                </v-card>      
            </v-col>
          </v-row>
        </v-card>
      <confirm ref="confirm"></confirm>
      </v-col>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import UserLiveSearch from '../components/UsersLiveSearch'
  import UserSearch from '../components/UsersSearch'
  import ClinicLiveSearch from '../components/ClinicsLiveSearch'
  import ServiceLiveSearch from '../components/ServicesLiveSearch'
  import PatientsLiveSearch from '../components/PatientsLiveSearch'
  import moment from 'moment';
  moment.locale('pl');
  const validDate = (value) => moment(value).local().format('HH:mm') !== '00:00'
  import {Helper} from '../mixins/helper'

  export default {
    components:{UserLiveSearch,ClinicLiveSearch,ServiceLiveSearch,confirm,PatientsLiveSearch,UserSearch},
    props:['loadPatient'],
    mixins:[
      Helper
    ],
    data: () => ({
      windowSize: {
        x: 0,
        y: 0,
      },
      defaultAppointmentTimeInMinutes:30,
      selectedDayMenu:false,
      selectedTimeMenu:false,
      fab: false,
      focus: '',
      calType: 'week',
      typeToLabel: {
        month: 'Miesiąc',
        week: 'Tydzień',
        day: 'Dzień',
      },
      origMainEvent:null,
      withWeekend:false,
      weekDays:[1, 2, 3, 4, 5],
      weekDaysWeekend:[1, 2, 3, 4, 5, 6, 0],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      selectedDayMenu:false,
      calRangeStart:null,
      calRangeEnd:null,
      clinicFixed:false,
    }),
    validations(){
        return {
          editedItem:{
            patient_id: {
              required
            },
            date_from:{
              required,
              validDate
            },
            service_id: {
              required
            },
            // clinic_id: {
            //   required
            // },
          }
        }
    },
    watch: {
      appointmentEditStatus(val){
        if(val===2){
          //this.close();
        }
      },
      appointmentEditLoadStatus(val){
        if(val===2){
            this.focus = this.editedItem.date_from.substring(0,10);//console.log(moment(this.editedItem.from).subtract(1,'h').local().format('HH:mm'));
            this.origMainEvent = JSON.parse(JSON.stringify(this.mainEvent));
            setTimeout(() => {
              this.$refs.calendar.scrollToTime(moment(this.editedItem.date_from).subtract(1,'h').local().format('HH:mm'));
            }, 100)
        }
      },
      appointmentAddStatus(val){
        if(val===2){
          this.close();
        }
      },
      editedItemUserId(val){
        // if(val && (this.editedItem.type!=2 && this.editedItem.type!=3)){
        //   this.editedItem.type = 1;
        // }else if(val && this.editedItem.type==3){
        //   this.editedItem.type = 2;
        // }

        //this.updateReferenceEvents();
      },
      editedItemClinicId(val){
        if(!val){
          this.editedItem.room = null
        }
      },
      referenceEvents(val){
        if(val.length>0 && this.mainEvent){
          this.mergeEvents();
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params) && this.$route.params.id;
      },
      editedItem(){
        return this.$store.getters.getAppointmentEdit;
      },
      editedItemUserId(){
        return this.$store.getters.getAppointmentEdit.user_id;
      },
      editedItemClinicId(){
        return this.$store.getters.getAppointmentEdit.clinic_id;
      },
      appointmentEditLoadStatus(){
        return this.$store.getters.getAppointmentEditLoadStatus();
      },
      appointmentEditStatus(){
        return this.$store.getters.getAppointmentEditStatus();
      },
      appointmentEditText(){
        return this.$store.getters.getAppointmentEditText
      },
      appointmentAddStatus(){
        return this.$store.getters.getAppointmentAddStatus();
      },
      appointmentAddText(){
        return this.$store.getters.getAppointmentAddText;
      },
    
      formTitle () {
        if(this.editedItem.read_only) return 'Podgląd działania'
        return !this.itemEditing ? 'Planowanie działania' : 'Zaplanowane działanie'
      },
      patient_idErrors () {
        const errors = []
        if (!this.$v.editedItem.patient_id.$dirty) return errors
        !this.$v.editedItem.patient_id.required && errors.push('Pacjent jest wymagany')
        return errors
      },
      service_idErrors () {
        const errors = []
        if (!this.$v.editedItem.service_id.$dirty) return errors
        !this.$v.editedItem.service_id.required && errors.push('Diagnostyka jest wymagana')
        return errors
      },
      // user_idErrors () {
      //   const errors = []
      //   if (!this.$v.editedItem.user_id.$dirty) return errors
      //   !this.$v.editedItem.user_id.required && errors.push('Lekarz jest wymagany')
      //   return errors
      // },
      // clinic_idErrors () {
      //   const errors = []
      //   //if ((this.editedItem.type!=4 && this.editedItem.type!=5) || !this.$v.editedItem.clinic_id.$dirty) return errors
      //   if (!this.$v.editedItem.clinic_id.$dirty) return errors
      //   !this.$v.editedItem.clinic_id.required && errors.push('Klinika jest wymagana')
      //   return errors
      // },
      roomErrors () {
        const errors = []
        //if ((this.editedItem.type!=4 && this.editedItem.type!=5) || !this.$v.editedItem.room.$dirty) return errors
        //!this.$v.editedItem.room.required && this.editedItem.type==4 && errors.push('Gabinet jest wymagany')
        //!this.$v.editedItem.room.required && this.editedItem.type==5 && errors.push('Sala jest wymagana')
        return errors
      },
      date_fromErrors() {
        if (!this.$v.editedItem.date_from.$dirty) return ''
        if(!this.$v.editedItem.date_from.required) return 'Zaznacz wpis w kalendarzu'
        if(!this.$v.editedItem.date_from.validDate) return 'Godzina jest wymagana'
      },
      mainEventChanged(){
        return !_.isEqual(this.mainEvent,this.origMainEvent);
      },
      type:{
        get(){
          return this.calendarCategories.length>1?'category':this.itemEditing?'day':this.calType;
        },
        set(val){
          this.calType = val;
        }
      },
      mainEvent:{
        get(){
          if(this.editedItem.date_from){
            return {
              id:this.editedItem.id,
              name: this.mainEventDesc,
              start: Date.parse(this.editedItem.date_from),
              end: Date.parse(this.editedItem.date_to),
              color:'secondary',
              timed:true,
              main:true,
              event_ids:this.editedItem.event_ids,
              readonly:this.editedItem.read_only,
              user_id:this.editedItem.user_id,
              clinic_id:this.editedItem.clinic_id,
              room:this.editedItem.room,
              category_id:this.editedItem.category_id,
              service_id:this.editedItem.service_id,
              note:this.editedItem.info,
              category:null,
              appointment:false
            }
          }
          return null
        },
        set(val){
          this.editedItem.date_from =  new Date(val.start).toISOString();
          this.editedItem.date_to =  new Date(val.end).toISOString();
          //if(!val.timed && this.editedItem.user_id)this.editedItem.type = 2;
          //else if(!val.timed)this.editedItem.type = 3;
        }
      },
      mainEvents(){
        var events = [];
        var existsCats = [];

        if(this.mainEvent){
          this.mainEvent.event_ids.forEach(e => {
            var ref = _.find(this.referenceEvents,{'id':e});
            //nie znalazł ref to znaczy, że zmienił się widok -> kasujemy mainEvent
            if(!ref){
              this.editedItem.date_from =  null
              this.editedItem.date_to =  null
            }else{
              existsCats.push(ref.category);
            }
          });
          if(this.mainEvent){//mógł w trakcie zostać skasowany
            if(this.calendarCategories.length===0 && existsCats.length===0)events.push(this.mainEvent);
            else{
               this.calendarCategories.forEach(cat => {
                var e = JSON.parse(JSON.stringify(this.mainEvent));
                e.category = cat;
                if(!_.includes(existsCats,cat))events.push(e);
              });
            }
          }
        }

        return events;
      },
      events(){
        return _.concat(this.mainEvents,this.referenceEvents,this.referenceAppointments);
        if(this.mainEvent && this.mainEvent.event_ids.length==0)return _.concat(this.mainEvent,this.referenceEvents);
        //else if(this.mainEvent && !this.itemEditing)return _.concat(this.mainEvent,this.referenceEvents);
        else return _.concat(this.mainEvents,this.referenceEvents);
      },
      mainEventDesc(){
        if(this.editedItem.patient && this.editedItem.patient.name){
          return this.editedItem.patient.name + ' ' + this.editedItem.patient.surname;
        }else if(this.editedItem.patient_id){
          return _.find(this.$store.getters.getPatientsLookup,{'value':this.editedItem.patient_id})?_.find(this.$store.getters.getPatientsLookup,{'value':this.editedItem.patient_id}).text:'';
        }
        return 'Pacjent';
      },
      referenceEvents(){
        var referenceEvents = [];
        this.$store.getters.getReferenceEvents.forEach(event => {
            var appointmentsInEvents = [];
            event.appointments.forEach(app => {
              var tooltip = moment(app.date_from).format('YYYY-MM-DD HH:mm') + '<br />' + app.patient.name + ' ' +  app.patient.surname + '<br />' + app.service.name + '<br />' + (app.clinic?app.clinic.name + '<br />':'')
              // app.team.forEach(element => {
              //   tooltip += element.name + ' ' + element.surname + '<br />'  
              // });
              appointmentsInEvents.push({
                id:app.id,
                name:app.desc,
                start:moment(app.date_from).valueOf(),
                end:moment(app.date_to).valueOf(),
                status:_.find(this.lookups.appointment_status,{'value':app.status}).text,
                category_id:app.category_id,
                tooltip:tooltip
            })});
            referenceEvents.push({
                        id: event.id,
                        name: event.desc,
                        color: event.read_only?'#757575':_.find(this.lookups.event_type,{'value':event.type}).color,
                        start: moment(event.date_from).valueOf(),
                        end:  moment(event.date_to).valueOf(),
                        timed: !event.all_day,
                        main:false,
                        readonly:event.read_only,
                        category:event.cat,
                        clinic:event.clinic,
                        room:event.room,
                        appointments:appointmentsInEvents,
                        appointment:false
                    });
            });
        return referenceEvents;
      },
      referenceAppointments(){
        var referenceEvents = [];
        this.$store.getters.getReferenceAppointments.forEach(event => {
            event.cat.forEach(cat => {
              var tooltip = moment(event.date_from).format('YYYY-MM-DD HH:mm') + '<br />' + event.patient.name + ' ' +  event.patient.surname + '<br />' + event.service.name + '<br />' + (event.clinic?event.clinic.name + '<br />':'')
              event.team.forEach(element => {
                tooltip += element.name + ' ' + element.surname + '<br />'  
              });

              referenceEvents.push({
                        id: event.id,
                        name: event.short_desc,
                        color: event.read_only?'#757575':'#2b2838',
                        start: moment(event.date_from).valueOf(),
                        end:  moment(event.date_to).valueOf(),
                        timed: true,
                        main:false,
                        readonly:event.read_only,
                        category:cat,
                        clinic:event.clinic,
                        room:event.room,
                        appointment:true,
                        category_id:event.category_id,
                        tooltip:tooltip
                    });
            });
        });
            
        return referenceEvents;
      },
      startingDate:{
        get(){
          if(!this.editedItem.date_from)return null;
          return moment(this.editedItem.date_from).local().format('YYYY-MM-DD');
        },
        set(val){
          if(this.startingTime){
            this.focus = val
            this.mainEvent = {start:moment(val + ' ' + this.startingTime).utc().valueOf(),end:moment(val + ' ' + moment(this.editedItem.date_to).local().format('HH:mm')).utc().valueOf()}
          }else{
            this.focus = val
            this.mainEvent = {start:moment(val + ' 00:00').utc().valueOf(),end:moment(val + ' 00:30').utc().valueOf()}
          }
          this.mergeEvents(true)
        }
      },
      startingTime:{
        get(){
          if(!this.editedItem.date_from || moment(this.editedItem.date_from).local().format('HH:mm') == '00:00')return null;
          return moment(this.editedItem.date_from).local().format('HH:mm');
        },
        set(val){
          if(this.startingDate){
            var start = moment(this.startingDate + ' ' + val).utc();
            this.mainEvent = {start:start.valueOf(),end:start.add(this.duration,'m').valueOf()}
          }else{
            var start = moment((this.focus?this.focus:moment().local().format('YYYY-MM-DD')) + ' ' + val).utc();
            this.mainEvent = {start:start.valueOf(),end:start.clone().add(30,'m').valueOf()}
          }
          this.mergeEvents(true)
        }
      },
      duration:{
        get(){
          if(!this.editedItem.date_from)return null;
          return moment.duration(moment(this.mainEvent.end).diff(moment(this.mainEvent.start))).asMinutes();
        },
        set(val){
          this.mainEvent = {start:this.mainEvent.start,end:moment(this.mainEvent.start).add(val,'m').valueOf()}
        }
      },
      pixelsPerMinute(){
        return this.$refs.calendar?this.$refs.calendar.intervalHeight/this.$refs.calendar.intervalMinutes:0;
      },
      selectedUsers:{
        get(){
          return _.map(this.editedItem.users,'value');
        },
        set(val){
          var newUsers = [];
          val.forEach(e => {
            newUsers.push(_.find(this.editedItem.users,{'value':e}));
          });
          this.editedItem.users = newUsers;
        }
      },
      calendarCategories(){
        var cats = _.map(this.editedItem.users,'text');
        if(this.editedItem.clinic_id && this.editedItem.room)cats.push(this.editedItem.clinic.name);
        return cats;
      },
      calendarConflictExists(){
        if(this.mainEvent){
          return this.searchForAppointments(this.mainEvent.start,this.mainEvent.end).length > 0
        }
        return false;
      }
    },
    mounted(){
      this.onResize();

      if(this.lookups.roles.length>0){
        this.itemEditing && this.$store.dispatch( 'loadAppointmentEdit', {slug:  this.$route.params.id} );
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.itemEditing && this.$store.dispatch( 'loadAppointmentEdit', {slug:  this.$route.params.id} );
        }, 2000)
      }

      if(this.loadPatient){
        this.editedItem.patient_id = this.$store.getters.getPatientEdit.id;
        this.editedItem.patient = {...this.$store.getters.getPatientEdit}
      }
      this.$refs.calendar.scrollToTime('08:00')
    },
    beforeRouteLeave (to, from, next) {
      this.$v.$reset();
      this.$store.dispatch( 'loadAppointmentEditDefault'); 
      this.$store.dispatch( 'loadAppointmentAddDefault');
      this.$store.commit('setServicesLookup',[]);
      this.$store.commit('setReferenceEvents',[]);
      next();
    },
    methods: {
      changeCategory(val){
        this.editedItem.category_id = val
        this.editedItem.service_id = null
        this.serviceChange(null);
        this.$store.commit('setServicesLookup',[]);
      },
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight - 64 - 64 - 64 - 30 }
      },
      mergeEvents(updateMain = false){
        this.editedItem.event_ids = this.searchForEvent(this.mainEvent.start,this.mainEvent.end,updateMain)
      },
      serviceChange(val){
        this.editedItem.clinic_id = null
        if(val){
          this.clinicFixed = true
        }else{
          this.clinicFixed = false
        }
        this.$store.dispatch( 'searchClinicsByServiceLookup',{service_id:val});
        this.updateReferenceEvents();
      },
      addUser(user){
        if(!_.includes(_.map(this.editedItem.users,'value'),user.value)){
          this.editedItem.users.push(user);
          this.updateReferenceEvents();
        }
      },
      moment: function (val) {
        return moment(val);
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      startDrag ({ event, timed }) {
        if (event && event.main && !event.readonly) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
      },
      goToEvent({event}){
        // if(!event.main && event.id){
        //   //this.editedItem.event_id = event.id
        //   //this.$router.push({ name: 'harmonogram', params: { id: event.id } })
        //   //this.$store.dispatch( 'loadEventEdit', {slug:  this.$route.params.id} );
        // }
      },
      startTime (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start
          this.dragTime = mouse - start
        } else{
          this.createStart = this.roundTime(mouse)
          this.createEvent = {
            name: this.mainEventDesc,
            color: 'secondary',
            start: this.createStart,
            end: this.createStart+(1000*60*this.defaultAppointmentTimeInMinutes),
            timed: true,
          }
          //patrzymy czy jest event !read_only który zamiera ten czas
          //console.log(this.searchForEvent(this.createEvent.start,this.createEvent.end));
          this.mainEvent = this.createEvent
          this.mergeEvents(true);
        }
      },
      searchForEvent(start,end,updateMain = false){
        //wyszukuje w aktualnie wyświtlonych przyszłych eventach czasowo pasujących
        var ids = []
        _.filter(this.referenceEvents,{'timed':true}).forEach(event => {
          if(start>=event.start && end<=event.end){
            ids.push(event.id);
            if(updateMain){
              this.editedItem.room = event.room;
              if(event.clinic){
                this.editedItem.clinic_id = event.clinic.id;
                this.editedItem.clinic = event.clinic;
              }else{
                this.editedItem.clinic_id = null
                this.editedItem.clinic = {name:null}
              }
            }
          }
        });
        return ids;
      },
      searchForAppointments(start,end){
        //wyszukuje w aktualnie wyświtlonych przyszłych eventach czasowo pasujących
        var ids = []
        _.filter(this.referenceAppointments,{'timed':true}).forEach(event => {
          if((start>=event.start && start<event.end) || (end>event.start && end<=event.end)){
            ids.push(event.id);
          }
        });
        this.referenceEvents.forEach(event => {
          event.appointments.forEach(app => {
            if((start>=app.start && start<app.end) || (end>app.start && end<=app.end)){
              ids.push(app.id);
            }
          });
        });
        return ids;
      },
      extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
          this.mainEvent = this.dragEvent;
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
          this.mainEvent = this.createEvent;
        }
      },
      endDrag () {
        this.mergeEvents(true);
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag () {
        if (this.createEvent) {
          this.mainEvent = this.createEvent;
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            // const i = this.events.indexOf(this.createEvent)
            // if (i !== -1) {
            //   this.events.splice(i, 1)
            // }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      updateReferenceEvents(){
            this.$store.dispatch( 'loadReferenceEvents', {
                id: null,
                user_id:  this.selectedUsers,
                clinic_id:  this.editedItem.clinic_id,
                room: this.editedItem.room,
                date_from: this.calRangeStart.toISOString(),
                date_to: this.calRangeEnd.endOf('day').toISOString(),
                service_id:this.editedItem.service_id
            });
            this.$store.dispatch( 'loadReferenceAppointments', {
                id: this.itemEditing?this.editedItem.id:null,
                user_id:  this.selectedUsers,
                clinic_id:  this.editedItem.clinic_id,
                room: this.editedItem.room,
                date_from: this.calRangeStart.toISOString(),
                date_to: this.calRangeEnd.endOf('day').toISOString(),
                service_id:this.editedItem.service_id
            });
      },
      updateRange ({ start, end }) {
        this.calRangeStart = moment(start.date);
        this.calRangeEnd = moment(end.date);
        if(this.itemEditing && this.mainEvent){
          this.updateReferenceEvents();
        }else if(!this.itemEditing){
          this.updateReferenceEvents();
        }
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunąć to zdarzenie?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteAppointment', {slug:  item.id});
               this.$router.push({ name: 'harmonogramy.powrot'})
            }
        })
      },
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadAppointmentEditDefault'); 
        this.$store.dispatch( 'loadAppointmentAddDefault');
        this.$store.commit('setReferenceEvents',[]);
        this.$store.commit('setReferenceAppointments',[]);
        //this.$router.push({ name: 'pulpit'})
        this.$router.go(-1);
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editAppointment', {
                slug: this.editedItem.id,
                category_id:  this.editedItem.category_id,
                  patient_id:  this.editedItem.patient_id,
                  clinic_id:  this.editedItem.clinic_id,
                  service_id: this.editedItem.service_id,
                  room: this.editedItem.room,
                  date_from: moment(this.editedItem.date_from).toISOString(),
                  date_to: moment(this.editedItem.date_to).toISOString(),
                  info:this.editedItem.info,
                  patient_info:this.editedItem.patient_info,
                  status:this.editedItem.status,
                  remainder_users:this.editedItem.remainder_users,
                  remainder_patient:this.editedItem.remainder_patient,
                  event_ids:this.editedItem.event_ids,
                  users:_.map(this.editedItem.users,'value'),
              });
            } else {//new item
              this.$store.dispatch( 'addAppointment', {
                  category_id:  this.editedItem.category_id,
                  patient_id:  this.editedItem.patient_id,
                  clinic_id:  this.editedItem.clinic_id,
                  service_id: this.editedItem.service_id,
                  room: this.editedItem.room,
                  date_from: this.editedItem.date_from,
                  date_to: this.editedItem.date_to,
                  info:this.editedItem.info,
                  patient_info:this.editedItem.patient_info,
                  status:this.editedItem.status,
                  remainder_users:this.editedItem.remainder_users,
                  remainder_patient:this.editedItem.remainder_patient,
                  event_ids:this.editedItem.event_ids,
                  users:_.map(this.editedItem.users,'value'),
              });

            }
        }
      },
    },
  }
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
  position:absolute;
  z-index:1;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
  z-index:1;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.v-event-appointment {
  position: absolute;
  left: 0;
  right: 0;
  background-color:#cc0839;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  z-index:0;
  // &::after {
  //   display: none;
  //   position: absolute;
  //   left: 50%;
  //   height: 4px;
  //   border-top: 1px solid white;
  //   border-bottom: 1px solid white;
  //   width: 16px;
  //   margin-left: -8px;
  //   opacity: 0.8;
  //   content: '';
  // }

  // &:hover::after {
  //   display: block;
  // }
}

.v-event-sub-appointment {
  position: absolute;
  left: 0;
  right: 0;
  color:black;
  background-color:white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  opacity: 0.4;
  z-index:0;
}

.v-event-ref-appointment {
  position: absolute;
  left: 0;
  right: 0;
  color:white;
  z-index:0;
}

.v-event-drag-right {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width:4px;
  cursor: w-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 16px;
    border-left: 1px solid white;
    border-right: 1px solid white;
    width: 4px;
    margin-top:2px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

[data-title]:after {            
    position : absolute;
     content : attr(data-title);
     opacity : 0;
     color:black;
     background-color: #00FF00;
     z-index: 99999;
     visibility: hidden;
     padding: 1px 5px 2px 5px;
     bottom: -0.05em;
}
[data-title]:hover:after {        
    opacity : 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title] {
    position: relative;
}



</style>
