<template>
    <v-row>
      <v-col>
         <h2 class="mt-5">STREFA PACJENTA</h2>     
         <v-divider color="#cc0839" class="mt-2 mb-5" />
         <v-row>
          <v-col>
            <v-card height="300" :to="{name:'portal.wyniki'}">
              <v-card-text class="text-center fill-height" >
                <v-icon color="blue" size="60" class="mt-8">mdi-cloud-upload</v-icon>
                <h3>Prześlij wyniki badań</h3>
                <p class="mt-3">HIFU CLINIC zachęca do<br />skorzystania z Ogólnopolskiego<br />Programu "PRZYŚLIJ WYNIK"<br />BEZPŁATNA KONSULTACJA ON-LINE</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="300" :to="{name:'portal.rejestracja'}">
              <v-card-text class="text-center">
                <v-icon color="#cc0839" size="60" class="mt-8">mdi-calendar-month</v-icon>
                <h3>Umów się na konsultacje</h3>
                <p class="mt-3">HIFU CLINIC oferuje Państwu możliwość<br />zerejestrowania się na konsultację<br />prowadzącą on-line z doktorem, który<br />podczas wirtualnego spotkania odpowie<br />na pytania oraz udzieli porady</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="col-12">
              <p class="v-card__text px-0 py-0">Na czym polega nasze wsparcie<br />
              W odpowiedzi na zarysowany wyżej problem HIFU CLINIC Centrum Leczenia Raka Prostaty - jako jedyna klinika w Polsce i tej części Europy - oferuje pacjentom bezpłatną
              konsultację wstępną "PRZYŚLIJ WYNIK". Prowadzimy ją za pośrednictwem poczty elektronicznej. Ta forma konsultacji pozwala Pacjentowi i jego rodzinie na szybko kontakt z
              wykwalifikowanym i doświadczonym personelem lekarskim, gotowym do udzielenia wyczerpujących informacji.
              </p>
              <p class="v-card__text px-0 py-0">
              W ramach bezpłatnej konsultacji wstępnej online nasz zespół medyczny analizuje historię choroby oraz wszystkie wyniki badań diagnostycznych nadesłanych przez Pacjenta.
              Następnie, na ich podstawie formowane są indywidualne dobrane zalecenia odnośnie dalszej diagnostyki i leczenia, które odsyłamy do Pacjenta.
              </p>
              <p class="v-card__text px-0 py-0">
              Uważamy, że w leczeniu nowotworowym najważniejszy jest czas, dlatego już przy pierwszym kontakcie każdemu Pacjentowi HIFU CLINIC Centrum Leczenia Raka Prostaty zostaje
              przypisany specjalnie wybrany Koordynator Pacjenta, odpowiedzialny za ułatwienie pacjentowi procesu diagnostyczno-leczniczego.
              </p>
          </v-col>
         </v-row>
      </v-col>
    </v-row>
</template>
<script>
export default {
  data: () => ({
   
  }),
  methods: {
   
  }
}
</script>