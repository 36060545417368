var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{style:(("margin-top: " + (_vm.$vuetify.application.top) + "px")),attrs:{"color":"primary","mini-variant":_vm.variant,"width":"180","app":"","dark":"","permanent":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":"","flat":""}},[_vm._l((_vm.items),function(item){return [(_vm.checkModules(_vm.user.permissions,item.modules))?_c('v-list-item',{key:item.path,staticClass:"pl-3",class:item.path.split('.')[0] === _vm.$route.name.split('.')[0] ? 'v-list-item--active' : '',attrs:{"to":{ name: item.path },"link":"","color":"primary"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"30","color":item.path.split('.')[0] === _vm.$route.name.split('.')[0] ? 'secondary' : 'white'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1):_vm._e()]})],2),_c('h5',{staticStyle:{"color":"grey","position":"absolute","bottom":"65px","left":"5px"}},[_vm._v(_vm._s(_vm.appVersion))])],1),_c('v-app-bar',{attrs:{"app":"","color":"primary","clipped-left":""}},[_c('v-app-bar-nav-icon',{attrs:{"dark":""},on:{"click":function($event){$event.stopPropagation();_vm.variant = !_vm.variant}}}),_c('v-toolbar-title',{staticClass:"ml-0",staticStyle:{"width":"200px"}},[_c('v-img',{attrs:{"src":"/logo.png"}})],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('patients-search',{staticClass:"mr-4",attrs:{"dense":"","label":"Wyszukaj pacjenta"},on:{"patientSelected":function($event){return _vm.openPatient($event)}}}),_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"secondary","content":_vm.user.unread_notifications.length,"overlap":"","value":_vm.user.unread_notifications.length>0}},[(_vm.user.unread_notifications.length>0)?_c('v-icon',_vm._g({attrs:{"size":"28","color":"white"}},on),[_vm._v("mdi-bell")]):_c('v-icon',{attrs:{"size":"28","color":"grey"}},[_vm._v("mdi-bell")])],1)]}}]),model:{value:(_vm.notificationMenu),callback:function ($$v) {_vm.notificationMenu=$$v},expression:"notificationMenu"}},[_c('v-list',_vm._l((_vm.user.unread_notifications),function(item,i){return _c('v-list-item',{key:i,attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.data.header))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.data.body))])],1),_c('v-list-item-icon',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover?'secondary':''},on:{"click":function($event){return _vm.markAsRead(item.id)}}},[_vm._v("mdi-delete "+_vm._s(_vm.notificationAsReadStatus===1 && hover?'mdi-spin':''))])]}}],null,true)})],1)],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-none ml-6",attrs:{"outlined":"","color":"white"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.user.name[0].toUpperCase()+_vm.user.surname[0].toUpperCase())+" ")],1)]}}])},[_c('v-list',[_c('v-list-item',{key:"logout",on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Wyloguj")])],1),_c('v-list-item',{key:"passchange",attrs:{"to":{ name: 'haslo' }}},[_c('v-list-item-title',[_vm._v("Zmień hasło")])],1)],1)],1),_c('confirm',{ref:"confirm"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }