<template>
   <v-row  class="mb-1">
                                <v-col cols="8" >
                                  <v-select  v-model="assignedUsers" :items="editedItem.content.assigned_users" @change="$emit('changed')"  deletable-chips multiple chips small-chips hide-details="auto" label="Kto ma wykonać zadanie" />
                                </v-col>
                                <v-col cols="4" >
                                  <user-search label="Wyszukaj osobę" @userSelected="addUser($event)"  />
                                </v-col>
                              </v-row>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import UserSearch from '../../components/UsersSearch'
  export default {
    components:{
      UserSearch
    },
    props: {
      height:{
        type:Number,
        default:0
      },
    },
    data: () => ({
      
    }),
    validations() {
      
    },
    watch: {
     
    },
    computed: {
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
      assignedUsers:{
        get(){
          return _.map(this.editedItem.content.assigned_users,'value');
        },
        set(val){
          var newUsers = [];
          val.forEach(e => {
            newUsers.push(_.find(this.editedItem.content.assigned_users,{'value':e}));
          });
          this.editedItem.content.assigned_users = newUsers;
        }
      },
    },
    methods: {
      addUser(user){
        if(!_.includes(_.map(this.editedItem.content.assigned_users,'value'),user.value)){
          this.editedItem.content.assigned_users.push(user);
          this.$emit('changed')
        }
      },
    },
  }
</script>