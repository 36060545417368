<template>
      <v-col>
          <v-card style="background-color:#F5F5F5;" :loading="categoryAddStatus===1 || categoryEditLoadStatus===1 || categoryEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Anuluj</v-btn>
              <v-btn color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row>
            <v-col class="col-6 pl-5">
                <v-card>
                  <v-toolbar dense flat><v-toolbar-title>Dane podstawowe</v-toolbar-title></v-toolbar>
                  <v-card-text>
                          <v-row>                   
                            <v-col cols="6">
                              <v-text-field dense v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa działania"></v-text-field>                            
                            </v-col>
                            
                            <v-col cols="12">
                              <v-text-field dense v-model="editedItem.description" label="Opis"></v-text-field>
                            </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>      
            </v-col>
            <v-col class="col-6 pl-0 pr-5">
                <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Ikona</v-toolbar-title>
                    <v-spacer />
                    <v-btn href="https://pictogrammers.github.io/@mdi/font/5.4.55/" target="_blank" color="primary" x-small>Lista ikon</v-btn>
                  </v-toolbar>
                  <v-card-text>
                         <v-row>                   
                            <v-col cols="5">
                              <v-text-field dense v-model="editedItem.icon" label="Kod ikony MDI"></v-text-field>   
                            </v-col>
                            <v-col cols="7">
                              <v-icon size="35">{{editedItem.icon}}</v-icon>
                            </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>      
            </v-col>
          </v-row>

        </v-card>
      </v-col>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'

  export default {
    data: () => ({

    }),
    validations: {
      editedItem:{
        name: {
          required,
        },
      }
    },
    watch: {
      categoryEditStatus(val){
        if(val===2){
          this.close();
        }
      },
      categoryAddStatus(val){
        if(val===2){
          this.close();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params);
      },
      editedItem(){
        return this.$store.getters.getCategoryEdit;
      },
      categoryEditLoadStatus(){
        return this.$store.getters.getCategoryEditLoadStatus
      },
      categoryEditStatus(){
        return this.$store.getters.getCategoryEditStatus();
      },
      categoryEditText(){
        return this.$store.getters.getCategoryEditText
      },
      categoryAddStatus(){
        return this.$store.getters.getCategoryAddStatus();
      },
      categoryAddText(){
        return this.$store.getters.getCategoryAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowe działanie' : 'Edycja działania'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa działania jest wymagana')
        return errors
      },
    },
    mounted(){
      if(this.itemEditing){
        this.$store.dispatch( 'loadCategoryEdit', {slug:  this.$route.params.id} );
      }else{

      }
    },
    methods: {
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadCategoryEditDefault'); 
        this.$store.dispatch( 'loadCategoryAddDefault');
        this.$router.push({ name: 'ustawienia.dzialania.powrot'})
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editCategory', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                description:  this.editedItem.description,
                icon: this.editedItem.icon
              });
            } else {//new item
              this.$store.dispatch( 'addCategory', {
                  name:  this.editedItem.name,
                  description:  this.editedItem.description,
                  icon: this.editedItem.icon
              });

            }
        }
      },
    },
  }
</script>

