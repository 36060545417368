<template>
    <v-row>
      <v-col>
         <h2 class="mt-5">STREFA PACJENTA - WYNIKI BADAŃ</h2>  
         <v-progress-linear :indeterminate="resultsAddStatus===1" color="#cc0839" class="mt-2 mb-5"></v-progress-linear> 

         <v-dialog v-model="dialogCode" max-width="500">
                  <v-card>
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                      Potwierdzenie wysłania wiadomości
                    </v-card-title>

                    <v-card-text>
                      <v-row class="pl-1">
                        <v-col class="col-8">
                           <v-text-field v-model="code" :error-messages="codeErrors" required  label="Przepisz kod otrzymany w SMS"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="resultsAddStatus===5">
                                <v-col class="d-flex flex-column align-center">
                                      <v-alert dense outlined type="error">
                                          Nieprawidłowy kod
                                      </v-alert>
                                </v-col>
                          </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn color="gray" text @click="resendCode">Wyślij kod ponownie</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close">Anuluj</v-btn>
                      <v-btn @click="handleSubmit" dark color="#cc0839">Zatwierdź</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog> 
          <v-dialog v-model="dialogSuccess" max-width="500">
                  <v-card>
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                      Potwierdzenie wysłania wiadomości
                    </v-card-title>

                    <v-card-text>
                      <v-row class="pl-1">
                        <v-col class="d-flex flex-column align-center">
                           <h3 class="mb-2">Dziękujemy za przesłanie wiadomości</h3>
                           <p class="mb-0">Niebawem skontaktuje się z Państwem nasz konsultant medyczny</p>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex flex-column align-center">
                      <v-btn @click="end" dark color="#cc0839">Zakończ</v-btn>
                    </v-card-actions>
                  </v-card>
          </v-dialog>  
         <v-row>
          <v-col>
            <v-card height="400" >
                <v-row class="px-2" dense>
                    <v-col class="col-12"><h4>Informacje o pacjencie</h4></v-col>
                    <v-col class="col-6"><v-text-field v-model="editedItem.name" :error-messages="nameErrors"  dense outlined  label="Imię" /></v-col>
                    <v-col class="col-6"><v-text-field v-model="editedItem.surname" dense :error-messages="surnameErrors" outlined  label="Nazwisko" /></v-col>
                    <v-col class="col-6"><v-text-field v-model="editedItem.email" dense :error-messages="emailErrors" outlined  label="Adres e-mail" /></v-col>
                    <v-col class="col-6"><v-text-field v-model="editedItem.phone" dense :error-messages="phoneErrors" outlined  label="Numer telefonu" /></v-col>
                    <v-col class="col-12"><v-textarea  v-model="editedItem.body" dense :error-messages="bodyErrors" outlined  height="200" label="Treść opisu do załączonych plików" /></v-col>
                </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="400" >
             <v-row class="px-2" dense>
                <v-col class="col-10"><h4>Załączone do wiadomości pliki (max 10 MB)</h4></v-col>
                <v-col class="col-2 text-end" v-if="fileSizeSum>0"><h4><span :style="fileSizeSumErrors?'color:red;':'color:black;'">{{fileSizeSum}} MB</span></h4></v-col>
                <v-col :class="editedItem.files.length>0?'col-5':'col-12'">
                                   <file-drop  :fileAccept="accept" height="340"
                                    @newFile="addNewFile($event)"
                                    /> 
                </v-col>
                <v-col class="col-7 pl-0" v-if="editedItem.files.length>0">
                                  <v-responsive
                                      class="overflow-y-auto fill-height"
                                      height="325"
                                  >
                                  <v-simple-table>
                                      <template v-slot:default>
                                        <tbody>
                                          <tr
                                            v-for="(item, index) in editedItem.files"
                                            :key="index"
                                          >
                                          <td class="px-0"><v-icon size="30">{{item['file_type']=='pdf'?'mdi-file-pdf-box':item['file_type']=='png'?'mdi-file-image-outline':item['file_type']=='jpg'?'mdi-file-image-outline':item['file_type']=='jpeg'?'mdi-file-image-outline':'mdi-file'}}</v-icon></td>
                                          <td class="px-1" width="210">{{item['file_name']+'.'+item['file_type']}}</td>
                                          <td class="px-0"><v-icon @click="deleteFile(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>
                                            <!-- <td><v-avatar size="35" :color="item.category.color"><v-icon size="20">{{item.category.icon}}</v-icon></v-avatar></td>
                                            <td><b>{{ item.date_from }}</b></td>
                                            <td><b>{{ item.service.name }}</b><br />{{item.clinic?item.clinic.name:''}}</td>
                                            <td><i>{{ _.find(lookups.appointment_status,{'value':item.status}).text }}</i></td>
                                            <td><v-btn :to="{ name: 'dzialanie', params: { id: item.id } }" color="primary" x-small>Przejdź</v-btn></td> -->
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-responsive>
                </v-col>
             </v-row>
            </v-card>
          </v-col>
          <v-col class="col-12 text-end">
              <v-btn large @click="handleSubmit" dark color="#cc0839">Wyślij</v-btn>
          </v-col>
         </v-row>
      </v-col>
    </v-row>
</template>
<script>
import FileDrop from '../components/FileDrop'
import required from 'vuelidate/lib/validators/required'
import email from 'vuelidate/lib/validators/email'
import numeric from 'vuelidate/lib/validators/numeric'
import maxValue from 'vuelidate/lib/validators/maxValue'

export default {
  components:{FileDrop},
  data: () => ({
   accept:"image/png, image/jpeg, image/bmp, application/pdf",
   dialogCode:false,
   dialogSuccess:false,
   code:null,
   editedItem:{
       name:null,
       surname:null,
       email:null,
       phone:null,
       body:null,
       files:[],
       size:null
   }
  }),
  validations(){
    if(this.dialogCode){
      return {
        code:{required},
        editedItem:{
            name:{required},
            surname:{required},
            email:{required,email},
            phone:{required,numeric},
            body:{required},
            
            files:{
                $each:{
                file_name:{required}
                }
            }
        },
        fileSizeSum:{ maxValue: maxValue(1) }
    }
    }else{
      return {
        editedItem:{
            name:{required},
            surname:{required},
            email:{required,email},
            phone:{required,numeric},
            body:{required},
            
            files:{
                $each:{
                file_name:{required}
                }
            }
        },
        fileSizeSum:{ maxValue: maxValue(1) }
    }
    }
    
  },
  watch:{
    resultsAddStatus(val){
      if(val===2){//ok, dodano
        this.dialogCode = false;
        this.dialogSuccess = true;
      }else if(val===5){//nieprawidłowy PIN

      }else if(val===4){//PIN wymagany
        this.code = null;
        this.dialogCode = true;
      }
    },
  },
  computed:{
    nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Imię jest wymagane')
        return errors
    },
    surnameErrors () {
        const errors = []
        if (!this.$v.editedItem.surname.$dirty) return errors
        !this.$v.editedItem.surname.required && errors.push('Nazwisko jest wymagane')
        return errors
    },
    emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.required && errors.push('Adres e-mail jest wymagane');
        !this.$v.editedItem.email.email && errors.push('Adres e-mail jest niepoprawny');
        return errors
    },
    phoneErrors () {
        const errors = []
        if (!this.$v.editedItem.phone.$dirty) return errors
        !this.$v.editedItem.phone.required && errors.push('Numer telefonu jest wymagany');
        !this.$v.editedItem.phone.numeric && errors.push('Może zawierać tylko cyfry');
        return errors
    },
    bodyErrors () {
        const errors = []
        if (!this.$v.editedItem.body.$dirty) return errors
        !this.$v.editedItem.body.required && errors.push('Treść jest wymagana')
        return errors
    },
    codeErrors () {
        const errors = []
        if (!this.dialogCode || !this.$v.code.$dirty) return errors
        !this.$v.code.required && errors.push('Kod jest wymagany')
        return errors
    },
    fileSizeSumErrors(){
        return !this.$v.fileSizeSum.maxValue;
    },
    fileSizeSum(){
        var sum = 0;
        this.editedItem.files.forEach(f => {
            sum += f.file_size;
        });
        return Math.round(sum*10)/10;
    },
    resultsAddStatus(){
      return this.$store.getters.getResultsAddStatus();
    }
  },
  methods: {
    addNewFile(file){
        var f = file;
        f.file_size = file['file_content'].length/1.37/1e+6;
        this.editedItem.files.push(file);
    },
    deleteFile(index){
        this.editedItem.files.splice(index,1);
    },
    close(){
      this.dialogCode = false;
      this.code = null;
    },
    end(){
      this.dialogSuccess = false
      this.$router.push({name:'portal.pulpit'})
    },
    resendCode(){
      this.code = null;
      this.dialogCode = false;
      this.handleSubmit();
    },
    handleSubmit () {
        this.$v.$touch()

        if (this.$v.$invalid) {

        } else {
              this.$store.dispatch( 'addResults', {
                  name:  this.editedItem.name,
                  surname:  this.editedItem.surname,
                  email:this.editedItem.email,
                  phone:this.editedItem.phone,
                  body:this.editedItem.body,
                  files:this.editedItem.files,
                  code:  this.code,
              });
        }
      },
  }
}
</script>