<template>
      <v-col>
          <v-card style="background-color:#F5F5F5;" :loading="leafletAddStatus===1 || leafletEditLoadStatus===1 || leafletEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Anuluj</v-btn>
              <v-btn color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row>
            <v-col class="col-5 pl-5">
                <v-card>
                  <v-toolbar dense flat><v-toolbar-title>Dane podstawowe</v-toolbar-title></v-toolbar>
                  <v-card-text>
                          <v-row>                   
                            <v-col cols="12">
                              <v-text-field dense v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa ulotki"></v-text-field>                            
                            </v-col>
                            <v-col cols="12">
                              <v-text-field dense v-model="editedItem.description" label="Opis"></v-text-field>                            
                            </v-col>
                            <v-col cols="9">
                              <v-text-field dense v-model="editedItem.file_name" :error-messages="file_nameErrors" required @input="$v.editedItem.file_name.$touch()"  @blur="$v.editedItem.file_name.$touch()" label="Nazwa pliku"></v-text-field>                            
                            </v-col>
                            <v-col cols="3">
                              <v-text-field readonly dense v-model="editedItem.file_type" :error-messages="file_typeErrors" required @input="$v.editedItem.file_type.$touch()"  @blur="$v.editedItem.file_type.$touch()" label="Typ pliku"></v-text-field>                            
                            </v-col>
                            <v-col cols="12">
                              
                            </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>      
            </v-col>
            <v-col class="col-7 pl-0 pr-5">
                <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Plik</v-toolbar-title>
                    
                    <v-spacer />
                    <v-btn v-if="editedItem.file_content" @click="clearFile" x-small color="secondary">Usuń</v-btn>  
                  </v-toolbar>
                  <v-card-text class="pt-0">
                          <v-row>
                            <v-col dense class="d-flex flex-column align-center">
                              <v-alert v-if="file_contentErrors" dense outlined type="error">{{file_contentErrors}}</v-alert>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">    
                                <v-col dense cols="12">
    <v-pagination
      v-if="editedItem.file_content && editedItem.file_type==='pdf' && pageCount>1"
      total-visible="6"
      v-model="currentPage"
      :length="pageCount"
    ></v-pagination>
                                  <pdf 
                                    v-if="editedItem.file_content && editedItem.file_type==='pdf'" 
                                    :src="editedItem.file_content" 
                                    @num-pages="pageCount = $event"
			                              :page="currentPage"
                                  />
                                  <v-img v-else-if="editedItem.file_content" :src="editedItem.file_content" contain height="80" />
                                  <file-drop 
                                    v-else 
                                    :height="200"
                                    :fileAccept="accept"
                                    :fileContent.sync="editedItem.file_content" 
                                    :fileName.sync="editedItem.file_name"
                                    :fileType.sync="editedItem.file_type" 
                                  />
                                </v-col>

                          </v-row>
                  </v-card-text>
                </v-card>       
            </v-col>
          </v-row>
        </v-card>
      </v-col>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import FileDrop from '../components/FileDrop.vue'
  import pdf from 'vue-pdf'

  export default {
    components: { FileDrop,pdf },
    data: () => ({
      accept:"image/png, image/jpeg, image/bmp, application/pdf",
      currentPage: 1,
			pageCount: 0,
    }),
    validations: {
      editedItem:{
        name: {
          required,
        },
        file_name: {
          required,
        },
        file_type: {
          required,
        },
        file_content: {
          required,
        },
      }
    },
    watch: {
      leafletEditStatus(val){
        if(val===2){
          this.close();
        }
      },
      leafletAddStatus(val){
        if(val===2){
          this.close();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params);
      },
      editedItem(){
        return this.$store.getters.getLeafletEdit;
      },
      leafletEditLoadStatus(){
        return this.$store.getters.getLeafletEditLoadStatus
      },
      leafletEditStatus(){
        return this.$store.getters.getLeafletEditStatus();
      },
      leafletEditText(){
        return this.$store.getters.getLeafletEditText
      },
      leafletAddStatus(){
        return this.$store.getters.getLeafletAddStatus();
      },
      leafletAddText(){
        return this.$store.getters.getLeafletAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowa ulotka' : 'Edycja ulotki'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa ulotki jest wymagana')
        return errors
      },
      file_nameErrors () {
        const errors = []
        if (!this.$v.editedItem.file_name.$dirty) return errors
        !this.$v.editedItem.file_name.required && errors.push('Nazwa pliku jest wymagana')
        return errors
      },
      file_typeErrors () {
        const errors = []
        if (!this.$v.editedItem.file_type.$dirty) return errors
        !this.$v.editedItem.file_type.required && errors.push('Typ pliku jest wymagany')
        return errors
      },
      file_contentErrors () {
        if (!this.$v.editedItem.file_content.$dirty) return ''
        if(!this.$v.editedItem.file_content.required) return 'Plik jest wymagany'
      },
    },
    mounted(){
      if(this.itemEditing){
        this.$store.dispatch( 'loadLeafletEdit', {slug:  this.$route.params.id} );
      }else{

      }
    },
    methods: {
      clearFile(){
        this.editedItem.file_content = null
        this.editedItem.file_name = null
        this.editedItem.file_type = null
      },
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadLeafletEditDefault'); 
        this.$store.dispatch( 'loadLeafletAddDefault');
        this.$router.push({ name: 'ustawienia.ulotki.powrot'})
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editLeaflet', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                description:  this.editedItem.description,
                file_name: this.editedItem.file_name,
                file_content: this.editedItem.file_content,
                file_type: this.editedItem.file_type,
              });
            } else {//new item
              this.$store.dispatch( 'addLeaflet', {
                  name:  this.editedItem.name,
                  description:  this.editedItem.description,
                  file_name: this.editedItem.file_name,
                  file_content: this.editedItem.file_content,
                  file_type: this.editedItem.file_type,
              });

            }
        }
      },
    },
  }
</script>

