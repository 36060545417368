var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.tableItems,"loading":_vm.patientsLoadStatus===1,"options":_vm.tableOptions,"server-items-length":_vm.options.lineCount,"hide-default-footer":"","must-sort":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Pacjenci")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchPatients($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[_c('span',[_vm._v("Wpisz min 3 znaki i naciśnij Enter")])]),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":"Pokaż zamknięte"},model:{value:(_vm.showDeleted),callback:function ($$v) {_vm.showDeleted=$$v},expression:"showDeleted"}}),(_vm.user.permissions['patient'] >= 10)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.user.permissions['patient'] >= 10)?_c('v-dialog',{attrs:{"max-width":_vm.itemEditing || _vm.patientEditing?'1000px':'500px'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mt-2",attrs:{"small":"","fab":"","dark":"","color":"primary"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,506460785),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('patient-editor',{attrs:{"item-editing":_vm.itemEditing},on:{"close":_vm.close}})],1):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status && _vm.user.permissions['patient'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]):_vm._e(),(item.status && item.can_delete && _vm.user.permissions['patient'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Wyłącz")])]):_vm._e(),(!item.status  && _vm.user.permissions['patient'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.reactivateItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-restore")])],1)]}}],null,true)},[_c('span',[_vm._v("Przywróć")])]):_vm._e(),(!item.status && item.can_delete  && _vm.user.permissions['patient'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.forceDeleteItem(item)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete-forever")])],1)]}}],null,true)},[_c('span',[_vm._v("Usuń na stałe")])]):_vm._e(),(item.status && _vm.user.permissions['patient'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"to":{name:'pacjent',params:{id:item.id}},"x-small":"","color":"secondary"}},on),[_vm._v("Karta")])]}}],null,true)},[_c('span',[_vm._v("Karta pacjenta")])]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone_prefix=='48'?item.phone:('+'+item.phone_prefix+item.phone))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(!item.status),callback:function ($$v) {_vm.$set(!item, "status", $$v)},expression:"!item.status"}})]}}])}),_c('div',{staticClass:"text-center pt-2"},[(!_vm.options.itemAdded && _vm.options.lineCount-_vm.tableItems.length>0 && _vm.patientsLoadStatus===2)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadMore}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Załaduj więcej ("+_vm._s(_vm.options.lineCount-_vm.tableItems.length)+")")],1):(_vm.options.itemAdded && _vm.options.lineCount-_vm.tableItems.length>0)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadPatients}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Odśwież tabele aby móc załadować więcej ("+_vm._s(_vm.options.lineCount-_vm.tableItems.length)+")")],1):(_vm.options.itemSearched)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadPatients}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Anuluj wyszukiwanie")],1):_vm._e()],1)],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }