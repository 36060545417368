const allCountries = [
    ["Afganistan (افغانستان‎)", "af", "93"],
    ["Albania (Shqipëri)", "al", "355"],
    ["Algieria (الجزائر‎)", "dz", "213"],
    ["Samoa Amerykańskie", "as", "1684"],
    ["Andora", "ad", "376"],
    ["Angola", "ao", "244"],
    ["Anguilla", "ai", "1264"],
    ["Antigua i Barbuda", "ag", "1268"],
    ["Argentyna", "ar", "54"],
    ["Armenia (Հայաստան)", "am", "374"],
    ["Aruba", "aw", "297"],
    ["Australia", "au", "61", 0],
    ["Austria (Österreich)", "at", "43"],
    ["Azerbejdżan (Azərbaycan)", "az", "994"],
    ["Bahamy", "bs", "1242"],
    ["Bahrajn (البحرين)", "bh", "973"],
    ["Bangladesz (বাংলাদেশ)", "bd", "880"],
    ["Barbados", "bb", "1246"],
    ["Białoruś (Беларусь)", "by", "375"],
    ["Belgia (Belgia)", "be", "32"],
    ["Belize", "bz", "501"],
    ["Benin (Bénin)", "bj", "229"],
    ["Bermudy", "bm", "1441"],
    ["Bhutan (འབྲུག)", "bt", "975"],
    ["Boliwia", "bo", "591"],
    ["Bośnia i Hercegowina (Босна и Херцеговина)", "ba", "387"],
    ["Botswana", "bw", "267"],
    ["Brazylia (Brazylia)", "br", "55"],
    ["Brytyjskie Terytorium Oceanu Indyjskiego", "io", "246"],
    ["Brytyjskie Wyspy Dziewicze", "vg", "1284"],
    ["Brunei", "bn", "673"],
    ["Bułgaria (България)", "bg", "359"],
    ["Burkina Faso", "bf", "226"],
    ["Burundi (Uburundi)", "bi", "257"],
    ["Kambodża (កម្ពុជា)", "kh", "855"],
    ["Kamerun (Kamerun)", "cm", "237"],
    // [
    //   "Kanada",
    //   "ca",
    //   "1",
    //   1,
    //   [
    //     "204",
    //     "226",
    //     "236",
    //     "249",
    //     "250",
    //     "289",
    //     "306",
    //     "343",
    //     "365",
    //     "387",
    //     "403",
    //     "416",
    //     "418",
    //     "431",
    //     "437",
    //     "438",
    //     "450",
    //     "506",
    //     "514",
    //     "519",
    //     "548",
    //     "579",
    //     "581",
    //     "587",
    //     "604",
    //     "613",
    //     "639",
    //     "647",
    //     "672",
    //     "705",
    //     "709",
    //     "742",
    //     "778",
    //     "780",
    //     "782",
    //     "807",
    //     "819",
    //     "825",
    //     "867",
    //     "873",
    //     "902",
    //     "905"
    //   ]
    // ],
    ["Wyspy Zielonego Przylądka (Kabu Verdi)", "cv", "238"],
    // ["Niderlandy Karaibskie", "bq", "599", 1],
    ["Kajmany", "ky", "1345"],
    ["Republika Środkowoafrykańska (République centralafricaine)", "cf", "236"],
    ["Czad (Czad)", "td", "235"],
    ["Chile", "cl", "56"],
    ["Chiny (中国)", "cn", "86"],
    // ["Wyspa Bożego Narodzenia", "cx", "61", 2],
    // ["Wyspy Kokosowe (Keelinga)", "cc", "61", 1],
    ["Kolumbia", "co", "57"],
    ["Komory (جزر القمر‎)", "km", "269"],
    ["Kongo (DRK) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243"],
    ["Kongo (Republika) (Kongo-Brazzaville)", "cg", "242"],
    ["Wyspy Cooka", "ck", "682"],
    ["Kostaryka", "cr", "506"],
    ["Wybrzeże Kości Słoniowej", "ci", "225"],
    ["Chorwacja (Hrvatska)", "hr", "385"],
    ["Kuba", "cu", "53"],
    ["Curaçao", "cw", "599", 0],
    ["Cypr (Κύπρος)", "cy", "357"],
    ["Republika Czeska (Česká republika)", "cz", "420"],
    ["Dania (Dania)", "dk", "45"],
    ["Dżibuti", "dj", "253"],
    ["Dominika", "dm", "1767"],
    // [
    //   "Republika Dominikańska (República Dominicana)",
    //   "do",
    //   "1",
    //   2,
    //   ["809", "829", "849"]
    // ],
    ["Ekwador", "ec", "593"],
    ["Egipt (مصر‎)", "eg", "20"],
    ["Salwador", "sv", "503"],
    ["Gwinea Równikowa (Gwinea Ekwatorialna)", "gq", "240"],
    ["Erytrea", "er", "291"],
    ["Estonia (Eesti)", "ee", "372"],
    ["Etiopia", "et", "251"],
    ["Falklandy (Islas Malvinas)", "fk", "500"],
    ["Wyspy Owcze (Føroyar)", "fo", "298"],
    ["Fidżi", "fj", "679"],
    ["Finlandia (Suomi)", "fi", "358", 0],
    ["Francja", "fr", "33"],
    ["Gujana Francuska (Gujana Francuska)", "gf", "594"],
    ["Polinezja Francuska (Polynésie française)", "pf", "689"],
    ["Gabon", "ga", "241"],
    ["Gambia", "gm", "220"],
    ["Gruzja (საქართველო)", "ge", "995"],
    ["Niemcy (Niemcy)", "de", "49"],
    ["Ghana (Gaana)", "gh", "233"],
    ["Gibraltar", "gi", "350"],
    ["Grecja (Ελλάδα)", "gr", "30"],
    ["Grenlandia (Kalaallit Nunaat)", "gl", "299"],
    ["Grenada", "gd", "1473"],
    ["Gwadelupa", "gp", "590", 0],
    ["Guam", "gu", "1671"],
    ["Gwatemala", "gt", "502"],
    //["Guernsey", "gg", "44", 1],
    ["Gwinea (Gwinea)", "gn", "224"],
    ["Gwinea Bissau (Gwinea Bissau)", "gw", "245"],
    ["Gujana", "gy", "592"],
    ["Haiti", "ht", "509"],
    ["Honduras", "hn", "504"],
    ["Hongkong (香港)", "hk", "852"],
    ["Węgry (Magyarország)", "hu", "36"],
    ["Islandia (Ísland)", "is", "354"],
    ["Indie (भारत)", "in", "91"],
    ["Indonezja", "id", "62"],
    ["Iran (اان‎)", "ir", "98"],
    ["Irak (العراق‎)", "iq", "964"],
    ["Irlandia", "ie", "353"],
    // ["Wyspa Man", "im", "44", 2],
    ["Izrael (ישראל‎)", "il", "972"],
    ["Włochy (Italia)", "it", "39", 0],
    ["Jamajka", "jm", "1876"],
    ["Japonia (日本)", "jp", "81"],
    // ["Jersey", "je", "44", 3],
    ["Jordan (الأردن)", "jo", "962"],
    // ["Kazachstan (Kazastan)", "kz", "7", 1],
    ["Kenia", "ke", "254"],
    ["Kiribati", "ki", "686"],
    ["Kosowo", "xk", "383"],
    ["Kuwejt (الكويت‎)", "kw", "965"],
    ["Kirgistan (Kыргызstan)", "kg", "996"],
    ["Laos (ລາວ)", "la", "856"],
    ["Łotwa (Łotwa)", "lv", "371"],
    ["Liban (لبنان‎)", "lb", "961"],
    ["Lesotho", "ls", "266"],
    ["Liberia", "lr", "231"],
    ["Libia (ليبيا‎)", "ly", "218"],
    ["Liechtenstein", "li", "423"],
    ["Litwa (Lietuva)", "lt", "370"],
    ["Luksemburg", "lu", "352"],
    ["Makau (澳門)", "mo", "853"],
    ["Macedonia (BJRM) (Македонија)", "mk", "389"],
    ["Madagaskar (Madagasikara)", "mg", "261"],
    ["Malawi", "mw", "265"],
    ["Malezja", "my", "60"],
    ["Malediwy", "mv", "960"],
    ["Mali", "ml", "223"],
    ["Malta", "mt", "356"],
    ["Wyspy Marshalla", "mh", "692"],
    ["Martynika", "mq", "596"],
    ["Mauretania (موريتانيا‎)", "mr", "222"],
    ["Mauritius (Moris)", "mu", "230"],
    // ["Majotta", "yt", "262", 1],
    ["Meksyk (Meksyk)", "mx", "52"],
    ["Mikronezja", "fm", "691"],
    ["Mołdawia (Republika Mołdawia)", "md", "373"],
    ["Monako", "mc", "377"],
    ["Mongolia (Монгол)", "mn", "976"],
    ["Czarnogóra (Crna Gora)", "me", "382"],
    ["Montserrat", "ms", "1664"],
    ["Maroko (المغرب‎)", "ma", "212", 0],
    ["Mozambik (Mocambik)", "mz", "258"],
    ["Myanmar (Birma) (မြန်မာ)", "mm", "95"],
    ["Namibia (Namibië)", "na", "264"],
    ["Nauru", "nr", "674"],
    ["Nepal (नेपाल)", "np", "977"],
    ["Holandia (Holandia)", "nl", "31"],
    ["Nowa Kaledonia (Nouvelle-Calédonie)", "nc", "687"],
    ["Nowa Zelandia", "nz", "64"],
    ["Nikaragua", "ni", "505"],
    ["Niger (Nijar)", "ne", "227"],
    ["Nigeria", "ng", "234"],
    ["Niue", "nu", "683"],
    ["Wyspa Norfolk", "nf", "672"],
    ["Korea Północna (조선 민주주의 인민 공화국)", "kp", "850"],
    ["Marianie Północne", "mp", "1670"],
    ["Norwegia (Norge)", "no", "47", 0],
    ["Oman (عُمان‎)", "om", "968"],
    ["Pakistan (پاکستان‎)", "pk", "92"],
    ["Palau", "pw", "680"],
    ["Palestyna (فلسطين)", "ps", "970"],
    ["Panama (Panamá)", "pa", "507"],
    ["Papua Nowa Gwinea", "pg", "675"],
    ["Paragwaj", "py", "595"],
    ["Peru (Peru)", "pe", "51"],
    ["Filipiny", "ph", "63"],
    ["Polska (Polska)", "pl", "48"],
    ["Portugalia", "pt", "351"],
    // ["Puerto Rico", "pr", "1", 3, ["787", "939"]],
    ["Katar (قطر‎)", "qa", "974"],
    ["Réunion (La Réunion)", "re", "262", 0],
    ["Rumunia (Rumunia)", "ro", "40"],
    ["Rosja (Россия)", "ru", "7", 0],
    ["Rwanda", "rw", "250"],
    // ["Św. Barthelemy", "bl", "590", 1],
    ["Święta Helena", "sh", "290"],
    ["Saint Kitts i Nevis", "kn", "1869"],
    ["Święta Łucja", "lc", "1758"],
    // ["Saint Martin (Saint-Martin (partie française))", "mf", "590", 2],
    ["Saint-Pierre i Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
    ["Święty Wincenty i Grenadyny", "vc", "1784"],
    ["Samoa", "ws", "685"],
    ["San Marino", "sm", "378"],
    ["Wys. Tomasza i Książęca (Wysp Świętego Tomasza i Książęca)", "st", "239"],
    ["Arabia Saudyjska (المملكة العربية السعودية‎)", "sa", "966"],
    ["Senegal (Sénégal)", "sn", "221"],
    ["Serbia (Србија)", "rs", "381"],
    ["Seszele", "sc", "248"],
    ["Sierra Leone", "sl", "232"],
    ["Singapur", "sg", "65"],
    ["Sint Maarten", "sx", "1721"],
    ["Słowacja (Slovensko)", "sk", "421"],
    ["Słowenia (Słowenia)", "si", "386"],
    ["Wyspy Salomona", "sb", "677"],
    ["Somalia (Soomaaliya)", "so", "252"],
    ["RPA", "za", "27"],
    ["Korea Południowa (대한민국)", "kr", "82"],
    ["Sudan Południowy (جنوب السودان‎)", "ss", "211"],
    ["Hiszpania (Hiszpania)", "es", "34"],
    ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94"],
    ["Sudan (السودان‎)", "sd", "249"],
    ["Surinam", "sr", "597"],
    ["Svalbard i Jan Mayen", "sj", "47", 1],
    ["Swaziland", "sz", "268"],
    ["Szwecja (Sverige)", "se", "46"],
    ["Szwajcaria (Szwajcaria)", "ch", "41"],
    ["Syria (سوريا‎)", "sy", "963"],
    ["Tajwan (台灣)", "tw", "886"],
    ["Tadżykistan", "tj", "992"],
    ["Tanzania", "tz", "255"],
    ["Tajlandia (ไทย)", "th", "66"],
    ["Timor Wschodni", "tl", "670"],
    ["Togo", "tg", "228"],
    ["Tokelau", "tk", "690"],
    ["Tonga", "to", "676"],
    ["Trynidad i Tobago", "tt", "1868"],
    ["Tunezja (تونس‎)", "tn", "216"],
    ["Turcja (Turcja)", "tr", "90"],
    ["Turkmenistan", "tm", "993"],
    ["Wyspy Turks i Caicos", "tc", "1649"],
    ["Tuvalu", "tv", "688"],
    ["Wyspy Dziewicze Stanów Zjednoczonych", "vi", "1340"],
    ["Uganda", "ug", "256"],
    ["Ukraina (Україна)", "ua", "380"],
    ["Zjednoczone Emiraty Arabskie (الإمارات العربية المتحدة‎)", "ae", "971"],
    ["Wielka Brytania", "gb", "44", 0],
    ["Stany Zjednoczone", "us", "1", 0],
    ["Urugwaj", "uy", "598"],
    ["Uzbekistan (Oʻzbekiston)", "uz", "998"],
    ["Vanuatu", "vu", "678"],
    // ["Watykan (Città del Vaticano)", "va", "39", 1],
    ["Wenezuela", "ve", "58"],
    ["Wietnam (Wietnam)", "vn", "84"],
    ["Wallis i Futuna (Wallis-et-Futuna)", "wf", "681"],
    // ["Sahara Zachodnia (الصحراء الغربية‎)", "eh", "212", 1],
    ["Jemen (اليمن‎)", "ye", "967"],
    ["Zambia", "zm", "260"],
    ["Zimbabwe", "zw", "263"],
    // ["Wyspy Alandzkie", "ax", "358", 1]
  ];
  
  export default allCountries.sort((a,b)=>a[0].localeCompare(b[0])).map(([name, iso2, dialCode]) => ({
    name,
    iso2: iso2.toUpperCase(),
    dialCode
  }));