import ServiceAPI from "../api/service";

export const services = {
    state: {
        services: [],
        serviceOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:15,
            itemAdded:false,
            itemSearched:false
        },
        servicesLoadStatus: 0,
        serviceAddStatus: 0,
        serviceAddText: "",

        serviceEdit: {
            id: undefined,
            lp: undefined,
            name: undefined,
            code:undefined,
            category_id:undefined,
            referral_type_id:undefined,
            info:undefined,
            patient_info:undefined,
        },
        serviceEditDefault: {
            id: undefined,
            lp: undefined,
            name: undefined,
            code:undefined,
            category_id:undefined,
            referral_type_id:undefined,
            info:undefined,
            patient_info:undefined,
        },
        serviceEditLoadStatus: 0,
        serviceEditStatus: 0,
        serviceEditText: "",

        serviceDeleteStatus: 0,
        serviceDeleteText: "",

        servicesLookup:[],
        servicesLookupLoadStatus:0,
    },

    actions: {
        loadServices({ state, commit, dispatch }) {
            commit("setServicesLoadStatus", 1);
                ServiceAPI.getServices({
                    offset:0,
                    itemsPerPage:state.serviceOptions.itemsPerPage, 
                    orderBy:state.serviceOptions.orderBy,
                    orderDirection:state.serviceOptions.orderDirection,
                    showDeleted:state.serviceOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setServiceLineCount", response.data.lineCount);
                    commit("setServices", response.data.services);
                    dispatch("applyIndexOnServices");
                    commit("setServicesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setServices", []);
                    commit("setServicesLoadStatus", 3);
                });
         
        },
        loadMoreServices({ state, commit, dispatch }) {
            commit("setServicesLoadStatus", 1);
                ServiceAPI.getServices({
                    offset:state.services.length,
                    itemsPerPage:state.serviceOptions.itemsPerPage, 
                    orderBy:state.serviceOptions.orderBy,
                    orderDirection:state.serviceOptions.orderDirection,
                    showDeleted:state.serviceOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setServiceLineCount", response.data.lineCount);
                    commit("setServices", _.concat(state.services, response.data.services));
                    dispatch("applyIndexOnServices");
                    commit("setServicesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setServices", []);
                    commit("setServicesLoadStatus", 3);
                });
         
        },
        searchServices({ state, commit, dispatch },data) {
            commit("setServicesLoadStatus", 1);
                ServiceAPI.searchServices({
                    showDeleted:state.serviceOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setServiceSearchedTrue");
                    commit("setServiceLineCount", response.data.lineCount);
                    commit("setServices", response.data.services);
                    dispatch("applyIndexOnServices");
                    commit("setServicesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setServices", []);
                    commit("setServicesLoadStatus", 3);
                });
         
        },
        searchServicesLookup({ state, commit, dispatch },data) {
            commit("setServicesLookupLoadStatus", 1);
                ServiceAPI.searchServicesLookup({
                    q:data.q,
                    category_id:data.category_id
                })
                .then(function(response) {
                    commit("setServicesLookup", response.data);
                    commit("setServicesLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setServicesLookup", []);
                    commit("setServicesLookupLoadStatus", 3);
                });
         
        },
        setServiceOptions({ state, commit, dispatch },data){
            commit("setServiceOptions",data.options);
            dispatch("loadServices");
        },
        applyIndexOnServices({ commit, state, dispatch }) {
            let localServices = state.services;

            var i = 1;
            localServices.forEach(service => {
                service.lp = i;
                i++;
            });

            commit("setServices",localServices);
        },
        addService({ commit, state, dispatch }, data) {
            commit("setServiceAddStatus", 1);
            commit("setServiceAddText", "");

            ServiceAPI.postAddNewService(
                data.name,
                data.code,
                data.category_id,
                data.referral_type_id,
                data.info,
                data.patient_info
            )
                .then(function(response) {
                    commit("setServiceAddStatus", 2);
                    commit("setAddedService",response.data);
                    commit("setServiceLineCount", state.serviceOptions.lineCount + 1);
                    dispatch("applyIndexOnServices");
                })
                .catch(function(error) {
                    commit("setServiceAddStatus", 3);
                    commit("setServiceAddText", error.response.data.errors);
                });
        },

        loadServiceEdit({ commit }, data) {
            commit("setServiceEditLoadStatus", 1);
            commit("setServiceEditStatus", 0);
            commit("setServiceEditText", "");

            ServiceAPI.getServiceEdit(data.slug)
                .then(function(response) {
                    commit("setServiceEdit", response.data);
                    commit("setServiceEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setServiceEdit", {});
                    commit("setServiceEditLoadStatus", 3);
                });
        },
        loadPermissionList({ commit }, data) {
            commit("setServiceEditLoadStatus", 1);
            commit("setServiceEditStatus", 0);
            commit("setServiceEditText", "");

            ServiceAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setPermissionListEdit", response.data);
                    commit("setServiceEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setServiceEdit", {});
                    commit("setServiceEditLoadStatus", 3);
                });
        },
        loadServiceEditDefault({ commit }) {
            commit("setServiceEditDefault");
        },
        loadServiceAddDefault({ commit }) {
            commit("setServiceAddStatus", 0);
            commit("setServiceAddText", "");
        },
        editService({ commit, state, dispatch }, data) {
            commit("setServiceEditStatus", 1);

            ServiceAPI.putEditService(
                data.slug,
                data.name,
                data.code,
                data.category_id,
                data.referral_type_id,
                data.info,
                data.patient_info
            )
                .then(function(response) {
           
                    commit("setServiceEditStatus", 2);
                    commit("setEditedService",response.data)
                    dispatch("applyIndexOnServices");
                })
                .catch(function(error) {
                    commit("setServiceEditStatus", 3);
                    commit("setServiceEditText", error.response.data.errors);
                });
        },
        editServiceReactivate({ commit, state, dispatch }, data) {
            commit("setServiceEditStatus", 1);
  
            ServiceAPI.putReactivateService(data.slug)
                .then(function(response) {
                    commit("setServiceEditStatus", 2);
                    commit("setEditedService",response.data)
                    dispatch("applyIndexOnServices");
                })
                .catch(function(error) {
                    commit("setServiceEditStatus", 3);
                });
        },
        deleteService({ commit, state, dispatch }, data) {
            commit("setServiceDeleteStatus", 1);

            ServiceAPI.deleteService(data.slug)
                .then(function(response) {
                    commit("setServiceDeleteStatus", 2);
                    if(state.serviceOptions.showDeleted){
                        commit("setEditedService",response.data)
                    }else{
                        commit("setServiceLineCount", state.serviceOptions.lineCount - 1);
                        commit("setDeletedService",response.data)
                    }
                    dispatch("applyIndexOnServices");
                })
                .catch(function() {
                    commit("setServiceDeleteStatus", 3);
                });
        },
        forceDeleteService({ commit, state, dispatch }, data) {
            commit("setServiceDeleteStatus", 1);

            ServiceAPI.forceDeleteService(data.slug)
                .then(function(response) {
                    commit("setServiceDeleteStatus", 2);
                    commit("setServiceLineCount", state.serviceOptions.lineCount - 1);
                    commit("setDeletedService",response.data)
                    dispatch("applyIndexOnServices");
                })
                .catch(function() {
                    commit("setServiceDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setServiceOptions(state,options){
            state.serviceOptions = options;
        },
        setServicesLoadStatus(state, status) {
            state.servicesLoadStatus = status;
        },
        setServicesLookupLoadStatus(state, status) {
            state.servicesLookupLoadStatus = status;
        },
        setServices(state, services) {
            state.services = services;
        },
        setServicesLookup(state, services) {
            state.servicesLookup = services;
        },
        setServiceLineCount(state, lineCount) {
            state.serviceOptions.lineCount = lineCount;
        },
        setServiceAddStatus(state, status) {
            state.serviceAddStatus = status;
        },
        setServiceAddText(state, text) {
            state.serviceAddText = text;
        },
        setServiceEdit(state, service) {
            state.serviceEdit = service;
        },
        setPermissionListEdit(state, list) {
            state.serviceEdit.computed_permissions = list;
        },
        setEditedService(state, service) {
            var lp = _.find(state.services,{'id':service.id}).lp;
            var index = _.findIndex(state.services,{'id':service.id});
       
            state.services[index] = service;
            state.services[index].lp = lp;
        },
        setAddedService(state, service) {
            state.serviceOptions.itemAdded = true;
            state.services.push(service);
        },
        setServiceSearchedTrue(state) {
            state.serviceOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.serviceOptions.itemAdded = false;
            state.serviceOptions.itemSearched = false;
        },
        setDeletedService(state, service) {
            var index = _.findIndex(state.services,{'id':service.id});
            state.services.splice(index,1);
        },
        setServiceEditDefault(state) {
            state.serviceEdit = { ...state.serviceEditDefault };
        },
        setServiceEditStatus(state, status) {
            state.serviceEditStatus = status;
        },
        setServiceEditText(state, text) {
            state.serviceEditText = text;
        },
        setServiceEditLoadStatus(state, status) {
            state.serviceEditLoadStatus = status;
        },
        setServiceDeleteStatus(state, status) {
            state.serviceDeleteStatus = status;
        },
        setServiceDeletedText(state, text) {
            state.serviceDeleteText = text;
        }
    },

    getters: {
        getServiceOptions(state){
            return state.serviceOptions;
        },
        getServicesLoadStatus(state) {
            return state.servicesLoadStatus;
        },
        getServicesLookupLoadStatus(state) {
            return state.servicesLookupLoadStatus;
        },
        getServices(state) {
            return state.services;
        },
        getServicesLookup(state) {
            return state.servicesLookup;
        },
        getServiceAddStatus(state) {
            return function(){
                return state.serviceAddStatus;
            } 
        },
        getServiceAddText(state) {
            return state.serviceAddText;
        },
        getServiceEdit(state) {
            return state.serviceEdit;
        },
        getServiceEditStatus(state) {
          return function(){
            return state.serviceEditStatus;
          }
        },
        getServiceEditing(state) {
            return state.serviceEdit.id > 0;
        },
        getServiceEditText(state) {
            return state.serviceEditText;
        },
        getServiceEditLoadStatus(state) {
            return state.serviceEditLoadStatus;
            
        },
        getServiceDeleteStatus(state) {
            return function(){
                return state.serviceDeleteStatus;
            }
        },
        getServiceDeleteText(state) {
            return state.serviceDeleteText;
        }
    }
};
