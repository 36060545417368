<template>
                  <v-card  :loading="taskAddStatus===1 || taskEditLoadStatus===1 || taskEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                      <span class="headline">{{ formTitle }}</span>
                      <v-spacer />
                      <v-switch v-model="detailes" label="Dodatkowe informacje" dense />
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>  
                            <v-col cols="7" >
                              <v-select v-model="assignedUsers" :items="editedItem.assigned_users" :error-messages="assigned_usersErrors" @input="$v.editedItem.assigned_users.$touch()"  @blur="$v.editedItem.assigned_users.$touch()" deletable-chips multiple chips small-chips hide-details="auto" label="Kto ma wykonać zadanie" />
                            </v-col>
                            <v-col cols="4" >
                              <user-search label="Wyszukaj osobę" @userSelected="addUser($event)"  />
                            </v-col>
                            <v-col cols="1" >
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="28"
                                  class="mt-5"
                                  @click="addLoggedUser()"
                                >
                                  mdi-account-plus
                                </v-icon>
                                </template>
                                  <span>Dodaj siebie</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea outlined dense v-model="editedItem.body" :error-messages="bodyErrors" @input="$v.editedItem.body.$touch()"  @blur="$v.editedItem.body.$touch()" label="Treść zadania"></v-textarea>
                            </v-col>  
                             <v-col v-if="detailes" cols="4">
                              <v-menu
                                          v-model="dateToMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="150px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="editedItem.date_to"
                                              label="Wykonaj do dnia"
                                              readonly
                                              v-on="on"
                                              dense
                                              clearable
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker no-title first-day-of-week="1" v-model="editedItem.date_to" @input="dateToMenu = false"></v-date-picker>
                                        </v-menu>
                            </v-col> 
                            <v-col v-if="detailes" cols="8"></v-col> 
                            <v-col v-if="detailes" cols="6" >
                              <patients-live-search dense clearable  :selectedPatientText="editedItem.patient_id?editedItem.patient.text:null" @patientSelected="editedItem.patient_id = $event.value;editedItem.patient.text = $event.text" label="Pacjent" />                        
                            </v-col>             
                           
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="itemEditing || taskEditing" color="gray" text @click="close()">Zamknij</v-btn>
                      <v-btn v-else color="gray" text @click="close()">Anuluj</v-btn>

                      <v-btn v-if="itemEditing || taskEditing" color="secondary darken-1" text @click="handleSubmit">Zapisz</v-btn>
                      <v-btn v-else color="secondary darken-1" text @click="handleSubmit" >Dodaj</v-btn>
                    </v-card-actions>
                    <confirm ref="confirm"></confirm>
                  </v-card>
</template>

<script>
  import confirm from '../components/Confirm'
  import UserSearch from '../components/UsersSearch'
  import PatientsLiveSearch from '../components/PatientsLiveSearch'
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'

  export default {
    components:{confirm,UserSearch,PatientsLiveSearch},
    data: () => ({
      componentKey:0,
      detailes:false,
      dateToMenu:false,
    }),
    props:['itemEditing'],
    validations: {
      editedItem:{
        body:{
          required
        },
        assigned_users:{
          required,
          minLength:minLength(1)
        }
        // pesel:{
        //   numeric,
        //   minLength:minLength(11),
        //   maxLength:maxLength(11)
        // },
        // // phone:{
        // //   numeric
        // // },
        // email:{
        //   email
        // }
      },
    },
    watch: {
      taskEditStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
     taskAddStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
          this.$store.dispatch('loadTasks');
        }
      },
     taskDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getTaskEdit;
      },
      taskEditLoadStatus(){
        return this.$store.getters.getTaskEditLoadStatus
      },
      taskEditStatus(){
        return this.$store.getters.getTaskEditStatus();
      },
      taskEditText(){
        return this.$store.getters.getTaskEditText
      },
      taskAddStatus(){
        return this.$store.getters.getTaskAddStatus();
      },
      taskAddText(){
        return this.$store.getters.getTaskAddText;
      },
      taskEditing(){
        return this.$store.getters.getTaskEditing;
      },
      formTitle () {
        return !this.itemEditing && !this.taskEditing ? 'Nowe zadanie' : 'Edycja zadania'
      },
      bodyErrors () {
        const errors = []
        if (!this.$v.editedItem.body.$dirty) return errors
        !this.$v.editedItem.body.required && errors.push('Treść zadania jest wymagana')
        return errors
      },
      assigned_usersErrors () {
        const errors = []
        if (!this.$v.editedItem.assigned_users.$dirty) return errors
        !this.$v.editedItem.assigned_users.minLength && errors.push('Musisz podać chociaż jedną osobę')
        !this.$v.editedItem.assigned_users.required && errors.push('Musisz podać chociaż jedną osobę')
        return errors
      },
      assignedUsers:{
        get(){
          return _.map(this.editedItem.assigned_users,'value');
        },
        set(val){
          var newUsers = [];
          val.forEach(e => {
            newUsers.push(_.find(this.editedItem.assigned_users,{'value':e}));
          });
          this.editedItem.assigned_users = newUsers;
        }
      },
    },
 
    methods: {
      addUser(user){
        if(!_.includes(_.map(this.editedItem.assigned_users,'value'),user.value)){
          this.editedItem.assigned_users.push(user);
        }
      },
      addLoggedUser(){
         this.editedItem.assigned_users.push({value:this.user.id,text:this.user.name + ' ' + this.user.surname});
      },
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.editedItem.$touch()
        if (this.$v.editedItem.$invalid) {

        } else {
         
            if (this.itemEditing || this.taskEditing) {
              //edycja
              this.$store.dispatch( 'editTask', {
                slug: this.editedItem.id,
                patient_id:  this.editedItem.patient_id,
                date_to:  this.editedItem.date_to,
                body:  this.editedItem.body,
                content:this.editedItem.content,
                assigned_to:this.assignedUsers
              });
            } else {//new item
              this.$store.dispatch( 'addTask', {
                  patient_id:  this.editedItem.patient_id,
                  appointment_id:  this.editedItem.appointment_id,
                  date_to:this.editedItem.date_to,
                  body:this.editedItem.body,
                  content:this.editedItem.patient_id?{link:'patient'}:this.editedItem.content,
                  assigned_to:this.assignedUsers
              });

            }
        }
      },
    },
  }
</script>

