<template>
      <v-col>
          <v-card style="background-color:#F5F5F5;" :loading="roleAddStatus===1 || roleEditLoadStatus===1 || roleEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Anuluj</v-btn>
              <v-btn color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row>
            <v-col class="col-6 pl-5">
                <v-card>
                  <v-toolbar dense flat><v-toolbar-title>Dane podstawowe</v-toolbar-title></v-toolbar>
                  <v-card-text>
                          <v-row>                   
                            <v-col cols="6">
                              <v-text-field dense v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa roli"></v-text-field>                            
                            </v-col>
                            
                            <v-col cols="12">
                              <v-text-field dense v-model="editedItem.description" label="Opis"></v-text-field>
                            </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>      
            </v-col>
            <v-col class="col-6 pl-0 pr-5">
                <v-card>
                  <v-toolbar dense flat><v-toolbar-title>Uprawnienia</v-toolbar-title></v-toolbar>
                  <v-card-text>
                         <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  Moduł
                                </th>
                                <th class="text-left">
                                  Brak
                                </th>
                                <th class="text-left">
                                  Swoje
                                </th>
                                <th class="text-left">
                                  Pełne
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in editedItem.computed_permissions"
                                :key="item.id"
                              >
                                <td>{{ item.label }}</td>
                                <td>
                                  <v-radio-group hide-details dense  class="mt-0" v-model="item.role">
                                    <v-radio
                                      v-if="item.types.includes(0)"
                                      color="secondary"
                                      :value="0"
                                    ></v-radio>
                                  </v-radio-group>
                                </td>
                                <td>
                                  <v-radio-group hide-details dense  class="mt-0" v-model="item.role">
                                    <v-radio
                                      v-if="item.types.includes(5)"
                                      color="secondary"
                                      :value="5"
                                    ></v-radio>
                                  </v-radio-group>
                                </td>
                                <td>
                                  <v-radio-group hide-details dense  class="mt-0" v-model="item.role">
                                    <v-radio
                                      v-if="item.types.includes(10)"
                                      color="secondary"
                                      :value="10"
                                    ></v-radio>
                                  </v-radio-group>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                  </v-card-text>
                </v-card>      
            </v-col>
          </v-row>

        </v-card>
      </v-col>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'

  export default {
    data: () => ({

    }),
    validations: {
      editedItem:{
        name: {
          required,
        },
      }
    },
    watch: {
      roleEditStatus(val){
        if(val===2){
          this.close();
        }
      },
      roleAddStatus(val){
        if(val===2){
          this.close();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params);
      },
      editedItem(){
        return this.$store.getters.getRoleEdit;
      },
      roleEditLoadStatus(){
        return this.$store.getters.getRoleEditLoadStatus
      },
      roleEditStatus(){
        return this.$store.getters.getRoleEditStatus();
      },
      roleEditText(){
        return this.$store.getters.getRoleEditText
      },
      roleAddStatus(){
        return this.$store.getters.getRoleAddStatus();
      },
      roleAddText(){
        return this.$store.getters.getRoleAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowa rola' : 'Edycja roli'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa roli jest wymagana')
        return errors
      },
    },
    mounted(){
      if(this.itemEditing){
        this.$store.dispatch( 'loadRoleEdit', {slug:  this.$route.params.id} );
      }else{
        this.$store.dispatch( 'loadPermissionList' );
      }
    },
    methods: {
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadRoleEditDefault'); 
        this.$store.dispatch( 'loadRoleAddDefault');
        this.$router.push({ name: 'ustawienia.role.powrot'})
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editRole', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                description:  this.editedItem.description,
                permissions: this.editedItem.computed_permissions
              });
            } else {//new item
              this.$store.dispatch( 'addRole', {
                  name:  this.editedItem.name,
                  description:  this.editedItem.description,
                  permissions: this.editedItem.computed_permissions
              });

            }
        }
      },
    },
  }
</script>

