import TaskAPI from "../api/task";

export const tasks = {
    state: {
        tasks:[],
        taskAddStatus: 0,
        taskAddText: "",
        tasksLoadStatus:0,
        taskDoneStatus:0,
        taskOptions:{
            selectedPatient:undefined,
            showDone:false,
            showCreated:false,
        },

        taskEdit: {
            id: null,
            patient_id: null,
            appointment_id:null,
            date_to:null,
            body:null,
            content:{},
            assigned_users:[],
            patient:{text:null}
        },
        taskEditDefault: {
            id: null,
            patient_id: null,
            appointment_id:null,
            date_to:null,
            body:null,
            content:{},
            assigned_users:[],
            patient:{text:null}
        },

        taskEditLoadStatus: 0,
        taskEditStatus: 0,
        taskEditText: "",

        taskDeleteStatus: 0,
        taskDeleteText: "",
    },

    actions: {
        loadTasks({ state, commit, dispatch }) {
            commit("setTasks", []);
            commit("setTasksLoadStatus", 1);
                TaskAPI.getTasks({
                    selectedPatient:state.taskOptions.selectedPatient,
                    showDone:state.taskOptions.showDone,
                    showCreated:state.taskOptions.showCreated,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setTasks", response.data);
                    commit("setTasksLoadStatus", 2);
                })
                .catch(function() {
                    commit("setTasks", []);
                    commit("setTasksLoadStatus", 3);
                });
         
        },
        addTask({ commit, state, dispatch }, data) {
            commit("setTaskAddStatus", 1);
            commit("setTaskAddText", "");

            TaskAPI.postAddNewTask(
                data.patient_id,
                data.appointment_id,
                data.date_to,
                data.body,
                data.content,
                data.assigned_to
            )
                .then(function(response) {
                    commit("setTaskAddStatus", 2);
                })
                .catch(function(error) {
                    commit("setTaskAddStatus", 3);
                    commit("setTaskAddText", error.response.data.errors);
                });
        },
        setTaskOptions({ state, commit, dispatch },data){
            commit("setTaskOptions",data.options);
            dispatch("loadTasks");
        },
        loadTaskEdit({ commit }, data) {
            commit("setTaskEditLoadStatus", 1);

            TaskAPI.getTaskEdit(data.slug)
                .then(function(response) {
                    commit("setTaskEdit", response.data);
                    commit("setTaskEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setTaskEdit", {});
                    commit("setTaskEditLoadStatus", 3);
                });
        },
        loadTaskEditDefault({ commit }) {
            commit("setTaskEditDefault");
        },
        loadTaskAddDefault({ commit }) {
            commit("setTaskAddStatus", 0);
            commit("setTaskAddText", "");
        },
        editTask({ commit, state, dispatch }, data) {
            commit("setTaskEditStatus", 1);

            TaskAPI.putEditTask(
                data.slug,
                data.patient_id,
                data.date_to,
                data.body,
                data.content,
                data.assigned_to
            )
                .then(function(response) {
                    commit("setTaskEditStatus", 2);
                    commit("setEditedTask",response.data);
                })
                .catch(function(error) {
                    commit("setTaskEditStatus", 3);
                    commit("setTaskEditText", error.response.data.errors);
                });
        },
        editTaskDone({ commit, state, dispatch }, data) {
            commit("setTaskEditStatus", 1);
            commit("setTaskDoneStatus",1);
            TaskAPI.putTaskDone(
                data.slug,
            )
                .then(function(response) {
                    commit("setTaskEditStatus", 2);
                    commit("setTaskDoneStatus",2);
                    if(state.taskOptions.showDone){
                        commit("setEditedTask",response.data);
                    }else{
                        commit("setDeletedTask",response.data);
                    }
                    
                })
                .catch(function(error) {
                    commit("setTaskEditStatus", 3);
                    commit("setTaskDoneStatus",3);
                    commit("setTaskEditText", error.response.data.errors);
                });
        },
        editTaskUndone({ commit, state, dispatch }, data) {
            commit("setTaskEditStatus", 1);
            commit("setTaskDoneStatus",1);
            TaskAPI.putTaskUndone(
                data.slug,
            )
                .then(function(response) {
                    commit("setTaskEditStatus", 2);
                    commit("setTaskDoneStatus",2);
                    commit("setEditedTask",response.data);
                })
                .catch(function(error) {
                    commit("setTaskEditStatus", 3);
                    commit("setTaskDoneStatus",3);
                    commit("setTaskEditText", error.response.data.errors);
                });
        },
        forceDeleteTask({ commit, state, dispatch }, data) {
            commit("setTaskDeleteStatus", 1);

            TaskAPI.forceDeleteTask(data.slug)
                .then(function(response) {
                    commit("setTaskDeleteStatus", 2);
                    commit("setDeletedTask", response.data);
                })
                .catch(function() {
                    commit("setTaskDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setTaskOptions(state,options){
            state.taskOptions = options;
        },
        setTasks(state, tasks) {
            state.tasks = tasks;
        },
        setTasksLoadStatus(state, status) {
            state.tasksLoadStatus = status;
        },
        setTaskAddStatus(state, status) {
            state.taskAddStatus = status;
        },
        setTaskDoneStatus(state,status){
            state.taskDoneStatus = status;
        },
        setTaskAddText(state, text) {
            state.taskAddText = text;
        },
        setTaskEdit(state, task) {
            state.taskEdit = task;
        },
        setEditedTask(state, task) {
            var index = _.findIndex(state.tasks,{'id':task.id});
            state.tasks[index] = task;
        },
        setDeletedTask(state, task) {
            var index = _.findIndex(state.tasks,{'id':task.id});
            state.tasks.splice(index,1);
        },
        setTaskEditDefault(state) {
            state.taskEdit = JSON.parse(JSON.stringify(state.taskEditDefault));
        },
        setTaskEditStatus(state, status) {
            state.taskEditStatus = status;
        },
        setTaskEditText(state, text) {
            state.taskEditText = text;
        },
        setTaskEditLoadStatus(state, status) {
            state.taskEditLoadStatus = status;
        },
        setTaskDeleteStatus(state, status) {
            state.taskDeleteStatus = status;
        },
        setTaskDeletedText(state, text) {
            state.taskDeleteText = text;
        }
    },

    getters: {
        getTaskOptions(state){
            return state.taskOptions;
        },
        getTaskAddStatus(state) {
            return function(){
                return state.taskAddStatus;
            } 
        },
        getTaskAddText(state) {
            return state.taskAddText;
        },
        getTasksLoadStatus(state) {
            return state.tasksLoadStatus;
        },
        getTasks(state) {
            return state.tasks;
        },
        getTaskEdit(state) {
            return state.taskEdit;
        },
        getTaskEditStatus(state) {
          return function(){
            return state.taskEditStatus;
          }
        },
        getTaskDoneStatus(state) {
            return function(){
              return state.taskDoneStatus;
            }
          },
        getTaskEditing(state) {
            return (state.taskEdit.id && state.taskEdit.id > 0)?true:false;
        },
        getTaskEditText(state) {
            return state.taskEditText;
        },
        getTaskEditLoadStatus(state) {
            return state.taskEditLoadStatus;
            
        },
        getTaskDeleteStatus(state) {
            return function(){
                return state.taskDeleteStatus;
            }
        },
        getTaskDeleteText(state) {
            return state.taskDeleteText;
        }
    }
};
