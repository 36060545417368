import BillingAPI from "../api/billing";

export const billings = {
    state: {
        billings: [],
        billingOptions:{
            lineCount:0,
            orderBy:'entry',
            itemSearched:false,
            orderDirection:'desc',
            itemsPerPage:25,
            fromDate:undefined,
            toDate:undefined,
            selectedStatus:null,
            selectedAction:null,
            selectedType:null,
        },
        billingsLoadStatus: 0,
        billingEditStatus: 0,
        billingImportStatus: 0,
        billingImportText: "",
    },

    actions: {
        loadBillings({ state, commit, dispatch }) {
            commit("setBillingsLoadStatus", 1);
                BillingAPI.getBillings({
                    offset:0,
                    itemsPerPage:state.billingOptions.itemsPerPage, 
                    orderBy:state.billingOptions.orderBy,
                    orderDirection:state.billingOptions.orderDirection,
                    fromDate:state.billingOptions.fromDate,
                    toDate:state.billingOptions.toDate,
                    selectedStatus:state.billingOptions.selectedStatus,
                    selectedType:state.billingOptions.selectedType,
                    selectedAction:state.billingOptions.selectedAction,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setBillingLineCount", response.data.lineCount);
                    commit("setBillings", response.data.billings);
                    dispatch("applyIndexOnBillings");
                    commit("setBillingsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBillings", []);
                    commit("setBillingsLoadStatus", 3);
                });
         
        },
        loadMoreBillings({ state, commit, dispatch }) {
            commit("setBillingsLoadStatus", 1);
                BillingAPI.getBillings({
                    offset:state.billings.length,
                    itemsPerPage:state.billingOptions.itemsPerPage, 
                    orderBy:state.billingOptions.orderBy,
                    orderDirection:state.billingOptions.orderDirection,
                    fromDate:state.billingOptions.fromDate,
                    toDate:state.billingOptions.toDate,
                    selectedStatus:state.billingOptions.selectedStatus,
                    selectedType:state.billingOptions.selectedType,
                    selectedAction:state.billingOptions.selectedAction,
                })
                .then(function(response) {
                    commit("setBillingLineCount", response.data.lineCount);
                    commit("setBillings", _.concat(state.billings, response.data.billings));
                    dispatch("applyIndexOnBillings");
                    commit("setBillingsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBillings", []);
                    commit("setBillingsLoadStatus", 3);
                });
         
        },
        searchBillings({ state, commit, dispatch },data) {
            commit("setBillingsLoadStatus", 1);
                BillingAPI.searchBilling({
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setBillingSearchedTrue");
                    commit("setBillingLineCount", response.data.lineCount);
                    commit("setBillings", response.data.billings);
                    dispatch("applyIndexOnBillings");
                    commit("setBillingsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBillings", []);
                    commit("setBillingsLoadStatus", 3);
                });
         
        },
        setBillingOptions({ state, commit, dispatch },data){
            commit("setBillingOptions",data.options);
            dispatch("loadBillings");
        },
        applyIndexOnBillings({ commit, state, dispatch }) {
            let localBillings = state.billings;

            var i = 1;
            localBillings.forEach(billing => {
                billing.lp = i;
                i++;
            });

            commit("setBillings",localBillings);
        },
        editBilling({ commit, state, dispatch }, data) {
            commit("setBillingEditStatus", 1);

            BillingAPI.putEditBilling(
                data.slug,
                data.action,
            )
                .then(function(response) {
                    commit("setBillingEditStatus", 2);
                })
                .catch(function(error) {
                    commit("setBillingEditStatus", 3);
                });
        },
        importBilling({ commit, state, dispatch }, data) {
            commit("setBillingImportStatus", 1);

            BillingAPI.postImportBilling(
                data.file_content,
            )
                .then(function(response) {
                    commit("setBillingImportStatus", 2);
                    dispatch("loadBillings");
                })
                .catch(function(error) {
                    commit("setBillingImportStatus", 3);
                    commit("setBillingImportText", error.response.data.errors);
                });
        },

    },
    mutations: {
        setBillingOptions(state,options){
            state.billingOptions = options;
        },
        setBillingsLoadStatus(state, status) {
            state.billingsLoadStatus = status;
        },
        setBillingImportStatus(state, status) {
            state.billingImportStatus = status;
        },
        setBillingEditStatus(state, status) {
            state.billingEditStatus = status;
        },
        setBillings(state, billings) {
            state.billings = billings;
        },
        setBillingLineCount(state, lineCount) {
            state.billingOptions.lineCount = lineCount;
        },
        setBillingSearchedTrue(state) {
            state.billingOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.billingOptions.itemSearched = false;
        },
    },
    getters: {
        getBillingOptions(state){
            return state.billingOptions;
        },
        getBillingsLoadStatus(state) {
            return state.billingsLoadStatus;
        },
        getBillings(state) {
            return state.billings;
        },
        getBillingImportStatus(state) {
          return function(){
            return state.billingImportStatus;
          }
        },
        getBillingImportText(state) {
            return state.billingImportText;
        },
        getBillingEditStatus(state) {
            return function(){
              return state.billingEditStatus;
            }
          },
    }
};
