<template>   
    <v-sheet
      v-if="!icon" 
      :height="height" 
      color="grey lighten-3" 
      width="100%" 
      @click="$refs.fileInput.$refs.input.click()" 
      style="cursor:pointer;z-index: 9999"
      v-cloak 
      @drop.prevent="addDropFile" 
      @dragover.prevent="dragenter"
      @dragleave.prevent="dragleave"
      :elevation="dragOverArea?3:0"
      >
    
      <v-file-input
        :accept="fileAccept"
        ref="fileInput"
        hide-input
        v-model="file"
        prepend-icon=""
      ></v-file-input>

      <v-row style="pointer-events: none;" :height="height" class="mt-5" justify="center" >
        <v-icon
          style="pointer-events: none;"
          :color="dragOverArea?'secondary':'grey darken-1'" 
          size="75"
        >mdi-cloud-upload-outline</v-icon>
      </v-row>
      <v-row style="pointer-events: none;" justify="center" class="text-center" >
        <span style="pointer-events: none;" :class="dragOverArea?'title secondary--text px-5':'title grey--text text--darken-1 px-5'">Przeciągnij plik lub kliknij aby wybrać</span>
      </v-row>
    </v-sheet>
    <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
                <v-file-input
                  class="pt-0 mt-0"
                  :accept="fileAccept"
                  ref="fileInput"
                  hide-input
                  v-model="file"
                  prepend-icon=""
                ></v-file-input>
                <v-icon size="30" v-on="on" class="mt-2 mr-2"  @click="$refs.fileInput.$refs.input.click()">mdi-attachment</v-icon>
            </template>
            <span>Załącz zewnętrzny plik</span>
    </v-tooltip>
    
      <!-- <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
          class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
      
        <label for="assetsFieldHandle" class="block cursor-pointer">
          <div>
            Explain to our users they can drop files in here 
            or <span class="underline">click here</span> to upload their files
          </div>
        </label>
        <ul class="mt-4" v-if="this.filelist.length" v-cloak>
          <li class="text-sm p-1" v-for="file in filelist">
            ${ file.name }<button type="button" @click="remove(filelist.indexOf(file))" title="Remove file">x</button>
          </li>
        </ul>
      </div> -->
</template>
<script>

export default {
    props:['fileContent','fileName','fileType','fileAccept','height','icon'],
    data: () => ({
      file: null,
      dragOverArea:false
    }),
    watch:{
      file(val){
        if(val){
          this.generateBase64File(val);
        }else{
          this.$emit('update:file-content',null);
          this.$emit('update:file-name',null);
          this.$emit('update:file-type',null);
        }
      }
    },
    methods:{
      addDropFile(event){
        if(event.dataTransfer.files.length>0){
          //sprawdza rodzaj
          // if(this.fileAccept.indexOf(event.dataTransfer.files[0].type)>-1){
          //   this.file = event.dataTransfer.files[0];
          // }
          event.dataTransfer.files.forEach(file => {
            if(!this.fileAccept || this.fileAccept.indexOf(file.type)>-1){
              //this.file = file;
              this.generateBase64File(file);
            }
          });
          this.dragOverArea = false;
        }
      },
      generateBase64File(file){
        const reader = new FileReader();
        var fileSplit = file.name.split('.');
        var fileExt  = fileSplit.pop().toLowerCase();
        var fileName = fileSplit.join('.');

        reader.onloadend = () => {
          const base64String = reader.result//.replace("data:", "").replace(/^.+,/, "");
          this.$emit('update:file-content',base64String);
          this.$emit('newFile',{'file_name':fileName,'file_type':fileExt,'file_content':base64String});
        };
        reader.readAsDataURL(file);
        
        this.$emit('update:file-name',fileName);
        this.$emit('update:file-type',fileExt);
        
      },
      dragenter(event) {
         this.dragOverArea = true;
      },
      dragleave() {
           this.dragOverArea = false;
      },
    },
 
  }
</script>