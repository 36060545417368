<template>
      <v-col>
          <v-card class="fill-height" style="background-color:#F5F5F5;" :loading="eventAddStatus===1 || eventEditLoadStatus===1 || eventEditStatus===1">
            
              <v-toolbar style="background-color:#F5F5F5;" flat>
                <v-btn color="primary" small @click="formReturn"><v-icon>mdi-arrow-left</v-icon>Wróć</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
                <v-btn :disabled="!mainEventChanged" color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              </v-toolbar>

          <v-row class="mt-0">
            <v-col class="col-8 pl-5 pt-0" v-resize="onResize">
                 <v-card class="fill-height">
                    <v-sheet height="64">
                      <v-toolbar flat>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Dziś</v-btn>
                          <v-btn fab text small color="grey darken-2" @click="prev"><v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-alert v-if="date_fromErrors" class="mr-5 mt-4" dense outlined type="error">{{date_fromErrors}}</v-alert>
                        <v-switch v-if="type=='week' || type=='month'" class="mt-5" v-model="withWeekend" label="Sb Nd"></v-switch>
                        <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              color="grey darken-2"
                               class="ml-5"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>{{ typeToLabel[type] }}</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="type = 'day'">
                              <v-list-item-title>Dzień</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                              <v-list-item-title>Tydzień</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                              <v-list-item-title>Miesiąc</v-list-item-title>
                            </v-list-item>
                            
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet :height="windowSize.y+'px'">
                      <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="secondary"
                        :events="events"
                        :type="type"
                        @change="updateRange"
                        @click:event="goToEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousedown:day="startDay"
                        @mousemove:time="mouseMove"
                        @mousemove:day="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseup:day="endDrag"
                        @mouseleave.native="cancelDrag"
                        :weekdays="withWeekend?weekDaysWeekend:weekDays"
                        first-time="08:00"
                        interval-minutes="30"
                        interval-count="28"
                        :short-intervals="false"
                        :short-weekdays="false"
                      >
                      <template v-slot:event="{  event, timed, eventSummary }">
                        <div  
                          v-for="ap in event.appointments" :key="ap.id" 
                          :style="'top:'+(ap.start-event.start)/1000/60*pixelsPerMinute+'px;height:'+((ap.end-ap.start)/1000/60)*pixelsPerMinute+'px'"
                          class="v-event-sub-appointment"
                          style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                          >
                          <v-icon size="20" style="position:absolute;">{{_.find(lookups.categories,{'value':ap.category_id}).icon}}</v-icon>
                          <p v-if="type=='category' || type=='day'" style="position:absolute;left:25px;top:2px;">{{_.find(lookups.categories,{'value':ap.category_id}).text}}</p>
                          <p class="mb-0"></p><p class="mb-0 mr-1"><strong>{{ap.name}}</strong></p>
                        </div>
                        <div  
                          v-if="event.appointment" 
                          class="v-event-ref-appointment"
                          style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;cursor:auto;"
                          >
                          <v-icon size="20" color="white" style="position:absolute;">{{_.find(lookups.categories,{'value':event.category_id}).icon}}</v-icon>
                          <p v-if="type=='category' || type=='day'" style="position:absolute;left:25px;top:2px;">{{_.find(lookups.categories,{'value':event.category_id}).text}}</p>
                          <p class="mb-0"></p><p class="mb-0 mr-1"><strong>{{event.name}}</strong></p>
                        </div>
                        <div
                          v-if="!event.appointment"
                          class="v-event-draggable"
                          v-html="eventSummary()"
                        ></div>
                        <div
                          v-if="timed && event.main && !event.readonly"
                          class="v-event-drag-bottom"
                          @mousedown.stop="extendBottom(event)"
                        ></div>
                        <div
                          v-else-if="event.main && !event.readonly"
                          class="v-event-drag-right"
                          @mousedown.stop="extendBottom(event)"
                        ></div>
                      </template>

                      </v-calendar>
                    </v-sheet>
                 </v-card>   
            </v-col>
            <v-col class="col-4 pl-0 pr-5 pt-0">
                <v-card>
                  <v-container fluid>
                  <v-card-text>
                          <v-row class="child-flex" >
                            <v-col cols="8" >
                              <user-live-search @change="updateReferenceEvents" :disabled="editedItem.read_only || _.includes([3],editedItem.type)" clearable :selectedUser="editedItem.user_id" :error-messages="user_idErrors" :selectedUserText="editedItem.user?editedItem.user.name + ' ' + editedItem.user.surname:null" @userSelected="editedItem.user_id = $event" :userType="1" label="Wyszukaj lekarza" />                        
                            </v-col>
                            <v-col cols="8">
                              <clinic-live-search :disabled="editedItem.read_only || _.includes([2,3],editedItem.type)" clearable :selectedClinic="editedItem.clinic_id" :error-messages="clinic_idErrors"  :selectedClinicText="editedItem.clinic?editedItem.clinic.name:null" @clinicSelected="editedItem.clinic_id = $event" />                        
                            </v-col>
                            <v-col cols="4">
                              <v-text-field @change="updateReferenceEvents" :disabled="editedItem.read_only || _.includes([2,3],editedItem.type)" v-model="editedItem.room" :error-messages="roomErrors" :label="editedItem.type==5?'Sala':'Gabinet'"></v-text-field>                            
                            </v-col>
                             <v-col cols="5">
                              <v-select :disabled="editedItem.read_only || _.includes([2,3],editedItem.type)" hide-details  clearable v-model="editedItem.category_id" :items="_.filter(lookups.categories,{'visible':1})"  label="Działanie"></v-select>
                            </v-col>
                            <v-col cols="7">
                              <v-text-field :disabled="editedItem.read_only || editedItem.read_only" hide-details v-model="editedItem.note" label="Notatka"></v-text-field>                            
                            </v-col>
                            <v-col cols="12">
                              <service-live-search :disabled="editedItem.read_only || _.includes([2,3],editedItem.type)" clearable :serviceCategory="editedItem.category_id" :selectedService="editedItem.service_id" :selectedServiceText="editedItem.service?editedItem.service.name:null" @serviceSelected="editedItem.service_id = $event" />                        
                            </v-col>
                            <v-col cols="6" v-if="canBePublic">
                              <v-checkbox @change="publicChange" label="Publiczne" hide-details v-model="editedItem.public" :disabled="editedItem.read_only || editedItem.read_only" />
                            </v-col>
                            <v-col cols="6" v-if="canBePublic && editedItem.public">
                              <v-select label="Czas wizyty" hide-details v-model="editedItem.interval" :items="lookups.interval" :disabled="editedItem.read_only || editedItem.read_only" />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-switch 
                                :disabled="editedItem.read_only"
                                :readonly="itemEditing" 
                                v-model="seriesActive" hide-details 
                                :label="'Cykliczne'+((itemEditing&&seriesActive)?' (od '+firstEventStart.format('LL')+' do '+lastEventStart.format('LL')+ ')':'')"
                              ></v-switch>
                            </v-col>
                            <v-col v-if="seriesActive && !itemEditing">
                              <v-text-field v-model="series_q" dense hide-details single-line type="number" prefix="Powtarzaj co" min="1" max="30"></v-text-field>
                            </v-col>
                            <v-col v-if="seriesActive && !itemEditing">
                              <v-select v-model="series_type" dense hide-details single-line :items="series_types" max-width="100"></v-select>
                            </v-col>
                            <v-spacer></v-spacer>

                            <v-col cols="12" class="pb-0 pb-3" v-if="seriesActive && series_type=='W' && !itemEditing">
                              <v-chip-group
                                
                                v-model="seriesSelectedDays"
                                multiple
                                column
                                active-class="secondary--text"
                              >
                                <v-chip
                                  outlined
                                  v-for="day in lookups.days"
                                  :key="day.value"
                                >
                                  {{ day.short }}
                                </v-chip>
                              </v-chip-group>
                            </v-col>
                            <v-col cols="12" class="pb-0 pt-4" v-if="seriesActive && series_type=='M' && !itemEditing">
                              <v-row dense>
                                <v-col cols="5" class="col-xl-4">
                                  <v-row dense>
                                      <v-col cols="2">
                                        <v-radio-group class="mt-1" v-model="seriesMonthType">
                                          <v-radio hide-details value="Q"></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col cols="10">
                                        <v-text-field :disabled="seriesMonthType=='ON'" v-model="seriesMonthQ" dense hide-details single-line type="number" prefix="w" suffix="dniu miesiąca" min="1" max="31"></v-text-field>
                                      </v-col>
                                    </v-row>
                                </v-col>
                            
                                <v-col cols="7" class="col-xl-6">
                                    <v-row dense>
                                      <v-col cols="2">
                                        <v-radio-group class="mt-1 ml-2" v-model="seriesMonthType">
                                          <v-radio hide-details value="ON"></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col cols="2">
                                        <v-text-field :disabled="seriesMonthType=='Q'" v-model="seriesMonthON_Q" dense hide-details single-line type="number" prefix="w" min="1" max="5"></v-text-field>
                                      </v-col>
                                      <v-col  cols="8">
                                        <v-select :disabled="seriesMonthType=='Q'" v-model="seriesMonthON_D" dense hide-details single-line :items="lookups.days" suffix="miesiąca" item-text="variant"></v-select>
                                      </v-col>
                                    </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="pt-1" v-if="seriesActive && !itemEditing">
                              <v-row dense>
                                <v-col cols="5" class="col-xl-3">
                                  <v-row dense>
                                      <v-col cols="3">
                                        <v-radio-group class="mt-1" v-model="seriesRepeatType">
                                          <v-radio hide-details value="Q"></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col cols="9">
                                        <v-text-field :disabled="seriesRepeatType=='Untill'" v-model="seriesRepeatQ" dense hide-details single-line type="number" :suffix="seriesRepeatQ==1?'raz':'razy'" min="1" max="100"></v-text-field>
                                      </v-col>
                                    </v-row>
                                </v-col>
                            
                                <v-col cols="7" class="col-xl-6">
                                    <v-row dense>
                                      <v-col cols="2">
                                        <v-radio-group class="mt-1 ml-2" v-model="seriesRepeatType">
                                          <v-radio hide-details value="Untill"></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col  cols="10">
                                        <v-menu
                                          v-model="selectedDayMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="150px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              :disabled="seriesRepeatType=='Q'" 
                                              v-model="seriesRepeatUntil"
                                              prefix="do dnia"
                                              readonly
                                              v-on="on"
                                              dense hide-details single-line
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker no-title first-day-of-week="1" v-model="seriesRepeatUntil" @input="selectedDayMenu = false"></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="pt-0" v-if="itemEditing && seriesActive && mainEventChanged">
                              <v-checkbox v-model="applyChangesToNext" dense hide-details>
                                <template v-slot:label>
                                  Zastosuj zamiany do <span class="font-weight-bold mx-1">KOLEJNYCH</span> zdarzeń cyklu
                                </template>
                              </v-checkbox>
                              <v-checkbox v-model="applyChangesToUndone" dense hide-details>
                                <template v-slot:label>
                                  Zastosuj zamiany do <span class="font-weight-bold mx-1">NIEROZPOCZĘTYCH</span> zdarzeń cyklu
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!seriesActive" @click="deleteItem(editedItem)" icon>
                      <div style="display: flex;flex-direction: column;align-items: center;"> 
                        <v-icon large class="mb-1">mdi-delete</v-icon>
                        <span class="text-caption">Usuń</span>
                      </div>
                    </v-btn>
                    <v-menu v-else top left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" icon>
                              <div style="display: flex;flex-direction: column;align-items: center;"> 
                                <v-icon large class="mb-1">mdi-delete</v-icon>
                                <span class="text-caption">Usuń</span>
                              </div>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="deleteItem(editedItem)">
                              <v-list-item-title>Usuń aktualne zdarzenie</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItemAndNext(editedItem)">
                              <v-list-item-title>Usuń aktualne i wszystkie kolejne zdarzenia cyklu</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItemAndUnsend(editedItem)">
                              <v-list-item-title>Usuń wszystkie nierozpoczęte zdarzenia cyklu</v-list-item-title>
                            </v-list-item>
                            
                          </v-list>
                  </v-menu>
                  </v-card-actions>
                  </v-container>
                  <v-speed-dial
                            v-if="editedItem.read_only"
                            v-model="fab" absolute
                            top right direction="bottom"
                            transition="scale-transition"
                          >
                            <template v-slot:activator>
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" fab dark large :color="_.find(lookups.event_type,{'value':editedItem.type}).color"><v-icon>{{_.find(lookups.event_type,{'value':editedItem.type}).icon}}</v-icon></v-btn>
                                </template>
                                  <span>{{_.find(lookups.event_type,{'value':editedItem.type}).text}}</span>
                              </v-tooltip>
                            </template>
                    </v-speed-dial>
                  <v-speed-dial
                            v-else
                            v-model="fab" absolute
                            top right direction="bottom"
                            transition="scale-transition"
                          >
                            <template v-slot:activator>
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" fab dark large :color="_.find(lookups.event_type,{'value':editedItem.type}).color"><v-icon>{{_.find(lookups.event_type,{'value':editedItem.type}).icon}}</v-icon></v-btn>
                                </template>
                                  <span>{{_.find(lookups.event_type,{'value':editedItem.type}).text}}</span>
                              </v-tooltip>
                            </template>
                                <v-tooltip left v-for="t in _.filter(lookups.event_type,function(o) { return o.value!=editedItem.type; })" :key="t.value">
                                    <template v-slot:activator="{ on }" >
                                      <v-btn
                                        v-on="on"
                                        fab
                                        dark
                                        small
                                        :color="t.color"
                                        @click="editedItem.type=t.value"
                                      >
                                        <v-icon>{{t.icon}}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{t.text}}</span>
                                </v-tooltip>
                    </v-speed-dial>
                </v-card>      
            </v-col>
          </v-row>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="3000"
        >
        Harmonogram został zapisany  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Zamknij
          </v-btn>
        </template>
        </v-snackbar>
      <confirm ref="confirm"></confirm>
      </v-col>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import UserLiveSearch from '../components/UsersLiveSearch'
  import ClinicLiveSearch from '../components/ClinicsLiveSearch'
  import ServiceLiveSearch from '../components/ServicesLiveSearch'
  import moment from 'moment';
  moment.locale('pl');

  export default {
    components:{UserLiveSearch,ClinicLiveSearch,ServiceLiveSearch,confirm},
    data: () => ({
      snackbar:false,
      windowSize: {
        x: 0,
        y: 0,
      },
      fab: false,
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Miesiąc',
        week: 'Tydzień',
        day: 'Dzień',
      },
      origMainEvent:null,
      applyChangesToNext:false,
      applyChangesToUndone:false,
      withWeekend:false,
      weekDays:[1, 2, 3, 4, 5],
      weekDaysWeekend:[1, 2, 3, 4, 5, 6, 0],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      seriesActive:false,
      series_type:'W',
      series_q:1,
      seriesSelectedDays:[],
      seriesMonthQ:1,
      seriesMonthType:'Q',
      seriesMonthON_Q:1,
      seriesMonthON_D:0,
      seriesRepeatType:'Q',
      seriesRepeatQ:4,
      seriesRepeatUntil:null,
      selectedDayMenu:false,
      calRangeStart:null,
      calRangeEnd:null
    }),
    validations(){
      if(this.editedItem.type==1 || this.editedItem.type==2){//Lekarz,Urlop
        return {
          editedItem:{
            date_from:{
              required
            },
            user_id: {
              required
            },
          }
        }
      }else if(this.editedItem.type==4 || this.editedItem.type==5){//Gabinet,Blok
        return {
          editedItem:{
            date_from:{
              required
            },
            clinic_id: {
              required
            },
            room: {
              required
            },
          }
        }
      }else{
        return {
          editedItem:{
            date_from:{
              required
            },
          }
        }
      }
    },
    watch: {
      eventEditStatus(val){
        if(val===2){
          this.snackbar = true;
          //this.close();
        }
      },
      eventEditLoadStatus(val){
        if(val===2){
            if(this.editedItem.series_def){
              this.seriesActive = true;
              this.seriesSelectedDef = this.editedItem.series_def;
            }
            if(this.editedItem.type==2 || this.editedItem.type==3){
              this.type = 'month';
            }
            this.focus = this.editedItem.date_from.substring(0,10);
            this.origMainEvent = JSON.parse(JSON.stringify(this.mainEvent));
        }
      },
      eventAddStatus(val){
        if(val===2){
          this.snackbar = true;
          var event_id = this.editedItem.id;
          this.$store.dispatch( 'loadEventEditDefault'); 
          this.$store.dispatch( 'loadEventAddDefault');
          this.$router.push({ name: 'harmonogram', params: { id: event_id } })
        }
      },
      editedItemUserId(val){
        if(val && (this.editedItem.type!=2 && this.editedItem.type!=3)){
          this.editedItem.type = 1;
        }else if(val && this.editedItem.type==3){
          this.editedItem.type = 2;
        }

        //this.updateReferenceEvents();
      },
      editedItemRoom(val){
      },
      editedItemClinicId(val){
        if(!val){
          this.editedItem.room = null
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params) && this.$route.params.id!=null;
      },
      editedItem(){
        return this.$store.getters.getEventEdit;
      },
      editedItemUserId(){
        return this.$store.getters.getEventEdit.user_id;
      },
      editedItemRoom(){
        return this.$store.getters.getEventEdit.room;
      },
      editedItemClinicId(){
        return this.$store.getters.getEventEdit.clinic_id;
      },
      eventEditLoadStatus(){
        return this.$store.getters.getEventEditLoadStatus();
      },
      eventEditStatus(){
        return this.$store.getters.getEventEditStatus();
      },
      eventEditText(){
        return this.$store.getters.getEventEditText
      },
      eventAddStatus(){
        return this.$store.getters.getEventAddStatus();
      },
      eventAddText(){
        return this.$store.getters.getEventAddText;
      },
    
      formTitle () {
        if(this.editedItem.read_only) return 'Podgląd harmonogramu: ' + this.editedItem.desc
        return !this.itemEditing ? 'Nowy harmonogram' : 'Edycja harmonogramu: ' + this.editedItem.desc
      },
      user_idErrors () {
        const errors = []
        if ((this.editedItem.type!=1 && this.editedItem.type!=2) || !this.$v.editedItem.user_id.$dirty) return errors
        !this.$v.editedItem.user_id.required && errors.push('Lekarz jest wymagany')
        return errors
      },
      clinic_idErrors () {
        const errors = []
        if ((this.editedItem.type!=4 && this.editedItem.type!=5) || !this.$v.editedItem.clinic_id.$dirty) return errors
        !this.$v.editedItem.clinic_id.required && errors.push('Klinika jest wymagana')
        return errors
      },
      roomErrors () {
        const errors = []
        if ((this.editedItem.type!=4 && this.editedItem.type!=5) || !this.$v.editedItem.room.$dirty) return errors
        !this.$v.editedItem.room.required && this.editedItem.type==4 && errors.push('Gabinet jest wymagany')
        !this.$v.editedItem.room.required && this.editedItem.type==5 && errors.push('Sala jest wymagana')
        return errors
      },
      date_fromErrors() {
        if (!this.$v.editedItem.date_from.$dirty) return ''
        if(!this.$v.editedItem.date_from.required) return 'Zaznacz wpis w kalendarzu'
      },
      mainEventChanged(){
        return !_.isEqual(this.mainEvent,this.origMainEvent);
      },
      mainEvent:{
        get(){
          if(this.editedItem.date_from){
            var appointmentsInEvents = [];
            this.editedItem.appointments.forEach(app => {
              appointmentsInEvents.push({
                id:app.id,
                name:app.desc,
                start:moment(app.date_from).valueOf(),
                end:moment(app.date_to).valueOf(),
                status:_.find(this.lookups.appointment_status,{'value':app.status}).text,
                category_id:app.category_id
              });
            });

            return {
              id:this.editedItem.id,
              type:this.editedItem.type,
              name: this.mainEventDesc,
              start: Date.parse(this.editedItem.date_from),
              end: Date.parse(this.editedItem.date_to),
              color:'secondary',
              timed: this.editedItem.type!=2 && this.editedItem.type!=3,
              main:true,
              readonly:this.editedItem.read_only,
              user_id:this.editedItem.user_id,
              clinic_id:this.editedItem.clinic_id,
              room:this.editedItem.room,
              category_id:this.editedItem.category_id,
              service_id:this.editedItem.service_id,
              note:this.editedItem.note,
              series_def:this.editedItem.series_def,
              appointments:appointmentsInEvents,
              public:this.editedItem.public,
              interval:this.editedItem.interval
            }
          }
          return null
        },
        set(val){
          this.editedItem.date_from =  new Date(val.start).toISOString();
          this.editedItem.date_to =  new Date(val.end).toISOString();
          if(!val.timed && this.editedItem.user_id)this.editedItem.type = 2;
          else if(!val.timed)this.editedItem.type = 3;
        }
      },
      events(){
        if(this.mainEvent && this.itemEditing)return _.concat(this.actualSeriesEvents,this.mainEvent,this.referenceEvents,this.referenceAppointments);
        else if(this.mainEvent && !this.itemEditing)return _.concat(this.newSeriesEvents,this.mainEvent,this.referenceEvents,this.referenceAppointments);
        else return _.concat(this.referenceEvents,this.referenceAppointments);
      },
      mainEventDesc(){
        if(this.editedItem.user_id){
          return _.find(this.$store.getters.getUsersLookup,{'value':this.editedItem.user_id})?_.find(this.$store.getters.getUsersLookup,{'value':this.editedItem.user_id}).text:this.editedItem.user.name+' '+this.editedItem.user.surname;
        }else if(this.editedItem.clinic_id){
          return _.find(this.$store.getters.getClinicsLookup,{'value':this.editedItem.clinic_id})?_.find(this.$store.getters.getClinicsLookup,{'value':this.editedItem.clinic_id}).text:this.editedItem.clinic.name;
        }else if(this.editedItem.category_id){
          return _.find(this.lookups.categories,{'value':this.editedItem.category_id}).text;
        }else if(this.editedItem.service_id){
          return _.find(this.$store.getters.getServicesLookup,{'value':this.editedItem.service_id})?_.find(this.$store.getters.getServicesLookup,{'value':this.editedItem.service_id}).text:this.editedItem.service.name;
        }else if(this.editedItem.note){
          return this.editedItem.note;
        }else{
          return 'Nowy wpis';
        }
      },
      series_types(){
        return [
          {value:'D',text:this.series_q==1?'dzień':'dni'},
          {value:'W',text:this.series_q==1?'tydzień':(this.series_q==2||this.series_q==3||this.series_q==4?'tygodnie':'tygodni')},
          {value:'M',text:this.series_q==1?'miesiąc':(this.series_q==2||this.series_q==3||this.series_q==4?'miesiące':'miesięcy')}
          ]
      },
      seriesSelectedParameters(){
        if(this.series_type=='W'){
          return (_.includes(this.seriesSelectedDays,0)?"1":"0")+(_.includes(this.seriesSelectedDays,1)?"1":"0")+(_.includes(this.seriesSelectedDays,2)?"1":"0")+
                 (_.includes(this.seriesSelectedDays,3)?"1":"0")+(_.includes(this.seriesSelectedDays,4)?"1":"0")+(_.includes(this.seriesSelectedDays,5)?"1":"0")+(_.includes(this.seriesSelectedDays,6)?"1":"0")
        }else if(this.series_type=='M'){
          if(this.seriesMonthType=='Q')return this.seriesMonthQ.toString();
          else{
            return 'ON' + this.seriesMonthON_Q.toString() + this.seriesMonthON_D.toString();
          }
        }else return null;
        
      },
      seriesSelectedRepeat(){
        if(this.seriesRepeatType=='Q')return 'Q' + this.seriesRepeatQ.toString();
        else{
          return this.seriesRepeatUntil?this.seriesRepeatUntil.replaceAll('-',''):'';
        }
      },
      seriesSelectedDef:{
        get(){
          var def = this.series_type.toString() + this.series_q.toString() + '_';
          if(this.seriesSelectedParameters)def = def + this.seriesSelectedParameters + '_';
          return def + this.seriesSelectedRepeat;
        },
        set(val){
          //parsowanie definicji
          var split = val.split('_');
          var def = split[0];
          this.series_type = def[0];
          this.series_q = parseInt(def.replace(this.series_type,''));
          var par = null
          var rep = null
          if(split.length==3){
            par = split[1];
            rep = split[2];
          }else{
            rep = split[1];
          }

          //repeat
          if(rep.startsWith('Q')){
            this.seriesRepeatType = 'Q';
            this.seriesRepeatQ = parseInt(rep.replace('Q',''));
          }else{
            this.seriesRepeatType = 'Untill';
            this.seriesRepeatUntil = rep.substring(0,4) + '-' + rep.substring(4,6) + '-' + rep.substring(6,8);
          }

          //par
          if(par && this.series_type=='W'){
            for (let index = 0; index < 7; index++) {
              if(par[index]=="1")this.seriesSelectedDays.push(index);
            }
          }else if(par){
            if(par.startsWith('ON')){
              this.seriesMonthType = 'ON'
              this.seriesMonthON_Q = parseInt(par.replace('ON','')[0]);
              this.seriesMonthON_D = parseInt(par.replace('ON','')[1]);
            }else{
              this.seriesMonthType = 'Q'
              this.seriesMonthQ = parseInt(par);
            }
          }
        }
      },
      firstEvent(){
        if(this.mainEvent && this.actualSeriesEvents.length>0)return _.orderBy(this.actualSeriesEvents,['start'],['asc'])[0];
        else return this.mainEvent;
      },
      lastEvent(){
        if(this.mainEvent && this.actualSeriesEvents.length>0)return _.orderBy(this.actualSeriesEvents,['start'],['desc'])[0];
        else return this.mainEvent;
      },
      firstEventStart(){
        return moment(this.firstEvent.start);
      },
      lastEventStart(){
        return moment(this.lastEvent.start);
      },
      pixelsPerMinute(){
        return this.$refs.calendar.intervalHeight/this.$refs.calendar.intervalMinutes;
      },
      canBePublic(){
        return this.editedItem.clinic_id && this.editedItem.service_id;
      },
      referenceEvents(){
        var referenceEvents = [];
        this.$store.getters.getReferenceEvents.forEach(event => {
            var appointmentsInEvents = [];
            event.appointments.forEach(app => {
              appointmentsInEvents.push({
                id:app.id,
                name:app.desc,
                start:moment(app.date_from).valueOf(),
                end:moment(app.date_to).valueOf(),
                status:_.find(this.lookups.appointment_status,{'value':app.status}).text,
                category_id:app.category_id
              });
            });
            referenceEvents.push({
                        id: event.id,
                        name: event.desc,
                        color: _.find(this.lookups.event_type,{'value':event.type}).color,
                        start: moment(event.date_from).valueOf(),
                        end:  moment(event.date_to).valueOf(),
                        timed: !event.all_day,
                        main:false,
                        readonly:true,
                        appointments:appointmentsInEvents
                    });
            });
        return referenceEvents;
      },
      referenceAppointments(){
        var referenceEvents = [];
        this.$store.getters.getReferenceAppointments.forEach(event => {
            event.cat.forEach(cat => {
              referenceEvents.push({
                        id: event.id,
                        name: event.desc,
                        color: 'primary',
                        start: moment(event.date_from).valueOf(),
                        end:  moment(event.date_to).valueOf(),
                        timed: true,
                        main:false,
                        readonly:event.read_only,
                        category:cat,
                        clinic:event.clinic,
                        room:event.room,
                        appointment:true,
                        category_id:event.category_id
                    });
            });
        });
            
        return referenceEvents;
      },
      actualSeriesEvents(){
        var seriesEvents = [];
        this.editedItem.series.forEach(event => {
            var new_start = null
            var new_end = null
            var mod = false
            //zastosuj do kolejnych
            if(this.applyChangesToNext && (moment(event.date_from).valueOf() > this.mainEvent.start)){
              mod = true
              new_start = moment(event.date_from).valueOf() + this.mainEvent.start - this.origMainEvent.start
              new_end = moment(event.date_to).valueOf() + this.mainEvent.end - this.origMainEvent.end
            }else if(this.applyChangesToUndone && !event.read_only){
              mod = true
              new_start = moment(event.date_from).valueOf() + this.mainEvent.start - this.origMainEvent.start
              new_end = moment(event.date_to).valueOf() + this.mainEvent.end - this.origMainEvent.end
            }else{
              new_start = event.date_from
              new_end = event.date_to
            }

            seriesEvents.push({
                        id: event.id,
                        name: this.mainEvent.name,
                        color: event.read_only?'#757575':'#f95b82',
                        start: moment(new_start).valueOf(),
                        end:  moment(new_end).valueOf(),
                        timed: this.mainEvent.timed,
                        main:false,
                        readonly:event.read_only,
                        modified:mod
                    });
            });
        return seriesEvents;
      },
      newSeriesEvents(){
        var seriesEvents = [];
        if(this.seriesActive){
          var currentStartData = moment(this.mainEvent.start);
          var currentEndData = moment(this.mainEvent.end);
          var createdCount = 0;
          var lastDate = this.seriesRepeatUntil
          if(this.series_type=='D'){
            currentStartData.add(this.series_q,'d')
            currentEndData.add(this.series_q,'d')
            while (this.seriesRepeatType=='Q'?createdCount!=this.seriesRepeatQ:currentStartData.isSameOrBefore(lastDate,'day')){
                if(this.seriesRepeatType=='Untill' && !lastDate)break;
                if(this.seriesRepeatType=='Q' || currentStartData.isSameOrBefore(lastDate,'day'))
                  seriesEvents.push({
                        name: this.mainEvent.name,
                        color: '#757575',
                        start: currentStartData.valueOf(),
                        end: currentEndData.valueOf(),
                        timed: this.mainEvent.timed,
                        main:false,
                    });
                createdCount++;
                currentStartData.add(this.series_q,'d')
                currentEndData.add(this.series_q,'d')
            }
          }else if(this.series_type=='W'){
            currentStartData.add(1,'d')
            currentEndData.add(1,'d')
            while (this.seriesRepeatType=='Q'?createdCount!=this.seriesRepeatQ:currentStartData.isSameOrBefore(lastDate,'day')){
                if(this.seriesRepeatType=='Untill' && !lastDate)break;
                if(_.includes(this.seriesSelectedDays,currentStartData.isoWeekday()-1)){
                    seriesEvents.push({
                        name: this.mainEvent.name,
                        color: '#757575',
                        start: currentStartData.valueOf(),
                        end: currentEndData.valueOf(),
                        timed: this.mainEvent.timed,
                        main:false,
                    });
                }
                if(currentStartData.isoWeekday()==7){
                  createdCount++;//niedziela
                  this.series_q>1 && currentStartData.add(this.series_q-1,'W')
                  this.series_q>1 && currentEndData.add(this.series_q-1,'W')
                }
                currentStartData.add(1,'d')
                currentEndData.add(1,'d')
                
            }
          }else if(this.series_type=='M'){
            console.log(this.getSelectedDayOfWeekInMonth(currentStartData,1,1).format());
            currentStartData.add(1,'d')
            currentEndData.add(1,'d')
            while (this.seriesRepeatType=='Q'?createdCount!=this.seriesRepeatQ:currentStartData.isSameOrBefore(lastDate,'day')){
                if(this.seriesRepeatType=='Untill' && !lastDate)break;
                if((this.seriesMonthType=='Q' && this.seriesMonthQ == currentStartData.date()) 
                || (this.seriesMonthType=='ON' && currentStartData.isSame(this.getSelectedDayOfWeekInMonth(currentStartData,parseInt(this.seriesMonthON_Q),this.seriesMonthON_D+1),'day'))){
                    seriesEvents.push({
                        name: this.mainEvent.name,
                        color: '#757575',
                        start: currentStartData.valueOf(),
                        end: currentEndData.valueOf(),
                        timed: this.mainEvent.timed,
                        main:false,
                    });
                    createdCount++;
                    this.series_q>1 && currentStartData.add(this.series_q-1,'M')
                    this.series_q>1 && currentEndData.add(this.series_q-1,'M')
                }
                currentStartData.add(1,'d')
                currentEndData.add(1,'d')
            }
          }
        }
        return seriesEvents
      },
    },
    mounted(){
       this.onResize();

       if(this.lookups.roles.length>0){
          this.itemEditing && this.$store.dispatch( 'loadEventEdit', {slug:  this.$route.params.id} );
        }else{
          this.$store.dispatch( 'loadLookups' );
          setTimeout(() => {
            this.itemEditing && this.$store.dispatch( 'loadEventEdit', {slug:  this.$route.params.id} );
          }, 2000)
        }
      this.$refs.calendar.scrollToTime('08:00')
    },
    beforeRouteLeave (to, from, next) {
      this.$v.$reset();
      this.$store.dispatch( 'loadEventEditDefault'); 
      this.$store.dispatch( 'loadEventAddDefault');
      if(to.name!=="harmonogramy.powrot"){
        this.$store.commit('setEvents',[])
      }
      this.$store.commit('setReferenceEvents',[]);
      next();
    },
    methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight - 64 - 64 - 64 - 30 }
      },
      getSelectedDayOfWeekInMonth(startDate,q,d) {
        var startOfMonth = moment(startDate).startOf('month');
        var currentDate = moment(startDate).startOf('month');
        var found = 0;
        while(currentDate.month() == startOfMonth.month()){
          if(currentDate.isoWeekday()==d)found++;
          if(found==q)return currentDate;
          currentDate.add(1,'d');
        }
        return null
      },
      publicChange(){
        if(this.editedItem.public){
          this.$store.dispatch( 'loadEventInterval',{clinic_id:this.editedItem.clinic_id,service_id:this.editedItem.service_id}); 
        }else{
          this.$store.commit('setEventInterval',null);
        }
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      startDrag ({ event, timed }) {
        if (event && event.main && !event.readonly) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
      },
      goToEvent({event}){
        if(!event.main && !event.appointment && event.id){
          this.$router.push({ name: 'harmonogram', params: { id: event.id } })
          this.$store.dispatch( 'loadEventEdit', {slug:  this.$route.params.id} );
        }
      },
      startTime (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start
          this.dragTime = mouse - start
        } else if(!this.itemEditing){
          this.createStart = this.roundTime(mouse)
          this.createEvent = {
            name: this.mainEventDesc,
            color: 'secondary',
            start: this.createStart,
            end: this.createStart,
            timed: this.editedItem.type!=2 && this.editedItem.type!=3,
          }
          this.mainEvent = this.createEvent
        }
      },
      startDay (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start
          this.dragTime = mouse - start
        } else if(!this.itemEditing){
          //console.log(new Date(mouse).getDate())
          this.createStart = mouse
          this.createEvent = {
            name: this.mainEventDesc,
            color: 'secondary',
            start: this.createStart,
            end: this.createStart,
            timed: false,
          }
          this.mainEvent = this.createEvent
        }
      },
      extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
          this.mainEvent = this.dragEvent;
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
          this.mainEvent = this.createEvent;
        }
      },
      endDrag () {
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag () {
        if (this.createEvent) {
          this.mainEvent = this.createEvent;
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            // const i = this.events.indexOf(this.createEvent)
            // if (i !== -1) {
            //   this.events.splice(i, 1)
            // }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      roundDay (time, down = true) {
        const roundTo = 60 * 24 // day
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      updateReferenceEvents(){
            this.$store.dispatch( 'loadReferenceEvents', {
                id: this.editedItem.id,
                user_id:  this.editedItem.user_id,
                clinic_id:  this.editedItem.clinic_id,
                room: this.editedItem.room,
                date_from: this.calRangeStart.toISOString(),
                date_to: this.calRangeEnd.endOf('day').toISOString(),
                service_id:null
            });
            this.$store.dispatch( 'loadReferenceAppointments', {
                id:null,
                user_id:  this.editedItem.user_id,
                clinic_id:  this.editedItem.clinic_id,
                room: this.editedItem.room,
                date_from: this.calRangeStart.toISOString(),
                date_to: this.calRangeEnd.endOf('day').toISOString(),
                service_id:this.editedItem.service_id
            });
      },
      updateRange ({ start, end }) {
        this.calRangeStart = moment(start.date);
        this.calRangeEnd = moment(end.date);
        if(this.itemEditing && this.mainEvent){
          this.updateReferenceEvents();
        }else if(!this.itemEditing){
          this.updateReferenceEvents();
        }
        //const events = []
        //console.log(moment(start.date).format() + ' ' + moment(end.date).endOf('day').format());
        

        // // const min = new Date(`${start.date}T00:00:00`)
        // // const max = new Date(`${end.date}T23:59:59`)
        // // const days = (max.getTime() - min.getTime()) / 86400000
        // // const eventCount = this.rnd(days, days + 20)

        // // for (let i = 0; i < eventCount; i++) {
        // //   const allDay = this.rnd(0, 3) === 0
        // //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        // //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        // //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        // //   const second = new Date(first.getTime() + secondTimestamp)

        // //   events.push({
        // //     name: this.names[this.rnd(0, this.names.length - 1)],
        // //     start: first,
        // //     end: second,
        // //     color: this.colors[this.rnd(0, this.colors.length - 1)],
        // //     timed: !allDay,
        // //   })
        // // }
        //    if(this.editedItem.from){
        //     events.push({
        //       name: 'nazwa',
        //       start: this.editedItem.from,
        //       end: this.editedItem.to,
        //       color:'secondary'
        //       //timed: false//this.editedItem != 2 && this.editedItem != 3 
        //     })
              
        //    }
        //  this.focus = this.editedItem.from.substring(0,10);
         //this.events = events
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunąć to zdarzenie?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteEvent', {slug:  item.id});
               this.$router.push({ name: 'harmonogramy.powrot'})
            }
        })
      },
      deleteItemAndNext(item){
        var that = this;
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunąć to i wszystkie kolejne zdarzenia cyklu?', { color: 'error' }).then((confirm) => {
            if(confirm){
               var ids = _.concat(_.map(_.filter(this.actualSeriesEvents,function(e){return moment(e.start).valueOf() > that.mainEvent.start}),'id'),item.id);
               this.$store.dispatch( 'deleteEvents', {slug:  ids});
               //console.log(ids);
               this.$router.push({ name: 'harmonogramy.powrot'})
            }
        })
      },
      deleteItemAndUnsend(item){
        var that = this;
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunąć wszystkie nierozpoczęte zdarzenia cyklu?', { color: 'error' }).then((confirm) => {
            if(confirm){
               var ids = _.concat(_.map(_.filter(this.actualSeriesEvents,function(e){return !e.readonly}),'id'),item.id);
               this.$store.dispatch( 'deleteEvents', {slug:  ids});
               this.$router.push({ name: 'harmonogramy.powrot'})
            }
        })
      },
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadEventEditDefault'); 
        this.$store.dispatch( 'loadEventAddDefault');
      },
      formReturn(){
        this.$store.commit('setReferenceEvents',[]);
        this.$router.go(-1)
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editEvent', {
                slug: this.editedItem.id,
                type:  this.editedItem.type,
                user_id:  this.editedItem.user_id,
                clinic_id:  this.editedItem.clinic_id,
                category_id: this.editedItem.category_id,
                service_id: this.editedItem.service_id,
                room: this.editedItem.room,
                date_from: moment(this.editedItem.date_from).toISOString(),
                date_to: moment(this.editedItem.date_to).toISOString(),
                note:this.editedItem.note,
                series_def:this.seriesActive?this.seriesSelectedDef:null,
                series_uuid:this.editedItem.series_uuid,
                series:_.filter(this.actualSeriesEvents,{'modified':true}),
                public:this.editedItem.public,
                interval:this.editedItem.interval
              });
            } else {//new item
              this.$store.dispatch( 'addEvent', {
                  type:  this.editedItem.type,
                  user_id:  this.editedItem.user_id,
                  clinic_id:  this.editedItem.clinic_id,
                  category_id: this.editedItem.category_id,
                  service_id: this.editedItem.service_id,
                  room: this.editedItem.room,
                  date_from: this.editedItem.date_from,
                  date_to: this.editedItem.date_to,
                  note:this.editedItem.note,
                  series_def:this.seriesActive?this.seriesSelectedDef:null,
                  series:this.newSeriesEvents,
                  public:this.editedItem.public,
                  interval:this.editedItem.interval
              });

            }
        }
      },
    },
  }
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.v-event-sub-appointment {
  position: absolute;
  left: 0;
  right: 0;
  color:black;
  background-color:white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  opacity: 0.4;
  z-index:0;
}

.v-event-ref-appointment {
  position: absolute;
  left: 0;
  right: 0;
  color:white;
  z-index:0;
}

.v-event-drag-right {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width:4px;
  cursor: w-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 16px;
    border-left: 1px solid white;
    border-right: 1px solid white;
    width: 4px;
    margin-top:2px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
