<template>
                  <v-card :key="componentKey" :height="height" :loading="attachmentAddStatus===1 || attachmentEditLoadStatus===1 || attachmentEditStatus===1 || templatesLoadStatus===1 || templateEditStatus===1 || templateAddStatus===1">
                    <v-toolbar flat dense class="pt-2 pb-0">
                      <v-toolbar-title>{{ editedItem.header }}</v-toolbar-title>
                      <v-btn x-small class="ml-2" v-if="editedItem.type==40" @click="$refs.email.sendAttachment(editedItem.id)" :disabled="!editedItem.content.recipient || !editedItem.content.header || !editedItem.body" color="secondary">Wyślij</v-btn>
                      <v-btn x-small class="ml-2" v-if="editedItem.type==70 && editedItem.content.prescription.status===0" @click="$refs.prescription.submitPrescription()" color="secondary">Wystaw</v-btn>
                      <!-- <v-btn x-small class="ml-2" v-if="editedItem.type==70 && editedItem.content.prescription.status===1" @click="cancelPrescription" color="secondary">Anuluj</v-btn>
                       -->
                      <v-spacer />
                      <v-icon @click="$store.commit('setAttachmentEditBodyHTML',!editedItem.bodyHTML);editorKey++;" v-if="_.includes([10,20],editedItem.type)"  :color="editedItem.bodyHTML?'secondary':'grey darken-2'" size="30" class="mr-2">mdi-format-size</v-icon>
                      <v-btn :disabled="!itemChanged && editedItem.type!==70" v-if="editedItem.type!==60" color="green darken-3" icon @click="handleSubmit"><v-icon size="30">mdi-content-save</v-icon></v-btn>
                      <slot name="maximize"></slot>
                      <v-btn color="grey darken-2" icon @click="close"><v-icon size="30">mdi-close</v-icon></v-btn>
                    </v-toolbar>
                      <prescription-attachment ref="prescription" v-if="editedItem.type===70" @changed="itemChanged=true"  :height="height" />
                      <referral-attachment v-else-if="editedItem.type===60"  @addFile="addNewFileOne($event);itemChanged=false;" @submit="handleSubmit" :height="height" />
                      
                      <v-container  v-else :style="'height:'+ (height-45) +'px;max-width:100%;'">
                          <v-tabs-items v-model="editedItem.tab" :style="'height:'+ (height-100) +'px;'">
                            <v-tab-item>
                              <email-attachment ref="email" v-if="editedItem.type==40" @changed="checkChange()" :height="height" />
                              <task-attachment ref="task" v-if="editedItem.type==50" @changed="itemChanged=true" :height="height" />
                              <results-attachment v-if="editedItem.type==80" @submit="handleSubmit" @changed="itemChanged=true" :height="height" />
                            
                              <tiptap-vuetify 
                                :key="editorKey"
                                :editor-properties="{autoFocus : true}"
                                v-if="_.includes([40],editedItem.type) || (_.includes([10,20],editedItem.type) && editedItem.bodyHTML)" 
                                v-model="editedItem.body" :extensions="extensions" 
                                :card-props="{ outlined: true }"
                                :max-height="''+(height-150-additionalHeight)+''" 
                                :min-height="''+(height-150-additionalHeight)+''" />
                              <v-textarea :key="editorKey" v-else-if="_.includes([30,50],editedItem.type) || (_.includes([10,20],editedItem.type) && !editedItem.bodyHTML)" hide-details="auto"  dense outlined v-model="editedItem.body" :height="(height-100-additionalHeight-5)"></v-textarea>                            
                            </v-tab-item>
                            <v-tab-item>
                              <v-row>
                                <v-col class="col-4">
                                   
                                   <file-drop  :fileAccept="accept" :height="(height-200)"
                                    @newFile="addNewFile($event)"
                                    /> 
                                    <v-menu bottom right v-model="leafletFilesMenu">
                                          <template v-slot:activator="{on}">
                                                <v-btn text color="secondary" v-on="on" @click="leafletFilesMenu = true" class="ml-0">Dodaj ulotkę</v-btn>
                                          </template>
                                          <v-list>
                                            <v-list-item v-for="(type) in lookups.leaflets" :key="type.value" @click.capture.stop="addLeaflet(type.value)">
                                              <v-list-item-content @click="">
                                                <v-list-item-title>{{ type.text }}</v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                  </v-menu>
                                </v-col>
                                <v-col class="col-8 pl-0">
                                  <v-responsive
                                      class="overflow-y-auto fill-height"
                                      :height="(height-100)"
                                  >
                                  <v-simple-table>
                                      <template v-slot:default>
                                        <tbody>
                                          <tr
                                            v-for="(item, index) in editedItem.files"
                                            :key="index"
                                          >
                                          <td class="px-0"><v-icon size="30">{{item['file_type']=='pdf'?'mdi-file-pdf-box':item['file_type']=='png'?'mdi-file-image-outline':item['file_type']=='jpg'?'mdi-file-image-outline':item['file_type']=='jpeg'?'mdi-file-image-outline':'mdi-file'}}</v-icon></td>
                                          <td class="px-1"><v-text-field @input="itemChanged=true;$v.editedItem.$touch()" :error-messages="fileNameErrors(index)" flat dense v-model="item['file_name']" required  hide-details="auto"></v-text-field></td>
                                          <td><v-icon @click="deleteFile(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>
                                            <!-- <td><v-avatar size="35" :color="item.category.color"><v-icon size="20">{{item.category.icon}}</v-icon></v-avatar></td>
                                            <td><b>{{ item.date_from }}</b></td>
                                            <td><b>{{ item.service.name }}</b><br />{{item.clinic?item.clinic.name:''}}</td>
                                            <td><i>{{ _.find(lookups.appointment_status,{'value':item.status}).text }}</i></td>
                                            <td><v-btn :to="{ name: 'dzialanie', params: { id: item.id } }" color="primary" x-small>Przejdź</v-btn></td> -->
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-responsive>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item>
                              <v-row dense>
                                <v-col class="col-6"><v-text-field class="my-2" outlined dense v-model="newFile.file_name" :error-messages="newFileNameErrors"  required label="Wpisz nazwę tworzonego pliku" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-6 text-end">
                                  <file-drop :icon="true" :fileAccept="accept" @newFile="addNewFileCustom($event)" /> 
                                  <v-menu bottom right v-model="leafletMenu">
                                          <template v-slot:activator="{on}">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on }">
                                                <v-icon
                                                  v-on="on"
                                                  @click="leafletMenu = true"
                                                  size="28"
                                                  class="mt-2 mr-2"
                                                >
                                                  mdi-clipboard-text-outline
                                                </v-icon>
                                              </template>
                                              <span>Dodaj ulotkę</span>
                                            </v-tooltip>
                                          </template>
                                          <v-list>
                                            <v-list-item v-for="(type) in lookups.leaflets" :key="type.value" @click.capture.stop="addLeaflet(type.value)">
                                              <v-list-item-content @click="">
                                                <v-list-item-title>{{ type.text }}</v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                  </v-menu>
                                  <v-btn :disabled="newFile.elements.length<1" @click="previewNewFile" small color="primary" class="mt-3">Podgląd</v-btn>
                                  <v-btn :disabled="newFile.elements.length<1" @click="saveNewFile" small color="secondary" class="mt-3 ml-2">Zapisz</v-btn>
                                </v-col>
                              </v-row>
                              <v-responsive
                                  class="overflow-y-auto fill-height"
                                  :height="(height-160)"
                              >
                              <v-simple-table>
                                      <template v-slot:default>
                                          <draggable
                                            :list="newFile.elements"
                                            tag="tbody"
                                          >
                                            <tr
                                              v-for="(element, index) in newFile.elements"
                                              :key="index"
                                            >
                                            <td width="20"><v-icon small class="page__grab-icon">mdi-arrow-all</v-icon></td>
                                            <td class="px-0" width="20"><v-icon size="25">{{element.type=='file' && element.content.file_type=='pdf'?'mdi-file-pdf-outline':element.type=='file' && element.content.file_type=='png'?'mdi-file-image-outline':element.type=='file' && element.content.file_type=='jpg'?'mdi-file-image-outline':element.type=='attachment'?'mdi-text-box-outline':'mdi-file'}}</v-icon></td>
                                            <td>{{element.type=='file'?element.content.file_name:element.type=='attachment'?element.content.header:''}}</td>
                                            <!-- <td class="px-1"><v-text-field @input="itemChanged=true" flat dense v-model="item['file_name']" required single-line hide-details="auto"></v-text-field></td> -->
                                            <td width="20"><v-icon @click="deleteElement(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>
                                            </tr>
                                          </draggable>
                                      </template>
                                </v-simple-table>  
                              </v-responsive>
                            </v-tab-item>
                          <v-tab-item>
                            <v-divider />
                              <v-row dense class="mx-4">
                                <v-col class="text-start pt-5">Nowy szablon</v-col>
                                <v-col class="col-6 text-end"><v-text-field class="my-2" outlined dense v-model="templateEditItem.name" label="Nazwa" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-auto text-start">
                                  <v-btn :disabled="!templateEditItem.name" @click="addNewTemplate" small color="secondary" class="mt-3 ml-2">Zapisz</v-btn>
                                </v-col>
                            </v-row>
                            <v-divider />
                              <v-responsive
                                  class="overflow-y-auto fill-height mt-4"
                                  :height="(height-180)"
                              >
                              
                              <v-simple-table :key="templateComponentKey" class="mx-4">
                                      <template v-slot:default>
                                            <tr
                                              v-for="(element, index) in templateList"
                                              :key="index"
                                            >
                                            <td>{{element.name}}</td>
                                            <td class="text-end">
                                              <v-btn class="mr-2" x-small color="primary" @click="chooseTemplate(element)">wybierz</v-btn>
                                              <v-btn class="mr-2" x-small color="secondary" @click="overrideTemplate(element.id)">nadpisz</v-btn>
                                              <v-btn x-small color="secondary" @click="deleteTemplate(element.id)"><v-icon small left>mdi-delete</v-icon>usuń</v-btn>
                                            </td>
                                           </tr>
                                      </template>
                                </v-simple-table>  
                              </v-responsive>
                              
                          </v-tab-item>
                          </v-tabs-items>
                          <v-tabs @change="$v.newFile.$reset()" hide-slider height="30" v-model="editedItem.tab">
                            <v-tab>Tekst</v-tab>
                            <v-tab>Załączniki</v-tab>
                            <v-tab>Utwórz PDF</v-tab>
                            <v-tab @change="loadTemplates">Szablony</v-tab>
                          </v-tabs>
                      </v-container>
                     <v-snackbar
                        v-model="snackbar"
                        :timeout="5000"
                      >
                      {{snackbarText}}
                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="blue"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Zamknij
                        </v-btn>
                      </template>
                      </v-snackbar>
                    <confirm ref="confirm"></confirm>
                  </v-card>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import FileDrop from './FileDrop.vue'
  import { PDFDocument, StandardFonts, rgb, setFillingGrayscaleColor } from 'pdf-lib'
  import fontkit from '@pdf-lib/fontkit';
  import draggable from "vuedraggable";
  
  import ReferralAttachment from '../components/attachments/Referral.vue'
  import ResultsAttachment from '../components/attachments/Results.vue'
  import EmailAttachment from '../components/attachments/Email.vue'
  import TaskAttachment from '../components/attachments/Task.vue'
  import PrescriptionAttachment from '../components/attachments/Prescription.vue'
  import {papierFirmowy} from '../components/pdf_resources'
  import {
      // component
      TiptapVuetify,
      // extensions
      Heading,
      Bold,
      Italic,
      Strike,
      Underline,
      Code,
      Paragraph,
      BulletList,
      OrderedList,
      ListItem,
      Link,
      Blockquote,
      HardBreak,
      HorizontalRule,
      History,
      Image,
      Table,TableRow,TableCell,TableHeader
    } from "tiptap-vuetify";


  export default {
    components:{confirm,FileDrop,draggable,TiptapVuetify,ReferralAttachment,ResultsAttachment,PrescriptionAttachment,EmailAttachment,TaskAttachment},
    data: () => ({
      accept:null,
      componentKey:0,
      templateComponentKey:0,
      detailes:false,
      dialog:false,
      snackbarText:'',
      snackbar:false,
      itemChanged:false,
      leafletMenu:false,
      leafletFilesMenu:false,
      editorKey:0,
      extensions: [
        //History,
        Bold,
        Underline,
        //Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
       
        //HorizontalRule,
        Paragraph,
        HardBreak
      ],
    }),
    props:['height'],
    validations() {
      if(this.editedItem.type==80){//Wyniki
        return {
          editedItem:{
            content:{
              results:{
                $each:{
                  name:{required}
                }
              }
            },
            files:{
                $each:{
                  file_name:{required}
                }
            },
          },
          newFile:{
            file_name:{required}
          },
        }
      }else{
        return {
          editedItem:{
            files:{
                $each:{
                  file_name:{required}
                }
            },
          },
          newFile:{
            file_name:{required}
          },
        }
      }
    },
    watch: {
      itemChanged(val){
        if(val){
          setTimeout(() => {
              this.itemChanged && this.handleSubmit();
          }, 5000)
        }
      },
      attachmentAddStatus(val){
        if(val===2){
          this.checkChange();
        }
      },
      attachmentEditStatus(val){
        if(val===2){
          //this.editedItem.key += 1;
          this.checkChange();
        }else if(val===3 && this.editedItem.type===70){//błąd recepty
          this.snackbarText = this.attachmentEditText.replace(/<[^>]+>/g, '');
          this.snackbar = true;
        }else if(val===4 && this.editedItem.type===40){//e-mail wysloany, zamykamy edytor
          this.checkChange();
          this.$emit('close');
        }
      },
      editedItemBody(val){
        this.checkChange();
      },
      leafletEditLoadStatus(val){
        if(val===2){
          if(this.editedItem.tab == 1){
            this.leafletFilesMenu = false
            this.editedItem.files.push(this.leafletEditedItem);
            this.$store.dispatch( 'loadLeafletEditDefault');
            this.itemChanged = true;
          }else{
            this.newFile.elements.push({type:'file',content:this.leafletEditedItem});
            this.$store.dispatch( 'loadLeafletEditDefault');
          }
        }
      },
      templateAddStatus(val){
        if(val===2){
          this.templateComponentKey += 1;
          this.$store.dispatch('loadTemplateEditDefault');
        }
      },
      templateEditStatus(val){
        if(val===2){
          this.templateComponentKey += 1;
        }
      },
      templateDeleteStatus(val){
        if(val===2){
          this.templateComponentKey += 1;
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      editing(){
        return this.$store.getters.getAttachmentEditing;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
      editedItemOrig(){
        return this.$store.getters.getAttachmentEdit.orig;
      },
      editedItemBody(){
        return this.$store.getters.getAttachmentEdit.body;
      },
      attachmentEditLoadStatus(){
        return this.$store.getters.getAttachmentEditLoadStatus
      },
      attachmentEditStatus(){
        return this.$store.getters.getAttachmentEditStatus();
      },
      attachmentEditText(){
        return this.$store.getters.getAttachmentEditText
      },
      attachmentAddStatus(){
        return this.$store.getters.getAttachmentAddStatus();
      },
      attachmentAddText(){
        return this.$store.getters.getAttachmentAddText;
      },
      additionalHeight(){
        if(this.editedItem.type==40)return 110;//e-mail
        if(this.editedItem.type==50)return 65;//zadanie
        return 0;
      },
      newFile(){
        return this.$store.getters.getAttachmentNewFile;
      },
      newFileNameErrors () {
        const errors = []
        if (!this.$v.newFile.file_name.$dirty) return errors
        !this.$v.newFile.file_name.required && errors.push('Nazwa pliku jest wymagana')
        return errors
      },
      
      leafletEditLoadStatus(){
        return this.$store.getters.getLeafletEditLoadStatus
      },
      leafletEditedItem(){
        return this.$store.getters.getLeafletEdit;
      },
      
      templateEditItem(){
        return this.$store.getters.getTemplateEdit;
      },
      templateList(){
        return this.$store.getters.getTemplates;
      },
      templatesLoadStatus(){
        return this.$store.getters.getTemplatesLoadStatus();
      },
      templateAddStatus(){
        return this.$store.getters.getTemplateAddStatus();
      },
      templateEditStatus(){
        return this.$store.getters.getTemplateEditStatus();
      },
      templateDeleteStatus(){
        return this.$store.getters.getTemplateDeleteStatus();
      },
      
    },
    methods: {
      addNewTemplate(){
        this.$store.dispatch( 'addTemplate', {
                  type:this.editedItem.type,
                  name:this.templateEditItem.name,
                  body:this.editedItem.body,
                  files:this.editedItem.files,
                  content:null,
        });
      },
      overrideTemplate(id){
        this.$store.dispatch( 'editTemplate', {
                  slug:id,
                  body:this.editedItem.body,
                  files:this.editedItem.files,
                  content:null,
        });
      },
      chooseTemplate(template){
        this.editedItem.body = template.body;
        this.editedItem.files = template.files;
        this.editedItem.tab=0;
      },
      deleteTemplate(id){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać szablon?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteTemplate', {slug:  id});
            }
        })
      },
      loadTemplates(){
         var currentOptions = {...this.$store.getters.getTemplateOptions};
         currentOptions.selectedType = this.editedItem.type;
         this.$store.dispatch( 'setTemplateOptions', {options:currentOptions} );
      },
      
      fileNameErrors (index) {
        const errors = []
        if (!this.$v.editedItem.files || !this.$v.editedItem.files.$each[index] || !this.$v.editedItem.files.$each[index].file_name.$dirty) return errors
        !this.$v.editedItem.files.$each[index].file_name.required && errors.push('Nazwa pliku jest wymagana')
        return errors
      },
      
      addNewFile(file){
        this.editedItem.files.push(file);
        this.itemChanged = true;
      },
      addNewFileOne(file){
        this.editedItem.files = [file];
        this.itemChanged = true;
      },
      addNewFileCustom(file){
        this.newFile.elements.push({type:'file',content:file});
      },
      addLeaflet(id){
        this.$store.dispatch( 'loadLeafletEdit', {slug:  id} );
        this.leafletMenu = false
      },
      deleteFile(index){
        this.editedItem.files.splice(index,1);
        this.itemChanged = true;
      },
      deleteElement(index){
        this.newFile.elements.splice(index,1);
        //this.itemChanged = true;
      },
      fillParagraph(text, font, fontSize, maxWidth) {
        var paragraphs = text.split('\n');
        for (let index = 0; index < paragraphs.length; index++) {
            var paragraph = paragraphs[index];
            if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
                var words = paragraph.split(' ');
                var newParagraph = [];
                var i = 0;
                newParagraph[i] = [];
                for (let k = 0; k < words.length; k++) {
                    var word = words[k];
                    newParagraph[i].push(word);
                    if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth) {
                        newParagraph[i].splice(-1); // retira a ultima palavra
                        i = i + 1;
                        newParagraph[i] = [];
                        newParagraph[i].push(word);
                    }
                }
                paragraphs[index] = newParagraph.map(p => p.join(' ')).join('\n');
            }
        }
        return paragraphs.join('\n');
      },
      
      async generateNewFile(){
        const pdfDoc = await PDFDocument.create()
        const fontBytesRegular = await fetch('/fonts/Roboto-Regular.ttf').then(res => res.arrayBuffer())
        const fontBytesItalic = await fetch('/fonts/Roboto-Italic.ttf').then(res => res.arrayBuffer())
        

        for(const element of this.newFile.elements){
          if(element.type=='file'){
            if(element.content.file_type=='pdf'){
              const newPdf = await PDFDocument.load(element.content.file_content);
              const copiedPagesA = await pdfDoc.copyPages(newPdf, newPdf.getPageIndices());
              copiedPagesA.forEach((page) => pdfDoc.addPage(page));
            }else{//image
              const page = pdfDoc.addPage();
              const lMargin = 5
              const rMargin = 5
              const { width, height } = page.getSize();
              var imageFile = null
              if(element.content.file_type=='jpg' || element.content.file_type=='jpeg'){
                imageFile = await pdfDoc.embedJpg(element.content.file_content)
              }else if(element.content.file_type=='png'){
                imageFile =  await pdfDoc.embedPng(element.content.file_content)
              }else{
                continue;
              }
              const imageDims = imageFile.scaleToFit(width-10,height-10)
              page.drawImage(imageFile, {
                x: page.getWidth() / 2 - imageDims.width / 2,
                y: page.getHeight() / 2 - imageDims.height / 2,
                width: imageDims.width,
                height: imageDims.height,
              })
            }
          }else if(element.type=='attachment'){
            const newPdf = await PDFDocument.load(papierFirmowy);
            const lMargin = 85
            const rMargin = 60
            newPdf.registerFontkit(fontkit);

            const regularFont = await newPdf.embedFont(fontBytesRegular)
            const italicFont = await newPdf.embedFont(fontBytesItalic)
            const page = newPdf.getPage(0);
            const { width, height } = page.getSize()
            const bodyNoHTML = element.content.body?element.content.body.replace(/(<([^>]+)>)/gi, ""):'';
            page.drawText(element.content.update+', '+element.content.author, { font:italicFont, size: 10,x:width - italicFont.widthOfTextAtSize(element.content.update+', '+element.content.author,10) - rMargin,y:height-150})
            page.drawText(element.content.header, { font:regularFont, size: 14,x:width/2 - regularFont.widthOfTextAtSize(element.content.header,14)/2,y:height-200})
            page.drawText(bodyNoHTML?this.fillParagraph(bodyNoHTML,regularFont,11,width-lMargin-rMargin):'', { font:regularFont,size: 11,lineHeight: 15,x:lMargin,y:height-230 })
            
            const copiedPagesA = await pdfDoc.copyPages(newPdf, newPdf.getPageIndices());
            copiedPagesA.forEach((page) => pdfDoc.addPage(page));
          }else if(element.type=='tag'){

          }
        }

        const res = await pdfDoc.saveAsBase64({ dataUri: true })
        return res
      },
      async saveNewFile(){
        this.$v.newFile.$touch()
        if (!this.$v.newFile.$invalid) {
            const base64data = await this.generateNewFile();
            this.addNewFile({
              file_name:this.newFile.file_name,
              file_content:base64data,
              file_type:'pdf',
              file_path:null});
            this.$store.commit( 'setNewFileDefault');
            this.editedItem.tab=1
        }
      },
      
      async previewNewFile(){
        const base64data = await this.generateNewFile();
        this.$store.commit("setFlowsAttachmentFileContent", {
          file_name:this.newFile.file_name,
          file_content:base64data,
          file_type:'pdf',
          file_path:null}
          );
      },
      
      checkChange(){
        this.itemChanged =  (this.$store.getters.getAttachmentEdit.orig.body !== this.$store.getters.getAttachmentEdit.body) 
        || JSON.stringify(this.$store.getters.getAttachmentEdit.orig.content) !== JSON.stringify(this.$store.getters.getAttachmentEdit.content);
        //(this.editedItem.orig.content != this.editedItem.content) ||
        //(this.editedItem.orig.files != this.editedItem.files);
        //console.log(JSON.stringify(this.$store.getters.getAttachmentEdit.orig.content));
        //console.log(JSON.stringify(this.$store.getters.getAttachmentEdit.content));
      },
      close(){
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.editedItem.$touch()
        if (this.$v.editedItem.$invalid && this.editedItem.type!==70) {//receptę można zapisać bez wszystkich danych

        } else {
            this.itemChanged = false;
            if (this.editing) {
              //edycja
              this.$store.dispatch( 'editAttachment', {
                slug: this.editedItem.id,
                header:this.editedItem.header,
                body:this.editedItem.body,
                files:this.editedItem.files,
                content:  this.editedItem.content,
              });
            } else {//new item
              this.$store.dispatch( 'addAttachment', {
                  appointment_id:  this.editedItem.appointment_id,
                  type:  this.editedItem.type,
                  header:this.editedItem.header,
                  body:this.editedItem.body,
                  files:this.editedItem.files,
                  content:  this.editedItem.content,
                  ref_id: this.editedItem.ref_id,
              });

            }
        }
      },
    },
  }
</script>
<style>
.tiptap-vuetify-editor__content{
    transition: none !important;
}
</style>
