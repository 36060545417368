<template>
  <v-combobox
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedValue"
                         @change="valueChange"
                        :loading="lookupLoadStatus==1"
                        :items="lookup"
                        @update:search-input="changeSelection"
                        flat
                        hide-no-data
                        hide-details="auto"
                        :label="label"
                        :dense="dense"
                        :clearable="clearable"
                        @click:clear="clearContent"
                        :disabled="disabled"
                      ></v-combobox>

</template>
<script>

export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:""
   },
   clearable:{
     type:Boolean,
     default:false
   },
   disabled:{
     type:Boolean,
     default:false
   },
   selectedValue:{
     type:String
   },
  },
  data: () => ({

  }),
  watch:{
  
  },
  computed: {
    lookupLoadStatus(){
      return this.$store.getters.getPatientsRecommendationLookup;
    },
    lookup(){
      return this.$store.getters.getPatientsRecommendationLookup;
      if(this.selectedValue &&  _.filter(this.$store.getters.getPatientsRecommendationLookup,{'value':this.selectedValue}).length===0)
      return [{value:this.selectedValue,text:this.selectedText}];
      else return this.$store.getters.getPatientsRecommendationLookup;
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    clearContent(){
      this.$emit('valueSelected', null)
    },
    valueChange(val){
      if(val){
        this.$emit('valueSelected', val.value)
      }
    },
    changeSelection (val) {
      if(this.selectedValue!=val){
        this.$emit('valueSelected', val)
        if(val && val.length>2){
          this.querySelections(val)
        }
      }
      
      // if(this.selectedValue){
      //   val && val !== _.find(this.lookup,{'value':this.selectedValue}).text && val.length>2 && this.querySelections(val)
      // }else{
      //   val && val.length>2 && this.querySelections(val)
      // }
    },
    querySelections (v) {
        this.$store.dispatch( 'searchPatientsRecommendationLookup',{q:v}); 
      },
  }
};
</script>