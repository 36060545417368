<template>
  <v-autocomplete
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedPatient"
                         @change="patientChange"
                        :loading="patientsLookupLoadStatus==1"
                        :items="patientsLookup"
                        @update:search-input="changeSelection"
                        flat
                        hide-no-data
                        hide-details="auto"
                        :label="label"
                        :dense="dense"
                        :clearable="clearable"
                        @click:clear="clearContent"
                        :disabled="disabled"
                        auto-select-first
                      ></v-autocomplete>

</template>
<script>

export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   excludePatientId:{
     type:Number,
     default:0
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj klinikę"
   },
   clearable:{
     type:Boolean,
     default:false
   },
   disabled:{
     type:Boolean,
     default:false
   },
   selectedPatientText:{
     type:String,
     default:null
   },
  },
  data: () => ({
    selectedPatient: null,
    selectedText: null
  }),
  computed: {
    patientsLookupLoadStatus(){
      return this.$store.getters.getPatientsLookupLoadStatus;
    },
    patientsLookup(){
      //jeżeli jest juz ustawiony patient ale lookup jest pusty to dajemy tego patienta
      if(this.selectedPatientText && _.filter(this.$store.getters.getPatientsLookup,{'value':this.selectedPatient}).length===0)
      return [{value:this.selectedPatient,text:this.selectedPatientText}];
      else return this.$store.getters.getPatientsLookup;
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    clearContent(){
      this.selectedPatient = null;
      this.selectedText = null;
      this.$emit('patientSelected', {value:null,text:null})
    },
    patientChange(val){
      if(val){
        this.selectedPatient = val
        this.selectedText = _.find(this.patientsLookup,{'value':this.selectedPatient}).text
        this.$emit('patientSelected', {value:this.selectedPatient,text:this.selectedText})
      }
    },
    changeSelection (val) {
      if(this.selectedPatientText){//edycja
        val && val !== this.selectedPatientText && val.length>2 && this.querySelections(val)
      }else{
        val && val !== this.selectedText && val.length>2 && this.querySelections(val)
      }
    },
    querySelections (v) {
        this.$store.dispatch( 'searchPatientsLookup',{q:v}); 
      },
  }
};
</script>
<style scoped>

</style>