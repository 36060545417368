<template>
  <v-autocomplete
                        ref="autoUser"
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="select"
                         @change="userChange"
                        :loading="usersLookupLoadStatus==1"
                        :items="usersLookup"
                        @update:search-input="changeSelection"
                        prepend-inner-icon='mdi-magnify'
                        append-icon=''
                        flat
                        single-line
                        hide-no-data
                        hide-details="auto"
                        :label="label"
                        :dense="dense"
                        :disabled="disabled"
                        auto-select-first
                      ></v-autocomplete>

</template>
<script>
import moment from 'moment';
export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj klinikę"
   },
   disabled:{
     type:Boolean,
     default:false
   },
  userType:{
     type:Number,
     default:undefined
   },
  },
  data: () => ({
    select:null
  }),
 
  computed: {
    usersLookupLoadStatus(){
      return this.$store.getters.getUsersLookupLoadStatus;
    },
    usersLookup(){
      return _.map(this.$store.getters.getUsersLookup,'text');
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      //return true;
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    userChange(val){
      if(val){
        this.$emit('userSelected', {value:_.find(this.$store.getters.getUsersLookup,{'text':val}).value,text:val})
        this.$store.commit("setUsersLookup", []);
        this.select = moment().valueOf().toString();
      }
    },
    changeSelection (val) {
      val && val.length>2 && val !== this.select && this.querySelections(val)
    },
    querySelections (v) {
      this.$store.dispatch( 'searchUsersLookup',{q:v,type:this.userType}); 
    },
  }
};
</script>
<style scoped>

</style>