<template>
  <div>
    <v-toolbar flat color="white">
                  <v-toolbar-title>Historia operacji</v-toolbar-title>
                  <!-- <v-btn x-small class="ml-2" color="secondary" :to="{name:'rozliczenia',params:{patient_id:editedItem.id}}">rozliczenia</v-btn>
                  <v-spacer />
                  <v-toolbar-title><span :style="'color:' + (editedItem.balance>0?'red;':'green;')"><b>{{ editedItem.balance}} {{_.find(lookups.currencies,{'value':editedItem.currency}).text}}</b></span></v-toolbar-title> -->
                  <v-spacer />
                  <v-dialog v-model="dialogLog" >
                    <template v-slot:activator="{ on }">
                      <v-btn @click="loadLog" v-on="on" x-small color="secondary" class="mr-5">Szczegóły</v-btn>
                    </template>
                    <v-card class="pl-1 pr-1">
                      <v-data-table
                        :headers="headersLog"
                        :items="tableLog"
                        class="elevation-0"
                        dense
                        hide-default-footer
                        disable-pagination
                        disable-sort
                        :loading="logLoadStatus == 1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat color="white">
                            <v-toolbar-title>Historia Operacji</v-toolbar-title>       
                            <v-spacer></v-spacer> 
                          
                            <v-menu
                                            v-model="dateFromMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="150px"
                                          >
                                            <template v-slot:activator="{ on }">
                                              <v-text-field
                                                v-model="logDateFrom"
                                                label="Od"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                                class="choose_date mt-6 mr-5"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker first-day-of-week="1" no-title v-model="logDateFrom" @input="dateFromMenu = false"></v-date-picker>
                            </v-menu>
                            <v-menu
                                            v-model="dateToMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="150px"
                                          >
                                            <template v-slot:activator="{ on }">
                                              <v-text-field
                                                v-model="logDateTo"
                                                label="Do"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                                class="choose_date mt-6 mr-5"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker first-day-of-week="1" no-title v-model="logDateTo" @input="dateToMenu = false"></v-date-picker>
                            </v-menu>


                            <v-btn  @click="dialogLog = false" outlined class="mb-2 mt-2"  color="primary">
                              <v-icon>mdi-close-thick</v-icon>
                            </v-btn>
                            
                          </v-toolbar>
                        </template>
                        <template v-slot:item.date="{ item }">
                          {{ item.created_at.split(' ')[0] }}
                        </template>
                        <template v-slot:item.hour="{ item }">
                          {{ item.created_at.split(' ')[1] }}
                        </template>
                      </v-data-table> 
                    </v-card>          
                  </v-dialog>
    </v-toolbar>
    <v-simple-table dense class="mx-3 pb-5">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in logItems"
                          :key="item.id"
                        >
                          <td nowrap ><b>{{ item.created_at }}</b></td>
                          <td v-if="item.param" class="text-body-2">{{ _.find(lookups.log,{'value':item.action}).text }} ({{_.find(lookups.logParams,{'value':item.param}).text}})</td>
                          <td v-else class="text-body-2">{{ _.find(lookups.log,{'value':item.action}).text }}</td>
                          <td><i>{{ item.user }}</i></td>
                        </tr>
                      </tbody>
                    </template>
    </v-simple-table>
  </div>
</template>

<script>
  export default {
  props:{
    type:{
      type:Number,
      default:1
    },
    ref_id:{
      type:Number,
      default:null
    },
  },
    data: () => ({
      dialogLog:false,
      dateFromMenu:false,
      dateToMenu:false,
      headersLog: [
        { text: 'Data', value: 'date' },
        { text: 'Godzina', value: 'hour' },
        { text: 'Operacja', value: 'action'},
        { text: 'Parametr', value: 'param'},
        { text: 'Przed', value: 'before'},
        { text: 'Po', value: 'after'},
        { text: 'Dokonał zmiany', value: 'user'},
      ],
    }),
    watch: {
      
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      logItems(){
        return this.type===1?this.$store.getters.getPatientEdit.latest_logs:(this.type===2?this.$store.getters.getAppointmentEdit.latest_logs:[]);
      },
      patientEditLoadStatus(){
        return this.$store.getters.getPatientEditLoadStatus();
      },
      patientEditStatus(){
        return this.$store.getters.getPatientEditStatus();
      },
      tableLog(){
        return this.$store.getters.getLog;
      },
      logLoadStatus(){
        return this.$store.getters.getLogLoadStatus;
      },
      logDateFrom: {
        get () {
          return this.$store.getters.getLogOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getLogOptions};
          currentOptions.fromDate = value;
          this.$store.dispatch( 'setLogOptions', {options:currentOptions} )
        }
      },
      logDateTo: {
        get () {
          return this.$store.getters.getLogOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getLogOptions};
          currentOptions.toDate = value;
          this.$store.dispatch( 'setLogOptions', {options:currentOptions} )
        }
      },
    },
   
    methods: {
      loadLog(){
        var currentOptions = {...this.$store.getters.getLogOptions};
        var dateFrom = new Date(); 
        var dateTo = new Date(); 
        var dateFrom = new Date(dateFrom.setMonth(dateTo.getMonth()-1));
        currentOptions.type = this.type;
        currentOptions.ref_id = this.ref_id;
        currentOptions.fromDate = dateFrom.toISOString().substr(0,10);
        currentOptions.toDate = dateTo.toISOString().substr(0,10);
        this.$store.dispatch( 'setLogOptions', {options:currentOptions} )
      },
      close(){

      },
     
    },
  }
</script>
<style scoped>
.choose_date{
      max-width: 150px;
}
</style>
