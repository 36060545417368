import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/patients
    */
    getPatients: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/patients',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/patients/log
    */
        getLog: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/patients/log',{
                params:{
                    type:options.type,
                    ref_id:options.ref_id,
                    fromDate:options.fromDate,
                    toDate:options.toDate,
            }});
        },

    /*
        GET     /api/patients/search
    */
   searchPatients: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/patients/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

/*
        GET     /api/patients/searchLookup
    */
   searchPatientsLookup: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/patients/searchLookup',{
        params:{
            q:options.q,
    }});
},

/*
        GET     /api/patients/searchGUSLookup
    */
        searchPatientsGUSLookup: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/patients/searchGUSLookup',{
                params:{
                    q:options.q,
            }});
        },

/*
        GET     /api/patients/searchRecommendationLookup
    */
        searchPatientsRecommendationLookup: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/patients/searchRecommendationLookup',{
                params:{
                    q:options.q,
            }});
        },

    /*
		POST 	/api/patients
	*/
    postAddNewPatient: function( name,surname,age,birth_date,pesel,city,phone_prefix,phone,email,recommendation){
        return axios.post( HGO_CONFIG.API_URL + '/patients',
            {
                name: name,
                surname:surname,
                age:age,
                birth_date:birth_date,
                pesel:pesel,
                city:city,
                phone_prefix:phone_prefix,
                phone:phone,
                email:email,
                recommendation:recommendation,
            }
        );
    },

    /*
		GET 	/api/patients/{slug}/edit
	*/
    getPatientEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/patients/' + slug + '/edit' );
	},


    /*
		PUT 	/api/patients/{slug}
	*/
    putEditPatient: function(slug, name,surname,age,birth_date,pesel,country_code,post_code,city,address,house,apartment,gus,sex,insurance,phone_prefix,phone,email,recommendation,first_contact,source,currency){
        return axios.put( HGO_CONFIG.API_URL + '/patients/'+ slug,
            {
                name: name,
                surname:surname,
                age:age,
                birth_date:birth_date,
                pesel:pesel,
                country_code:country_code,
                post_code:post_code,
                city:city,
                address:address,
                house:house,
                apartment:apartment,
                gus:gus,
                sex:sex,
                insurance:insurance,
                phone_prefix:phone_prefix,
                phone:phone,
                email:email,
                recommendation:recommendation,
                first_contact:first_contact,
                source:source,
                currency:currency
            }
        );
    },

     /*
		GET 	/api/patients/reactivate/{slug}
	*/
    putReactivatePatient: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/patients/reactivate/' + slug);
    },

    /*
		DELETE /api/patients/{slug}
	*/
    deletePatient: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/patients/' + slug );
    },
    
    /*
		DELETE /api/patients/{slug}/force
	*/
    forceDeletePatient: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/patients/' + slug + '/force' );
	},

      /*
		POST 	/api/patients/contact
	*/
    postAddNewContact: function( patient_id,name,surname,type,phone_prefix,phone,email,xdefault){
        return axios.post( HGO_CONFIG.API_URL + '/patients/contact',
            {
                patient_id:patient_id,
                name: name,
                surname:surname,
                type:type,
                phone_prefix:phone_prefix,
                phone:phone,
                email:email,
                default:xdefault,
            }
        );
    },

    /*
		GET 	/api/patients/{slug}/edit/contact
	*/
    getContactEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/patients/' + slug + '/edit/contact' );
	},


    /*
		PUT 	/api/patients/{slug}/concact
	*/
    putEditContact: function(slug, name,surname,type,phone_prefix,phone,email,xdefault){
        return axios.put( HGO_CONFIG.API_URL + '/patients/'+ slug + '/contact',
            {
                name: name,
                surname:surname,
                type:type,
                phone_prefix:phone_prefix,
                phone:phone,
                email:email,
                default:xdefault,
            }
        );
    },

    /*
		DELETE /api/patients/{slug}/force/contact
	*/
    forceDeleteContact: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/patients/' + slug + '/force/contact' );
	},
}