import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/prescriptions
    */
    getProducts: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/prescriptions/products',{
            params:{
                q:options.q
        }});
    },

    /*
        GET     /api/prescriptions/packages
    */
        getPackages: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/prescriptions/packages',{
                params:{
                    q:options.q
            }});
        },

        /*
		GET /api/presences/{slug}/force
        */
        getCreatePrescription: function( slug ){
            return axios.get( HGO_CONFIG.API_URL + '/prescriptions/' + slug + '/create' );
        },
}