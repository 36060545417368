<template>
<v-responsive
             class="overflow-y-auto fill-height"
             :style="'height:'+ (height-50) +'px;'"
                      >
    <v-card-text>
                        <v-row dense>
                                <v-col class="col-6"><v-select outlined clearable dense v-model="editedItem.content.referral.type_id" :items="lookups.referral_type" label="Badanie"></v-select></v-col>
                                <v-col class="col-6 text-end">
                                  <v-btn :disabled="editedItem.content.referral.type_id==null" @click="previewReferralFile" small color="primary" class="mt-3">Podgląd</v-btn>
                                  <v-btn :disabled="editedItem.content.referral.type_id==null" @click="saveReferralFile" small color="secondary" class="mt-3 ml-2">Zapisz</v-btn>
                                </v-col>
                        </v-row>
                         
                         <h3>Dane pacjenta</h3>
                         <v-row dense>
                           <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.patient.name" label="Imię i nazwisko" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-4"><v-text-field v-model="editedItem.content.referral.patient.pesel" label="PESEL" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-2"><v-text-field v-model="editedItem.content.referral.patient.age" label="Wiek" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.patient.phone" label="Telefon" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.patient.email" label="E-mail" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-12"><v-text-field v-model="editedItem.content.referral.patient.address" label="Adres" hide-details="auto"></v-text-field></v-col>
                         </v-row>
                         <h3 class="mt-3">Dane kliniczne</h3>
                         <v-row dense>
                           <v-col class="col-8"><v-text-field v-model="editedItem.content.referral.data.diagnosis" label="Rozpoznanie" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-4"><v-text-field v-model="editedItem.content.referral.data.idc" label="IDC 10" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-12"><v-text-field v-model="editedItem.content.referral.data.purpose" label="Cel badania" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.data.dre" label="Wynik badania DRE" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.data.psa" label="Aktualny poziom PSA" hide-details="auto"></v-text-field></v-col>
                           <v-col class="col-12"><v-text-field v-model="editedItem.content.referral.data.other" label="Przebyte leczenie" hide-details="auto"></v-text-field></v-col>
                         </v-row>
                         <h3 class="mt-3">Wyniki badań</h3>
                         <v-row dense>
                          <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.results.score" label="4Kscore TEST" hide-details="auto"></v-text-field></v-col>
                          <v-col v-if="editedItem.content.referral.type_id==1" class="col-6"><v-text-field v-model="editedItem.content.referral.results.pca" label="PCA3" hide-details="auto"></v-text-field></v-col>
                          <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.results.mri" label="whole-body MRI" hide-details="auto"></v-text-field></v-col>
                          <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.results.pet_mri" label="PET/MRI z choliną" hide-details="auto"></v-text-field></v-col>
                          <v-col class="col-6"><v-text-field v-model="editedItem.content.referral.results.mdx" label="Test Sekect MDx Liquid Biopsy" hide-details="auto"></v-text-field></v-col>
                          <v-col v-if="editedItem.content.referral.type_id!=1" class="col-6"><v-text-field v-model="editedItem.content.referral.results.pet_ct" label="PET/CT z choliną" hide-details="auto"></v-text-field></v-col>
                          <v-col v-if="editedItem.content.referral.type_id!=1" class="col-6"><v-text-field v-model="editedItem.content.referral.results.mpMri" label="mp/MRI prostaty" hide-details="auto"></v-text-field></v-col>
                         </v-row>
        </v-card-text>
</v-responsive>
</template>

<script>
  import { PDFDocument, StandardFonts, rgb, setFillingGrayscaleColor } from 'pdf-lib'
  import fontkit from '@pdf-lib/fontkit';
  import {diagSkierowanie,mriSkierowanie} from '../../components/pdf_resources'

  export default {
    components:{

    },
    data: () => ({
     
    }),
    props: {
      height:{
        type:Number,
        default:0
      },
    },
    watch: {
     
    },
    computed: {
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
    },
    methods: {
      async saveReferralFile(){
            const base64data = await this.generateReferralFile();
            this.$emit('addFile',{
              file_name:_.find(this.lookups.referral_type,{'value':this.editedItem.content.referral.type_id}).text,
              file_content:base64data,
              file_type:'pdf',
              file_path:null});
            this.$store.commit( 'setNewFileDefault');
            this.$emit('submit');
      },
      async previewReferralFile(){
        const base64data = await this.generateReferralFile();
        this.$store.commit("setFlowsAttachmentFileContent", {
          file_name:_.find(this.lookups.referral_type,{'value':this.editedItem.content.referral.type_id}).text,
          file_content:base64data,
          file_type:'pdf',
          file_path:null}
          );
      },
      async generateReferralFile(){
        const pdfDoc = await PDFDocument.create()
        const fontBytesRegular = await fetch('/fonts/Roboto-Regular.ttf').then(res => res.arrayBuffer())
        const fontBytesItalic = await fetch('/fonts/Roboto-Italic.ttf').then(res => res.arrayBuffer())
        
        const refType = this.editedItem.content.referral.type_id;
        const newPdf = await PDFDocument.load(refType==1?mriSkierowanie:diagSkierowanie);
        const form = newPdf.getForm()
        const lMargin = 85
        const rMargin = 60
        newPdf.registerFontkit(fontkit);

        const regularFont = await newPdf.embedFont(fontBytesRegular)
        const italicFont = await newPdf.embedFont(fontBytesItalic)
        const page = newPdf.getPage(0);
        const { width, height } = page.getSize()
        //MRI
        if(refType==1){
          page.drawText(this.editedItem.content.referral.patient.name?this.editedItem.content.referral.patient.name:'', { font:regularFont, size: 12,x:180,y:height-180})
          page.drawText(this.editedItem.content.referral.patient.pesel?this.editedItem.content.referral.patient.pesel:'', { font:regularFont, size: 12,x:105,y:height-207})
          page.drawText(this.editedItem.content.referral.patient.age?this.editedItem.content.referral.patient.age.toString():'', { font:regularFont, size: 12,x:288,y:height-207})
          page.drawText(this.editedItem.content.referral.patient.phone?this.editedItem.content.referral.patient.phone.toString():'', { font:regularFont, size: 12,x:200,y:height-231})
          page.drawText(this.editedItem.content.referral.patient.email?this.editedItem.content.referral.patient.email.toString():'', { font:regularFont, size: 12,x:370,y:height-231})
          page.drawText(this.editedItem.content.referral.patient.address?this.editedItem.content.referral.patient.address.toString():'', { font:regularFont, size: 12,x:138,y:height-257})

          page.drawText(this.editedItem.content.referral.data.diagnosis?this.editedItem.content.referral.data.diagnosis.toString():'', { font:regularFont, size: 12,x:128,y:height-316})
          page.drawText(this.editedItem.content.referral.data.idc?this.editedItem.content.referral.data.idc.toString():'', { font:regularFont, size: 12,x:455,y:height-316})
          page.drawText(this.editedItem.content.referral.data.purpose?this.editedItem.content.referral.data.purpose.toString():'', { font:regularFont, size: 12,x:179,y:height-343})
          page.drawText(this.editedItem.content.referral.data.dre?this.editedItem.content.referral.data.dre.toString():'', { font:regularFont, size: 12,x:158,y:height-368})
          page.drawText(this.editedItem.content.referral.data.psa?this.editedItem.content.referral.data.psa.toString():'', { font:regularFont, size: 12,x:455,y:height-368})
          page.drawText(this.editedItem.content.referral.data.other?this.editedItem.content.referral.data.other.toString():'', { font:regularFont, size: 12,x:375,y:height-395})

          page.drawText(this.editedItem.content.referral.results.score?this.editedItem.content.referral.results.score.toString():'', { font:regularFont, size: 12,x:243,y:height-450})
          page.drawText(this.editedItem.content.referral.results.pca?this.editedItem.content.referral.results.pca.toString():'', { font:regularFont, size: 12,x:243,y:height-475})
          page.drawText(this.editedItem.content.referral.results.mri?this.editedItem.content.referral.results.mri.toString():'', { font:regularFont, size: 12,x:243,y:height-550})
          page.drawText(this.editedItem.content.referral.results.pet_mri?this.editedItem.content.referral.results.pet_mri.toString():'', { font:regularFont, size: 12,x:243,y:height-525})
          page.drawText(this.editedItem.content.referral.results.mdx?this.editedItem.content.referral.results.mdx.toString():'', { font:regularFont, size: 12,x:243,y:height-500})
          
        }else{
          page.drawText(_.find(this.lookups.referral_type,{'value':refType}).text, { font:regularFont, size: 12,x:145,y:height-152})//nazwa badania

          page.drawText(this.editedItem.content.referral.patient.name?this.editedItem.content.referral.patient.name:'', { font:regularFont, size: 12,x:180,y:height-174})
          page.drawText(this.editedItem.content.referral.patient.pesel?this.editedItem.content.referral.patient.pesel:'', { font:regularFont, size: 12,x:105,y:height-200})
          page.drawText(this.editedItem.content.referral.patient.age?this.editedItem.content.referral.patient.age.toString():'', { font:regularFont, size: 12,x:288,y:height-200})
          page.drawText(this.editedItem.content.referral.patient.phone?this.editedItem.content.referral.patient.phone.toString():'', { font:regularFont, size: 12,x:200,y:height-224})
          page.drawText(this.editedItem.content.referral.patient.email?this.editedItem.content.referral.patient.email.toString():'', { font:regularFont, size: 12,x:370,y:height-224})
          page.drawText(this.editedItem.content.referral.patient.address?this.editedItem.content.referral.patient.address.toString():'', { font:regularFont, size: 12,x:145,y:height-248})

          page.drawText(this.editedItem.content.referral.data.diagnosis?this.editedItem.content.referral.data.diagnosis.toString():'', { font:regularFont, size: 12,x:128,y:height-312})
          page.drawText(this.editedItem.content.referral.data.idc?this.editedItem.content.referral.data.idc.toString():'', { font:regularFont, size: 12,x:455,y:height-312})
          page.drawText(this.editedItem.content.referral.data.purpose?this.editedItem.content.referral.data.purpose.toString():'', { font:regularFont, size: 12,x:179,y:height-338})
          page.drawText(this.editedItem.content.referral.data.dre?this.editedItem.content.referral.data.dre.toString():'', { font:regularFont, size: 12,x:158,y:height-361})
          page.drawText(this.editedItem.content.referral.data.psa?this.editedItem.content.referral.data.psa.toString():'', { font:regularFont, size: 12,x:455,y:height-361})
          page.drawText(this.editedItem.content.referral.data.other?this.editedItem.content.referral.data.other.toString():'', { font:regularFont, size: 12,x:58,y:height-435})

          page.drawText(this.editedItem.content.referral.results.score?this.editedItem.content.referral.results.score.toString():'', { font:regularFont, size: 12,x:138,y:height-545})
          page.drawText(this.editedItem.content.referral.results.mri?this.editedItem.content.referral.results.mri.toString():'', { font:regularFont, size: 12,x:332,y:height-545})
          page.drawText(this.editedItem.content.referral.results.pet_mri?this.editedItem.content.referral.results.pet_mri.toString():'', { font:regularFont, size: 12,x:500,y:height-545})
          page.drawText(this.editedItem.content.referral.results.mdx?this.editedItem.content.referral.results.mdx.toString():'', { font:regularFont, size: 12,x:138,y:height-570})
          page.drawText(this.editedItem.content.referral.results.pet_ct?this.editedItem.content.referral.results.pet_ct.toString():'', { font:regularFont, size: 12,x:332,y:height-570})
          page.drawText(this.editedItem.content.referral.results.mpMri?this.editedItem.content.referral.results.mpMri.toString():'', { font:regularFont, size: 12,x:500,y:height-570})
        }
        form.flatten();
        const copiedPagesA = await pdfDoc.copyPages(newPdf, newPdf.getPageIndices());
        copiedPagesA.forEach((page) => pdfDoc.addPage(page));
        
        const res = await pdfDoc.saveAsBase64({ dataUri: true })
        return res
      },
    },
  }
</script>