import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
		POST 	/api/attachments
	*/
    postAddNewAttachment: function( appointment_id,type,header,body,files,content,ref_id){
        return axios.post( HGO_CONFIG.API_URL + '/attachments',
            {
                appointment_id: appointment_id,
                type:type,
                header:header,
                body:body,
                my_files:files,
                content:content,
                ref_id:ref_id
            }
        );
    },

    /*
		POST 	/api/attachments/files
	*/
    postAddNewFiles: function( patient_id,files){
        return axios.post( HGO_CONFIG.API_URL + '/attachments/files',
            {
                patient_id: patient_id,
                my_files:files,
            }
        );
    },

    /*
		GET 	/api/attachments/{slug}/edit
	*/
    getAttachmentEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/attachments/' + slug + '/edit' );
	},


    /*
		PUT 	/api/attachments/{slug}
	*/
    putEditAttachment: function(slug, header,body,files,content){
        return axios.put( HGO_CONFIG.API_URL + '/attachments/'+ slug,
            {
                header: header,
                body:body,
                my_files:files,
                content:content
            }
        );
    },

     /*
		PUT 	/api/attachments/{slug}/send
	*/
    putSendAttachment: function(slug){
        return axios.put( HGO_CONFIG.API_URL + '/attachments/'+ slug +'/send');
    },

    
    /*
		DELETE /api/attachments/{slug}/force
	*/
    forceDeleteAttachment: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/attachments/' + slug + '/force' );
	}
}