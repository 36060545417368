import axios from 'axios'
import UserAPI from "../api/user";
import RoleAPI from "../api/role";

export const users = {
    state: {
        users: [],
       
        usersLoadStatus: 0,
        userAddStatus: 0,
        userAddText: "",
        userOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'surname',
            orderDirection:'asc',
            itemsPerPage:15,
            itemAdded:false,
            itemSearched:false
        },

        user: {unread_notifications:[]},
        userLoadStatus: 0,
        userLoginStatus: 0,
        userUpdateStatus: 0,

        userEdit: {
            id: 0,
            lp: 0,
            login: "",
            name: "",
            surname: "",
            mobile:"",
            color:undefined,
            signature:undefined,
            cert:undefined,
            zla_exists:false,
            info:undefined,
            session_length:undefined,
            pwz:undefined,
            type: '',
            role_id: '',
            password: "",
            oneTimePassword: true,
            computed_permissions:[]
        },
        userEditDefault: {
            id: 0,
            lp: 0,
            login: "",
            name: "",
            surname: "",
            mobile:"",
            color:undefined,
            signature:undefined,
            cert:undefined,
            zla_exists:false,
            info:undefined,
            session_length:undefined,
            pwz:undefined,
            type: '',
            role_id: '',
            password: "",
            oneTimePassword: true,
            computed_permissions:[]
        },
        userEditLoadStatus: 0,
        userEditStatus: 0,
        userEditText: "",

        userDeleteStatus: 0,
        userDeleteText: "",

        usersLookup:[],
        usersLookupLoadStatus:0,

        notificationAsReadStatus:0,
    },

    actions: {
        loginUser( { commit, dispatch }, data ){
          commit( 'setUserLoginStatus', 1 );
                UserAPI.getCookie()
                .then( function( response ){
                    UserAPI.loginUser(
                        data.login,
                        data.password,
                        data.code)
                        .then( function( response ){
                            if(response.status===202){//logowwanie ok, pin request
                                commit( 'setUserLoginStatus', 4 );
                            }else if(response.status===204){
                                dispatch('loadUser');
                                commit( 'setUserLoginStatus', 2 );
                            }
                        })
                        .catch( function(error){
                            if(error.response.data.error==='invalid_credentials'){//nieporawidłowy login i hasło
                                commit( 'setUserLoginStatus', 3 );
                            }else if(error.response.data.error==='invalid_token'){
                                commit( 'setUserLoginStatus', 5 );
                            }
                        });
                })     
           
        },
        logoutUser( { commit } ){
                UserAPI.logoutUser()
                .then( function( response ){
                  //commit( 'setUser', {} );
                  commit( 'setUserLoginStatus', 0 );
                  //commit( 'setUserLoadStatus', 0 );
                  //clear other things
                })
        },
        loadEmptyUser({commit}){
          commit( 'setUser', {} );
          commit( 'setUserLoadStatus', 0 );
          commit( 'setUserLoginStatus', 0 );
        },
        setNotificationAsRead({ state, commit, dispatch },data) {
            commit( 'setNotificationAsReadStatus', 1 );
            UserAPI.putNotificationAsRead({
                id:data.id
            })
            .then(function(response) {
                commit( 'setNotificationAsReadStatus', 2 );
                commit("setMarkedNofitication",response.data)
            })
            .catch(function() {
                commit( 'setNotificationAsReadStatus', 3 );
            });
     
        },
        loadUser( { commit,state } ){
          commit( 'setUserLoadStatus', 1 );
          
          UserAPI.getUser()
            .then( function( response ){
              commit( 'setUser', response.data );
              commit( 'setUserLoadStatus', 2 );
            })
            .catch( function(){
              commit( 'setUser', {} );
              commit( 'setUserLoadStatus', 3 );
            })
            .catch(function (err) {
              commit( 'setUser', {} );
              commit( 'setUserLoadStatus', 3 );
          });
        },
        
        //#############################################################################

        loadUsers({ state, commit, dispatch }) {
            commit("setUsersLoadStatus", 1);
                UserAPI.getUsers({
                    offset:0,
                    itemsPerPage:state.userOptions.itemsPerPage, 
                    orderBy:state.userOptions.orderBy,
                    orderDirection:state.userOptions.orderDirection,
                    showDeleted:state.userOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setUserLineCount", response.data.lineCount);
                    commit("setUsers", response.data.users);
                    dispatch("applyIndexOnUsers");
                    commit("setUsersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsers", []);
                    commit("setUsersLoadStatus", 3);
                });
         
        },
        loadMoreUsers({ state, commit, dispatch }) {
            commit("setUsersLoadStatus", 1);
                UserAPI.getUsers({
                    offset:state.users.length,
                    itemsPerPage:state.userOptions.itemsPerPage, 
                    orderBy:state.userOptions.orderBy,
                    orderDirection:state.userOptions.orderDirection,
                    showDeleted:state.userOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setUserLineCount", response.data.lineCount);
                    commit("setUsers", _.concat(state.users, response.data.users));
                    dispatch("applyIndexOnUsers");
                    commit("setUsersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsers", []);
                    commit("setUsersLoadStatus", 3);
                });
         
        },
        searchUsers({ state, commit, dispatch },data) {
            commit("setUsersLoadStatus", 1);
                UserAPI.searchUsers({
                    showDeleted:state.userOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setUserSearchedTrue");
                    commit("setUserLineCount", response.data.lineCount);
                    commit("setUsers", response.data.users);
                    dispatch("applyIndexOnUsers");
                    commit("setUsersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsers", []);
                    commit("setUsersLoadStatus", 3);
                });
         
        },
        setUserOptions({ state, commit, dispatch },data){
            commit("setUserOptions",data.options);
            dispatch("loadUsers");
        },
        applyIndexOnUsers({ commit, state, rootState }) {
            let localUsers = state.users;

            var i = 1;
            localUsers.forEach(user => {
                user.lp = i;
                i++;
            });

            commit("setUsers",localUsers);
        },
        addUser({ commit, state, dispatch }, data) {
            commit("setUserAddStatus", 1);
            commit("setUserAddText", "");

            UserAPI.postAddNewUser(
                data.name,
                data.surname,
                data.mobile,
                data.login,
                data.color,
                data.signature,
                data.zla,
                data.info,
                data.password,
                data.oneTimePassword,
                data.type,
                data.session_length,
                data.pwz,
                data.role_id,
                data.permissions
            )
                .then(function(response) {
                    commit("setUserAddStatus", 2);
                    commit("setAddedUser",response.data);
                    commit("setUserLineCount", state.userOptions.lineCount + 1);
                    dispatch("loadUserLookup");
                    dispatch("applyIndexOnUsers");
                })
                .catch(function(error) {
                    commit("setUserAddStatus", 3);
                    commit("setUserAddText", error.response.data.errors);
                });
        },

        loadUserEdit({ commit }, data) {
            commit("setUserEditLoadStatus", 1);
            commit("setUserEditStatus", 0);
            commit("setUserEditText", "");

            UserAPI.getUserEdit(data.slug)
                .then(function(response) {
                    commit("setUserEdit", response.data);
                    commit("setUserEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUserEdit", {});
                    commit("setUserEditLoadStatus", 3);
                });
        },
        searchUsersLookup({ state, commit, dispatch },data) {
            commit("setUsersLookupLoadStatus", 1);
                UserAPI.searchUsersLookup({
                    q:data.q,
                    type:data.type
                })
                .then(function(response) {
                    commit("setUsersLookup", response.data);
                    commit("setUsersLookupLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsersLookup", []);
                    commit("setUsersLookupLoadStatus", 3);
                });
         
        },
        loadUserEditDefault({ commit }) {
            commit("setUserEditDefault");
        },
        loadUserAddDefault({ commit }) {
            commit("setUserAddStatus", 0);
            commit("setUserAddText", "");
        },
        loadUserEmptyPermissionList({ commit }, data) {
            commit("setUserEditLoadStatus", 1);
            commit("setUserEditStatus", 0);
            commit("setUserEditText", "");

            RoleAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setUserPermissionListEdit", response.data);
                    commit("setUserEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUserEdit", {});
                    commit("setUserEditLoadStatus", 3);
                });
        },
        loadUserPermissionList({ commit }, data) {
            commit("setUserEditLoadStatus", 1);
            commit("setUserEditStatus", 0);
            commit("setUserEditText", "");

            RoleAPI.getPermissionList(data.slug)
                .then(function(response) {
                    commit("setUserPermissionListEdit", response.data);
                    commit("setUserEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUserEdit", {});
                    commit("setUserEditLoadStatus", 3);
                });
        },
        editUser({ commit, state, dispatch }, data) {
            commit("setUserEditStatus", 1);

            UserAPI.putEditUser(
                data.slug,
                data.name,
                data.surname,
                data.mobile,
                data.login,
                data.color,
                data.signature,
                data.zla,
                data.info,
                data.type,
                data.session_length,
                data.pwz,
                data.role_id,
                data.permissions
            )
                .then(function(response) {
           
                    commit("setUserEditStatus", 2);
                    commit("setEditedUser",response.data)
                    dispatch("applyIndexOnUsers");
                    dispatch("loadUserLookup");
                })
                .catch(function(error) {
                    commit("setUserEditStatus", 3);
                    commit("setUserEditText", error.response.data.errors);
                });
        },
        editUserReactivate({ commit, state, dispatch }, data) {
            commit("setUserEditStatus", 1);
  
            UserAPI.putReactivateUser(data.slug)
                .then(function(response) {
                    commit("setUserEditStatus", 2);
                    commit("setEditedUser",response.data);
                    dispatch("applyIndexOnUsers");
                    dispatch("loadUserLookup");
                })
                .catch(function(error) {
                    commit("setUserEditStatus", 3);
                });
        },
        deleteUser({ commit, state, dispatch }, data) {
            commit("setUserDeleteStatus", 1);

            UserAPI.deleteUser(data.slug)
                .then(function(response) {
                    commit("setUserDeleteStatus", 2);
                    if(state.userOptions.showDeleted){
                        commit("setEditedUser",response.data)
                    }else{
                        commit("setUserLineCount", state.userOptions.lineCount - 1);
                        commit("setDeletedUser",response.data) 
                    }
                    dispatch("loadUserLookup");
                    dispatch("applyIndexOnUsers");
                })
                .catch(function() {
                    commit("setUserDeleteStatus", 3);
                });
        },
        forceDeleteUser({ commit, state, dispatch }, data) {
            commit("setUserDeleteStatus", 1);

            UserAPI.forceDeleteUser(data.slug)
                .then(function(response) {
                    commit("setUserDeleteStatus", 2);
                    commit("setUserLineCount", state.userOptions.lineCount - 1);
                    commit("setDeletedUser",response.data)
                    dispatch("applyIndexOnUsers");
                })
                .catch(function() {
                    commit("setUserDeleteStatus", 3);
                });
        },    
        editUserPassword({ commit, state, dispatch }, data) {
          commit("setUserEditStatus", 1);

          UserAPI.putEditUserPassword(
              data.slug,
              data.password,
              data.oneTimePassword,
          )
              .then(function(response) {
                  commit("setUserEditStatus", 2);
                  //dispatch("loadUsers");
              })
              .catch(function(error) {
                  commit("setUserEditStatus", 3);
              });
        },
        editMyPassword({ commit, state, dispatch }, data) {
            commit("setUserEditStatus", 1);
  
            UserAPI.putEditUserPassword(
                data.slug,
                data.password,
                data.oneTimePassword,
            )
                .then(function(response) {
                    commit( 'setUser', response.data );
                    commit("setUserEditStatus", 2);
                })
                .catch(function(error) {
                    commit("setUserEditStatus", 3);
                });
          },
        editUserOneTimePassword({ commit, state, dispatch }, data) {
          commit("setUserEditStatus", 1);

          UserAPI.putEditUserOneTimePassword(
              data.slug,
              data.password,
              data.oldPassword,
          )
              .then(function(response) {
                  commit("setUserEditStatus", 2);
                  dispatch("loadUser");
              })
              .catch(function(error) {
                  commit("setUserEditStatus", 3);
              });
        },
    },
    mutations: {
        setUserLoadStatus( state, status ){
          state.userLoadStatus = status;
        },
        setUserLoginStatus( state, status ){
          state.userLoginStatus = status;
        },
        setUser( state, user ){
            if(user){
                state.user = user;
            }else{
                state.user = {};
            }
        },
        setUserUpdateStatus( state, status ){
          state.userUpdateStatus = status;
        },

        setUserOptions(state,options){
            state.userOptions = options;
        },
        setUserPermissionListEdit(state, list) {
            state.userEdit.computed_permissions = list;
        },
        setNotificationAsReadStatus(state,status){
            state.notificationAsReadStatus = status;
        },
        //##############################################
        
        setUsersLoadStatus(state, status) {
            state.usersLoadStatus = status;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setUserLineCount(state, lineCount) {
            state.userOptions.lineCount = lineCount;
        },
        setUsersLookup(state, users) {
            state.usersLookup = users;
        },
        setUsersLookupLoadStatus(state, status) {
            state.usersLookupLoadStatus = status;
        },
        setUserAddStatus(state, status) {
            state.userAddStatus = status;
        },
        setUserAddText(state, text) {
            state.userAddText = text;
        },
        setUserEdit(state, user) {
            state.userEdit = user;
        },
        setUserEditSignature(state, data) {
            state.userEdit.signature = data;
        },
        setUserEditZLA(state, data) {
            state.userEdit.cert = data;
        },
        setEditedUser(state, user) {
            var lp = _.find(state.users,{'id':user.id}).lp;
            var index = _.findIndex(state.users,{'id':user.id});
            state.users[index] = user;
            state.users[index].lp = lp;
        },
        setAddedUser(state, user) {
            state.userOptions.itemAdded = true;
            state.users.push(user);
        },
        setUserSearchedTrue(state) {
            state.userOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.userOptions.itemAdded = false;
            state.userOptions.itemSearched = false;
        },
        setDeletedUser(state, user) {
            var index = _.findIndex(state.users,{'id':user.id});
            state.users.splice(index,1);
        },
        setMarkedNofitication(state, notification) {
            var index = _.findIndex(state.user.unread_notifications,{'id':notification.id});
            state.user.unread_notifications.splice(index,1);
        },
        setUserEditDefault(state) {
            state.userEdit = { ...state.userEditDefault };
        },
        setUserEditStatus(state, status) {
            state.userEditStatus = status;
        },
        setUserEditText(state, text) {
            state.userEditText = text;
        },
        setUserEditLoadStatus(state, status) {
            state.userEditLoadStatus = status;
        },
        setUserDeleteStatus(state, status) {
            state.userDeleteStatus = status;
        },
        setUserDeletedText(state, text) {
            state.userDeleteText = text;
        }
    },

    getters: {
        getUserLoadStatus( state ){
          return function(){
            return state.userLoadStatus;
          }
        },
        getUserLoginStatus( state ){
            return state.userLoginStatus;
        },
        getUser( state ){
          return state.user;
        },
        getUserOneTime( state ){
            return function(){
                return state.user.oneTimePassword;
            }  
        },
        getUserUpdateStatus( state, status ){
          return state.userUpdateStatus;
        },
        getNotificationAsReadStatus( state, status ){
            return function(){
                return state.notificationAsReadStatus;
            }
          },
        //#################################################

        getUserOptions(state){
            return state.userOptions;
        },
        getUsersLoadStatus(state) {
            return state.usersLoadStatus;
        },
        getUsers(state) {
            return state.users;
        },
        getUserAddStatus(state) {
            return function(){
                return state.userAddStatus;
            } 
        },
        getUserAddText(state) {
            return state.userAddText;
        },
        getUserEdit(state) {
            return state.userEdit;
        },
        getUserEditStatus(state) {
          return function(){
            return state.userEditStatus;
          }
        },
        getUserEditing(state) {
            return state.userEdit.id > 0;
        },
        getUserEditText(state) {
            return state.userEditText;
        },
        getUserEditLoadStatus(state) {
            return state.userEditLoadStatus;
            
        },
        getUserDeleteStatus(state) {
            return function(){
                return state.userDeleteStatus;
            }
        },
        getUserDeleteText(state) {
            return state.userDeleteText;
        },
        getUsersLookupLoadStatus(state) {
            return state.usersLookupLoadStatus;
        },
        getUsersLookup(state) {
            return state.usersLookup;
        },
    }
};
