import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/clinics
    */
    getClinics: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/clinics',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/clinics/search
    */
   searchClinics: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/clinics/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

/*
        GET     /api/clinics/searchLookup
    */
   searchClinicsLookup: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/clinics/searchLookup',{
        params:{
            q:options.q,
    }});
},

/*
        GET     /api/clinics/searchByServiceLookup
    */
        searchClinicsByServiceLookup: function(options){
            return axios.get( HGO_CONFIG.API_URL + '/clinics/searchByServiceLookup',{
                params:{
                    service_id:options.service_id,
            }});
        },
        

    /*
		POST 	/api/clinics
	*/
    postAddNewClinic: function( name,type,post_code,city,address,house,phone,email,logo,nip,regon,nfz,erecepta_oid,erecepta_rpwdl,referrals_email,contacts){
        return axios.post( HGO_CONFIG.API_URL + '/clinics',
            {
                name: name,
                type:type,
                post_code:post_code,
                city:city,
                address:address,
                house:house,
                phone:phone,
                email:email,
                logo:logo,
                nip:nip,
                regon:regon,
                nfz:nfz,
                erecepta_oid:erecepta_oid,
                erecepta_rpwdl:erecepta_rpwdl,
                referrals_email:referrals_email,
                contacts:contacts
            }
        );
    },

    /*
		GET 	/api/clinics/{slug}/edit
	*/
    getClinicEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/clinics/' + slug + '/edit' );
	},


    /*
		PUT 	/api/clinics/{slug}
	*/
    putEditClinic: function(slug, name,type,post_code,city,address,house,phone,email,logo,nip,regon,nfz,erecepta_oid,erecepta_rpwdl,referrals_email,contacts){
        return axios.put( HGO_CONFIG.API_URL + '/clinics/'+ slug,
            {
                name: name,
                type:type,
                post_code:post_code,
                city:city,
                address:address,
                house:house,
                phone:phone,
                email:email,
                logo:logo,
                nip:nip,
                regon:regon,
                nfz:nfz,
                erecepta_oid:erecepta_oid,
                erecepta_rpwdl:erecepta_rpwdl,
                referrals_email:referrals_email,
                contacts:contacts
            }
        );
    },

     /*
		GET 	/api/clinics/reactivate/{slug}
	*/
    putReactivateClinic: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/clinics/reactivate/' + slug);
    },

    /*
		DELETE /api/clinics/{slug}
	*/
    deleteClinic: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/clinics/' + slug );
    },
    
    /*
		DELETE /api/clinics/{slug}/force
	*/
    forceDeleteClinic: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/clinics/' + slug + '/force' );
	}
}