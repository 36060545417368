<template>
      <v-col>
          <v-card style="background-color:#F5F5F5;" :loading="userAddStatus===1 || userEditLoadStatus===1 || userEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Anuluj</v-btn>
              <v-btn color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ formTitle }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row>
            <v-col class="col-6 pl-5">
                <v-row v-if="userEditStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(userEditText)" :key="err.index" dense outlined type="error">
                                          {{err}}
                                        </v-alert>    
                                    </v-col>
                </v-row>   
                 <v-row v-if="userAddStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(userAddText)" :key="err.index" dense outlined type="error">
                                          {{err}}
                                        </v-alert>    
                                    </v-col>
                 </v-row>      
                <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Dane podstawowe</v-toolbar-title>
                    <v-spacer />
                      <v-chip label color="recepta">Dane wymagane do e-recepty</v-chip>
                    </v-toolbar>
                  <v-card-text  class="pt-0">
                          <v-row>    
                            <v-col dense cols="6">
                                <v-text-field 
                                 v-model="editedItem.login" 
                                 :error-messages="loginErrors"
                                 required 
                                 append-icon="mdi-face"
                                 @click:append="generateLogin"
                                 @input="$v.editedItem.login.$touch()"  
                                 @blur="$v.editedItem.login.$touch()" 
                                 label="Login"></v-text-field>                            
                            </v-col>
                            <v-col dense cols="6">
                                  <v-select background-color="recepta" v-model="editedItem.type" :items="lookups.user_types" :error-messages="typeErrors" required @input="$v.editedItem.type.$touch()"  @blur="$v.editedItem.type.$touch()" label="Stanowisko"></v-select>
                            </v-col>
                            
                            <v-col dense cols="6">
                              <v-text-field background-color="recepta" v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Imię"></v-text-field>                            
                            </v-col>
                            <v-col dense cols="6">
                                <v-text-field background-color="recepta" v-model="editedItem.surname" :error-messages="surnameErrors" required @input="$v.editedItem.surname.$touch()"  @blur="$v.editedItem.surname.$touch()"  label="Nazwisko"></v-text-field>                            
                            </v-col>

                                <v-col dense cols="6">
                                  <v-text-field v-model="editedItem.mobile"  label="Telefon"></v-text-field>                            
                                </v-col>

                                

                                <v-col dense cols="6">
                                  <v-select @change="roleChanged" v-model="editedItem.role_id" :items="lookups.roles" :error-messages="roleErrors" required @input="$v.editedItem.role_id.$touch()"  @blur="$v.editedItem.role_id.$touch()" label="Rola"></v-select>
                                </v-col>

                                <v-col dense v-if="!itemEditing" cols="5">
                                  <v-text-field 
                                    v-if="!itemEditing"
                                    append-icon="mdi-refresh"
                                    @click:append="generatePassword"
                                    v-model="editedItem.password" 
                                    :error-messages="passwordErrors" 
                                    required 
                                    @input="$v.editedItem.password.$touch()"  
                                    @blur="$v.editedItem.password.$touch()"  
                                    label="Hasło"></v-text-field>                            
                                </v-col>

                                <v-col dense v-if="!itemEditing" cols="7">
                                  <v-checkbox
                                    v-model="editedItem.oneTimePassword"
                                    label="Wymagana zmiana hasła przy pierwszym logowaniu"
                                  ></v-checkbox> 
                                </v-col>

                                <v-col cols="4">
                                  <v-menu
                                      v-model="menuColor"
                                      :close-on-content-click="false"
                                      :close-on-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="editedItem.color"
                                          label="Kolor"
                                          readonly
                                          v-on="on"
                                          clearable
                                        >
                                        <v-icon slot="prepend" :color="editedItem.color">
                                            mdi-checkbox-blank
                                        </v-icon>
                                        </v-text-field>
                                      </template>
                                      <v-card>
                                        <v-color-picker hide-inputs v-model="selectedColor"></v-color-picker>
                                        <v-card-actions>
                                          <v-spacer></v-spacer>

                                          <v-btn text @click="selectedColor = editedItem.color;menuColor = false">Anuluj</v-btn>
                                          <v-btn color="secondary" @click="editedItem.color = selectedColor['hex'];menuColor = false" text>Wybierz</v-btn>
                                        </v-card-actions>
                                      </v-card>
                                      
                                    </v-menu>    
                                </v-col>

                                <v-col dense cols="4">
                                    <v-text-field v-model="editedItem.session_length" :error-messages="session_lengthErrors" required @input="$v.editedItem.session_length.$touch()"  @blur="$v.editedItem.session_length.$touch()"  label="Ważność sesji (w minutach)"></v-text-field>                            
                                </v-col>
                                <v-col dense cols="4">
                                    <v-text-field background-color="recepta" v-model="editedItem.pwz" :error-messages="pwzErrors" required @input="$v.editedItem.pwz.$touch()"  @blur="$v.editedItem.pwz.$touch()"  label="Prawo wykonywania zawodu"></v-text-field>                            
                                </v-col>
                          </v-row>
                  </v-card-text>
                </v-card> 
                <v-card class="mt-5">
                  <v-toolbar dense flat>
                    <v-toolbar-title>Skan podpisu</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="editedItem.signature" @click="signature = null" x-small color="secondary">Usuń</v-btn>  
                  </v-toolbar>
                  <v-card-text class="pt-0">
                          <v-row>    
                                <v-col dense cols="12">
                                  <v-img v-if="editedItem.signature" :src="editedItem.signature" contain height="80" />
                                  <file-drop v-else :fileContent.sync="signature" :fileAccept="accept" :height="200" />
                                </v-col>

                          </v-row>
                  </v-card-text>
                </v-card>   
                <v-card class="mt-5">
                  <v-toolbar dense flat>
                    <v-toolbar-title>Stopka maila</v-toolbar-title>
                    <v-spacer></v-spacer><v-switch v-model="infoRaw" label="HTML" class="mt-5"></v-switch>
                  </v-toolbar>
                  <v-card-text class="pt-0">
                          <v-row>    
                                <v-col dense cols="12">

                                  <tiptap-vuetify 
                                    v-if="!infoRaw"
                                    v-model="editedItem.info" :extensions="extensions" 
                                    :card-props="{ outlined: true }"
                                    min-height="200"
                                    />
                                  <v-textarea v-else hide-details solo v-model="editedItem.info" rows="6" />
                                </v-col>
                          </v-row>
                  </v-card-text>
                </v-card>        
            </v-col>
            <v-col class="col-6 pl-0 pr-5">
               <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Certyfikat e-ZLA</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="editedItem.zla_exists || cert" @click="cert = null;editedItem.zla_exists = false" x-small color="secondary">Usuń</v-btn>  
                  </v-toolbar>
                  <v-card-text class="pt-0">
                          <v-row>    
                                <v-col dense cols="12">
                                  <v-icon size="45" v-if="editedItem.zla_exists || cert">mdi-certificate</v-icon>
                                  <file-drop v-else :fileContent.sync="cert" :fileAccept="acceptZLA" :height="200" />
                                </v-col>

                          </v-row>
                  </v-card-text>
                </v-card>   
                <v-card class="mt-5">
                  <v-toolbar dense flat>
                    <v-toolbar-title>Uprawnienia</v-toolbar-title>
                    <v-spacer />
                    <v-icon class="mr-1" color="secondary" dense>mdi-checkbox-blank-circle-outline</v-icon><span class="text-caption">uprawnienia wynikające z roli</span>
                  </v-toolbar>
                  <v-card-text  class="pt-0">
                         <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  Moduł
                                </th>
                                <th class="text-left">
                                  Brak
                                </th>
                                <th class="text-left">
                                  Swoje
                                </th>
                                <th class="text-left">
                                  Pełne
                                </th>
                                <th class="text-left">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in editedItem.computed_permissions"
                                :key="item.id"
                              >
                                <td>{{ item.label }}</td>
                                <td>
                                  <v-radio-group hide-details dense  class="mt-0" v-model="item.user">
                                    <v-radio
                                      v-if="item.types.includes(0)"
                                      color="secondary"
                                      :value="0"
                                      :class="item.role==0?'role_selected':''"
                                    ></v-radio>
                                  </v-radio-group>
                                </td>
                                <td>
                                  <v-radio-group hide-details dense  class="mt-0" v-model="item.user">
                                    <v-radio
                                      v-if="item.types.includes(5)"
                                      color="secondary"
                                      :value="5"
                                      :class="item.role==5?'role_selected':''"
                                    ></v-radio>
                                  </v-radio-group>
                                </td>
                                <td>
                                  <v-radio-group hide-details dense class="mt-0" v-model="item.user">
                                    <v-radio
                                      v-if="item.types.includes(10)"
                                      color="secondary"
                                      :value="10"
                                      :class="item.role==10?'role_selected':''"
                                    ></v-radio>
                                  </v-radio-group>
                                </td>
                                <td>
                                    <v-icon @click="item.user = undefined" v-if="item.hasOwnProperty('user') && item.user>=0">mdi-close</v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                  </v-card-text>
                </v-card>      
            </v-col>
          </v-row>

        </v-card>
      </v-col>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'
  import passwordComplexity from '../plugins/passwordComplexity'
  import {Helper} from '../mixins/helper'
  import FileDrop from '../components/FileDrop.vue'
  import {
      // component
      TiptapVuetify,
      // extensions
      Heading,
      Bold,
      Italic,
      Strike,
      Underline,
      Code,
      Paragraph,
      BulletList,
      OrderedList,
      ListItem,
      Link,
      Blockquote,
      HardBreak,
      HorizontalRule,
      History,
      Image,
      Table,TableRow,TableCell,TableHeader
    } from "tiptap-vuetify";

  export default {
    components: { FileDrop,TiptapVuetify },
    mixins:[
      Helper
    ],
    data: () => ({
      menuColor:false,
      selectedColor:undefined,
      infoRaw:false,
      accept:"image/png, image/jpeg, image/bmp",
      acceptZLA:"application/x-pkcs12,application/x-pkcs12",
      extensions: [
        //History,
        Bold,
        Underline,
        //Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        
        //HorizontalRule,
        Paragraph,
        Link,
        Image,
        Table,TableRow,TableCell,TableHeader,
        HardBreak
      ],
    }),
    validations() {
      if(this.itemEditing){
        return {
        editedItem:{
          name: {
            required,
          },
          surname: {
            required,
          },
          login:{
            required
          },
          type:{
            required
          },
          role_id:{
            required
          },
          pwz:{
            numeric
          },
          session_length:{
            numeric
          }
          // mobile:{
          //   required,
          //   numeric,
          //   minLength:minLength(9),
          //   maxLength:maxLength(9)
          // },
        }}
      }else{
        return {
        editedItem:{
          name: {
            required,
          },
          surname: {
            required,
          },
          login:{
            required
          },
          type:{
            required
          },
          role_id:{
            required
          },
          session_length:{
            numeric
          },
          pwz:{
            numeric
          },
          // mobile:{
          //   required,
          //   numeric,
          //   minLength:minLength(9),
          //   maxLength:maxLength(9)
          // },
          password:{
            required,
            minLength: minLength(8),
            passwordComplexity
          },
        }}
      }
      
    },
    watch: {
      userEditStatus(val){
        if(val===2){
          this.close();
        }
      },
      userAddStatus(val){
        if(val===2){
          this.close();
        }
      },

    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params);
      },
      editedItem(){
        return this.$store.getters.getUserEdit;
      },
      userEditLoadStatus(){
        return this.$store.getters.getUserEditLoadStatus
      },
      userEditStatus(){
        return this.$store.getters.getUserEditStatus();
      },
      userEditText(){
        return this.$store.getters.getUserEditText
      },
      userAddStatus(){
        return this.$store.getters.getUserAddStatus();
      },
      userAddText(){
        return this.$store.getters.getUserAddText;
      },
      signature:{
        get(){
          return this.editedItem.signature;
        },
        set(value){
          this.$store.commit('setUserEditSignature',value);
        }
      },
      cert:{
        get(){
          return this.editedItem.cert;
        },
        set(value){
          this.$store.commit('setUserEditZLA',value);
        }
      },
      formTitle () {
        return !this.itemEditing ? 'Nowy użytkownik' : 'Edycja użytkownika'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Imię jest wymagane')
        return errors
      },
      surnameErrors () {
        const errors = []
        if (!this.$v.editedItem.surname.$dirty) return errors
        !this.$v.editedItem.surname.required && errors.push('Nazwisko jest wymagane')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (this.itemEditing || !this.$v.editedItem.password.$dirty) return errors
        !this.itemEditing && !this.$v.editedItem.password.required && errors.push('Hasło jest wymagane')
        !this.itemEditing && !this.$v.editedItem.password.minLength && errors.push('Hasło jest za krótkie')
        !this.itemEditing && !this.$v.editedItem.password.passwordComplexity && errors.push('Hasło jest za proste')
        return errors
      },
      mobileErrors () {
        const errors = []
        if (!this.$v.editedItem.mobile.$dirty) return errors
        !this.$v.editedItem.mobile.required && errors.push('Telefon jest wymagany')
        !this.$v.editedItem.mobile.numeric && errors.push('Telefon może zawierać tylko cyfry')
        !this.$v.editedItem.mobile.minLength && errors.push('Telefon musi mieć 9 cyfr')
        !this.$v.editedItem.mobile.maxLength && errors.push('Telefon musi mieć 9 cyfr')
        return errors
      },
      loginErrors () {
        const errors = []
        if (!this.$v.editedItem.login.$dirty) return errors
        !this.$v.editedItem.login.required && errors.push('Login jest wymagany')
        return errors
      },
      typeErrors () {
        const errors = []
        if (!this.$v.editedItem.type.$dirty) return errors
        !this.$v.editedItem.type.required && errors.push('Stanowisko jest wymagane')
        return errors
      },
      roleErrors () {
        const errors = []
        if (!this.$v.editedItem.role_id.$dirty) return errors
        !this.$v.editedItem.role_id.required && errors.push('Rola jest wymagana')
        return errors
      },
      session_lengthErrors () {
        const errors = []
        if (!this.$v.editedItem.session_length.$dirty) return errors
        !this.$v.editedItem.session_length.numeric && errors.push('Ważność sesji musi być liczbą')
        return errors
      },
      pwzErrors () {
        const errors = []
        if (!this.$v.editedItem.pwz.$dirty) return errors
        !this.$v.editedItem.pwz.numeric && errors.push('PWZ musi być liczbą')
        return errors
      },
    },
    mounted(){
      if(this.lookups.roles.length>0){
        this.loadEditItem();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadEditItem();
        }, 2000)
      }
    },
    methods: {
      roleChanged(){
        this.$store.dispatch( 'loadUserPermissionList',{slug:this.editedItem.role_id} );
      },
      loadEditItem(){
        if(this.itemEditing){
          this.$store.dispatch( 'loadUserEdit', {slug:  this.$route.params.id} );
        }else{
          this.editedItem.password = this.randPasswordString();
          this.$store.dispatch( 'loadUserEmptyPermissionList' );
        }
      },
      errorMessageArray(val) {
        const errors = val;
        var err = [];
        if(!errors)return [];

        if(errors.login){
          errors.login.forEach(e => {
            err.push(e);
          });
        }

        if(errors.name){
          errors.name.forEach(e => {
            err.push(e);
          });
        }
      
        return err;
      },    
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadUserEditDefault'); 
        this.$store.dispatch( 'loadUserAddDefault');
        this.$router.push({ name: 'ustawienia.uzytkownicy.powrot'})
      },
      generateLogin(){
        if(this.editedItem.name && this.editedItem.surname){
          this.editedItem.login = _.toLower(this.noPL(this.editedItem.name)).substring(0,1)
           + '.' + _.toLower(_.replace(this.noPL(this.editedItem.surname)))
        }
      },
      generatePassword(){
        this.editedItem.password = this.randPasswordString();
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
           if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editUser', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                surname:  this.editedItem.surname,
                mobile:  this.editedItem.mobile,
                login: this.editedItem.login,
                color:  this.editedItem.color,
                signature: this.editedItem.signature,
                zla: this.editedItem.cert,
                info: this.editedItem.info,
                type: this.editedItem.type,
                session_length: this.editedItem.session_length,
                pwz:this.editedItem.pwz,
                role_id: this.editedItem.role_id,
                permissions: this.editedItem.computed_permissions
              });
            } else {//new item

              this.$store.dispatch( 'addUser', {
                  name:  this.editedItem.name,
                  surname:  this.editedItem.surname,
                  mobile:  this.editedItem.mobile,
                  login: this.editedItem.login,
                  color:  this.editedItem.color,
                  signature: this.editedItem.signature,
                  zla: this.editedItem.cert,
                  info: this.editedItem.info,
                  password:  this.editedItem.password,
                  oneTimePassword: this.editedItem.oneTimePassword,
                  type: this.editedItem.type,
                  session_length: this.editedItem.session_length,
                  pwz:this.editedItem.pwz,
                  role_id: this.editedItem.role_id,
                  permissions: this.editedItem.computed_permissions
              });

            }
        }
      },
    },
  }
</script>
<style scoped>
.role_selected /deep/ i {
    color:#cc0839;
}
</style>

