<template>
    <v-container class="d-flex flex-column pa-0 ml-3">

        <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'dzialaniePacjenta' }" color="secondary" icon><v-icon size="35">mdi-calendar-edit</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Umów</span>
        </v-container>

        <v-dialog v-if="user.permissions.clinic >= 5" v-model="taskDialog" max-width="700px">
                    <template v-slot:activator="{ on }">
                      <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
                        <v-btn v-on="on" color="green darken-3" icon><v-icon size="35">mdi-clipboard-edit-outline</v-icon></v-btn>
                        <span class="text-caption font-weight-medium" style="font-size:0.5em;">Zadanie</span>
                      </v-container>
                    </template>
                    <task-editor :item-editing="false" @close="closeTask"></task-editor>
        </v-dialog>

        <v-dialog v-if="user.permissions.clinic >= 5" v-model="filesDialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                      <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
                        <v-btn v-on="on" color="blue darken-3" icon><v-icon size="35">mdi-file-document-multiple-outline</v-icon></v-btn>
                        <span class="text-caption font-weight-medium" style="font-size:0.5em;">Pliki</span>
                      </v-container>
                    </template>
                    <files-editor @close="closeFiles" :patient-id="patient.id" ></files-editor>
        </v-dialog>

        <!-- <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn  color="blue darken-3" icon><v-icon size="35">mdi-file-document-multiple-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Wyniki</span>
        </v-container> -->

        <!-- <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn  color="orange darken-3" icon><v-icon size="35">mdi-file-table-box-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Skierowanie</span>
        </v-container>

        <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn  color="purple darken-3" icon><v-icon size="35">mdi-alpha-r-circle-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Recepta</span>
        </v-container> -->

        <!-- <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn  color="grey darken-3" icon><v-icon size="35">mdi-folder-multiple-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Skany</span>
        </v-container> -->
        <!-- <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'dzialanie' }" color="secondary" icon><v-icon size="35">mdi-calendar-edit</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Dzialanie</span>
        </v-container> -->

        <!-- <v-dialog v-if="user.permissions['patient'] >= 10" v-model="patientDialog" :max-width="patientEditing?'1000px':'500px'">
                    <template v-slot:activator="{ on }">
                      <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
                        <v-btn v-on="on" color="secondary" icon><v-icon size="35">mdi-calendar-edit</v-icon></v-btn>
                        <span class="text-caption font-weight-medium" style="font-size:0.5em;">Pacjent</span>
                      </v-container>
                    </template>
                    <patient-editor :item-editing="false" @close="closePatiente"></patient-editor>
        </v-dialog> -->

    </v-container>
</template>

<script>
import PatientEditor from '../components/PatientEditor'
import TaskEditor from '../components/TaskEditor'
import FilesEditor from '../components/FilesEditor.vue'
export default {
    components:{
      PatientEditor,
      TaskEditor,
      FilesEditor
    },
    data(){
        return{
            defaultColor:'secondary', 
            selectedColor:'primary',
            patientDialog:false,
            taskDialog:false,
            filesDialog:false
        }
    },
    mounted(){

    },
    watch:{
      patientDialog (val) {
        val || this.closePatiente()
      },
      taskDialog (val) {
        if(val){
          this.task.patient_id = this.patient.id;
          this.task.patient = {...this.patient};
        }else {
          this.closeTask()
        }
      },
      patientAddStatus(val){
        if(val===2){
          //this.closePatiente();
        }
      },
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
      patient(){
        return this.$store.getters.getPatientEdit;
      },
      task(){
        return this.$store.getters.getTaskEdit;
      },
      patientAddStatus(){
        return this.$store.getters.getPatientAddStatus();
      },
      patientEditing(){
        return this.$store.getters.getPatientEditing;
      },
    },
    methods:{
      closePatiente () {
        this.patientDialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadPatientEditDefault'); 
          this.$store.dispatch( 'loadPatientAddDefault');
          this.$store.commit('setPatientsRecommendationLookup',[]);
        }, 300)
      },
      closeTask () {
        this.taskDialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadTaskEditDefault'); 
          this.$store.dispatch( 'loadTaskAddDefault');
        }, 300)
      },
      closeFiles () {
        this.filesDialog = false
      },
    }
}
</script>

<style scoped>
.menu{
  margin-bottom: 1em;
  padding-top:0px;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}
</style>