import ClinicServiceAPI from "../api/clinicService";
import i18n from "../main";

export const clinicServices = {
    state: {
        clinicServices: [],
        clinicServiceOptions:{
            showDeleted:false,
            clinicId:undefined,
            itemAdded:false,
            itemSearched:false,
        },
        clinicServicesLoadStatus: 0,
        clinicServiceAddStatus: 0,
        clinicServiceAddText: "",

        clinicServiceEdit: {
            id: null,
            lp: null,
            service_id:null,
            service_name:null,
            service_code:null,
            service_category_id:null,
            price:[],
            cost:[],
            referral_type_id:null,
            patient_info:null,
            interval:null
        },
        clinicServiceEditDefault: {
            id: null,
            lp: null,
            service_id:null,
            service_name:null,
            service_code:null,
            service_category_id:null,
            price:[],
            cost:[],
            referral_type_id:null,
            patient_info:null,
            interval:null
        },
        clinicServiceEditLoadStatus: 0,
        clinicServiceEditStatus: 0,
        clinicServiceEditText: "",

        clinicServiceDeleteStatus: 0,
        clinicServiceDeleteText: "",

    },

    actions: {
        loadClinicServices({ state, commit, dispatch }) {
            commit("setClinicServicesLoadStatus", 1);
                ClinicServiceAPI.getClinicServices({
                    offset:0,
                    clinicId:state.clinicServiceOptions.clinicId,
                    itemsPerPage:state.clinicServiceOptions.itemsPerPage, 
                    orderBy:state.clinicServiceOptions.orderBy,
                    orderDirection:state.clinicServiceOptions.orderDirection,
                    showDeleted:state.clinicServiceOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setClinicServices", response.data);
                    dispatch("applyIndexOnClinicServices");
                    commit("setClinicServicesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinicServices", []);
                    commit("setClinicServicesLoadStatus", 3);
                });
         
        },
        setClinicServiceOptions({ state, commit, dispatch },data){
            commit("setClinicServiceOptions",data.options);
            dispatch("loadClinicServices",data.slug);
        },
        applyIndexOnClinicServices({ commit, state, dispatch }) {
            let localClinicServices = state.clinicServices;

            var i = 1;
            localClinicServices.forEach(clinicService => {
                clinicService.lp = i;
                i++;
            });

            commit("setClinicServices",localClinicServices);
        },
        addClinicService({ commit, state, dispatch }, data) {
            commit("setClinicServiceAddStatus", 1);
            commit("setClinicServiceAddText", "");

            ClinicServiceAPI.postAddNewClinicService(
                state.clinicServiceOptions.clinicId,
                data.service_id,
                data.service_name,
                data.service_code,
                data.service_category_id,
                data.price,
                data.cost,
                data.referral_type_id,
                data.patient_info,
                data.interval,
            )
                .then(function(response) {
                    commit("setClinicServiceAddStatus", 2);
                    commit("setAddedClinicService",response.data);
                    commit("setClinicServiceLineCount", state.clinicServiceOptions.lineCount + 1);
                    dispatch("applyIndexOnClinicServices");
                })
                .catch(function(error) {
                    commit("setClinicServiceAddStatus", 3);
                    commit("setClinicServiceAddText", error.response.data.errors);
                });
        },

        loadClinicServiceEdit({ commit, rootState }, data) {
            commit("setClinicServiceEditLoadStatus", 1);
            commit("setClinicServiceEditStatus", 0);
            commit("setClinicServiceEditText", "");
            

            ClinicServiceAPI.getClinicServiceEdit(data.slug)
                .then(function(response) {
                    commit("setClinicServiceEdit", {data:response.data,currencies:rootState.lookups.currencies});
                    commit("setClinicServiceEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setClinicServiceEdit", {});
                    commit("setClinicServiceEditLoadStatus", 3);
                });
        },
        loadClinicServiceCurrencies({ commit, rootState, state }) {
            commit("setClinicServiceEdit", {data:state.clinicServiceEditDefault,currencies:rootState.lookups.currencies});
        },
        loadClinicServiceEditDefault({ commit }) {
            commit("setClinicServiceEditDefault");
        },
        loadClinicServiceAddDefault({ commit }) {
            commit("setClinicServiceAddStatus", 0);
            commit("setClinicServiceAddText", "");
        },
        editClinicService({ commit, state, dispatch }, data) {
            commit("setClinicServiceEditStatus", 1);

            ClinicServiceAPI.putEditClinicService(
                data.slug,
                data.price,
                data.cost,
                data.referral_type_id,
                data.patient_info,
                data.interval
            )
                .then(function(response) {
           
                    commit("setClinicServiceEditStatus", 2);
                    commit("setEditedClinicService",response.data)
                    dispatch("applyIndexOnClinicServices");
                })
                .catch(function(error) {
                    commit("setClinicServiceEditStatus", 3);
                    commit("setClinicServiceEditText", error.response.data.errors);
                });
        },
        editClinicServiceReactivate({ commit, state, dispatch }, data) {
            commit("setClinicServiceEditStatus", 1);
  
            ClinicServiceAPI.putReactivateClinicService(data.slug)
                .then(function(response) {
                    commit("setClinicServiceEditStatus", 2);
                    commit("setEditedClinicService",response.data)
                    dispatch("applyIndexOnClinicServices");
                })
                .catch(function(error) {
                    commit("setClinicServiceEditStatus", 3);
                });
        },
        deleteClinicService({ commit, state, dispatch }, data) {
            commit("setClinicServiceDeleteStatus", 1);

            ClinicServiceAPI.deleteClinicService(data.slug)
                .then(function(response) {
                    commit("setClinicServiceDeleteStatus", 2);
                    if(state.clinicServiceOptions.showDeleted){
                        commit("setEditedClinicService",response.data)
                    }else{
                        commit("setClinicServiceLineCount", state.clinicServiceOptions.lineCount - 1);
                        commit("setDeletedClinicService",response.data)
                    }
                    dispatch("applyIndexOnClinicServices");
                })
                .catch(function() {
                    commit("setClinicServiceDeleteStatus", 3);
                });
        },
        forceDeleteClinicService({ commit, state, dispatch }, data) {
            commit("setClinicServiceDeleteStatus", 1);

            ClinicServiceAPI.forceDeleteClinicService(data.slug)
                .then(function(response) {
                    commit("setClinicServiceDeleteStatus", 2);
                    commit("setClinicServiceLineCount", state.clinicServiceOptions.lineCount - 1);
                    commit("setDeletedClinicService",response.data)
                    dispatch("applyIndexOnClinicServices");
                })
                .catch(function() {
                    commit("setClinicServiceDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setClinicServiceOptions(state,options){
            state.clinicServiceOptions = options;
        },
        setClinicServicesLoadStatus(state, status) {
            state.clinicServicesLoadStatus = status;
        },
        setClinicServices(state, clinicServices) {
            state.clinicServices = clinicServices;
        },
        setClinicServiceLineCount(state, lineCount) {
            state.clinicServiceOptions.lineCount = lineCount;
        },
        setClinicServiceAddStatus(state, status) {
            state.clinicServiceAddStatus = status;
        },
        setClinicServiceAddText(state, text) {
            state.clinicServiceAddText = text;
        },
        setClinicServiceEdit(state, clinicService) {
            var local = JSON.parse(JSON.stringify(clinicService.data));//clinicService.data;
            if(!local.price) local.price = [];
            if(!local.cost) local.cost = [];
            clinicService.currencies.forEach(c => {
                 var val = _.find(local.price,{'currency_id':c.value});
                 if(!val)local.price.push({'currency_id':c.value,'amount':null})
                 //else val.amount = i18n.n(val.amount, 'number', 'pl-PL')
            
                 val = _.find(local.cost,{'currency_id':c.value});
                 if(!val)local.cost.push({'currency_id':c.value,'amount':null})
                 //else val.amount = i18n.n(val.amount, 'number', 'pl-PL')
            });
            local.price = _.sortBy(local.price,['currency_id']);
            local.cost = _.sortBy(local.cost,['currency_id']);
            state.clinicServiceEdit = local;
        },
        setEditedClinicService(state, clinicService) {
            var lp = _.find(state.clinicServices,{'id':clinicService.id}).lp;
            var index = _.findIndex(state.clinicServices,{'id':clinicService.id});
       
            state.clinicServices[index] = clinicService;
            state.clinicServices[index].lp = lp;
        },
        setAddedClinicService(state, clinicService) {
            state.clinicServiceOptions.itemAdded = true;
            state.clinicServices.push(clinicService);
        },
        setClinicServiceSearchedTrue(state) {
            state.clinicServiceOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.clinicServiceOptions.itemAdded = false;
            state.clinicServiceOptions.itemSearched = false;
        },
        setDeletedClinicService(state, clinicService) {
            var index = _.findIndex(state.clinicServices,{'id':clinicService.id});
            state.clinicServices.splice(index,1);
        },
        setClinicServiceEditDefault(state) {
            state.clinicServiceEdit = JSON.parse(JSON.stringify(state.clinicServiceEditDefault));//{ ...state.clinicServiceEditDefault };
        },
        setClinicServiceEditStatus(state, status) {
            state.clinicServiceEditStatus = status;
        },
        setClinicServiceEditText(state, text) {
            state.clinicServiceEditText = text;
        },
        setClinicServiceEditLoadStatus(state, status) {
            state.clinicServiceEditLoadStatus = status;
        },
        setClinicServiceDeleteStatus(state, status) {
            state.clinicServiceDeleteStatus = status;
        },
        setClinicServiceDeletedText(state, text) {
            state.clinicServiceDeleteText = text;
        }
    },

    getters: {
        getClinicServiceOptions(state){
            return state.clinicServiceOptions;
        },
        getClinicServicesLoadStatus(state) {
            return state.clinicServicesLoadStatus;
        },
        getClinicServices(state) {
            return state.clinicServices;
        },
        getClinicServiceAddStatus(state) {
            return function(){
                return state.clinicServiceAddStatus;
            } 
        },
        getClinicServiceAddText(state) {
            return state.clinicServiceAddText;
        },
        getClinicServiceEdit(state) {
            return state.clinicServiceEdit;
        },
        getClinicServiceEditStatus(state) {
          return function(){
            return state.clinicServiceEditStatus;
          }
        },
        getClinicServiceEditing(state) {
            return state.clinicServiceEdit.id > 0;
        },
        getClinicServiceEditText(state) {
            return state.clinicServiceEditText;
        },
        getClinicServiceEditLoadStatus(state) {
            return state.clinicServiceEditLoadStatus;
            
        },
        getClinicServiceDeleteStatus(state) {
            return function(){
                return state.clinicServiceDeleteStatus;
            }
        },
        getClinicServiceDeleteText(state) {
            return state.clinicServiceDeleteText;
        }
    }
};
