<template>
  <v-autocomplete
                        ref="autocomplete"
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedService"
                         @change="serviceChange"
                        :loading="servicesLookupLoadStatus==1"
                        :items="servicesLookup"
                        @update:search-input="changeSelection"
                        flat
                        hide-details="auto"
                        label="Wyszukaj diagnostykę"
                        :dense="dense"
                        :clearable="clearable"
                        @click:clear="clearContent"
                        :disabled="disabled"
                      >
                      
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Zacznij wpisywać nazwę 
                            <strong>diagnostyki</strong>
                            , lub 
                            <v-btn small :disabled="servicesLookupLoadStatus==1" color="secondary" class="ml-1" @click="showAll">pokaż listę</v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      
                      </v-autocomplete>

</template>
<script>

export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   excludeServiceId:{
     type:Number,
     default:0
   },
   dense:{
     type:Boolean,
     default:false
   },
   clearable:{
     type:Boolean,
     default:false
   },
   disabled:{
     type:Boolean,
     default:false
   },
   selectedService:{
     type:Number
   },
   selectedServiceText:{
     type:String,
     default:null
   },
   serviceCategory:{
     type:Number,
     default:null
   }
  },
  data: () => ({
    searchService: null,
    serviceId:undefined,
  }),
  watch:{
    // selectedService(val){
    //   if(!val){
    //     this.searchService = null;
    //   }
    // }
  },
  computed: {
    user(){
      return this.$store.getters.getUser;
     },
    lookups(){
      return this.$store.getters.getLookups;
    },
    servicesLookupLoadStatus(){
      return this.$store.getters.getServicesLookupLoadStatus;
    },
    servicesLookup(){
      if(this.selectedService && this.selectedServiceText && _.filter(this.$store.getters.getServicesLookup,{'value':this.selectedService}).length===0)
      return [{value:this.selectedService,text:this.selectedServiceText}];
      else return this.$store.getters.getServicesLookup;
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    clearContent(){
      this.$emit('serviceSelected', null)
      this.$emit('change', null)
    },
    serviceChange(val){
      if(val){
        this.$emit('serviceSelected', val)
        this.$emit('change', val)
      }
    },
    changeSelection (val) {
      if(this.selectedService){
        val && val !== _.find(this.servicesLookup,{'value':this.selectedService}).text && val.length>2 && this.querySelections(val)
      }else{
        val && val.length>2 && this.querySelections(val)
      }
    },
    querySelections (v) {
        this.$store.dispatch( 'searchServicesLookup',{q:v,category_id:this.serviceCategory}); 
    },
    showAll(){
        this.$refs.autocomplete.internalSearch = null;
        this.clearContent();
        this.$store.dispatch( 'searchServicesLookup',{q:'SHOW_ALL',category_id:this.serviceCategory}); 
    }
  }
};
</script>
<style scoped>

</style>