<template>
      <v-col>
          <v-card flat>
         <v-data-table
            dense
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-1"
            :loading="billingsLoadStatus===1 || billingImportStatus===1 || billingEditStatus===1"
            :options.sync="tableOptions"
            :server-items-length="options.lineCount"
            hide-default-footer
            must-sort
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-btn v-if="$route.params.patient_id" color="primary" small @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon>Wróć</v-btn>
                <v-toolbar-title v-else>Billing</v-toolbar-title>
                
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-menu
                                      v-model="fromDateMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          hide-details
                                          clearable
                                          v-model="fromDate"
                                          label="Od"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="fromDate" @input="fromDateMenu = false"></v-date-picker>
                      </v-menu>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-menu
                                      v-model="toDateMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          hide-details
                                          clearable
                                          v-model="toDate"
                                          label="Do"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="toDate" @input="toDateMenu = false"></v-date-picker>
                      </v-menu>
                      <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    

                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          class="search"
                          @keydown.enter="searchBilling"
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>
                <!-- <v-divider
                  v-if="!$route.params.patient_id"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <patients-live-search 
                  v-if="!$route.params.patient_id"
                  dense
                  class="choose_user" 
                  clearable 
                  @patientSelected="selectedPatient=$event" 
                  :selectedPatient="selectedPatient" 
                  label="Pacjent" /> -->
                <!-- <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Wyszukaj"
                      single-line
                      hide-details
                      class="search"
                      @keydown.enter="searchBillings"
                  ></v-text-field>
                  </template>
                    <span>Wpisz min 3 znaki i naciśnij Enter</span>
                </v-tooltip>
                  -->

                <v-spacer></v-spacer>

                <!-- <services-live-search 
                  dense
                  class="choose_service" 
                  clearable 
                  @serviceSelected="selectedService=$event" 
                  :selectedService="selectedService" 
                  label="Usługa medyczna" />


                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <clinics-live-search 
                  dense
                  class="choose_user" 
                  clearable 
                  @clinicSelected="selectedClinic=$event" 
                  :selectedClinic="selectedClinic" 
                  label="Klinika" />

                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                
                <users-live-search 
                  dense
                  class="choose_user" 
                  clearable 
                  @userSelected="selectedUser=$event" 
                  :selectedUser="selectedUser" 
                  :userType="1"
                  label="Lekarz" />

                  <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider> -->

<v-select 
                  clearable
                  class="choose_status mt-4 mr-2" 
                  dense 
                  v-model="selectedType" 
                  :items="lookups.billing_type"
                  label="Typ połączenia"
                  ></v-select>
 <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-select 
                  clearable
                  class="choose_status mt-4" 
                  dense 
                  v-model="selectedStatus" 
                  :items="lookups.billing_status"
                  label="Status połączenia"
                  ></v-select>
 <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-select 
                  clearable
                  class="choose_status mt-4" 
                  dense 
                  v-model="selectedAction" 
                  :items="lookups.billing_action"
                  label="Stan realizacji"
                  ></v-select>
<v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                 <!-- 
                  <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              size="28"
                              class="mr-4"
                              :disabled="tableItems.length==0"
                              @click="downloadXlsFile"
                            >
                              mdi-file-excel
                            </v-icon>
                          </template>
                          <span>Pobierz jako Excel</span>
                        </v-tooltip> -->
                <v-dialog v-if="user.permissions['billing'] >= 10" v-model="dialogImport" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab dark small color="secondary"><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                  <import-billing @close="closeImport"></import-billing>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.entry="{ item }">
              {{item.entry}}
           </template>
           <template v-slot:item.caller="{ item }">
              {{item.caller_name?item.caller_name+' ('+item.caller+')':item.caller}}
           </template>
           <template v-slot:item.recipient="{ item }">
              {{item.recipient_name?item.recipient_name+' ('+item.recipient+')':item.recipient}}
           </template>
            <template v-slot:item.actions="{ item }">

                <v-tooltip v-if="user.permissions['billing'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="saveBilling(item)" color="secondary" icon><v-icon>mdi-content-save</v-icon></v-btn>
                  </template>
                    <span>Zapisz</span>
                </v-tooltip>

            </template>

      
            <template v-slot:item.action="{ item }">
              <v-select 
                  dense
                  flat
                  :items="lookups.billing_action"
                  v-model="item.action"
                  single-line
                  hide-details
              ></v-select>  
           </template>

          <template v-slot:item.type="{ item }">
              {{_.find(lookups.billing_type,{'value':item.type}).text}}
          </template>

          <template v-slot:item.stat="{ item }">
              {{_.find(lookups.billing_status,{'value':item.status}).text}}
          </template>

          <template v-slot:item.actions="{ item }">

                <v-tooltip v-if="user.permissions['billing'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="saveBilling(item)" color="secondary" icon><v-icon>mdi-content-save</v-icon></v-btn>
                  </template>
                    <span>Zapisz</span>
                </v-tooltip>

            </template>

          </v-data-table> 
          <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && billingsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadBillings" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadBillings" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>

          </v-card>
      
      <confirm ref="confirm"></confirm>
      </v-col>
      

</template>

<script>
  import XLSX from "xlsx"
  import confirm from '../components/Confirm'
  import ImportBilling from '../components/ImportBilling.vue';

  //import PatientsLiveSearch from '../components/PatientsLiveSearch.vue';

  export default {
    components:{
        confirm,
        ImportBilling,
        //PatientsLiveSearch
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      search:'',
      dialogImport:false,
      toDateMenu:false,
      fromDateMenu:false,
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Data', value: 'entry',filterable:true,filterColumn:"entry",visible:true },
        { text: 'Strona dzwoniąca', value: 'caller',filterable:true,filterColumn:"caller",visible:true },
        { text: 'Strona dzwoniąca', value: 'caller_name',filterable:true,filterColumn:"caller_name",visible:false },
        { text: 'Adresat połączenia', value: 'recipient',sortable: true,filterable:true,filterColumn:"recipient",visible:true },
        { text: 'Strona odbierająca', value: 'receiver',sortable: true,filterable:true,filterColumn:"receiver",visible:true},
        { text: 'Typ połączenia', value: 'type',sortable: true,filterable:false,filterColumn:"type",visible:true},
        { text: 'Status', value: 'stat',sortable: true,filterable:false,filterColumn:"status",visible:true},
        { text: 'Czas trwania', value: 'duration',sortable: true,filterable:false,filterColumn:"duration",visible:true},
        { text: 'Stan realizacji', value: 'action',width:'200', sortable: true,filterable:false,visible:true },
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true },
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      billingImportStatus(val){
        if(val===2){
          this.closeImport();
        }
      },
      billingEditStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getBillingOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setBillingOptions', {options:value} )
        }
      },
      fromDate:{
        get () {
          return this.$store.getters.getBillingOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.fromDate = value;
          this.options = currentOptions;
          //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
        }
      },
      toDate:{
        get () {
          return this.$store.getters.getBillingOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.toDate = value;
          this.options = currentOptions;
          //this.$store.dispatch( 'loadCourseLookup', {year_id:this.options.selectedCourse} );
        }
      },
      selectedType:{
        get () {
          return this.$store.getters.getBillingOptions.selectedType;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.selectedType = value;
          this.options = currentOptions;
        }
      },
      selectedAction:{
        get () {
          return this.$store.getters.getBillingOptions.selectedAction;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.selectedAction = value;
          this.options = currentOptions;
        }
      },
      selectedStatus:{
        get () {
          return this.$store.getters.getBillingOptions.selectedStatus;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.selectedStatus = value;
          this.options = currentOptions;
        }
      },
      tableItems(){
        return this.$store.getters.getBillings;
      },
      billingsLoadStatus(){
        return this.$store.getters.getBillingsLoadStatus;
      },
      billingImportStatus(){
        return this.$store.getters.getBillingImportStatus();
      },
      billingEditStatus(){
        return this.$store.getters.getBillingEditStatus();
      },
      computedHeaders () {
        var h = _.filter(this.headers,function(o) { return o.visible; })

        //if(!this.showDeleted && this.billing.permissions['billings'] == 0) return h.filter(h => h.value!=='status' && h.value!=='actions')
        //else if(this.showDeleted && this.billing.permissions['billings'] <= 5) return h.filter(h => h.value!=='actions')
        //else 
        if(!this.showDeleted) return h.filter(h => h.value!=='status')
        else return h
      },
    },
    mounted(){
      if(this.lookups.roles.length>0){
        if(this.$route.params.patient_id){
          //this.selectedPatient = parseInt(this.$route.params.patient_id)
        }else this.loadBillings();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          if(this.$route.params.patient_id){
            //this.selectedPatient = parseInt(this.$route.params.patient_id)
        }else this.loadBillings();
        }, 2000)
      }
    },
    beforeRouteLeave (to, from, next) {
      var currentOptions = {...this.options};
      currentOptions.selectedPatient = null;
      this.$store.commit('setBillingOptions',currentOptions);
      next();
    },
    methods: {
      downloadXlsFile(){
        //przygotowanie danych
        // var items = []
        // this.tableItems.forEach(item => {
        //   items.push({
        //     date_from:item.date_from,
        //     patient:item.patient.name+' '+item.patient.surname,
        //     service:item.service.name,
        //     clinic:item.clinic.name, 
        //     cost:this.priceText(item.cost), 
        //     invoice_number:item.invoice_number, 
        //     invoice_date:item.invoice_date,
        //     price: item.price?_.find(item.price,{'currency_id':item.patient.currency})['amount']:null,
        //     billing_amount:item.billing_amount,
        //     billing_currency:_.find(this.lookups.currencies,{'value':item.billing_currency?item.billing_currency:item.patient.currency}).text,
        //     billing_status:_.find(this.lookups.billing_status,{'value':item.billing_status}).text
        //     })
        // });

        // var wb = XLSX.utils.book_new()
        // var ws_name = "Zestawienie";
        // var ws = XLSX.utils.aoa_to_sheet([["Data","Pacjent","Usługa","Klinika","Koszt","Numer faktury","Data faktury","Cena","Wpłata","Waluta","Status zapłaty"]]);
        // XLSX.utils.sheet_add_json(ws,items,{header:["date_from","patient","service","clinic","cost","invoice_number","invoice_date","price","billing_amount","billing_currency","billing_status"], skipHeader:true, origin:1})
        
        // XLSX.utils.book_append_sheet(wb, ws, ws_name);
      
        // var rightNow = new Date();
        // rightNow.setMinutes(rightNow.getMinutes() - rightNow.getTimezoneOffset());
        // var filename = 'Zestawienie_' + rightNow.toISOString().replace(/-/g,'').replace(/:/g,'').replace('T','_').slice(0,15) +'.xlsx';
        // XLSX.writeFile(wb, filename, {})
      },
      loadBillings(){
        this.search='';
        this.$store.dispatch( 'loadBillings' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreBillings' );
      },
      searchBilling(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchBillings',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
      closeImport(){
        //this.$v.$reset();
        this.dialogImport = false
      },
      // changeStatus(item){
      //   if(item.billing_status==5){//opłacone
      //     item.billing_amount = _.find(item.price,{'currency_id':item.patient.currency})['amount'];
      //     item.billing_currency = item.patient.currency;
      //   }if(item.billing_status==10){//opłacone czesciowo
      //     item.billing_currency = item.patient.currency;
      //   }else if(!item.billing_amount){
      //     item.billing_amount = null;
      //     item.billing_currency = null;
      //   }
      // },
      // changeAmount(item){
      //   var p = this.priceValue(item.price,item.patient.currency);
      //   if(item.billing_amount == p){
      //     this.tableItems[this.tableItems.indexOf(item)].billing_status = 5;
      //   }else if(item.billing_amount>0){
      //     this.tableItems[this.tableItems.indexOf(item)].billing_status = 10;
      //   }else{
      //     this.tableItems[this.tableItems.indexOf(item)].billing_status = 1;
      //   }
      // },
      saveBilling(item){
        this.$store.dispatch( 'editBilling', {
                slug: item.id,
                action:  item.action,
              });
      },
    },
  }
</script>

<style scoped>
.search{
      max-width: 250px;
}
.choose_date{
  max-width: 150px;
}
.choose_status{
  max-width: 200px;
}

</style>

<style>
.cost_background{
  background: rgb(237, 244, 255);
}
.price_background{
  background: rgb(250, 229, 229);
}
.smaller_font input {
    font-size: 1em;
    background:rgba(255, 255, 255, 0.555);
}
</style>