<template>
     <v-dialog v-model="dialog" max-width="500px" persistent>
                    
                    <v-card :loading="userLoginStatus===1">
                        
                        <v-card-text class="pb-0 pt-3">
                            <v-container>
                                <v-row>
                                    <v-col cols="5" class="pt-5">
                                        <v-img src="/icon.png" class="mt-10 ml-4" width="100"></v-img>
                                    </v-col>
                                    <v-col cols="7" class="pt-6">
                                        <v-text-field
                                         :disabled="userLoginStatus===4 || userLoginStatus===5"
                                         
                                        :error-messages="loginErrors" 
                                        @blur="$v.login.$touch()"
                                        v-model="login"  
                                        label="login"></v-text-field>
                                        <v-text-field 
                                            :disabled="userLoginStatus===4 || userLoginStatus===5"
                                            :error-messages="passwordErrors" 
                                            required 
                                            @input="$v.password.$touch()"  
                                            @blur="$v.password.$touch()"  
                                            v-model="password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showPassword ? 'text' : 'password'"                               
                                            @click:append="showPassword = !showPassword"
                                            @keydown.enter="loginto"
                                            label="hasło"></v-text-field>

                                            <v-text-field 
                                            v-focus
                                            ref="code"
                                            v-if="code || userLoginStatus===4 || userLoginStatus===5"
                                            :error-messages="codeErrors" 
                                            @keydown.enter="loginto"
                                            v-model="code"  
                                            label="SMS PIN"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-if="userLoginStatus===3">
                                    <v-col class="d-flex justify-center">
                                        <v-alert dense outlined type="error" class="mb-0">
                                        Nieprawidłowy login lub hasło
                                        </v-alert>    
                                    </v-col>
                                </v-row>

                                <v-row v-if="userLoginStatus===5">
                                    <v-col class="d-flex justify-center">
                                        <v-alert dense outlined type="error" class="mb-0">
                                        Nieprawidłowy PIN
                                        </v-alert>    
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="pr-6 pb-3">
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1" text @click="loginto">Zaloguj</v-btn>
                        </v-card-actions>
                    </v-card>
     </v-dialog>
                    
</template>

<script>

import required from 'vuelidate/lib/validators/required'
import ZmianaHasla from '../pages/ZmianaHasla';

export default {
  data: () => ({
    showPassword:false,
    dialog:true, 
    login:'',
    password:'',
    code:'' ,
    changePasswordPage:ZmianaHasla 
  }),
  validations() {
      if(this.userLoginStatus===4 || this.userLoginStatus===5){
        return {
          login: {
            required
          },
          password:{
            required
          },
          code:{
            required
          }
        }
      }else{
        return {
          login: {
            required
          },
          password:{
            required
          },
        }
      }
  },
  watch: {
      userLoadStatus(val){
          //console.log(val)
        if(val===2){//user załadowany
            if(this.user.oneTimePassword) this.$router.push({ path: '/haslo' }); 
            else this.$router.push({ path: '/pulpit' }); 

           this.$store.dispatch( 'loadLookups' );
        }else if(val===4){
           //this.$refs.code.$el.children[0].focus();
           //this.$nextTick(() => this.$refs.code.$el.focus())
        }
      }
  },
  computed: {
      user(){
        return this.$store.getters.getUser;
      },
      userLoginStatus(){
        return this.$store.getters.getUserLoginStatus;
      },
      userLoadStatus(){
        return this.$store.getters.getUserLoadStatus();
      },
      passwordErrors () {
        const errors = []
        if ( !this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Hasło jest wymagane')
        return errors
      },
      loginErrors () {
        const errors = []
        if (!this.$v.login.$dirty) return errors
        !this.$v.login.required && errors.push('Login jest wymagany')
        return errors
      },
      codeErrors () {
        const errors = []
        if(this.userLoginStatus!==4 && this.userLoginStatus!==5) return errors
        if (!this.$v.code.$dirty) return errors
        !this.$v.code.required && errors.push('PIN jest wymagany')
        return errors
      },
  },
  created() {
    
  },

  methods: {
    focusPassword() {
        this.$refs.password.nativeView.focus();
    },
    loginto(){
        this.$v.$touch()
        if (!this.$v.$invalid) {
            this.$store.dispatch( 'loginUser', {
                login: this.login,
                password:  this.password,
                code: this.code
            });
        }
    }
  }
};
</script>
