import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/clinicServices
    */
    getClinicServices: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/clinicServices',{
            params:{
                clinicId :options.clinicId,
                showDeleted:options.showDeleted,
        }});
    },


    /*
		POST 	/api/clinicServices
	*/
    postAddNewClinicService: function( clinic_id,service_id,service_name,service_code,service_category_id,price,cost,referral_type_id,patient_info,interval){
        return axios.post( HGO_CONFIG.API_URL + '/clinicServices',
            {
                clinic_id: clinic_id,
                service_id:service_id,
                service_name:service_name,
                service_code:service_code,
                service_category_id:service_category_id,
                price:price,
                cost:cost,
                referral_type_id:referral_type_id,
                patient_info:patient_info,
                interval:interval
            }
        );
    },

    /*
		GET 	/api/clinicServices/{slug}/edit
	*/
    getClinicServiceEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/clinicServices/' + slug + '/edit' );
	},


    /*
		PUT 	/api/clinicServices/{slug}
	*/
    putEditClinicService: function(slug,price,cost,referral_type_id,patient_info,interval){
        return axios.put( HGO_CONFIG.API_URL + '/clinicServices/'+ slug,
            {
                price:price,
                cost:cost,
                referral_type_id:referral_type_id,
                patient_info:patient_info,
                interval:interval
            }
        );
    },

     /*
		GET 	/api/clinicServices/reactivate/{slug}
	*/
    putReactivateClinicService: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/clinicServices/reactivate/' + slug);
    },

    /*
		DELETE /api/clinicServices/{slug}
	*/
    deleteClinicService: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/clinicServices/' + slug );
    },
    
    /*
		DELETE /api/clinicServices/{slug}/force
	*/
    forceDeleteClinicService: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/clinicServices/' + slug + '/force' );
	}
}