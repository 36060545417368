export const Helper = {
    methods: {
      RGB2HTML(red, green, blue)
      {
          var decColor =0x1000000+ blue + 0x100 * green + 0x10000 *red ;
          return '#'+decColor.toString(16).substr(1);
      },
      randPasswordString(){
        var possible = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
        var possibleNumber = '23456789';
        var text = '';
        for(var i=0; i < 7; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        text += possibleNumber.charAt(Math.floor(Math.random() * possibleNumber.length));
        return text;
      },
      noPL(val){
        return val.replace(/ą/g, 'a').replace(/Ą/g, 'A')
        .replace(/ć/g, 'c').replace(/Ć/g, 'C')
        .replace(/ę/g, 'e').replace(/Ę/g, 'E')
        .replace(/ł/g, 'l').replace(/Ł/g, 'L')
        .replace(/ń/g, 'n').replace(/Ń/g, 'N')
        .replace(/ó/g, 'o').replace(/Ó/g, 'O')
        .replace(/ś/g, 's').replace(/Ś/g, 'S')
        .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
        .replace(/ź/g, 'z').replace(/Ź/g, 'Z');
      },
      getAge(birthDate) 
      {
          var today = new Date();
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
          {
              age--;
          }
          return age;
      },
      peselDecode(pesel) 
      {
        // http://artpi.pl/?p=8
        // Funkcja dekodujaca nr. Pesel 
        // Wycinamy daty z numeru
        var rok=parseInt(pesel.substring(0,2),10);
        var miesiac = parseInt(pesel.substring(2,4),10)-1;
        var dzien = parseInt(pesel.substring(4,6),10);
        // Powszechnie uwaza sie, iz daty w numerach pesel obejmuja tylko ludzi urodzonych do 2000 roku. Na szczescie prawodawcy o tym pomysleli i do miesiaca dodawane sa liczby tak, by pesele starczyly az do 23 wieku. 
        if(miesiac>80) {
              rok = rok + 1800;
              miesiac = miesiac - 80;
        }
        else if(miesiac > 60) {
              rok = rok + 2200;
              miesiac = miesiac - 60;
        }
        else if (miesiac > 40) {
              rok = rok + 2100;
              miesiac = miesiac - 40;
        }
        else if (miesiac > 20) {
              rok = rok + 2000;
              miesiac = miesiac - 20;
        }
        else
        {
              rok += 1900;
        }
        // Daty sa ok. Teraz ustawiamy.
        var urodzony=new Date();
        urodzony.setFullYear(rok, miesiac, dzien);
          
        // Teraz zweryfikujemy numer pesel
        // Metoda z wagami jest w sumie najszybsza do weryfikacji.
        var wagi = [9,7,3,1,9,7,3,1,9,7];
        var suma = 0;
          
        for(var i=0;i<wagi.length;i++) {
            suma+=(parseInt(pesel.substring(i,i+1),10) * wagi[i]);
        }
        suma=suma % 10;
        var valid=(suma===parseInt(pesel.substring(10,11),10));
          
        //plec
          if(parseInt(pesel.substring(9,10),10) % 2 === 1) { 
              var plec='m';
          } else {
              var plec='k';
          }
        return {valid:valid,sex:plec,date:urodzony};
      }
    }
  }