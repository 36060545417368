import { unset } from "lodash";
import EventAPI from "../api/event";

export const events = {
    state: {
        events: [],
        eventOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'date_from',
            orderDirection:'asc',
            itemsPerPage:20,
            itemAdded:false,
            itemSearched:false,
            selectedType:undefined,
            fromDate:undefined,
            toDate:undefined
        },
        eventsLoadStatus: 0,
        eventIntervalLoadStatus:0,
        eventAddStatus: 0,
        eventAddText: "",

        eventEdit: {
            id: undefined,
            lp: undefined,
            type: 6,
            user_id:undefined,
            clinic_id:undefined,
            category_id:undefined,
            service_id:undefined,
            room:undefined,
            date_from:undefined,
            date_to:undefined,
            note:undefined,
            series_def:undefined,
            series_uuid:undefined,
            user:{name:null,surname:null},
            clinic:{name:null},
            service:{name:null},
            series:[],
            read_only:false,
            appointments:[],
            interval:undefined,
            public:false
        },
        eventEditDefault: {
            id: undefined,
            lp: undefined,
            type: 6,
            user_id:undefined,
            clinic_id:undefined,
            category_id:undefined,
            service_id:undefined,
            room:undefined,
            date_from:undefined,
            date_to:undefined,
            note:undefined,
            series_def:undefined,
            series_uuid:undefined,
            user:{name:null,surname:null},
            clinic:{name:null},
            service:{name:null},
            series:[],
            read_only:false,
            appointments:[],
            interval:undefined,
            public:false
        },
        eventEditLoadStatus: 0,
        eventEditStatus: 0,
        eventEditText: "",

        eventDeleteStatus: 0,
        eventDeleteText: "",

        eventsLookup:[],
        eventsLookupLoadStatus:0,

        referenceEvents:[],
        referenceEventsLoadStatus:0
    },

    actions: {
        loadEvents({ state, commit, dispatch }) {
            commit("setEventsLoadStatus", 1);
                EventAPI.getEvents({
                    offset:0,
                    itemsPerPage:state.eventOptions.itemsPerPage, 
                    orderBy:state.eventOptions.orderBy,
                    orderDirection:state.eventOptions.orderDirection,
                    showDeleted:state.eventOptions.showDeleted,
                    selectedType:state.eventOptions.selectedType,
                    fromDate:state.eventOptions.fromDate,
                    toDate:state.eventOptions.toDate,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setEventLineCount", response.data.lineCount);
                    commit("setEvents", response.data.events);
                    dispatch("applyIndexOnEvents");
                    commit("setEventsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setEvents", []);
                    commit("setEventsLoadStatus", 3);
                });
         
        },
        loadReferenceEvents({ state, commit, dispatch },data) {
            commit("setReferenceEventsLoadStatus", 1);
                EventAPI.getReferenceEvents({
                    id:data.id,
                    user_id:data.user_id,
                    clinic_id:data.clinic_id,
                    room:data.room,
                    date_from:data.date_from,
                    date_to:data.date_to,
                    service_id:data.service_id
                })
                .then(function(response) {
                    commit("setReferenceEvents", response.data);
                    commit("setReferenceEventsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setReferenceEvents", []);
                    commit("setReferenceEventsLoadStatus", 3);
                });
         
        },
        loadMoreEvents({ state, commit, dispatch }) {
            commit("setEventsLoadStatus", 1);
                EventAPI.getEvents({
                    offset:state.events.length,
                    itemsPerPage:state.eventOptions.itemsPerPage, 
                    orderBy:state.eventOptions.orderBy,
                    orderDirection:state.eventOptions.orderDirection,
                    showDeleted:state.eventOptions.showDeleted,
                    selectedType:state.eventOptions.selectedType,
                    fromDate:state.eventOptions.fromDate,
                    toDate:state.eventOptions.toDate,
                })
                .then(function(response) {
                    commit("setEventLineCount", response.data.lineCount);
                    commit("setEvents", _.concat(state.events, response.data.events));
                    dispatch("applyIndexOnEvents");
                    commit("setEventsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setEvents", []);
                    commit("setEventsLoadStatus", 3);
                });
         
        },
        searchEvents({ state, commit, dispatch },data) {
            commit("setEventsLoadStatus", 1);
                EventAPI.searchEvents({
                    showDeleted:state.eventOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setEventSearchedTrue");
                    commit("setEventLineCount", response.data.lineCount);
                    commit("setEvents", response.data.events);
                    dispatch("applyIndexOnEvents");
                    commit("setEventsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setEvents", []);
                    commit("setEventsLoadStatus", 3);
                });
         
        },
        setEventOptions({ state, commit, dispatch },data){
            commit("setEventOptions",data.options);
            dispatch("loadEvents");
        },
        applyIndexOnEvents({ commit, state, dispatch }) {
            let localEvents = state.events;

            var i = 1;
            localEvents.forEach(event => {
                event.lp = i;
                i++;
            });

            commit("setEvents",localEvents);
        },
        addEvent({ commit, state, dispatch }, data) {
            commit("setEventAddStatus", 1);
            commit("setEventAddText", "");

            EventAPI.postAddNewEvent(
                data.type,
                data.user_id,
                data.clinic_id,
                data.category_id,
                data.service_id,
                data.room,
                data.date_from,
                data.date_to,
                data.note,
                data.series_def,
                data.series,
                data.public,
                data.interval
            )
                .then(function(response) {
                    commit("setEventAddStatus", 2);
                    commit("setAddedEvents",response.data.events);
                    commit("setEventLineCount", state.eventOptions.lineCount + response.data.events.length);
                    commit("setEventEdit", response.data.data);
                    dispatch("applyIndexOnEvents");
                })
                .catch(function(error) {
                    commit("setEventAddStatus", 3);
                    commit("setEventAddText", error.response.data.errors);
                });
        },
        loadEventEdit({ commit }, data) {
            commit("setEventEditLoadStatus", 1);
            commit("setEventEditStatus", 0);
            commit("setEventEditText", "");

            EventAPI.getEventEdit(data.slug)
                .then(function(response) {
                    commit("setReferenceEvents", response.data['references']);
                    commit("setReferenceAppointments", response.data['referenceAppointments']);
                    unset(response.data['references']);
                    commit("setEventEdit", response.data);
                    commit("setEventEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setEventEdit", {});
                    commit("setEventEditLoadStatus", 3);
                });
        },
        loadEventInterval({ commit }, data) {
            commit("setEventIntervalLoadStatus", 1);

            EventAPI.getEventInterval(data.clinic_id,data.service_id)
                .then(function(response) {
                    commit("setEventInterval", response.data);
                    commit("setEventIntervalLoadStatus", 2);
                })
                .catch(function() {
                    commit("setEventInterval", null);
                    commit("setEventIntervalLoadStatus", 3);
                });
        },
        loadPermissionList({ commit }, data) {
            commit("setEventEditLoadStatus", 1);
            commit("setEventEditStatus", 0);
            commit("setEventEditText", "");

            EventAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setPermissionListEdit", response.data);
                    commit("setEventEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setEventEdit", {});
                    commit("setEventEditLoadStatus", 3);
                });
        },
        loadEventEditDefault({ commit }) {
            commit("setEventEditDefault");
        },
        loadEventAddDefault({ commit }) {
            commit("setEventAddStatus", 0);
            commit("setEventAddText", "");
        },
        editEvent({ commit, state, dispatch }, data) {
            commit("setEventEditStatus", 1);

            EventAPI.putEditEvent(
                data.slug,
                data.type,
                data.user_id,
                data.clinic_id,
                data.category_id,
                data.service_id,
                data.room,
                data.date_from,
                data.date_to,
                data.note,
                data.series_def,
                data.series,
                data.public,
                data.interval
            )
                .then(function(response) {
           
                    commit("setEventEditStatus", 2);
                    commit("setEditedEvents",response.data.events)
                    commit("setReferenceEvents", response.data.data['references']);
                    commit("setReferenceAppointments", response.data.data['referenceAppointments']);
                    unset(response.data.data['references']);
                    commit("setEventEdit", response.data.data);
                    dispatch("applyIndexOnEvents");
                })
                .catch(function(error) {
                    commit("setEventEditStatus", 3);
                    commit("setEventEditText", error.response.data.errors);
                });
        },
        editEventReactivate({ commit, state, dispatch }, data) {
            commit("setEventEditStatus", 1);
  
            EventAPI.putReactivateEvent(data.slug)
                .then(function(response) {
                    commit("setEventEditStatus", 2);
                    commit("setEditedEvents",response.data)
                    dispatch("applyIndexOnEvents");
                })
                .catch(function(error) {
                    commit("setEventEditStatus", 3);
                });
        },
        deleteEvent({ commit, state, dispatch }, data) {
            commit("setEventDeleteStatus", 1);

            EventAPI.deleteEvent(data.slug)
                .then(function(response) {
                    commit("setEventDeleteStatus", 2);
                    if(state.eventOptions.showDeleted){
                        commit("setEditedEvents",response.data)
                    }else{
                        commit("setEventLineCount", state.eventOptions.lineCount - 1);
                        commit("setDeletedEvent",response.data)
                    }
                    dispatch("applyIndexOnEvents");
                })
                .catch(function() {
                    commit("setEventDeleteStatus", 3);
                });
        },
        deleteEvents({ commit, state, dispatch }, data) {
            commit("setEventDeleteStatus", 1);

            EventAPI.deleteEvents(data.slug)
                .then(function(response) {
                    commit("setEventDeleteStatus", 2);
                    if(state.eventOptions.showDeleted){
                        commit("setEditedEvents",response.data)
                    }else{
                        commit("setEventLineCount", state.eventOptions.lineCount - response.data.length);
                        commit("setDeletedEvents",response.data)
                    }
                    dispatch("applyIndexOnEvents");
                })
                .catch(function() {
                    commit("setEventDeleteStatus", 3);
                });
        },
        forceDeleteEvent({ commit, state, dispatch }, data) {
            commit("setEventDeleteStatus", 1);

            EventAPI.forceDeleteEvent(data.slug)
                .then(function(response) {
                    commit("setEventDeleteStatus", 2);
                    commit("setEventLineCount", state.eventOptions.lineCount - 1);
                    commit("setDeletedEvent",response.data)
                    dispatch("applyIndexOnEvents");
                })
                .catch(function() {
                    commit("setEventDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setEventOptions(state,options){
            state.eventOptions = options;
        },
        setEventsLoadStatus(state, status) {
            state.eventsLoadStatus = status;
        },
        setEventIntervalLoadStatus(state, status) {
            state.eventIntervalLoadStatus = status;
        },
        setEventsLookupLoadStatus(state, status) {
            state.eventsLookupLoadStatus = status;
        },
        setReferenceEventsLoadStatus(state, status) {
            state.referenceEventsLoadStatus = status;
        },
        setEvents(state, events) {
            state.events = events;
        },
        setEventsLookup(state, events) {
            state.eventsLookup = events;
        },
        
        setReferenceEvents(state, events) {
            state.referenceEvents = events;
        },
        setEventLineCount(state, lineCount) {
            state.eventOptions.lineCount = lineCount;
        },
        setEventAddStatus(state, status) {
            state.eventAddStatus = status;
        },
        setEventAddText(state, text) {
            state.eventAddText = text;
        },
        setEventEdit(state, event) {
            state.eventEdit = event;
        },
        setEventInterval(state,interval){
            state.eventEdit.interval = interval;
        },
        setPermissionListEdit(state, list) {
            state.eventEdit.computed_permissions = list;
        },
        setEditedEvents(state, e) {
            e.forEach(event => {
                var lp = _.find(state.events,{'id':event.id}).lp;
                var index = _.findIndex(state.events,{'id':event.id});
        
                state.events[index] = event;
                state.events[index].lp = lp;
            });
        },
        setAddedEvents(state, events) {
            events.forEach(event => {
                state.eventOptions.itemAdded = true;
                state.events.push(event);
            });
        },
        setEventSearchedTrue(state) {
            state.eventOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.eventOptions.itemAdded = false;
            state.eventOptions.itemSearched = false;
        },
        setDeletedEvent(state, event) {
            var index = _.findIndex(state.events,{'id':event.id});
            state.events.splice(index,1);
        },
        setDeletedEvents(state, events) {
            events.forEach(e => {
                var index = _.findIndex(state.events,{'id':e.id});
                state.events.splice(index,1);      
            });
        },
        setEventEditDefault(state) {
            state.eventEdit = { ...state.eventEditDefault };
        },
        setEventEditStatus(state, status) {
            state.eventEditStatus = status;
        },
        setEventEditText(state, text) {
            state.eventEditText = text;
        },
        setEventEditLoadStatus(state, status) {
            state.eventEditLoadStatus = status;
        },
        setEventDeleteStatus(state, status) {
            state.eventDeleteStatus = status;
        },
        setEventDeletedText(state, text) {
            state.eventDeleteText = text;
        }
    },

    getters: {
        getEventOptions(state){
            return state.eventOptions;
        },
        getEventsLoadStatus(state) {
            return state.eventsLoadStatus;
        },
        getEventsLookupLoadStatus(state) {
            return state.eventsLookupLoadStatus;
        },
        getReferenceEventsLoadStatus(state) {
            return state.referenceEventsLoadStatus;
        },
        getEvents(state) {
            return state.events;
        },
        getReferenceEvents(state) {
            return state.referenceEvents;
        },
        getEventsLookup(state) {
            return state.eventsLookup;
        },
        getEventAddStatus(state) {
            return function(){
                return state.eventAddStatus;
            } 
        },
        getEventAddText(state) {
            return state.eventAddText;
        },
        getEventEdit(state) {
            return state.eventEdit;
        },
        getEventEditStatus(state) {
          return function(){
            return state.eventEditStatus;
          }
        },
        getEventEditing(state) {
            return state.eventEdit.id > 0;
        },
        getEventEditText(state) {
            return state.eventEditText;
        },
        getEventEditLoadStatus(state) {
            return function(){
                return state.eventEditLoadStatus;
            }
        },
        getEventDeleteStatus(state) {
            return function(){
                return state.eventDeleteStatus;
            }
        },
        getEventDeleteText(state) {
            return state.eventDeleteText;
        }
    }
};
