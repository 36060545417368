<template>
  <v-autocomplete
                        ref="autocomplete"
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="select"
                         @change="serviceChange"
                        :loading="servicesLookupLoadStatus==1"
                        :items="servicesLookup"
                        @update:search-input="changeSelection"
                        prepend-inner-icon='mdi-magnify'
                        append-icon=''
                        flat
                        single-line
                        clearable
                        hide-details="auto"
                        :label="label"
                        @click:clear="clearContent"
                        :dense="dense"
                        :disabled="disabled"
                        auto-select-first
                      >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Zacznij wpisywać nazwę 
                            <strong>diagnostyki</strong>
                            , lub 
                            <v-btn small :disabled="servicesLookupLoadStatus==1" color="secondary" class="ml-1" @click="showAll">pokaż listę</v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      </v-autocomplete>

</template>
<script>
import moment from 'moment';
export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj usługę"
   },
   disabled:{
     type:Boolean,
     default:false
   },
  serviceCategory:{
     type:Number,
     default:undefined
   },
  },
  data: () => ({
    select:null
  }),
 
  computed: {
    servicesLookupLoadStatus(){
      return this.$store.getters.getServicesLookupLoadStatus;
    },
    servicesLookup(){
      return _.map(this.$store.getters.getServicesLookup,'text');
    },
  },
  methods: {
    clearContent(){
      this.$store.commit("setServicesLookup", []);
    },
    filterObject(item, queryText, itemText) {
      //return true;
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    serviceChange(val){
      if(val){
        this.$emit('serviceSelected', {value:_.find(this.$store.getters.getServicesLookup,{'text':val}).value,text:val, code:_.find(this.$store.getters.getServicesLookup,{'text':val}).code})
        this.$store.commit("setServicesLookup", []);
        this.select = moment().valueOf().toString();
      }
    },
    changeSelection (val) {
      val && val.length>2 && val !== this.select && this.querySelections(val)
    },
    querySelections (v) {
      this.$store.dispatch( 'searchServicesLookup',{q:v,category_id:this.serviceCategory}); 
    },
    showAll(){
        this.$refs.autocomplete.internalSearch = null;
        this.clearContent();
        this.$store.dispatch( 'searchServicesLookup',{q:'SHOW_ALL',category_id:this.serviceCategory}); 
    }
  }
};
</script>
<style scoped>

</style>