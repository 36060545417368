var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.tableItems,"loading":_vm.billingsLoadStatus===1 || _vm.billingImportStatus===1 || _vm.billingEditStatus===1,"options":_vm.tableOptions,"server-items-length":_vm.options.lineCount,"hide-default-footer":"","must-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(_vm.$route.params.patient_id)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v("Wróć")],1):_c('v-toolbar-title',[_vm._v("Billing")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date",attrs:{"hide-details":"","clearable":"","label":"Od","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date",attrs:{"hide-details":"","clearable":"","label":"Do","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.toDateMenu = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchBilling($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[_c('span',[_vm._v("Wpisz min 3 znaki i naciśnij Enter")])]),_c('v-spacer'),_c('v-select',{staticClass:"choose_status mt-4 mr-2",attrs:{"clearable":"","dense":"","items":_vm.lookups.billing_type,"label":"Typ połączenia"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-select',{staticClass:"choose_status mt-4",attrs:{"clearable":"","dense":"","items":_vm.lookups.billing_status,"label":"Status połączenia"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-select',{staticClass:"choose_status mt-4",attrs:{"clearable":"","dense":"","items":_vm.lookups.billing_action,"label":"Stan realizacji"},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.user.permissions['billing'] >= 10)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"secondary"}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1720553761),model:{value:(_vm.dialogImport),callback:function ($$v) {_vm.dialogImport=$$v},expression:"dialogImport"}},[_c('import-billing',{on:{"close":_vm.closeImport}})],1):_vm._e()],1)]},proxy:true},{key:"item.entry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entry)+" ")]}},{key:"item.caller",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.caller_name?item.caller_name+' ('+item.caller+')':item.caller)+" ")]}},{key:"item.recipient",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.recipient_name?item.recipient_name+' ('+item.recipient+')':item.recipient)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.user.permissions['billing'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.saveBilling(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Zapisz")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","flat":"","items":_vm.lookups.billing_action,"single-line":"","hide-details":""},model:{value:(item.action),callback:function ($$v) {_vm.$set(item, "action", $$v)},expression:"item.action"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find(_vm.lookups.billing_type,{'value':item.type}).text)+" ")]}},{key:"item.stat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find(_vm.lookups.billing_status,{'value':item.status}).text)+" ")]}}])}),_c('div',{staticClass:"text-center pt-2"},[(!_vm.options.itemAdded && _vm.options.lineCount-_vm.tableItems.length>0 && _vm.billingsLoadStatus===2)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadMore}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Załaduj więcej ("+_vm._s(_vm.options.lineCount-_vm.tableItems.length)+")")],1):(_vm.options.itemAdded && _vm.options.lineCount-_vm.tableItems.length>0)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadBillings}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Odśwież tabele aby móc załadować więcej ("+_vm._s(_vm.options.lineCount-_vm.tableItems.length)+")")],1):(_vm.options.itemSearched)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadBillings}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Anuluj wyszukiwanie")],1):_vm._e()],1)],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }