import LeafletAPI from "../api/leaflet";

export const leaflets = {
    state: {
        leaflets: [],
        leafletOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:15,
            itemAdded:false,
            itemSearched:false
        },
        leafletsLoadStatus: 0,
        leafletAddStatus: 0,
        leafletAddText: "",

        leafletEdit: {
            id: undefined,
            lp: undefined,
            name: undefined,
            description:undefined,
            file_name:undefined,
            file_content:undefined,
            file_type:undefined
        },
        leafletEditDefault: {
            id: undefined,
            lp: undefined,
            name: undefined,
            description:undefined,
            file_name:undefined,
            file_content:undefined,
            file_type:undefined
        },
        leafletEditLoadStatus: 0,
        leafletEditStatus: 0,
        leafletEditText: "",

        leafletDeleteStatus: 0,
        leafletDeleteText: "",

    },

    actions: {
        loadLeaflets({ state, commit, dispatch }) {
            commit("setLeafletsLoadStatus", 1);
                LeafletAPI.getLeaflets({
                    offset:0,
                    itemsPerPage:state.leafletOptions.itemsPerPage, 
                    orderBy:state.leafletOptions.orderBy,
                    orderDirection:state.leafletOptions.orderDirection,
                    showDeleted:state.leafletOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setLeafletLineCount", response.data.lineCount);
                    commit("setLeaflets", response.data.leaflets);
                    dispatch("applyIndexOnLeaflets");
                    commit("setLeafletsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setLeaflets", []);
                    commit("setLeafletsLoadStatus", 3);
                });
         
        },
        loadMoreLeaflets({ state, commit, dispatch }) {
            commit("setLeafletsLoadStatus", 1);
                LeafletAPI.getLeaflets({
                    offset:state.leaflets.length,
                    itemsPerPage:state.leafletOptions.itemsPerPage, 
                    orderBy:state.leafletOptions.orderBy,
                    orderDirection:state.leafletOptions.orderDirection,
                    showDeleted:state.leafletOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setLeafletLineCount", response.data.lineCount);
                    commit("setLeaflets", _.concat(state.leaflets, response.data.leaflets));
                    dispatch("applyIndexOnLeaflets");
                    commit("setLeafletsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setLeaflets", []);
                    commit("setLeafletsLoadStatus", 3);
                });
         
        },
        searchLeaflets({ state, commit, dispatch },data) {
            commit("setLeafletsLoadStatus", 1);
                LeafletAPI.searchLeaflets({
                    showDeleted:state.leafletOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setLeafletSearchedTrue");
                    commit("setLeafletLineCount", response.data.lineCount);
                    commit("setLeaflets", response.data.leaflets);
                    dispatch("applyIndexOnLeaflets");
                    commit("setLeafletsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setLeaflets", []);
                    commit("setLeafletsLoadStatus", 3);
                });
         
        },
        setLeafletOptions({ state, commit, dispatch },data){
            commit("setLeafletOptions",data.options);
            dispatch("loadLeaflets");
        },
        applyIndexOnLeaflets({ commit, state, dispatch }) {
            let localLeaflets = state.leaflets;

            var i = 1;
            localLeaflets.forEach(leaflet => {
                leaflet.lp = i;
                i++;
            });

            commit("setLeaflets",localLeaflets);
        },
        addLeaflet({ commit, state, dispatch }, data) {
            commit("setLeafletAddStatus", 1);
            commit("setLeafletAddText", "");

            LeafletAPI.postAddNewLeaflet(
                data.name,
                data.description,
                data.file_name,
                data.file_content,
                data.file_type
            )
                .then(function(response) {
                    commit("setLeafletAddStatus", 2);
                    commit("setAddedLeaflet",response.data);
                    commit("setLeafletLineCount", state.leafletOptions.lineCount + 1);
                    dispatch("applyIndexOnLeaflets");
                })
                .catch(function(error) {
                    commit("setLeafletAddStatus", 3);
                    commit("setLeafletAddText", error.response.data.errors);
                });
        },

        loadLeafletEdit({ commit }, data) {
            commit("setLeafletEditLoadStatus", 1);
            commit("setLeafletEditStatus", 0);
            commit("setLeafletEditText", "");

            LeafletAPI.getLeafletEdit(data.slug)
                .then(function(response) {
                    commit("setLeafletEdit", response.data);
                    commit("setLeafletEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setLeafletEdit", {});
                    commit("setLeafletEditLoadStatus", 3);
                });
        },
        loadPermissionList({ commit }, data) {
            commit("setLeafletEditLoadStatus", 1);
            commit("setLeafletEditStatus", 0);
            commit("setLeafletEditText", "");

            LeafletAPI.getEmptyPermissionList()
                .then(function(response) {
                    commit("setPermissionListEdit", response.data);
                    commit("setLeafletEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setLeafletEdit", {});
                    commit("setLeafletEditLoadStatus", 3);
                });
        },
        loadLeafletEditDefault({ commit }) {
            commit("setLeafletEditDefault");
        },
        loadLeafletAddDefault({ commit }) {
            commit("setLeafletAddStatus", 0);
            commit("setLeafletAddText", "");
        },
        editLeaflet({ commit, state, dispatch }, data) {
            commit("setLeafletEditStatus", 1);

            LeafletAPI.putEditLeaflet(
                data.slug,
                data.name,
                data.description,
                data.file_name,
                data.file_content,
                data.file_type
            )
                .then(function(response) {
           
                    commit("setLeafletEditStatus", 2);
                    commit("setEditedLeaflet",response.data)
                    dispatch("applyIndexOnLeaflets");
                })
                .catch(function(error) {
                    commit("setLeafletEditStatus", 3);
                    commit("setLeafletEditText", error.response.data.errors);
                });
        },
        editLeafletReactivate({ commit, state, dispatch }, data) {
            commit("setLeafletEditStatus", 1);
  
            LeafletAPI.putReactivateLeaflet(data.slug)
                .then(function(response) {
                    commit("setLeafletEditStatus", 2);
                    commit("setEditedLeaflet",response.data)
                    dispatch("applyIndexOnLeaflets");
                })
                .catch(function(error) {
                    commit("setLeafletEditStatus", 3);
                });
        },
        deleteLeaflet({ commit, state, dispatch }, data) {
            commit("setLeafletDeleteStatus", 1);

            LeafletAPI.deleteLeaflet(data.slug)
                .then(function(response) {
                    commit("setLeafletDeleteStatus", 2);
                    if(state.leafletOptions.showDeleted){
                        commit("setEditedLeaflet",response.data)
                    }else{
                        commit("setLeafletLineCount", state.leafletOptions.lineCount - 1);
                        commit("setDeletedLeaflet",response.data)
                    }
                    dispatch("applyIndexOnLeaflets");
                })
                .catch(function() {
                    commit("setLeafletDeleteStatus", 3);
                });
        },
        forceDeleteLeaflet({ commit, state, dispatch }, data) {
            commit("setLeafletDeleteStatus", 1);

            LeafletAPI.forceDeleteLeaflet(data.slug)
                .then(function(response) {
                    commit("setLeafletDeleteStatus", 2);
                    commit("setLeafletLineCount", state.leafletOptions.lineCount - 1);
                    commit("setDeletedLeaflet",response.data)
                    dispatch("applyIndexOnLeaflets");
                })
                .catch(function() {
                    commit("setLeafletDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setLeafletOptions(state,options){
            state.leafletOptions = options;
        },
        setLeafletsLoadStatus(state, status) {
            state.leafletsLoadStatus = status;
        },
        setLeaflets(state, leaflets) {
            state.leaflets = leaflets;
        },
        setLeafletLineCount(state, lineCount) {
            state.leafletOptions.lineCount = lineCount;
        },
        setLeafletAddStatus(state, status) {
            state.leafletAddStatus = status;
        },
        setLeafletAddText(state, text) {
            state.leafletAddText = text;
        },
        setLeafletEdit(state, leaflet) {
            state.leafletEdit = leaflet;
        },
        setPermissionListEdit(state, list) {
            state.leafletEdit.computed_permissions = list;
        },
        setEditedLeaflet(state, leaflet) {
            var lp = _.find(state.leaflets,{'id':leaflet.id}).lp;
            var index = _.findIndex(state.leaflets,{'id':leaflet.id});
       
            state.leaflets[index] = leaflet;
            state.leaflets[index].lp = lp;
        },
        setAddedLeaflet(state, leaflet) {
            state.leafletOptions.itemAdded = true;
            state.leaflets.push(leaflet);
        },
        setLeafletSearchedTrue(state) {
            state.leafletOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.leafletOptions.itemAdded = false;
            state.leafletOptions.itemSearched = false;
        },
        setDeletedLeaflet(state, leaflet) {
            var index = _.findIndex(state.leaflets,{'id':leaflet.id});
            state.leaflets.splice(index,1);
        },
        setLeafletEditDefault(state) {
            state.leafletEdit = { ...state.leafletEditDefault };
        },
        setLeafletEditStatus(state, status) {
            state.leafletEditStatus = status;
        },
        setLeafletEditText(state, text) {
            state.leafletEditText = text;
        },
        setLeafletEditLoadStatus(state, status) {
            state.leafletEditLoadStatus = status;
        },
        setLeafletDeleteStatus(state, status) {
            state.leafletDeleteStatus = status;
        },
        setLeafletDeletedText(state, text) {
            state.leafletDeleteText = text;
        }
    },

    getters: {
        getLeafletOptions(state){
            return state.leafletOptions;
        },
        getLeafletsLoadStatus(state) {
            return state.leafletsLoadStatus;
        },
        getLeaflets(state) {
            return state.leaflets;
        },
        getLeafletAddStatus(state) {
            return function(){
                return state.leafletAddStatus;
            } 
        },
        getLeafletAddText(state) {
            return state.leafletAddText;
        },
        getLeafletEdit(state) {
            return state.leafletEdit;
        },
        getLeafletEditStatus(state) {
          return function(){
            return state.leafletEditStatus;
          }
        },
        getLeafletEditing(state) {
            return state.leafletEdit.id > 0;
        },
        getLeafletEditText(state) {
            return state.leafletEditText;
        },
        getLeafletEditLoadStatus(state) {
            return state.leafletEditLoadStatus;
            
        },
        getLeafletDeleteStatus(state) {
            return function(){
                return state.leafletDeleteStatus;
            }
        },
        getLeafletDeleteText(state) {
            return state.leafletDeleteText;
        }
    }
};
