<template>
  <v-autocomplete
                        :filter="filterObject"
                        :error-messages="errorMessages"
                        :value="selectedUser"
                         @change="userChange"
                        :loading="usersLookupLoadStatus==1"
                        :items="usersLookup"
                        @update:search-input="changeSelection"
                        flat
                        hide-no-data
                        hide-details="auto"
                        :label="label"
                        :dense="dense"
                        :clearable="clearable"
                        @click:clear="clearContent"
                        :disabled="disabled"
                      ></v-autocomplete>

</template>
<script>

export default {
  props: {
   errorMessages:{
     type:Array,
     default:null
   },
   excludeUserId:{
     type:Number,
     default:0
   },
   dense:{
     type:Boolean,
     default:false
   },
   label:{
     type:String,
     default:"Wyszukaj użytkownika"
   },
   clearable:{
     type:Boolean,
     default:false
   },
   disabled:{
     type:Boolean,
     default:false
   },
   selectedUser:{
     type:Number
   },
   selectedUserText:{
     type:String,
     default:null
   },
   userType:{
     type:Number
   }
  },
  data: () => ({
    searchUser: null,
    userId:undefined
  }),
  watch:{
    // selectedUser(val){
    //   if(!val){
    //     this.searchUser = null;
    //   }
    // }
  },
  computed: {
    usersLookupLoadStatus(){
      return this.$store.getters.getUsersLookupLoadStatus;
    },
    usersLookup(){
      //jeżeli jest juz ustawiony user ale lookup jest pusty to dajemy tego usera
      if(this.selectedUser && this.selectedUserText && _.filter(this.$store.getters.getUsersLookup,{'value':this.selectedUser}).length===0)
      return [{value:this.selectedUser,text:this.selectedUserText}];
      else return this.$store.getters.getUsersLookup;
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        this.toAsciiUpper(itemText).indexOf(this.toAsciiUpper(queryText)) > -1
      );
    },
    toAsciiUpper(val){
      return val.toUpperCase().replace("Ą","A").replace("Ć","C").replace("Ę","E").replace("Ł","L").replace("Ó","O").replace("Ś","S").replace("Ż","Z").replace("Ź","Z").replace("Ń","N");
    },
    clearContent(){
      this.$emit('userSelected', null)
      this.$emit('change', null)
      //this.searchUser = null;
    },
    userChange(val){
      if(val){
        this.$emit('userSelected', val)
        this.$emit('change', val)
      }
    },
    changeSelection (val) {
      if(this.selectedUser){
        val && val !== _.find(this.usersLookup,{'value':this.selectedUser}).text && val.length>2 && this.querySelections(val)
      }else{
        val && val.length>2 && this.querySelections(val)
      }
    },
    querySelections (v) {
        this.$store.dispatch( 'searchUsersLookup',{q:v,type:this.userType}); 
      },
  }
};
</script>
<style scoped>

</style>