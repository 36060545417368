<template>
                 <v-card class="fill-height" :loading="attachmentFileContentLoadStatus===1" style="background-color:#CECECE;">
                      <div v-if="previewMode" >
                      <v-row class="mx-0 my-0">  
                        <v-col class="col-2 px-0 py-0 m-0">
                                <v-toolbar  flat style="background-color:#CECECE;">
                                  <v-menu  bottom right v-model="statusMenu" content-class="noscroll" >
                                    <template v-slot:activator="{ on }">
                                      <v-icon color="secondary" v-on="on" size="35" >mdi-menu</v-icon>
                                    </template>
                                    <v-row dense style="background-color:white;">
                                      <v-col class="pb-0">
                                        <h4 class="ml-4 mt-3">Działania</h4>
                                        <v-list>
                                          <v-list-item @click.capture.stop="toggleCategoriesCompleteSelection">
                                            <v-list-item-action class="mr-4">
                                              <v-checkbox :indeterminate="someCategoriesSelected" :input-value="allCategoriesSelected"  @change="toggleCategoriesCompleteSelection" />
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>Wszystkie</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item v-for="(category) in lookups.categories" :key="category.value" @click.capture.stop="toggleCategory(category)">
                                            <v-list-item-action class="mr-4">
                                              <v-checkbox :color="category.color"  v-model="selectedCategories" multiple :value="category.value" />
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>{{ category.text }}</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-col>
                                      <v-col class="pb-0">
                                        <h4 class="ml-4 mt-3">Załączniki</h4>
                                        <v-list>
                                          <v-list-item @click.capture.stop="toggleAttachmentsCompleteSelection">
                                            <v-list-item-action class="mr-4">
                                              <v-checkbox :indeterminate="someAttachmentsSelected" :input-value="allAttachmentsSelected"  @change="toggleAttachmentsCompleteSelection" />
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>Wszystkie</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item v-for="(type) in lookups.attachment_type" :key="type.value" @click.capture.stop="toggleAttachment(type)">
                                            <v-list-item-action class="mr-4">
                                              <v-checkbox :color="type.color" v-model="selectedAttachments" multiple :value="type.value" />
                                            </v-list-item-action>
                                            <v-list-item-content @click="">
                                              <v-list-item-title>{{ type.name }}</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-col>
                                      <v-col class="pb-0">
                                        <h4 class="ml-4 mt-3">Statusy<v-icon color="secondary" @click="statusMenu = false" class="ml-16" style="margin-top:-10px;">mdi-close</v-icon></h4>
                                        <v-list>
                                          <v-list-item @click.capture.stop="toggleStatusesCompleteSelection">
                                            <v-list-item-action class="mr-4">
                                              <v-checkbox :indeterminate="someStatusesSelected" :input-value="allStatusesSelected"  @change="toggleStatusesCompleteSelection" />
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>Wszystkie</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item v-for="(type) in lookups.appointment_status" :key="type.value" @click.capture.stop="toggleStatus(type)">
                                            <v-list-item-action class="mr-4">
                                              <v-checkbox :color="type.color" v-model="selectedStatuses" multiple :value="type.value" />
                                            </v-list-item-action>
                                            <v-list-item-content @click="">
                                              <v-list-item-title>{{ type.text }}</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-col>
                                    </v-row>
                                    
                                  </v-menu>
                                  <v-toolbar-title class="ml-2 text-caption">{{appointments.length}} {{appointments.length==1?'wpis':(appointments.length==2||appointments.length==3||appointments.length==4?'wpisy':'wpisów')}}</v-toolbar-title>
                                
                                </v-toolbar>
                            </v-col>
                            <v-col class="col-10 px-0 py-0 m-0">
                                <v-toolbar  flat style="background-color:#CECECE;">
                                    
                                          <v-icon color="secondary" size="30">{{getFileIcon(attachmentFileContent.file_type)}}</v-icon>
                                          <v-toolbar-title class="ml-2">
                                            {{attachmentFileContent.file_name}}
                                            <v-icon v-if="attachmentFileContent.signed" size="30" class="ml-1" color="orange">mdi-certificate-outline</v-icon>
                                          </v-toolbar-title>
                                          <v-text-field outlined dense class="ml-2 mt-7 mr-2" v-model="attachmentFileContent.file_desc" label="Opis">
                                            <template v-slot:append>
                                              <v-icon @click="editFileContentDesc" color="green darken-3">mdi-content-save</v-icon>
                                            </template>
                                          </v-text-field>
                                          <v-spacer></v-spacer>
                                          
                                          <v-btn v-if="attachmentFileContent.file_type==='pdf' && user.zla_exists && !attachmentFileContent.signed" @click="passwordDialog = true" small color="orange" class="mr-3"><v-icon size="23" left>mdi-certificate-outline</v-icon>Podpisz cyfrowo</v-btn>
                                          <v-btn v-if="attachmentFileContent.file_type==='pdf' && attachmentFileContent.signed" @click="unsignZLAFile" small color="orange" class="mr-3"><v-icon size="23" left>mdi-certificate-outline</v-icon>Usuń podpis</v-btn>
                                          
                                          <v-icon @click="editDeleteFile" size="35" class="mr-1" color="secondary">mdi-delete</v-icon>
                                          <v-icon @click="printFileContent" size="35" class="mr-1" color="primary">mdi-printer</v-icon>
                                          <v-icon @click="loadedFileRotation==270?loadedFileRotation=0:loadedFileRotation+=90" size="35" class="mr-1" color="primary">mdi-rotate-right-variant</v-icon>
                                          <v-icon @click="loadedFileRotation==90?loadedFileRotation=0:loadedFileRotation-=90" size="35" class="mr-1" color="primary">mdi-rotate-left-variant</v-icon>
                                          <v-icon @click="downloadFileContent" size="38" color="primary">mdi-download-box-outline</v-icon>
                                          <v-icon :disabled="!loadedFileDetails || !loadedFileDetails.prev" @click="loadFileContent(loadedFileDetails.prev_id,loadedFileDetails.prev)" size="38" color="primary">mdi-arrow-left-bold-box-outline</v-icon>
                                          <v-icon :disabled="!loadedFileDetails || !loadedFileDetails.next" @click="loadFileContent(loadedFileDetails.next_id,loadedFileDetails.next)" size="38" color="primary">mdi-arrow-right-bold-box-outline</v-icon>
                                          
                                          <v-icon @click="unloadFileContent" size="38" color="secondary">mdi-close-box-outline</v-icon>
                                          <v-icon v-if="(chooseMode || chooseFileMode) && loadedFileIndexInAttachments < 0" @click="chooseLoadedFile()" color="secondary"  size="38">mdi-plus-box-outline</v-icon>
                                          <v-icon v-if="(chooseMode || chooseFileMode) && loadedFileIndexInAttachments >= 0" @click="unChooseLoadedFile()" color="secondary"  size="38">mdi-minus-box-outline</v-icon>
                                          
                                          <v-dialog v-model="passwordDialog" max-width="500px" >
                                            <v-card>
                                              <v-card-title>
                                                <span class="headline">Podpis cyfrowy</span>
                                              </v-card-title>

                                              <v-card-text>
                                                <v-container>
                                                  
                                                    <v-row>                   
                                                      <v-col cols="12">
                                                        <v-text-field 
                                                        v-model="password" 
                                                        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                        @click:append="() => (value = !value)"
                                                        :type="value ? 'password' : 'text'" 
                                                        label="Hasło do certyfikatu"></v-text-field>                            
                                                      </v-col>
                                                    </v-row>
                                                    <v-row v-if="attachmentFileContentLoadStatus===5">
                                                        <v-col class="d-flex flex-column align-center">
                                                              <v-alert dense outlined type="error">
                                                                  Błędne hasło do certyfikatu
                                                              </v-alert>
                                                        </v-col>
                                                  </v-row>
                                                  <v-row v-if="attachmentFileContentLoadStatus===6">
                                                        <v-col class="d-flex flex-column align-center">
                                                              <v-alert dense outlined type="error">
                                                                  Błąd podpisu certyfikatu
                                                              </v-alert>
                                                        </v-col>
                                                  </v-row>
                                                </v-container>
                                              </v-card-text>

                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="gray" text :disabled="attachmentFileContentLoadStatus===1" @click="closePassword">Anuluj</v-btn>
                                                <v-btn color="secondary darken-1" text :disabled="attachmentFileContentLoadStatus===1" @click="signZLAFile" >Podpisz</v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </v-dialog>
                                        
                                </v-toolbar>
                            </v-col>
                        </v-row> 
                      </div>
                      <v-toolbar flat v-else style="background-color:#CECECE;">
                        <v-menu  bottom right v-model="statusMenu" content-class="noscroll" >
                          <template v-slot:activator="{ on }">
                            <v-icon color="secondary" v-on="on" size="35" >mdi-menu</v-icon>
                          </template>
                          <v-row dense style="background-color:white;">
                            <v-col class="pb-0">
                              <h4 class="ml-4 mt-3">Działania</h4>
                              <v-list>
                                <v-list-item @click.capture.stop="toggleCategoriesCompleteSelection">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :indeterminate="someCategoriesSelected" :input-value="allCategoriesSelected"  @change="toggleCategoriesCompleteSelection" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>Wszystkie</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(category) in lookups.categories" :key="category.value" @click.capture.stop="toggleCategory(category)">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :color="category.color"  v-model="selectedCategories" multiple :value="category.value" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ category.text }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col class="pb-0">
                              <h4 class="ml-4 mt-3">Załączniki</h4>
                              <v-list>
                                <v-list-item @click.capture.stop="toggleAttachmentsCompleteSelection">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :indeterminate="someAttachmentsSelected" :input-value="allAttachmentsSelected"  @change="toggleAttachmentsCompleteSelection" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>Wszystkie</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(type) in lookups.attachment_type" :key="type.value" @click.capture.stop="toggleAttachment(type)">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :color="type.color" v-model="selectedAttachments" multiple :value="type.value" />
                                  </v-list-item-action>
                                  <v-list-item-content @click="">
                                    <v-list-item-title>{{ type.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col class="pb-0">
                              <h4 class="ml-4 mt-3">Statusy<v-icon color="secondary" @click="statusMenu = false" class="ml-16" style="margin-top:-10px;">mdi-close</v-icon></h4>
                              <v-list>
                                <v-list-item @click.capture.stop="toggleStatusesCompleteSelection">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :indeterminate="someStatusesSelected" :input-value="allStatusesSelected"  @change="toggleStatusesCompleteSelection" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>Wszystkie</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(type) in lookups.appointment_status" :key="type.value" @click.capture.stop="toggleStatus(type)">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :color="type.color" v-model="selectedStatuses" multiple :value="type.value" />
                                  </v-list-item-action>
                                  <v-list-item-content @click="">
                                    <v-list-item-title>{{ type.text }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                           
                        </v-menu>
                        <v-toolbar-title class="ml-2 text-caption">{{appointments.length}} {{appointments.length==1?'wpis':(appointments.length==2||appointments.length==3||appointments.length==4?'wpisy':'wpisów')}}</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Wyszukaj"
                              single-line
                              hide-details
                              class="search mr-10"
                          ></v-text-field>
                          </template>
                            <span>Wpisz min 1 znak</span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="35"
                            class="mr-2"
                            @click="viewStyle = 'flow'"
                            :color="viewStyle === 'flow'?'secondary':''"
                          >
                            mdi-timeline-text-outline
                          </v-icon>
                          </template>
                            <span>Widok flow</span>
                        </v-tooltip>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="35"
                            class="mr-2"
                            @click="expandAll = true;viewStyle = 'files'"
                            :color="viewStyle === 'files'?'secondary':''"
                          >
                            mdi-folder-open-outline
                          </v-icon>
                          </template>
                            <span>Widok plików</span>
                        </v-tooltip>
                         <v-tooltip left>
                          <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="35"
                            class="mr-2"
                            @click="expandAll = true;viewStyle = 'table'"
                            :color="viewStyle === 'table'?'secondary':''"
                          >
                            mdi-table
                          </v-icon>
                          </template>
                            <span>Widok tabeli</span>
                        </v-tooltip>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon  :disabled="viewStyle !== 'flow'" v-on="on" size="30" @click="expandAll = !expandAll">{{expandAll?'mdi-chevron-double-up':'mdi-chevron-double-down'}}</v-icon>
                          </template>
                            <span>Pokaż/ukryj treść załączników</span>
                        </v-tooltip>
                        <!-- <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              class="ml-5"
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>Załączniki</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(type) in lookups.attachment_type" :key="type.value" @click.capture.stop="toggleAttachment(type)">
                              <v-list-item-action class="mr-4">
                                <v-checkbox :color="type.color" v-model="selectedAttachments" multiple :value="type.value" />
                              </v-list-item-action>
                              <v-list-item-content @click="">
                                <v-list-item-title>{{ type.name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu> -->
                        
                      </v-toolbar>
                      
                      <v-row dense> 
                        <v-col v-if="previewMode" class="col-2 pl-5 pb-3" >
                          <v-responsive
                              class="overflow-y-auto fill-height"
                              :height="flowHeight-additionalHeight"
                              :width="flowWidth-additionalWidth"
                          >
                          <div 
                              v-for="appointment in appointments"
                              :key="appointment.id"
                            >
                            <flow-element 
                              files-view
                              :id="appointment.id"
                              :date="appointment.date_from.split(' ')[0]" :time="appointment.date_from.split(' ')[1]"
                              class="mt-0"
                              v-if="_.filter(appointment.attachments, function(a) { return a.files.length > 0; }).length > 0"
                            >
                            </flow-element>
                                <div 
                                  v-for="attachment in appointment.attachments"
                                  :key="attachment.id"
                                >
                                <flow-element 
                                  files-view
                                  :id="attachment.id"
                                  sub-item
                                >
                               
                                <template v-slot:files>
                                    <div>
                                      <v-simple-table dense style="background-color: transparent!important;overflow-x: clip;">
                                          <template v-slot:default>
                                                    <tbody>
                                                      <tr
                                                        v-for="(item, index) in viewStyle === 'files'?_.filter(attachment.files,{'hide':false}):attachment.files"
                                                        :key="index"
                                                        :style="loadedFilePath==item.file_path?'background-color:#cea292!important;':'border-color: transparent!important;'"
                                                      >
                                                      <td class="px-0 text-start" style="border-color: transparent!important;"  width="25">
                                                        <v-tooltip right>
                                                          <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" @click="loadFileContent(attachment.id,item.file_path)" color="secondary" size="25">
                                                              {{getFileIcon(item['file_type'])}}
                                                            </v-icon>
                                                          </template>
                                                          <span>{{item['file_name']}}</span>
                                                        </v-tooltip>
                                                        
                                                      </td>
                                                      <td class="px-0 text-start text-caption" style="border-color: transparent!important;white-space: nowrap">{{_.truncate(item['file_name'],{'length': 20})}}</td>
                                                      </tr>
                                                    </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </template>

                                </flow-element>
                                </div>
                          </div>
                          </v-responsive>
                        </v-col>   
                        <v-col :class="previewMode?'col-10 pl-0 pb-3':'col-12 pl-5 pb-3'" >

                          <!-- <v-pagination
                            v-if="previewMode && attachmentFileContent.file_type==='pdf' && pageCount>1"
                            total-visible="6"
                            v-model="currentPage"
                            :length="pageCount"
                          ></v-pagination> -->

                          <v-responsive
                              class="overflow-y-auto fill-height"
                              :height="flowHeight-additionalHeight"
                              :width="flowWidth-additionalWidth"
                          >

                          <pdf 
                                    v-if="previewMode && attachmentFileContent.file_type==='pdf'"
                                    :src="attachmentFileContent.file_content" 
                                    @num-pages="pageCount = $event"
			                              style="display: none;"
                          />
                          <v-container v-if="previewMode && attachmentFileContent.file_type==='pdf'" class="pl-0 pr-0">
                            <pdf
                              v-for="i in pageCount"
                              :key="i"
                              :src="attachmentFileContent.file_content"
                              :page="i"
                              class="mb-1"
                              style="height: 100%"
                              :rotate="loadedFileRotation"
                            ></pdf>
                          </v-container>
                          
                          <v-img :style="`transform: rotate(${loadedFileRotation}deg);`" v-else-if="previewMode && (attachmentFileContent.file_type==='png' || attachmentFileContent.file_type==='jpg' || attachmentFileContent.file_type==='jpeg')" :src="attachmentFileContent.file_content" contain />
                          <h2 v-else-if="previewMode" class="text-center mt-12" style="color:grey;">Brak podglądu</h2>

                          <v-simple-table style="background-color:#CECECE;" class="mr-4" v-else-if="viewStyle==='table'">
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th class="text-left" width="100">Data</th>
                                            <th class="text-left" width="30"></th>
                                            <th class="text-left">Diagnostyka</th>
                                            <th class="text-left">Załączniki</th>
                                            <th class="text-left">Pliki</th>
                                            <th class="text-left" width="100">Status</th>
                                          </tr>
                                        </thead>
                                          <tbody>
                                          <tr
                                            v-for="(appointment, index) in appointments"
                                            :key="index"
                                          >
                                          <td style="font-size: .75rem;"><b><text-highlight :queries="search">{{appointment.date_from.split(' ')[0]}}</text-highlight><br /><text-highlight :queries="search">{{appointment.date_from.split(' ')[1]}}</text-highlight></b></td>
                                          <td style="font-size: .75rem;"><v-icon size="20" >{{appointment.category.icon}}</v-icon></td>
                                          <td style="font-size: .75rem;"><router-link style="text-decoration: none;" :to="{name:'realizacja',params:{id:appointment.id}}"><b><text-highlight :queries="search">{{appointment.service.name}}</text-highlight></b></router-link></td>
                                          <td style="font-size: .75rem;"><text-highlight :queries="search">{{attachmentsList(appointment.attachments)}}</text-highlight></td>
                                          <td style="font-size: .75rem;" class="text-right">
                                            <div v-for="(attachment,index) in appointment.attachments" :key="index">
                                            <div v-for="(item, index) in attachment.files" :key="index">
                                              <text-highlight v-if="item['file_desc']" :queries="search">{{item['file_desc'] + ' / '}}</text-highlight> <text-highlight :queries="search">{{_.truncate(item['file_name'],{'length': 30})}}</text-highlight>
                                              <v-badge
                                                    v-if="item.signed"
                                                    dot bordered
                                                    color="orange"
                                                    bottom
                                                    overlap
                                                  >
                                                  <v-icon @click="loadFileContent(attachment.id,item.file_path)" color="secondary" size="30">{{getFileIcon(item['file_type'])}}</v-icon>
                                                  </v-badge>
                                                  <v-icon v-else @click="loadFileContent(attachment.id,item.file_path)" color="secondary" size="30">{{getFileIcon(item['file_type'])}}</v-icon>
                                                  <v-icon v-if="chooseMode || chooseFileMode" @click="chooseFile(attachment.id,item.file_path)" color="secondary"  size="20">mdi-plus</v-icon>
                                            </div>
                                            </div>
                                          </td>
                                          <td style="font-size: .75rem;"><text-highlight :queries="search">{{_.find(lookups.appointment_status,{'value':appointment.status}).text}}</text-highlight></td>
                                          <!-- <td class="px-1"><v-text-field @input="itemChanged=true;$v.editedItem.$touch()" :error-messages="fileNameErrors(index)" flat dense v-model="item['file_name']" required  hide-details="auto"></v-text-field></td>
                                          <td><v-icon @click="deleteFile(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>
                                            <td><v-avatar size="35" :color="item.category.color"><v-icon size="20">{{item.category.icon}}</v-icon></v-avatar></td>
                                            <td><b>{{ item.date_from }}</b></td>
                                            <td><b>{{ item.service.name }}</b><br />{{item.clinic?item.clinic.name:''}}</td>
                                            <td><i>{{ _.find(lookups.appointment_status,{'value':item.status}).text }}</i></td>
                                            <td><v-btn :to="{ name: 'dzialanie', params: { id: item.id } }" color="primary" x-small>Przejdź</v-btn></td>
                                           -->
                                           </tr>
                                        </tbody>
                                      </template>
                            </v-simple-table>
                            <div 
                              v-else
                              v-for="appointment in appointments"
                              :key="appointment.id"
                            >
                            <flow-element 
                              :search="search"
                              :files-view="viewStyle === 'files'"
                              :table-view="viewStyle === 'table'"
                              :id="appointment.id"
                              :date="appointment.date_from.split(' ')[0]" :time="appointment.date_from.split(' ')[1]"
                              :icon="appointment.category.icon" 
                              :color="appointment.category.color" 
                              :title="appointment.service.name" 
                              :sub-title="appointment.clinic?appointment.clinic.name:''"
                              :status="_.find(lookups.appointment_status,{'value':appointment.status}).text"
                              :editable="appointment.id==editableAppointment"
                              class="mt-3"
                              v-if="viewStyle !== 'files' || _.filter(appointment.attachments, function(a) { return a.files.length > 0; }).length > 0"
                            >
                            </flow-element>
                                <div 
                                  v-for="attachment in appointment.attachments"
                                  :key="attachment.id"
                                >
                                <flow-element 
                                  :search="search"
                                  :files-view="viewStyle === 'files'"
                                  :table-view="viewStyle === 'table'"
                                  :key="comKey"
                                  :chooseMode="chooseMode"
                                  :id="attachment.id"
                                  :sub-item="true"
                                  :title="attachment.header" 
                                  :status="attachment.updated_at+' / <b>'+attachment.user.name+' '+attachment.user.surname +'</b>'"
                                  :editable="appointment.id==editableAppointment"
                                  :readonly="attachment.type==50 && attachment.content.deleted_at!==null"
                                  @edit="attachment.expanded = true;edit($event);"
                                  @del="del($event)"
                                  @chooseAttachment="chooseAttachment($event,appointment.id)"
                                >
                                <template v-slot:expand>
                                  <v-col md="auto" class="pl-0"><v-icon size="20" @click="attachment.expanded = !attachment.expanded;comKey += 1;">{{attachment.expanded?'mdi-chevron-double-up':'mdi-chevron-double-down'}}</v-icon></v-col>
                                </template>
                                <template  v-slot:body>
                                  <v-expand-transition>
                                    <div v-show="attachment.expanded">
                                      <div v-if="attachment.type==50" class="mb-1">
                                        <v-icon size="18" class="mr-1">mdi-at</v-icon>
                                        <span class="text-caption"><text-highlight :queries="search">{{_.join(_.map(attachment.content.assigned_users,'text'),', ')}}</text-highlight></span>
                                      </div>
                                      <div v-if="attachment.type==40" class="mb-1">
                                        <v-icon size="18" class="mr-1">mdi-at</v-icon><span class="text-caption" ><text-highlight :queries="search">{{attachment.content.recipient}}</text-highlight></span>
                                        <v-icon size="18" class="mr-1">mdi-format-title</v-icon><span class="text-caption"><text-highlight :queries="search">{{attachment.content.header}}</text-highlight></span>
                                        <v-icon size="18" class="mr-1 ml-1" v-if="attachment.content.send_date">mdi-email-send-outline</v-icon><span v-if="attachment.content.send_date" class="text-caption mr-1" ><i><text-highlight :queries="search">{{moment(attachment.content.send_date).local().format('LLL')}}</text-highlight></i></span>
                                        <v-icon size="18" class="mr-1 ml-1" v-else>mdi-email-open-outline</v-icon><br />
                                        
                                      </div>
                                      <div class="tiptap-vuetify-editor__content" v-html="htmlHighlight(attachment.body,search)"/>
                                      <!-- <div v-if="attachment.type==40">
                                        <span v-if="attachment.content.footer" class="text-caption ml-1" ><i>STOPKA</i></span>
                                      </div> -->
                                      <div v-if="attachment.type==50" class="mt-2 text-end">
                                        <span v-if="attachment.content.deleted_at" class="text-caption mr-1"><i><text-highlight :queries="search">{{moment(attachment.content.deleted_at).local().format('LLL')}}</text-highlight></i></span>
                                        <v-icon size="20" v-if="attachment.content.deleted_at">mdi-check-box-outline</v-icon>
                                        <v-icon size="20" v-else>mdi-checkbox-blank-outline</v-icon>
                                      </div>
                                      <div v-if="attachment.type==70" class="mb-1">
                                        <v-row>
                                          <v-col class="col-6">
                                              <span class="text-caption"><b>Wystawiający: </b></span><span class="text-caption mr-1"><i><text-highlight :queries="search">{{attachment.content.prescription.author.name}} {{attachment.content.prescription.author.surname}}, {{attachment.content.prescription.clinic.name}}</text-highlight></i></span>
                                              <br /><span class="text-caption"><b>Status: </b></span><span class="text-caption mr-1"><i><text-highlight :queries="search">{{_.find(lookups.prescription_status,{'value':attachment.content.prescription.status}).text}}</text-highlight></i></span>
                                          </v-col>
                                          <v-col class="col-6">
                                              <span v-if="attachment.content.prescription.updated" class="text-caption"><b>Data: </b></span><span v-if="attachment.content.prescription.updated" class="text-caption mr-1"><i><text-highlight :queries="search">{{moment(attachment.content.prescription.updated).local().format('LLL')}}</text-highlight></i></span>
                                              <br v-if="attachment.content.prescription.code" /><span v-if="attachment.content.prescription.code" class="text-caption"><b>Kod odbioru: </b></span><span class="text-caption mr-1"><i><text-highlight :queries="search">{{attachment.content.prescription.code}}</text-highlight></i></span>
                                          </v-col>
                                        </v-row>

                                        <h4 class="mt-2">Leki:</h4>
                                        
                                        <div v-for="med in attachment.content.prescription.medicines" :key="med.packageId" class="text-caption mr-1"><i><text-highlight :queries="search">{{med.nazwa}} ({{med.postac}} {{med.opakowanie}}, {{med.dawka}}) - {{med.odplatnosc.value}} </text-highlight></i></div>
                                        <!-- <v-icon size="18" class="mr-1">mdi-at</v-icon><span class="text-caption" ><text-highlight :queries="search">{{attachment.content.recipient}}</text-highlight></span>
                                        <v-icon size="18" class="mr-1">mdi-format-title</v-icon><span class="text-caption"><text-highlight :queries="search">{{attachment.content.header}}</text-highlight></span>
                                        <v-icon size="18" class="mr-1 ml-1" v-if="attachment.content.send_date">mdi-email-send-outline</v-icon><span v-if="attachment.content.send_date" class="text-caption mr-1" ><i><text-highlight :queries="search">{{moment(attachment.content.send_date).local().format('LLL')}}</text-highlight></i></span>
                                        <v-icon size="18" class="mr-1 ml-1" v-else>mdi-email-open-outline</v-icon><br /> -->
                                        
                                      </div>
                                      <div v-if="attachment.type==80" class="mb-1">
                                        <v-row>
                                          <v-col>
                                          <div v-for="result in attachment.content.results" :key="result.name" class="mr-1">
                                            <text-highlight :queries="search">{{result.name}}</text-highlight> : <b><text-highlight :queries="search">{{result.value}}</text-highlight></b>
                                          </div>
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </div>
                                  </v-expand-transition>
                                </template>

                                <template v-if="attachment.files.length>0" v-slot:files>
                                  <v-expand-transition>
                                    <div v-show="attachment.expanded">
                                      <v-simple-table dense style="background-color: transparent!important;">
                                          <template v-slot:default>
                                                    <tbody>
                                                      <tr
                                                        style="border-color: transparent!important;"
                                                        v-for="(item, index) in viewStyle === 'files'?_.filter(attachment.files,{'hide':false}):attachment.files"
                                                        :key="index"
                                                      >
                                                      <td class="px-0 text-end text-caption" style="border-color: transparent!important;"><text-highlight v-if="item['file_desc']" :queries="search">{{item['file_desc'] + ' / '}}</text-highlight><text-highlight :queries="search">{{item['file_name']}}</text-highlight></td>
                                                      <td class="px-0 text-center" style="border-color: transparent!important;" width="85">
                                                        <v-badge
                                                          v-if="item.signed"
                                                          dot bordered
                                                          color="orange"
                                                          bottom
                                                          overlap
                                                        >
                                                        <v-icon @click="loadFileContent(attachment.id,item.file_path)" color="secondary" size="30">{{getFileIcon(item['file_type'])}}</v-icon>
                                                        </v-badge>
                                                        <v-icon v-else @click="loadFileContent(attachment.id,item.file_path)" color="secondary" size="30">{{getFileIcon(item['file_type'])}}</v-icon>
                                                        <v-icon v-if="chooseMode || chooseFileMode" :disabled="fileIndexInAttachments(item) >= 0" @click="chooseFile(attachment.id,item.file_path)" color="secondary"  size="20">mdi-plus</v-icon>

                                                        <!-- <v-icon v-if="(chooseMode || chooseFileMode) && loadedFileIndexInAttachments < 0" @click="chooseLoadedFile()" color="secondary"  size="38">mdi-plus-box-outline</v-icon>
                            <v-icon v-if="(chooseMode || chooseFileMode) && loadedFileIndexInAttachments >= 0" @click="unChooseLoadedFile()" color="secondary"  size="38">mdi-minus-box-outline</v-icon> -->
                            
                                                      </td>
                                                      </tr>
                                                    </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                  </v-expand-transition>
                                </template>

                                </flow-element>
                                </div>
                                
                          </div>
                          </v-responsive>
                        </v-col>
                      </v-row>
                      <confirm ref="confirm"></confirm>
                 </v-card>   
</template>
<script>
  import confirm from '../components/Confirm'
  import printJS from 'print-js'
  import FlowElement from '../components/FlowElement'
  import pdf from 'vue-pdf'
  import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
  import moment from 'moment';
  moment.locale('pl');

  export default {
    components:{FlowElement,pdf,confirm},
    props:{
      editableAppointment:{
        type:Number,
        default:null
      },
      flowHeight:{
        type:Number,
        default:100
      },
       flowWidth:{
        type:Number,
        default:100
      },
      chooseMode:{
        type:Boolean,
        default:false
      },
      chooseFileMode:{
        type:Boolean,
        default:false
      }
    },
    data: () => ({
      //viewStyle:'flow',
      statusMenu:false,
      attachmentMenu:false,
      search:'',
      currentPage: 1,
			pageCount: 0,
      addingFile:false,
      comKey:0,
      currentAttachmentId:undefined,
      password:null,
      passwordDialog:false,
      value: true,
      loadedFilePath: null,
      loadedFileRotation:0
    }),
    validations(){
        return {
         
        }
    },
    // beforeRouteLeave (to, from, next) {
    //   this.unloadFileContent();
    //   next();
    // },
    watch: {
      attachmentFileContentLoadStatus(val){
        if(val==2 && this.chooseMode && this.addingFile){//nowy pdf
          this.newFile.elements.push({type:'file',content:this.attachmentFileContent});
          this.unloadFileContent();
          this.addingFile = false
        }else if(val==2 && this.chooseFileMode && this.addingFile){//za k aczniki
          var fileContent = this.attachmentFileContent;
          fileContent.file_content = undefined //aby nie dodawał tego jako nowy plik
          this.attachmentEditItem.files.push(fileContent);
          this.$emit('newFile');
          this.unloadFileContent();
          this.addingFile = false
        }else if(val===4){//poprawnie podpisano
          this.comKey += 1;
          this.closePassword();
        }else if(val===5){
          //błąd podpisu certyfikatu - hasło

        }else if(val===6){
          //błąd podpisu
        }else if(val===7){
          this.comKey += 1;
        }
      },
      previewMode(val){
        this.$emit('preview-change',val);
      },
      attachmentEditStatus(val){
        // if(val===1){
        //   this.previousOffsetTop = this.offsetTop;
        // }else if(val===2){
        //   this.$vuetify.goTo(this.previousOffsetTop,{ container: '#scroll-target' })
        // }
        if(val===2 || val===4){console.log(val)
          this.comKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      flowsLoadStatus(){
        return this.$store.getters.getFlowsLoadStatus();
      },
      attachmentFileContent(){
        return this.$store.getters.getFlowsAttachmentFileContent;
      },
      previewMode(){
        return this.attachmentFileContent.file_content!==null;
      },
      attachmentFileContentLoadStatus(){
        return this.$store.getters.getFlowsAttachmentFileContentLoadStatus();
      },
      filesInAppointments(){
        var files = []
        var flows = this.appointments;
        var prevFile = null
        var prevId = null
        flows.forEach(app => {
            for (let index = 0; index < app.attachments.length; index++) {
              _.filter(app.attachments[index].files,{hide:false}).forEach(file => {
                  files.push({id:app.attachments[index].id,file:file.file_path,prev:prevFile,next:null,prev_id:prevId,next_id:null});

                  if(prevFile){
                    _.find(files,{file:prevFile}).next = file.file_path;
                    _.find(files,{file:prevFile}).next_id = app.attachments[index].id;
                  }
                  prevFile = file.file_path
                  prevId = app.attachments[index].id
              
              });
            }
          }); 

        return files;
      },
      loadedFileDetails(){
        return _.find(this.filesInAppointments,{file:this.loadedFilePath});
      },
      appointments(){
        var searchString = this.search.toLowerCase();
        var flows = this.$store.getters.getFlows;

        //tryb plików filtrujemy duble
        if(this.viewStyle === 'files' || this.previewMode){
          var showedFilePaths = [];
          _.sortBy(flows,['date_from'],['asc']).forEach(app => {
            for (let index = 0; index < app.attachments.length; index++) {
              app.attachments[index].files.forEach(file => {
                if(!showedFilePaths.includes(file.file_name)){
                    showedFilePaths.push(file.file_name)
                    file.hide = false
                }else{//już jest
                    file.hide = true
                }
              });
            }
          }); 
          //tylko te appointmenty które mają nieukryte pliki
          flows = flows.filter(app =>{
            for (let index = 0; index < app.attachments.length; index++) {
              if(_.filter(app.attachments[index].files,{'hide':false}).length > 0)return true
            }
            return false
          })
        }

        if(searchString && searchString.length>2){
          flows = _.cloneDeep(this.$store.getters.getFlows);
          //filtrowanie wyświetlanych działań
          let appointments = _.filter(flows,(app) =>{
            if(app.date_from.toLowerCase().includes(searchString)) return true;
            if(app.service && app.service.name.toLowerCase().includes(searchString)) return true;
            if(app.clinic && app.clinic.name.toLowerCase().includes(searchString)) return true;
            if(_.find(this.lookups.appointment_status,{'value':app.status}).text.toLowerCase().includes(searchString)) return true;

            app.attachments = _.filter(app.attachments,(att) =>{
              if(att.header.toLowerCase().includes(searchString)) return true;
              if(att.updated_at.toLowerCase().includes(searchString)) return true;
              if(att.user.name.toLowerCase().includes(searchString)) return true;
              if(att.user.surname.toLowerCase().includes(searchString)) return true;
              if((att.user.name + ' ' + att.user.surname).toLowerCase().includes(searchString)) return true;
              if(att.body && att.body.toLowerCase().includes(searchString)) return true;
              if(att.content && _.join(_.values(att.content)).toLowerCase().includes(searchString)) return true;
              for (let fIndex = 0; fIndex < att.files.length; fIndex++) {//nazwy plików
                if(att.files[fIndex].file_name.toLowerCase().includes(searchString)) return true;
              }
              if(att.content && att.content.hasOwnProperty('results')){//patrzymy na wyniki
                for (let fIndex = 0; fIndex < att.content.results.length; fIndex++) {
                  if(att.content.results[fIndex].name.toLowerCase().includes(searchString)) return true;
                }
              }
            })
            if(app.attachments.length>0)return true;
            // for (let index = 0; index < app.attachments.length; index++) {
            //   if(app.attachments[index].header.toLowerCase().includes(searchString)) return true;
            //   if(app.attachments[index].updated_at.toLowerCase().includes(searchString)) return true;
            //   if(app.attachments[index].user.name.toLowerCase().includes(searchString)) return true;
            //   if(app.attachments[index].user.surname.toLowerCase().includes(searchString)) return true;
            //   if((app.attachments[index].user.name + ' ' + app.attachments[index].user.surname).toLowerCase().includes(searchString)) return true;
            //   if(app.attachments[index].body && app.attachments[index].body.toLowerCase().includes(searchString)) return true;
            //   if(app.attachments[index].content && _.join(_.values(app.attachments[index].content)).toLowerCase().includes(searchString)) return true;
            //   for (let fIndex = 0; fIndex < app.attachments[index].files.length; fIndex++) {//nazwy plików
            //     if(app.attachments[index].files[fIndex].file_name.toLowerCase().includes(searchString)) return true;
            //   }
            //   if(app.attachments[index].content && app.attachments[index].content.hasOwnProperty('results')){//patrzymy na wyniki
            //     for (let fIndex = 0; fIndex < app.attachments[index].content.results.length; fIndex++) {
            //       if(app.attachments[index].content.results[fIndex].name.toLowerCase().includes(searchString)) return true;
            //     }
            //   }
            // }
          })


          return appointments;
        }else 
        return flows;
      },
      expandAll:{
        get(){
          return this.$store.getters.getFlowOptions.expandAll;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getFlowOptions};
          currentOptions.expandAll = val;
          this.$store.commit( 'setFlowOptions', currentOptions);
          this.$store.commit( 'setFlowExpandAll', val);
        }
      },
      selectedCategories:{
        get(){
          return this.$store.getters.getFlowOptions.selectedCategories;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getFlowOptions};
          currentOptions.selectedCategories = val;
          this.$store.dispatch( 'setFlowOptions', {options:currentOptions} );
        }
      },
      noCategoriesSelected() {
        return this.selectedCategories.length === 0;
      },
      someCategoriesSelected() {
        return this.selectedCategories.length > 0 && !this.allCategoriesSelected;
      },
      allCategoriesSelected() {
        return (
            this.selectedCategories.length === this.lookups.categories.length
        );
      },
      selectedAttachments:{
        get(){
          return this.$store.getters.getFlowOptions.selectedAttachments;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getFlowOptions};
          currentOptions.selectedAttachments = val;
          this.$store.dispatch( 'setFlowOptions', {options:currentOptions} );
        }
      },
      noAttachmentsSelected() {
        return this.selectedAttachments.length === 0;
      },
      someAttachmentsSelected() {
        return this.selectedAttachments.length > 0 && !this.allAttachmentsSelected;
      },
      allAttachmentsSelected() {
        return (
            this.selectedAttachments.length === this.lookups.attachment_type.length
        );
      },
      selectedStatuses:{
        get(){
          return this.$store.getters.getFlowOptions.selectedStatuses;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getFlowOptions};
          currentOptions.selectedStatuses = val;
          this.$store.dispatch( 'setFlowOptions', {options:currentOptions} );
        }
      },
      noStatusesSelected() {
        return this.selectedStatuses.length === 0;
      },
      someStatusesSelected() {
        return this.selectedStatuses.length > 0 && !this.allStatusesSelected;
      },
      allStatusesSelected() {
        return (
            this.selectedStatuses.length === this.lookups.appointment_status.length
        );
      },
      newFile(){
        return this.$store.getters.getAttachmentNewFile;
      },
      additionalHeight(){
        if(this.previewMode && this.attachmentFileContent.file_type==='pdf' && this.pageCount>1)return 45;//paginator
        return 0;
      },
      additionalWidth(){
        if(this.$store.getters.getMiniVariant)return 220;
        else return 350;
      },
      attachmentEditItem(){
        return this.$store.getters.getAttachmentEdit;
      },
      attachmentEditStatus(){
        return this.$store.getters.getAttachmentEditStatus();
      },
      viewStyle:{
        get(){
          return this.$store.getters.getAppointmentViewStyle;
        },
        set(value){
          this.$store.commit("setAppointmentViewStyle", value);
        }
      },
      loadedFileIndexInAttachments(){
        if(this.chooseMode){
          for (let index = 0; index < this.newFile.elements.length; index++) {
            if(this.newFile.elements[index].type == 'file'){//patrzymy tylko na pliki, mogą być całe załączniki
              const element = this.newFile.elements[index].content;
              if(this.attachmentFileContent.file_name !== null && (element.file_name == this.attachmentFileContent.file_name)) return index;
            }
          }
        }else if(this.chooseFileMode){
          for (let index = 0; index < this.attachmentEditItem.files.length; index++) {
            const element = this.attachmentEditItem.files[index];
            if(this.attachmentFileContent.file_name !== null && (element.file_name == this.attachmentFileContent.file_name)) return index;
          }
        }
        
        return -1
      },
      
    },
    mounted(){
      if(this.lookups.roles.length==0){
        this.$store.dispatch( 'loadLookups' );
      }
      // if(this.lookups.roles.length>0){
      //   //this.updateReferenceEvents();
      //   //this.itemEditing && this.$store.dispatch( 'loadFlowEdit', {slug:  this.$route.params.id} );
      // }else{
      //   this.$store.dispatch( 'loadLookups' );
      //   setTimeout(() => {
      //     //this.updateReferenceEvents();
      //     //this.itemEditing && this.$store.dispatch( 'loadFlowEdit', {slug:  this.$route.params.id} );
      //   }, 2000)
      // }
      //this.$refs.calendar.scrollToTime('08:00')
    },
    methods: {
      fileIndexInAttachments(file){
        if(this.chooseMode){
          for (let index = 0; index < this.newFile.elements.length; index++) {
            if(this.newFile.elements[index].type == 'file'){//patrzymy tylko na pliki, mogą być całe załączniki
              const element = this.newFile.elements[index].content;
              if(element.file_name == file.file_name) return index;
            }
          }
        }else if(this.chooseFileMode){
          for (let index = 0; index < this.attachmentEditItem.files.length; index++) {
            const element = this.attachmentEditItem.files[index];
            if(element.file_name == file.file_name) return index;
          }
        }
        
        return -1
      },
      htmlHighlight(words,query){
        if(!words | !query)return words
        var iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function(matchedTxt,a,b){
            return ('<mark class=\'text__highlight\'>' + matchedTxt + '</mark>');
        });
      },
      attachmentsList(attachments){
        return _.join(_.map(attachments,'header'),', ');
      },
      loadFileContent(id,path){
        this.currentPage = 1;
        this.pageCount = 0;
        this.loadedFileRotation = 0;
        this.addingFile = false
        this.currentAttachmentId = id
        this.loadedFilePath = path
        this.$store.dispatch( 'loadAttachmentFileContent', {slug:id,file_path:path} );
      },
      chooseFile(id,path){
        this.addingFile = true
        this.$store.dispatch( 'loadAttachmentFileContent', {slug:id,file_path:path} );
      },
      chooseLoadedFile(){
        if(this.chooseMode){
          this.newFile.elements.push({type:'file',content:this.attachmentFileContent});
          this.unloadFileContent();
        }else if(this.chooseFileMode){
          var fileContent = this.attachmentFileContent;
          fileContent.file_content = undefined //aby nie dodawał tego jako nowy plik
          this.attachmentEditItem.files.push(fileContent);
          this.$emit('newFile');
          this.unloadFileContent();
        }
      },
      unChooseLoadedFile(){
        if(this.chooseMode){
          this.newFile.elements.splice(this.loadedFileIndexInAttachments,1);
          this.unloadFileContent();
        }else if(this.chooseFileMode){
          this.attachmentEditItem.files.splice(this.loadedFileIndexInAttachments,1)
          this.$emit('newFile');
          this.unloadFileContent();
        }
      },
      signZLAFile(){
        this.$store.dispatch( 'signZLAAttachmentFileContent', {slug:this.currentAttachmentId,file_path:this.attachmentFileContent.file_path,password:this.password} );
      },
      unsignZLAFile(){
        this.$store.dispatch( 'unsignZLAAttachmentFileContent', {slug:this.currentAttachmentId,file_path:this.attachmentFileContent.file_path,password:'agent009'} );
      },
      editFileContentDesc(){
        this.$store.dispatch( 'editFileContentDesc', {slug:this.currentAttachmentId,file_path:this.attachmentFileContent.file_path,desc:this.attachmentFileContent.file_desc} );
      },
      editDeleteFile(){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać plik?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'editDeleteFile', {slug:this.currentAttachmentId,file_path:this.attachmentFileContent.file_path} );
               this.unloadFileContent();
            }
        })
      },
      closePassword(){
        this.passwordDialog = false
        this.password = null
        this.$store.commit("setFlowsAttachmentFileContentLoadStatus", 0);
      },
      chooseAttachment(attachment_id,appointment_id){
        var att = _.find(_.find(this.appointments,{'id':appointment_id}).attachments,{'id':attachment_id});
        this.newFile.elements.push({type:'attachment',content:{header:att.header,body:att.body,author:att.user.name+' '+att.user.surname,update:att.updated_at}});
      },
      unloadFileContent(){
        this.currentPage = 1;
        this.pageCount = 0;
        this.loadedFileRotation = 0;
        this.loadedFilePath = null
        this.$store.commit("setFlowsAttachmentFileContent", {
          file_name:null,
          file_content:null,
          file_type:null,
          file_path:null});
      },
      downloadFileContent(){
        var fileLink = document.createElement('a');
        fileLink.href = this.attachmentFileContent.file_content;
        fileLink.setAttribute('download', this.attachmentFileContent.file_name + '.' + this.attachmentFileContent.file_type);
        document.body.appendChild(fileLink);
        fileLink.click();
      },
      async printFileContent(){
        var base64 = null;
        if(this.attachmentFileContent.file_type!='pdf'){
          const pdfDoc = await PDFDocument.create()
          const page = pdfDoc.addPage();
          const { width, height } = page.getSize();
          var imageFile = null
          if(this.attachmentFileContent.file_type=='jpg' || this.attachmentFileContent.file_type=='jpeg'){
              imageFile = await pdfDoc.embedJpg(this.attachmentFileContent.file_content)
          }else if(this.attachmentFileContent.file_type=='png'){
              imageFile =  await pdfDoc.embedPng(this.attachmentFileContent.file_content)
          }
          const imageDims = imageFile.scaleToFit(width-10,height-10)
          page.drawImage(imageFile, {
            x: page.getWidth() / 2 - imageDims.width / 2,
            y: page.getHeight() / 2 - imageDims.height / 2,
            width: imageDims.width,
            height: imageDims.height,
          })

          base64 = await pdfDoc.saveAsBase64({ dataUri: false })
        }else{
          base64 = this.attachmentFileContent.file_content.split(',')[1];
        }
        
        printJS({
          type:'pdf',
          printable: base64, 
          base64: true})
      },
      toggleCategory (c) {
        var cats = [...this.selectedCategories];
        if (cats.includes(c.value)) {
          cats.splice(cats.indexOf(c.value), 1);
          this.selectedCategories = cats;
        } else {
          cats.push(c.value);
          this.selectedCategories = cats;
        }
        //this.statusMenu = false;
      },
      toggleCategoriesCompleteSelection() {
          if(this.allCategoriesSelected) {
            this.selectedCategories = [];
          } else {
            this.selectedCategories = _.map(this.lookups.categories,'value');
          }
      },
      toggleAttachment (c) {
        var cats = [...this.selectedAttachments];
        if (cats.includes(c.value)) {
          cats.splice(cats.indexOf(c.value), 1);
          this.selectedAttachments = cats;
        } else {
          cats.push(c.value);
          this.selectedAttachments = cats;
        }
        //this.attachmentMenu = false;
      },
      toggleAttachmentsCompleteSelection() {
          if(this.allAttachmentsSelected) {
            this.selectedAttachments = [];
          } else {
            this.selectedAttachments = _.map(this.lookups.attachment_type,'value');
          }
      },
      toggleStatus (c) {
        var cats = [...this.selectedStatuses];
        if (cats.includes(c.value)) {
          cats.splice(cats.indexOf(c.value), 1);
          this.selectedStatuses = cats;
        } else {
          cats.push(c.value);
          this.selectedStatuses = cats;
        }
        //this.attachmentMenu = false;
      },
      toggleStatusesCompleteSelection() {
          if(this.allStatusesSelected) {
            this.selectedStatuses = [];
          } else {
            this.selectedStatuses = _.map(this.lookups.appointment_status,'value');
          }
      },
      searchFlows(){
        // if(this.search.length>2){
        //   const filterableHeaders = "name"
        //   this.$store.dispatch( 'searchFlows',{filterableHeaders:filterableHeaders,q:this.search});
        // }
      },
      getFileIcon(type){
        return type=='pdf'?'mdi-file-pdf-box':type=='png'?'mdi-file-image-outline':type=='jpg'?'mdi-file-image-outline':type=='jpeg'?'mdi-file-image-outline':'mdi-file';
      },
      editAttachment(id){
        //console.log(id);
      },
      edit(id){
        this.$emit('edit', id);
      },
      del(id){
        this.$emit('del', id);
      },
      moment: function (val) {
        return moment(val);
      },
    },
  }
</script>
<style>
.noscroll{
  overflow-y: hidden;
}
.search{
  max-width:250px;
}

.v-data-table__wrapper{
  overflow-x: clip !important;
}
</style>
