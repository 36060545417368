<template>
    <v-simple-table
        style="background-color:#D8D8D8;"
        :key="componentKey"
                  class="mx-3 pb-5"                    
                  >
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in tableItems"
                          :key="item.id"
                        >
                          <td  width="30">
                              <v-hover v-slot="{ hover }">
                                  <v-icon v-if="!item.status" :color="hover?'secondary':''" @click="selectTaskUndone(item.id)">mdi-check-box-outline</v-icon>
                                  <v-icon v-else :color="hover?'secondary':''" @click="selectTaskDone(item.id)" >mdi-checkbox-blank-outline</v-icon>
                              </v-hover>
                          </td>
                          <td :colspan="(item.status && item.date_to)||!item.status?'1':'2'">{{ item.body }}<v-icon class="ml-1" color="secondary" v-if="hasLinkObject(item)" @click="goToLinkObject(item)">mdi-forwardburger</v-icon><br />
                          <span class="text-caption font-weight-light" style="color:grey;" v-if="item.author">{{item.author.name+' '+item.author.surname}}</span>
                          <span class="text-caption font-weight-light ml-1" style="color:red;" >({{item.updated_at}})</span>
                          </td>
                          
                          <td v-if="(item.status && item.date_to)||!item.status" width="100" class="text-end" >
                              <span style="color:red;" class="text-caption font-weight-medium" v-if="item.status && item.date_to">{{ item.date_to }}</span>
                              <span class="text-caption font-weight-medium" v-else-if="!item.status"><i>{{ item.deleted_at }}</i></span>
                          </td>
                        
                          <td width="80" class="text-end">
                              <v-icon @click="editTask(item.id)" v-if="item.status && item.created_by==user.id" size="20">mdi-pencil</v-icon> 
                              <v-icon @click="deleteTask(item.id)" v-if="item.status && item.created_by==user.id" color="secondary" size="20">mdi-delete</v-icon>
                          </td>
                          <!-- <td><v-btn :to="{ name: 'dzialanie', params: { id: item.id } }" color="primary" x-small>Przejdź</v-btn></td> -->
                        </tr>
                      </tbody>
                      <confirm ref="confirm"></confirm>
                      <v-dialog v-model="taskDialog" max-width="700px">
                        <task-editor :item-editing="false" @close="closeTask"></task-editor>
                      </v-dialog>
                    </template>
        </v-simple-table>
</template>
<script>
import confirm from '../components/Confirm'
import TaskEditor from '../components/TaskEditor'
export default {
  components:{confirm,TaskEditor},
  props:{
    id:{
      type:Number,
      default:null
    },
  },
  data: () => ({
      componentKey:0,
      taskDialog:false
  }),
  watch:{
      taskDeleteStatus(val){
        if(val==2){
              this.componentKey++;
          }
      },
      taskDoneStatus(val){
        if(val==2){
              this.componentKey++;
          }
      },
  },
  computed:{
      user(){
        return this.$store.getters.getUser;
      },
      tableItems(){
        return this.$store.getters.getTasks;
      },
      showDone: {
        get () {
          return this.$store.getters.getTaskOptions.showDone;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDone = value;
          this.options = currentOptions;
        }
      },
      showCreated: {
        get () {
          return this.$store.getters.getTaskOptions.showCreated;
        },
      },
      taskEditStatus(){
        return this.$store.getters.getTaskEditStatus();
      },
      taskDoneStatus(){
        return this.$store.getters.getTaskDoneStatus();
      },
      taskDeleteStatus(){
        return this.$store.getters.getTaskDeleteStatus();
      },
      
  },
  methods: {
    hasLinkObject(task){
        return task.content && task.content.hasOwnProperty('link')
    },
    goToLinkObject(task){
        if(task.content && task.content.hasOwnProperty('link')){
          const linkType = task.content.link;
          if(linkType==='patient'){
            this.$router.push({name:'pacjent',params:{id:task.patient_id}})
          }
        }
    },
    selectTaskDone(id){
        this.$store.dispatch('editTaskDone',{slug:id});
    },
    selectTaskUndone(id){
        this.$store.dispatch('editTaskUndone',{slug:id});
    },
    deleteTask(id){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać utworzone zadanie na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteTask', {slug:  id});
            }
        })
    },
    editTask(id){
        this.taskDialog = true;
        this.$store.dispatch('loadTaskEdit',{slug:id})
    },
    closeTask () {
        this.taskDialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadTaskEditDefault'); 
          this.$store.dispatch( 'loadTaskAddDefault');
        }, 300)
      },
    // chooseAttachment(){
    //   this.$emit('chooseAttachment', this.id);
    // },
    // edit(){
    //   this.$emit('edit', this.id);
    // },
    // del(){
    //   this.$emit('del', this.id);
    // }
  }
}
</script>