<template>
                 <v-card height="100%" v-resize="onResize">
                    <v-sheet height="64px">
                      <v-toolbar flat>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Dziś</v-btn>
                          <v-btn fab text small color="grey darken-2" @click="prev"><v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                         <v-progress-circular v-if="appointmentsLoadStatus===1" class="ml-5" indeterminate color="secondary" size="25" width="2"></v-progress-circular>
                        <v-spacer></v-spacer>
                        <!-- <v-select hide-details single-line v-model="selectedCategories" multiple chips small-chips :items="lookups.categories"  label="Rodzaje działań"></v-select> -->
                        <v-switch v-if="type=='week' || type=='month'" class="mt-5" v-model="withWeekend" label="Sb Nd"></v-switch>
                        <v-menu  bottom left v-model="statusMenu" content-class="noscroll" :close-on-content-click="false" min-width="530" >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              outlined
                              class="ml-5"
                              color="grey darken-2"
                              v-on="on"
                            >
                              <v-icon v-if="selectedUser || selectedService" color="secondary" left>
                                mdi-filter
                              </v-icon>
                              <span>Widok</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-row dense style="background-color:white;">
                            <v-col class="pb-0 col-4">
                              <h4 class="ml-4 mt-3">Rodzaje</h4>
                              <v-list>
                                <v-list-item @click.capture.stop="toggleCategoriesCompleteSelection">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :indeterminate="someCategoriesSelected" :input-value="allCategoriesSelected"  @change="toggleCategoriesCompleteSelection" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>Wszystkie</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                               <v-list-item v-for="(category) in _.filter(lookups.categories,{visible:1})" :key="category.value" @click.capture.stop="toggleCategory(category)">
                              <v-list-item-action class="mr-4">
                                <v-checkbox :color="category.color"  v-model="selectedCategories" multiple :value="category.value" />
                              </v-list-item-action>
                              <v-list-item-content @click="">
                                <v-list-item-title>{{ category.text }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col class="pb-0  col-4">
                              <h4 class="ml-4 mt-3">Harmonogramy</h4>
                              <v-list>
                                <v-list-item @click.capture.stop="toggleTypesCompleteSelection">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :indeterminate="someTypesSelected" :input-value="allTypesSelected"  @change="toggleTypesCompleteSelection" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>Wszystkie</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(type) in lookups.event_type" :key="type.value" @click.capture.stop="toggleType(type)">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :color="type.color" v-model="selectedTypes" multiple :value="type.value" />
                                  </v-list-item-action>
                                  <v-list-item-content @click="">
                                    <v-list-item-title>{{ type.text }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col class="pb-0  col-4">
                              <h4 class="ml-4 mt-3">Statusy <v-icon color="secondary" @click="statusMenu = false" class="ml-16">mdi-close</v-icon></h4>
                              <v-list>
                                <v-list-item @click.capture.stop="toggleStatusesCompleteSelection">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :indeterminate="someStatusesSelected" :input-value="allStatusesSelected"  @change="toggleStatusesCompleteSelection" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>Wszystkie</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(type) in lookups.appointment_status" :key="type.value" @click.capture.stop="toggleStatus(type)">
                                  <v-list-item-action class="mr-4">
                                    <v-checkbox :color="type.color" v-model="selectedStatuses" multiple :value="type.value" />
                                  </v-list-item-action>
                                  <v-list-item-content @click="">
                                    <v-list-item-title>{{ type.text }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                           <v-row dense style="background-color:white;">
                             <v-col class="ml-4 mr-4 pb-5">
                                <h4 class="mt-3">Lekarz</h4>
                                <users-live-search 
                                  clearable 
                                  @userSelected="selectedUser=$event" 
                                  :selectedUser="selectedUser" 
                                  :userType="1"
                                  label="Wyszukaj lekarza" />
                             </v-col>
                           </v-row>
                            <v-row dense style="background-color:white;">
                             <v-col class="ml-4 mr-4 pb-5">
                                <h4 class="mt-3">Diagnostyka</h4>
                                <services-live-search 
                                  clearable 
                                  @serviceSelected="selectedService=$event" 
                                  :selectedService="selectedService" 
                                  label="Wyszukaj diagnostykę" />
                             </v-col>
                           </v-row>
                        </v-menu>
                        <!-- <v-menu bottom right v-if="type!='category'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              class="ml-5"
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>Rodzaje</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(category) in _.filter(lookups.categories,{visible:1})" :key="category.value" @click.capture.stop="toggleCategory(category)">
                              <v-list-item-action class="mr-4">
                                <v-checkbox :color="category.color"  v-model="selectedCategories" multiple :value="category.value" />
                              </v-list-item-action>
                              <v-list-item-content @click="">
                                <v-list-item-title>{{ category.text }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-menu bottom right v-if="type!='category'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              class="ml-5"
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>Harmonogramy</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(type) in lookups.event_type" :key="type.value" @click.capture.stop="toggleType(type)">
                              <v-list-item-action class="mr-4">
                                <v-checkbox :color="type.color" v-model="selectedTypes" multiple :value="type.value" />
                              </v-list-item-action>
                              <v-list-item-content @click="">
                                <v-list-item-title>{{ type.text }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu> -->
                        <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              class="ml-5"
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>{{ typeToLabel[type] }}</span>
                              <v-icon right>
                                mdi-menu-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="type = 'day'">
                              <v-list-item-title>Dzień</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'category'">
                              <v-list-item-title>Kategorie</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'category_doc'">
                              <v-list-item-title>Lekarze</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                              <v-list-item-title>Tydzień</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                              <v-list-item-title>Miesiąc</v-list-item-title>
                            </v-list-item>
                            
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet :height="windowSize.y+'px'">
                      <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="secondary"
                        :events="events"
                        :type="type==='category_doc'?'category':type"
                        :categories="calendarCategories"
                        @change="updateRange"
                        @click:event="goToEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        :weekdays="withWeekend?weekDaysWeekend:weekDays"
                        first-time="08:00"
                        interval-minutes="30"
                        interval-count="28"
                        :short-intervals="false"
                        :short-weekdays="false"
                        event-overlap-mode="column"
                      >
                      
                      <template v-slot:event="{  event, timed, eventSummary }">
                        <div  
                          v-for="ap in event.appointments" :key="ap.id" 
                          :style="'top:'+(ap.start-event.start)/1000/60*pixelsPerMinute+'px;height:'+((ap.end-ap.start)/1000/60)*pixelsPerMinute+'px'"
                          :class="event.status===20?'v-event-sub-appointment-canceled':'v-event-sub-appointment'"
                          style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                          @click="goToSubEvent(ap)"
                          >
                          <v-icon v-if="type=='category_doc' || type=='category' || type=='day'" size="20" style="position:absolute;top:2px;left:2px;">{{_.find(lookups.categories,{'value':ap.category_id}).icon}}</v-icon>
                          <p v-if="type=='category_doc' ||type=='category' || type=='day'" style="position:absolute;left:25px;top:2px;">{{ap.desc}}
                            <v-avatar 
                            rounded 
                            size="22" 
                            v-for="t in ap.team" 
                            :key="t.id" 
                            :color="t.color?t.color:'grey'" 
                            class="ml-1" 
                            :style="(ap.end-ap.start)/1000/60 <= 15 || type=='month'?'margin-top:-2px;':''" 
                            ><span class="white--text">{{t.name[0]+t.surname[0]}}</span></v-avatar>
                          </p>
                          <p v-else-if="type!='category_doc'" style="position:absolute;left:2px;top:2px;" >
                            <v-avatar 
                            rounded 
                            size="22" 
                            v-for="t in ap.team" 
                            :key="t.id" 
                            :color="t.color?t.color:'grey'" 
                            class="ml-1" 
                            :style="(ap.end-ap.start)/1000/60 <= 15 || type=='month'?'margin-top:-2px;':''" 
                            ><span class="white--text">{{t.name[0]+t.surname[0]}}</span></v-avatar>
                          </p>
                          <p class="mb-0"></p><p class="mb-0 mr-1" style="z-index:100000;"><strong :style="'background-color:' + event.color">{{ap.name}}</strong></p>
                        </div>
                        <!-- <div  
                          v-if="mainEvent && mainEvent.event_ids.length>0 && _.includes(mainEvent.event_ids,event.id)" 
                          :style="'top:'+(mainEvent.start-event.start)/1000/60*pixelsPerMinute+'px;height:'+duration*pixelsPerMinute+'px'"
                          class="v-event-appointment"
                          style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                          >
                          <p class="mb-0"></p><p class="mb-0 mr-1"><strong>{{mainEventDesc}}</strong></p>
                        </div> -->
                        <div
                          v-if="!event.main && !event.appointment" 
                          class="v-event-draggable"
                        ><v-icon v-if="event.public" size="20" class="ml-0 mr-1" color="white">mdi-account-multiple</v-icon><strong>{{type=='category' || type=='day'?event.name:_.last(_.split(event.name,' - '))}}</strong>
                        </div>
                        <v-tooltip right v-if="event.appointment" >
                          <template  v-slot:activator="{ on }" >
                            <div  
                              v-on="on"
                              :class="event.status===20?'v-event-ref-appointment-canceled':'v-event-ref-appointment'"
                              style="height: 100%;width:100%;display: flex;flex-direction: column;justify-content: space-between;text-align: right;"
                              >
                              <v-icon v-if="type=='category_doc' || type=='category' || type=='day'" size="20" color="black" style="position:absolute;top:2px;left:2px;">{{_.find(lookups.categories,{'value':event.category_id}).icon}}</v-icon>
                              <p v-if="type=='category' || type=='day'" style="position:absolute;left:25px;top:2px;">
                                <v-avatar 
                                rounded 
                                size="22" 
                                v-for="t in event.team" 
                                :key="t.id" 
                                :color="t.color?t.color:'grey'" 
                                class="mr-1" 
                                :style="(event.end-event.start)/1000/60 <= 15 || type=='month'?'margin-top:-2px;':''" 
                                ><span class="white--text">{{t.name[0]+t.surname[0]}}</span></v-avatar>
                                {{event.desc}}
                              </p>
                              <p v-else-if="type!='category_doc'" style="position:absolute;left:2px;top:2px;" >
                                <v-avatar 
                                rounded 
                                size="22" 
                                v-for="t in event.team" 
                                :key="t.id" 
                                :color="t.color?t.color:'grey'" 
                                class="mr-1" 
                                :style="(event.end-event.start)/1000/60 <= 15 || type=='month'?'margin-top:-2px;':''" 
                                ><span class="white--text">{{t.name[0]+t.surname[0]}}</span></v-avatar>
                              </p>
                              
                              <p class="mb-0"></p><p class="mb-0 mr-1" style="z-index:100000;"><strong :style="'background-color:' + event.color">{{type=='category' || type=='day'?event.name:_.filter(event.name.split(" "),function(x){return x.length>2}).pop()}}</strong></p>
                            </div>
                          </template>
                        <span v-html="event.tooltip"></span>
                        </v-tooltip>
                      </template>

                      </v-calendar>
                    </v-sheet>
                 </v-card>   
</template>
<script>
  import moment from 'moment';
  moment.locale('pl');
  import UsersLiveSearch from '../components/UsersLiveSearch.vue';
  import ServicesLiveSearch from '../components/ServicesLiveSearch.vue';
  import {Helper} from '../mixins/helper'

  export default {
    components:{
      UsersLiveSearch,ServicesLiveSearch
    },
    mixins:[
      Helper
    ],
    data: () => ({
      windowSize: {
        x: 0,
        y: 0,
      },
      defaultAppointmentTimeInMinutes:30,
      fab: false,
      typeToLabel: {
        month: 'Miesiąc',
        week: 'Tydzień',
        day: 'Dzień',
        category: 'Kategorie',
        category_doc:'Lekarze'
      },
      withWeekend:false,
      weekDays:[1, 2, 3, 4, 5],
      weekDaysWeekend:[1, 2, 3, 4, 5, 6, 0],
      extendOriginal: null,
      calRangeStart:null,
      calRangeEnd:null,
      statusMenu:false,
    }),
    validations(){
        return {
         
        }
    },
    watch: {
      
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      cal () {
        return this.ready ? this.$refs.calendar : null
      },
      appointmentsLoadStatus(){
        return this.$store.getters.getAppointmentsLoadStatus();
      },
      selectedCategories:{
        get(){
          return this.$store.getters.getAppointmentOptions.selectedCategories;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.selectedCategories = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
          this.updateReferenceEvents();
        }
      },
      noCategoriesSelected() {
        return this.selectedCategories.length === 0;
      },
      someCategoriesSelected() {
        return this.selectedCategories.length > 0 && !this.allCategoriesSelected;
      },
      allCategoriesSelected() {
        return (
            this.selectedCategories.length === _.filter(this.lookups.categories,{visible:1}).length
        );
      },
      calendarCategories(){
        //lista lekarzy
        if(this.type==='category_doc'){
          //sortowanie po lekarzu
          var uniq = _.sortBy(_.uniq(_.map(this.events,'category')))
          var uniqLen = uniq.length;
          //przesuwa Brak na koniec
          if(_.pull(uniq,'Brak').length!==uniqLen)uniq.push('Brak');
          return uniq;
        }

        var cats = [];
        if(_.find(this.events, ['category', 'Konsultacje']))cats.push('Konsultacje');
        if(_.find(this.events, ['category', 'Badania']))cats.push('Badania');
        if(_.find(this.events, function(e) { return e.category !== 'Konsultacje' && e.category !== 'Badania' ; }))cats.push('Inne');
        return cats;
      },
      selectedTypes:{
        get(){
          return this.$store.getters.getAppointmentOptions.selectedTypes;
        },
        set(val){
           var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.selectedTypes = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
          this.updateReferenceEvents();
        }
      },
      noTypesSelected() {
        return this.selectedTypes.length === 0;
      },
      someTypesSelected() {
        return this.selectedTypes.length > 0 && !this.allTypesSelected;
      },
      allTypesSelected() {
        return (
            this.selectedTypes.length === this.lookups.event_type.length
        );
      },
      selectedUser:{
        get(){
          return this.$store.getters.getAppointmentOptions.selectedUser;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.selectedUser = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
          this.updateReferenceEvents();
        }
      },
      selectedService:{
        get(){
          return this.$store.getters.getAppointmentOptions.selectedService;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.selectedService = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
          this.updateReferenceEvents();
        }
      },
      selectedStatuses:{
        get(){
          return this.$store.getters.getAppointmentOptions.selectedStatuses;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.selectedStatuses = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
          this.updateReferenceEvents();
        }
      },
      noStatusesSelected() {
        return this.selectedStatuses.length === 0;
      },
      someStatusesSelected() {
        return this.selectedStatuses.length > 0 && !this.allStatusesSelected;
      },
      allStatusesSelected() {
        return (
            this.selectedStatuses.length === this.lookups.appointment_status.length
        );
      },
      focus:{
        get(){
          return this.$store.getters.getAppointmentOptions.focus;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.focus = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
        }
      },
      type:{
        get(){
          return this.$store.getters.getAppointmentOptions.type;
        },
        set(val){
          var currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.type = val;
          this.$store.commit( 'setAppointmentOptions', currentOptions );
        }
      },
      events(){
        return _.concat(this.referenceEvents,this.referenceAppointments);
        // if(this.mainEvent && this.mainEvent.event_ids.length==0)return _.concat(this.mainEvent,this.referenceEvents);
        // //else if(this.mainEvent && !this.itemEditing)return _.concat(this.mainEvent,this.referenceEvents);
        // else return _.concat(this.mainEvents,this.referenceEvents);
      }, 
      referenceEvents(){
        var referenceEvents = [];
        this.$store.getters.getEvents.forEach(event => {
            var appointmentsInEvents = [];
            if(event.appointments){
              event.appointments.forEach(app => {
                appointmentsInEvents.push({
                  id:app.id,
                  name:app.short_desc,
                  desc:app.long_desc,
                  start:moment(app.date_from).valueOf(),
                  end:moment(app.date_to).valueOf(),
                  status:app.status,
                  category_id:app.category_id,
                  team:app.team
              })});
            }
            var cat = event.category_id==3?'Konsultacje':(event.category_id==1?'Badania':'Inne');
             //kategoria lekarze
              if(this.type==='category_doc'){
                if(event.user_id){//event lekarza
                  cat = event.cat;
                }else cat = 'Brak';
              }
            referenceEvents.push({
                        id: event.id,
                        name: event.desc,
                        color: _.find(this.lookups.event_type,{'value':event.type}).color,
                        start: moment(event.date_from).valueOf(),
                        end:  moment(event.date_to).valueOf(),
                        timed: !event.all_day,
                        main:false,
                        readonly:event.read_only,
                        category:cat,
                        clinic:event.clinic,
                        room:event.room,
                        appointments:appointmentsInEvents,
                        appointment:false,
                        public:event.public
                    });
            });
        return referenceEvents;
      },
      referenceAppointments(){
        var referenceEvents = [];
        this.$store.getters.getAppointments.forEach(event => {
              var tooltip = _.find(this.lookups.appointment_status,{'value':event.status}).text + '<br />' + moment(event.date_from).format('YYYY-MM-DD HH:mm') + '<br />' + event.patient.name + ' ' +  event.patient.surname + '<br />' + (event.patient.phone?event.patient.phone + '<br />':'') + event.service.name + '<br />' + (event.clinic?event.clinic.name + '<br />':'')
              event.team.forEach(element => {
                tooltip += element.name + ' ' + element.surname + '<br />'  
              });
              var cat = event.category_id==3?'Konsultacje':(event.category_id==1?'Badania':'Inne');

              //kategoria lekarze
              if(this.type==='category_doc'){
                if(event.team.length>0){
                  cat = event.team[0].name + ' ' + event.team[0].surname;
                }else cat = 'Brak';
              }
              
              referenceEvents.push({
                        id: event.id,
                        name: event.short_desc,
                        desc: event.long_desc,
                        color: _.find(this.lookups.categories,{'value':event.category_id}).color,
                        category: cat,
                        start: moment(event.date_from).valueOf() + (this.type!='category'? _.find(this.lookups.categories,{'value':event.category_id}).order_cal*60000 : 0),
                        end:  moment(event.date_to).valueOf(),
                        timed: true,
                        readonly:true,
                        clinic:event.clinic,
                        room:event.room,
                        appointment:true,
                        category_id:event.category_id,
                        team:event.team,
                        tooltip:tooltip,
                        status:event.status
                        //index:_.find(this.lookups.categories,{'value':event.category_id}).order_cal
                    });
        });
            
        return referenceEvents;
      },
      pixelsPerMinute(){
        return this.$refs.calendar.intervalHeight/this.$refs.calendar.intervalMinutes;
      },
    },
    mounted(){
      this.onResize();
      // if(this.lookups.roles.length>0){
      //   //this.updateReferenceEvents();
      //   //this.itemEditing && this.$store.dispatch( 'loadAppointmentEdit', {slug:  this.$route.params.id} );
      // }else{
      //   this.$store.dispatch( 'loadLookups' );
      //   setTimeout(() => {
      //     //this.updateReferenceEvents();
      //     //this.itemEditing && this.$store.dispatch( 'loadAppointmentEdit', {slug:  this.$route.params.id} );
      //   }, 2000)
      // }
      //this.$refs.calendar.scrollToTime('08:00')
    },
    methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight - 64 -64 - 43 }
      },
      toggleCategory (c) {
        var cats = [...this.selectedCategories];
        if (cats.includes(c.value)) {
          cats.splice(cats.indexOf(c.value), 1);
          this.selectedCategories = cats;
        } else {
          cats.push(c.value);
          this.selectedCategories = cats;
        }
        
      },
      toggleCategoriesCompleteSelection() {
          if(this.allCategoriesSelected) {
            this.selectedCategories = [];
          } else {
            this.selectedCategories = _.map(_.filter(this.lookups.categories,{visible:1}),'value');
          }
      },
      toggleType (c) {
         var cats = [...this.selectedTypes];
        if (cats.includes(c.value)) {
          cats.splice(cats.indexOf(c.value), 1);
          this.selectedTypes = cats;
        } else {
          cats.push(c.value);
          this.selectedTypes = cats;
        }
      },
      toggleTypesCompleteSelection() {
          if(this.allTypesSelected) {
            this.selectedTypes = [];
          } else {
            this.selectedTypes = _.map(this.lookups.event_type,'value');
          }
      },
      toggleStatus (c) {
         var cats = [...this.selectedStatuses];
        if (cats.includes(c.value)) {
          cats.splice(cats.indexOf(c.value), 1);
          this.selectedStatuses = cats;
        } else {
          cats.push(c.value);
          this.selectedStatuses = cats;
        }
      },
      toggleStatusesCompleteSelection() {
          if(this.allStatusesSelected) {
            this.selectedStatuses = [];
          } else {
            this.selectedStatuses = _.map(this.lookups.appointment_status,'value');
          }
      },
      // mergeEvents(){
      //   this.editedItem.event_ids = this.searchForEvent(this.mainEvent.start,this.mainEvent.end)
      // },
      moment: function (val) {
        return moment(val);
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      goToEvent({event}){
        if(event.id && event.appointment){
          //this.editedItem.event_id = event.id
          this.$router.push({ name: (this.user.type===1?'realizacja':'dzialanie'), params: { id: event.id } })
          //this.$store.dispatch( 'loadEventEdit', {slug:  this.$route.params.id} );
        }else if(event.id ){
          this.$router.push({ name: 'harmonogram', params: { id: event.id } })
        }
      },
      goToSubEvent(app){
        if(app.id){
          this.$router.push({ name: (this.user.type===1?'realizacja':'dzialanie'), params: { id: app.id } })
        }
      },
      searchForEvent(start,end){
        //wyszukuje w aktualnie wyświtlonych przyszłych eventach czasowo pasujących
        var ids = []
        _.filter(this.referenceEvents,{'readonly':false,'timed':true}).forEach(event => {
          if(start>=event.start && end<=event.end){
            ids.push(event.id);
          }
        });
        return ids;
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      updateReferenceEvents(){
        var currentOptions;
        if(this.lookups.categories.length>1){
          currentOptions = {...this.$store.getters.getAppointmentOptions};
          currentOptions.fromDate = this.calRangeStart.toISOString();
          currentOptions.toDate = this.calRangeEnd.endOf('day').toISOString();
          this.$store.dispatch( 'setAppointmentOptions', {options:currentOptions} )
        }else{
          this.$store.dispatch( 'loadLookups' );
          setTimeout(() => {
            currentOptions = {...this.$store.getters.getAppointmentOptions};
            currentOptions.fromDate = this.calRangeStart.toISOString();
            currentOptions.toDate = this.calRangeEnd.endOf('day').toISOString();
            this.$store.dispatch( 'setAppointmentOptions', {options:currentOptions} )
          }, 2000)
        }
      },
      updateRange ({ start, end }) {
        this.calRangeStart = moment(start.date);
        this.calRangeEnd = (this.type=='category' || this.type=='category_doc')? moment(end.date).subtract(1, "days") : moment(end.date);
        this.updateReferenceEvents();
      },
     
    },
  }
</script>

<style scoped lang="scss">

.v-event-draggable {
  padding-left: 6px;
  position:absolute;
  z-index:1;
}

.v-event-public {
  border:1px black dotted;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-appointment {
  position: absolute;
  left: 0;
  right: 0;
  background-color:#cc0839;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  z-index:0;

}

.v-event-sub-appointment {
  position: absolute;
  left: 0;
  right: 0;
  color:black;
  background-color:white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  opacity: 0.4;
  z-index:0;
  
}

.v-event-sub-appointment:hover{
  opacity: 0.5;
  z-index: 2;
}

.v-event-ref-appointment {
  position: absolute;
  left: 0;
  right: 0;
  color:black;
  z-index:0;
}

.v-event-ref-appointment-canceled {
  position: absolute;
  left: 0;
  right: 0;
  color:black;
  z-index:0;
  opacity: 0.2;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.noscroll{
  overflow-y: hidden;
}
</style>