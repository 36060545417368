import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/billings
    */
    getBillings: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/billings',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                selectedType:options.selectedType,
                selectedStatus:options.selectedStatus,
                selectedAction:options.selectedAction,
                fromDate:options.fromDate,
                toDate:options.toDate,
        }});
    },


    /*
        GET     /api/billings/search
    */
   searchBilling: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/billings/search',{
            params:{
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },

    /*
		PUT 	/api/billings
	*/
    putEditBilling: function(slug,action){
        return axios.put( HGO_CONFIG.API_URL + '/billings/' + slug,
            {
                action:action,
            }
        );
    },

    /*
		POST 	/api/billings
	*/
    postImportBilling: function(file_content){
        return axios.post( HGO_CONFIG.API_URL + '/billings',
            {
                file_content:file_content,
            }
        );
    },

}