import PrescriptionAPI from "../api/prescription";

export const prescriptions = {
    state: {
        products: [],
        productsLoadStatus: 0,

        packages: [],
        packagesLoadStatus: 0
    },

    actions: {
        getPrescriptionProducts({ state, commit, dispatch },data) {
            commit("setPrescriptionProducts", []);
            commit("setPrescriptionProductsLoadStatus", 1);
            PrescriptionAPI.getProducts({
                    q:data.q
                })
                .then(function(response) {
                    commit("setPrescriptionProducts", response.data);
                    commit("setPrescriptionProductsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPrescriptionProducts", []);
                    commit("setPrescriptionProductsLoadStatus", 3);
                });
         
        },
        getPrescriptionPackages({ state, commit, dispatch },data) {
            commit("setPrescriptionPackages", []);
            commit("setPrescriptionPackagesLoadStatus", 1);
            PrescriptionAPI.getPackages({
                    q:data.q
                })
                .then(function(response) {
                    commit("setPrescriptionPackages", response.data);
                    commit("setPrescriptionPackagesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPrescriptionPackages", []);
                    commit("setPrescriptionPackagesLoadStatus", 3);
                });
         
        },
        getPrescriptionCreate({ state, commit, dispatch },data) {
            commit("setAttachmentEditStatus", 1);

            PrescriptionAPI.getCreatePrescription(
                data.slug,
            )
                .then(function(response) {
                    commit("setAttachmentEditOrig", response.data);
                    commit("setEditedFlow", response.data);
                    commit("setAttachmentEditStatus", 2);
                })
                .catch(function(error) {
                    commit("setAttachmentEditStatus", 3);
                    commit("setAttachmentEditText", error.response.data.errors);
                });
        },
    },
    mutations: {
        setPrescriptionProductsLoadStatus(state, status) {
            state.productsLoadStatus = status;
        },
        setPrescriptionProducts(state, products) {
            state.products = products;
        },
        setPrescriptionPackagesLoadStatus(state, status) {
            state.packagesLoadStatus = status;
        },
        setPrescriptionPackages(state, packages) {
            state.packages = packages;
        },
    },

    getters: {
        getPrescriptionProductsLoadStatus(state) {
            return function(){
                return state.productsLoadStatus;
            }
        },
        getPrescriptionProducts(state) {
            return state.products;
        },
        getPrescriptionPackagesLoadStatus(state) {
            return function(){
                return state.packagesLoadStatus;
            }
        },
        getPrescriptionPackages(state) {
            return state.packages;
        },
    }
};
