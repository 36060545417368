<template>
    <v-container class="d-flex flex-column pa-0 ml-3">

        <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'dzialanie' }" color="secondary" icon><v-icon size="35">mdi-calendar-edit</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Umów</span>
        </v-container>

      <!-- <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn color="green darken-3" icon><v-icon size="35">mdi-clipboard-edit-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Zadanie</span>
        </v-container> -->

        <v-dialog v-if="user.permissions.tasks >= 5" v-model="taskDialog" max-width="700px">
                    <template v-slot:activator="{ on }">
                      <v-container v-if="user.permissions.tasks >= 5" class="menu pa-0 d-flex flex-column align-center">
                        <v-btn v-on="on" color="green darken-3" icon><v-icon size="35">mdi-clipboard-edit-outline</v-icon></v-btn>
                        <span class="text-caption font-weight-medium" style="font-size:0.5em;">Zadanie</span>
                      </v-container>
                    </template>
                    <task-editor :item-editing="false" @close="closeTask"></task-editor>
        </v-dialog>

        <!-- <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :to="{name: 'dzialanie' }" color="secondary" icon><v-icon size="35">mdi-calendar-edit</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Dzialanie</span>
        </v-container> -->

        <v-dialog v-if="user.permissions['patient'] >= 10" v-model="patientDialog" :max-width="patientEditing?'1000px':'500px'">
                    <template v-slot:activator="{ on }">
                      <v-container v-if="user.permissions.clinic >= 5" class="menu pa-0 d-flex flex-column align-center">
                        <v-btn v-on="on" color="blue darken-3" icon><v-icon size="35">mdi-human-edit</v-icon></v-btn>
                        <span class="text-caption font-weight-medium" style="font-size:0.5em;">Pacjent</span>
                      </v-container>
                    </template>
                    <patient-editor :item-editing="false" @close="closePatiente"></patient-editor>
        </v-dialog>
    <confirm ref="confirm"></confirm>
    </v-container>
</template>

<script>
import confirm from '../components/Confirm'
import PatientEditor from '../components/PatientEditor'
import TaskEditor from '../components/TaskEditor'
export default {
    components:{
      PatientEditor,
      TaskEditor,
      confirm
    },
    data(){
        return{
            defaultColor:'secondary', 
            selectedColor:'primary',
            patientDialog:false,
            taskDialog:false,
        }
    },
    mounted(){

    },
    watch:{
      patientDialog (val) {
        val || this.closePatiente()
      },
      taskDialog (val) {
        val || this.closeTask()
      },
      patientAddStatus(val){
        if(val==2){
          this.$refs.confirm.open('Pytanie', 'Przejść do karty pacjenta?', { color: 'primary' }).then((confirm) => {
            if(confirm){
              const id = this.patientEditedItem.id
              this.$store.dispatch( 'loadPatientEditDefault'); 
              this.$store.dispatch( 'loadPatientAddDefault');
              this.$router.push({name:'pacjent',params:{id:id}})
            }
        })
        }
      }
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
      patientEditing(){
        return this.$store.getters.getPatientEditing;
      },
      patientAddStatus(){
        return this.$store.getters.getPatientAddStatus();
      },
      patientEditedItem(){
        return this.$store.getters.getPatientEdit;
      },
    },
    methods:{
      closePatiente () {
        this.patientDialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadPatientEditDefault'); 
          this.$store.dispatch( 'loadPatientAddDefault');
          this.$store.commit('setPatientsRecommendationLookup',[]);
        }, 300)
      },
      closeTask () {
        this.taskDialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadTaskEditDefault'); 
          this.$store.dispatch( 'loadTaskAddDefault');
        }, 300)
      },
    }
}
</script>

<style scoped>
.menu{
  margin-bottom: 1em;
  padding-top:0px;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}
</style>