<template>
      <v-col>
          <v-card flat>
         <v-data-table
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-1"
            :loading="categoriesLoadStatus===1"
            :options.sync="tableOptions"
            :server-items-length="options.lineCount"
            hide-default-footer
            must-sort
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Działania</v-toolbar-title>
                
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                

                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Wyszukaj"
                      single-line
                      hide-details
                      class="search"
                      @keydown.enter="searchCategories"
                  ></v-text-field>
                  </template>
                    <span>Wpisz min 3 znaki i naciśnij Enter</span>
                </v-tooltip>
                 

                <v-spacer></v-spacer>

                <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>

                <v-divider
                  v-if="user.permissions['category'] >= 10"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-tooltip v-if="user.permissions['category'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="{name:'ustawienia.dzialanie',params:{}}" fab dark small><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                    <span>Dodaj rekord</span>
                </v-tooltip>

              </v-toolbar>
            </template>
            <template v-slot:item.icon="{ item }">
              <v-icon>{{item.icon}}</v-icon>
            </template>
            <template v-slot:item.actions="{ item }">

                <v-tooltip v-if="item.status && user.permissions['category'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="{name:'ustawienia.dzialanie',params:{id:item.id}}" icon><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && item.can_delete && user.permissions['category'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="deleteItem(item)" icon><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status  && user.permissions['category'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="reactivateItem(item)" icon><v-icon>mdi-delete-restore</v-icon></v-btn>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['category'] >= 10" right>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="forceDeleteItem(item)" icon><v-icon color="error">mdi-delete-forever</v-icon></v-btn>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                
            </template>

            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>

          </v-data-table> 
          <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && categoriesLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadCategories" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadCategories" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>

          </v-card>
      
      <confirm ref="confirm"></confirm>
      </v-col>
      

</template>

<script>
  import confirm from '../components/Confirm'

  export default {
    props:{
      reload: Boolean
    },
    components:{
      confirm,
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      search:'',
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: '',align: 'icon',sortable: false,value: 'icon',filterable:false,visible:true},
        { text: 'Nazwa', value: 'name',filterable:true,filterColumn:"name",visible:true},
        { text: 'Opis', value: 'description',filterable:true,filterColumn:"description",visible:true},
        { text: 'Status', value: 'status',filterable:false,filterColumn:"status",visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
    
      dialog (val) {
        val || this.close()
      },
      categoryEditStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      },
      categoryAddStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      },
      categoryDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      options:{
        get () {  
          return this.$store.getters.getCategoryOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setCategoryOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getCategoryOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      editedItem(){
        return this.$store.getters.getCategoryEdit;
      },
      tableItems(){
        return this.$store.getters.getCategories;
      },
      categoriesLoadStatus(){
        return this.$store.getters.getCategoriesLoadStatus;
      },
      categoryEditLoadStatus(){
        return this.$store.getters.getCategoryEditLoadStatus
      },
      categoryEditStatus(){
        return this.$store.getters.getCategoryEditStatus();
      },
      categoryAddStatus(){
        return this.$store.getters.getCategoryAddStatus();
      },
      categoryDeleteStatus(){
        return this.$store.getters.getCategoryDeleteStatus();
      },
      computedHeaders () {
        var h = _.filter(this.headers,function(o) { return o.visible; })

        // if(!this.showDeleted && this.user.permissions['category'] <= 5) return h.filter(h => h.value!=='status' && h.value!=='actions')
        // else if(this.showDeleted && this.user.permissions['category'] <= 5) return h.filter(h => h.value!=='actions')
        // else 
        if(!this.showDeleted) return h.filter(h => h.value!=='status')
        else return h
      },
    },
    mounted(){
      if(this.reload)this.loadCategories();
    },
    methods: {
      loadCategories(){
        this.search='';
        this.$store.dispatch( 'loadCategories' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreCategories' );
      },
      reactivateItem(item){
        this.$store.dispatch( 'editCategoryReactivate',{slug:item.id});
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz wyłączyć category?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteCategory', {slug:  item.id});
            }
        })
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać category na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteCategory', {slug:  item.id});
            }
        })
      },
      searchCategories(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchCategories',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>