import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'tiptap-vuetify/dist/main.css'
import pl from 'vuetify/es5/locale/pl'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
          light: {
            primary: '#2b2838',
            secondary: '#cc0839',
            accent: '#8c9eff',
            error: '#b71c1c',
            recepta: '#E8F5E9'
          },
        },
      },
    lang: {
        locales: { pl },
        current: 'pl',
    },
}

const vuetify = new Vuetify(opts)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

export default vuetify