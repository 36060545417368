export const Permissions = {
    methods: {
      // Brak dostępu 0
      // Dostęp ograniczony 5
      // Pełny dostęp 10
      // Administrator 20
        checkPermission(module,minPermission){
          return this.user.permissions[module] >= minPermission;
            // switch( module ){
            //     case 'desktop':
            //       return this.user.permissions[module] >= minPermission;
            //     case 'journal':
            //       return  this.user.group_id <= 5;
            //     case 'report':
            //       return this.user.group_id === 1;
            //     case 'dictionary':
            //       return true;
            //     case 'setting':
            //       return true;
            //     case 'mobile':
            //       return true;
            //     }
            //   return false;
          },
          checkModules(userPermissions,modules){
            var sum = 0
            modules.forEach(m => {
              sum += userPermissions[m];
            });
            return sum >=5;
          }
    }
  }