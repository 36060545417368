<template>
    <v-container :style="'height:'+ (height-45) +'px;'">
                          <v-tabs-items v-model="editedItem.tab" :style="'height:'+ (height-100) +'px;'">
                            <v-tab-item>
                               <v-row dense class="">
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('name')" v-model="editedItem.content.prescription.patient.name" label="Imię" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-5"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('surname')" v-model="editedItem.content.prescription.patient.surname" label="Nazwisko" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-3"><v-select :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('sex')" :items="lookups.patient_sex"  v-model="editedItem.content.prescription.patient.sex" hide-details="auto" label="Płeć"></v-select></v-col>
                              </v-row> 
                              <v-row dense class="mt-6">
                                <v-col class="col-6"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('address')" v-model="editedItem.content.prescription.patient.address" label="Ulica" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-3"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('house')"  v-model="editedItem.content.prescription.patient.house" label="Nr domu" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-3"><v-text-field :readonly="editedItem.content.prescription.status!==0" v-model="editedItem.content.prescription.patient.apartment" label="Nr mieszkania" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-3"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('post_code')" v-model="editedItem.content.prescription.patient.post_code" label="Kod pocztowy" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-6"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('city')" v-model="editedItem.content.prescription.patient.city" label="Miasto" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-3"><v-text-field :readonly="true" background-color="recepta" :error-messages="patientRequireError('country')" v-model="editedItem.content.prescription.patient.country" label="Kraj" hide-details="auto"></v-text-field></v-col>
                              </v-row> 
                              <v-row dense class="mt-6">
                                <v-col class="col-6"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="patientRequireError('pesel')" v-model="editedItem.content.prescription.patient.pesel" label="PESEL" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-6"><v-text-field
                                               background-color="recepta" 
                                               :readonly="editedItem.content.prescription.status!==0" 
                                               :error-messages="patientRequireError('birth_date')"
                                              v-model="editedItem.content.prescription.patient.birth_date"
                                              label="Data urodzenia"
                                              hint="RRRR-MM-DD"></v-text-field
                                 ></v-col>
                              </v-row> 
                               <v-row dense class="mt-6">
                                <v-col class="col-6"><v-text-field :readonly="editedItem.content.prescription.status!==0"  background-color="recepta" :error-messages="patientRequireError('gus')" v-model="editedItem.content.prescription.patient.gus" label="GUS TERC" hide-details="auto">
                                  <template v-slot:prepend-inner>
                                    <v-menu
                                      offset-y
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          icon small
                                          @click="searchGUS(editedItem.content.prescription.patient.city)"
                                          :disabled="!editedItem.content.prescription.patient.city"
                                        >
                                          <v-icon>
                                            mdi-map-search
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list dense v-if="patientsGUSLookup.length===0 && patientsGUSLookupLoadStatus===2 ">
                                        <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title>Brak danych</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>
                                     </v-list>
                                     <v-list dense v-else-if="patientsGUSLookup.length===0 && patientsGUSLookupLoadStatus===1 ">
                                        <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title>Pobieranie danych</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>
                                     </v-list>
                                     <v-list dense v-else>
                                        <v-list-item v-for="(gus) in patientsGUSLookup" :key="gus.value" @click="editedItem.content.prescription.patient.gus = gus.value;">
                                              <v-list-item-content @click="">
                                                <v-list-item-title>{{ gus.text }}</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>
                                     </v-list>
                                    </v-menu>
                                  </template>
                                  </v-text-field></v-col>
                                <v-col class="col-6"><v-select :readonly="editedItem.content.prescription.status!==0"  background-color="recepta" :error-messages="patientRequireError('insurance')" :items="lookups.patient_insurance" v-model="editedItem.content.prescription.patient.insurance" hide-details="auto" label="Ubezpieczenie"></v-select></v-col>
                              </v-row> 
                               <v-row dense class="mt-6">
                                <v-col class="col-6"><v-text-field :readonly="editedItem.content.prescription.status!==0" v-model="editedItem.content.prescription.patient.phone" label="Nr telefonu" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-6"><v-text-field :readonly="editedItem.content.prescription.status!==0" v-model="editedItem.content.prescription.patient.passport" label="Nr paszportu" ></v-text-field
                                 ></v-col>
                              </v-row> 
                            </v-tab-item>
                            <v-tab-item>
                              <h4>Lekarz</h4>
                               <v-row dense>
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="authorRequireError('name')" v-model="editedItem.content.prescription.author.name" label="Imię" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-5"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="authorRequireError('surname')" v-model="editedItem.content.prescription.author.surname" label="Nazwisko" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-3"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="authorRequireError('pwz')" v-model="editedItem.content.prescription.author.pwz" hide-details="auto" label="PWZ"></v-text-field></v-col>
                              </v-row> 
                              <h4 class="mt-6">Klinika</h4>
                               <v-row dense>
                                <v-col class="col-8"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('name')" v-model="editedItem.content.prescription.clinic.name" label="Nazwa" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('phone')" v-model="editedItem.content.prescription.clinic.phone" label="Nr telefonu" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('adress')" v-model="editedItem.content.prescription.clinic.address" label="Ulica" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-2"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('house')" v-model="editedItem.content.prescription.clinic.house" label="Nr domu" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-2"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('post_code')"  v-model="editedItem.content.prescription.clinic.post_code" label="Kod pocztowy" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('city')" v-model="editedItem.content.prescription.clinic.city" label="Miasto" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-4"><v-select :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('nfz')" v-model="editedItem.content.prescription.clinic.nfz" :items="lookups.nfz" label="Odział NFZ"></v-select></v-col>
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="clinicRequireError('regon')" v-model="editedItem.content.prescription.clinic.regon" label="REGON" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-4"><v-text-field :readonly="editedItem.content.prescription.status!==0"  v-model="editedItem.content.prescription.clinic.krs" label="KRS" hide-details="auto"></v-text-field></v-col>
                              </v-row> 
                              <h4>System</h4>
                               <v-row dense>
                                <v-col class="col-12"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="systemRequireError('iod')"  v-model="editedItem.content.prescription.oid" label="Identyfikator lokalny OID" hide-details="auto"></v-text-field></v-col>
                                <v-col class="col-12"><v-text-field :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="systemRequireError('rpwdl')" v-model="editedItem.content.prescription.rpwdl" label="Numery księgi rejestrowej RPWDL" hide-details="auto"></v-text-field></v-col>
                              </v-row> 
                            </v-tab-item>
                            <v-tab-item>
                              <v-row dense>
                                  <v-col class="col-12">
                                    <prescription-products-search :readonly="editedItem.content.prescription.status!==0"  ref="productSearch" @valueSelected="prescriptionProductName = $event" :selectedText="prescriptionProductName" />
                                  </v-col> 
                                  <v-col class="col-12">
                                    <v-select ref="packageSelect" :readonly="editedItem.content.prescription.status!==0" v-model="prescriptionProductPackage" :disabled="$store.getters.getPrescriptionPackages.length===0" :loading="$store.getters.getPrescriptionPackagesLoadStatus()===1" :items="$store.getters.getPrescriptionPackages" label="Opakowanie">
                                      <template v-slot:item="data">
                                        <v-list-item-avatar size="30">
                                          <v-btn text x-small fab
                                            class="green lighten-5"
                                          >
                                            RP
                                          </v-btn>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title>{{data.item.data.OPAKOWANIE}}, {{data.item.data.POSTAC}}</v-list-item-title>
                                          <v-list-item-subtitle><b>{{data.item.data.DAWKA}}</b></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-list-item-action-text>
                                            <v-btn small text class="mx-0" color="green darken-4" :disabled="!data.item.data.REFUNDACJA.POZIOMY_ODPLATNOSCI.includes('R')">REF</v-btn>
                                            <v-btn small text class="mx-0" color="green darken-4" :disabled="!data.item.data.REFUNDACJA.UPRAWNIENIA_DODATKOWE['75PLUS']">75+</v-btn>
                                            <v-btn small text class="mx-0" color="green darken-4" :disabled="!data.item.data.REFUNDACJA.UPRAWNIENIA_DODATKOWE['CIAZA']">CIĄŻA</v-btn>
                                          </v-list-item-action-text>
                                              <!-- <v-icon
                                                color="grey lighten-1"
                                              >
                                                mdi-star-outline
                                              </v-icon> -->
                                          </v-list-item-action>
                                      </template>
                                      <template v-slot:append-outer>
                                        <v-btn :disabled="$store.getters.getPrescriptionPackages.length===0 || prescriptionProductPackage==null || editedItem.content.prescription.medicines.length>=5" x-small color="secondary" @click="addMedicine">Dodaj</v-btn>
                                      </template>
                                    </v-select>
                                  </v-col> 
                                  <!-- <v-col>
                                    <v-btn x-small color="primary">Dodaj</v-btn>
                                  </v-col> -->
                              </v-row> 
                                <h4>Wybrane leki ({{editedItem.content.prescription.medicines.length}}/5)</h4>
                              <v-row dense>
                                <v-col>
                                   <v-responsive
                                      class="overflow-y-auto fill-height"
                                      :height="(height-240)"
                                  >
                                  <v-simple-table>
                                        <template v-slot:default>
                                          <tbody>
                                            <tr
                                                v-for="(element, index) in editedItem.content.prescription.medicines"
                                                :key="index"
                                                height="80"
                                                style="border-top:1px solid;"
                                              >
                                              <td><b>{{element.nazwa}}</b><br />
                                                {{element.opakowanie}}, {{element.postac}}, {{element.dawka}}
                                              </td>
                                              <td width="150" class="px-2"><v-select :readonly="editedItem.content.prescription.status!==0" background-color="recepta" :error-messages="medicinePaymentErrors(index)" dense @input="itemChanged=true" outlined :items="element.odplatnosc.params" v-model="element.odplatnosc.value" hide-details="auto" label="Odpłatność"></v-select></td>
                                              
                                              <td width="80" class="px-2"><v-text-field :readonly="editedItem.content.prescription.status!==0" dense background-color="recepta"  :error-messages="medicineQuantityErrors(index)" @input="itemChanged=true" outlined v-model="element.ilosc" hide-details="auto" type="number" label="Ilość"></v-text-field></td>
                                              
                                              <td width="150" class="px-2"><v-text-field :readonly="editedItem.content.prescription.status!==0" dense background-color="recepta"  :error-messages="medicineDosageErrors(index)" @input="itemChanged=true" outlined v-model="element.dawkowanie" hide-details="auto" hint="np. 1x1, 3x1" label="Dawkowanie"></v-text-field></td>
                                              <!-- <td width="20"><v-icon small class="page__grab-icon">mdi-arrow-all</v-icon></td>
                                              <td class="px-0" width="20"><v-icon size="25">{{element.type=='file' && element.content.file_type=='pdf'?'mdi-file-pdf-outline':element.type=='file' && element.content.file_type=='png'?'mdi-file-image-outline':element.type=='file' && element.content.file_type=='jpg'?'mdi-file-image-outline':element.type=='attachment'?'mdi-text-box-outline':'mdi-file'}}</v-icon></td>
                                              <td>{{element.type=='file'?element.content.file_name:element.type=='attachment'?element.content.header:''}}</td>
                                              <td class="px-1"><v-text-field @input="itemChanged=true" flat dense v-model="item['file_name']" required single-line hide-details="auto"></v-text-field></td>
                                              -->
                                              <td width="20"><v-icon :disabled="editedItem.content.prescription.status!==0" @click="deleteMedicine(index)" color="secondary" size="20" class="ml-1">mdi-delete</v-icon></td>

                                              </tr>
                                          </tbody>
                                        </template>
                                  </v-simple-table>
                                   </v-responsive>
                                </v-col>  
                              </v-row>
                            </v-tab-item>
                          <!-- <v-tab-item>
                            <v-row><v-col><v-btn color="secondary" @click="submitPrescription">Wystaw e-receptę</v-btn></v-col></v-row>

                            <v-row v-if="attachmentEditStatus===3 && attachmentEditText!=null">
                                <v-col class="d-flex flex-column align-center">
                                      <v-alert dense outlined type="error" v-html="">
                                          {{attachmentEditText.replace(/<[^>]+>/g, '')}}
                                      </v-alert>
                                </v-col>
                          </v-row>
                            
                          </v-tab-item> -->
                          </v-tabs-items>
                          <v-tabs @change="$v.newFile.$reset()" hide-slider height="30" v-model="editedItem.tab">
                            <v-tab>Pacjent</v-tab>
                            <v-tab>Wystawca</v-tab>
                            <v-tab>Lista leków</v-tab>
                          </v-tabs>
                      </v-container>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import PrescriptionProductsSearch from '../../components/PrescriptionProductsSearch.vue'

  export default {
    components:{
      PrescriptionProductsSearch
    },
    props: {
      height:{
        type:Number,
        default:0
      },
    },
    data: () => ({
      prescriptionProductName:null,
      prescriptionProductPackage:null,
    }),
    validations() {
       return {
          editedItem:{
            content:{
              prescription:{
                patient:{
                  name:{required},surname:{required},sex:{required},address:{required},house:{required},post_code:{required},city:{required},country:{required},pesel:{required},gus:{required},birth_date:{required},insurance:{required}
                },
                author:{
                  name:{required},surname:{required},pwz:{required}
                },
                clinic:{name:{required},address:{required},house:{required},post_code:{required},city:{required},nfz:{required},phone:{required},regon:{required}},
                medicines:{
                  $each:{
                    odplatnosc:{value:{required}},
                    dawkowanie:{required},
                    ilosc:{required}
                  }
                },
                oid:{required},
                rpwdl:{required}
              }
            }
          },
           newFile:{
             file_name:{required}
           },
        }
    },
    watch: {
      prescriptionPackagesLoadStatus(val){
        if(val===2 && this.$store.getters.getPrescriptionPackages.length>0){
          this.prescriptionProductPackage = this.$store.getters.getPrescriptionPackages[0].value
        }
      }
    },
    computed: {
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
      patientsGUSLookupLoadStatus(){
        return this.$store.getters.getPatientsGUSLookupLoadStatus;
      },
      patientsGUSLookup(){
        return this.$store.getters.getPatientsGUSLookup;
      },
      prescriptionPackagesLoadStatus(){
        return this.$store.getters.getPrescriptionPackagesLoadStatus();
      }
    },
    methods: {
      cancelPrescription(){

      },
      addMedicine(){
        //console.log(this.$store.getters.getPrescriptionPackages);
        var lek = _.find(this.$store.getters.getPrescriptionPackages,{'value':this.prescriptionProductPackage}).data
        this.editedItem.content.prescription.medicines.push({
          nazwa:lek.NAZWA_PRODUKT,
          dawka:lek.DAWKA,
          opakowanie:lek.OPAKOWANIE,
          postac:lek.POSTAC,
          recieptType:'RP',
          packageId:lek.ID_OPAKOWANIE,
          ilosc:1,
          dawkowanie:null,
          odplatnosc:{params:lek.REFUNDACJA.POZIOMY_ODPLATNOSCI,value:null},
          receptaRok:false
        });
        this.prescriptionProductName = null
        this.prescriptionProductPackage = null
        this.$refs.productSearch.clearContent()
        this.$emit('changed')
      },
      deleteMedicine(index){
        this.editedItem.content.prescription.medicines.splice(index,1);
        this.$emit('changed')
      },
      searchGUS(city){
        this.$store.dispatch( 'searchPatientsGUSLookup', {q:  city});
      },
      patientRequireError(param){
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription.patient[param] || !this.$v.editedItem.content.prescription.patient[param].$dirty) return errors
        !this.$v.editedItem.content.prescription.patient[param].required && errors.push('Pole wymagane')
        return errors
      },
      authorRequireError(param){
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription.author[param] || !this.$v.editedItem.content.prescription.author[param].$dirty) return errors
        !this.$v.editedItem.content.prescription.author[param].required && errors.push('Pole wymagane')
        return errors
      },
      clinicRequireError(param){
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription.clinic[param] || !this.$v.editedItem.content.prescription.clinic[param].$dirty) return errors
        !this.$v.editedItem.content.prescription.clinic[param].required && errors.push('Pole wymagane')
        return errors
      },
      systemRequireError(param){
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription[param] || !this.$v.editedItem.content.prescription[param].$dirty) return errors
        !this.$v.editedItem.content.prescription[param].required && errors.push('Pole wymagane')
        return errors
      },
      medicinePaymentErrors (index) {
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription.medicines.$each[index] || !this.$v.editedItem.content.prescription.medicines.$each[index].odplatnosc.value.$dirty) return errors
        !this.$v.editedItem.content.prescription.medicines.$each[index].odplatnosc.value.required && errors.push('Pole wymagane')
        return errors
      },
      medicineQuantityErrors (index) {
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription.medicines.$each[index] || !this.$v.editedItem.content.prescription.medicines.$each[index].ilosc.$dirty) return errors
        !this.$v.editedItem.content.prescription.medicines.$each[index].ilosc.required && errors.push('Pole wymagane')
        return errors
      },
      medicineDosageErrors (index) {
        const errors = []
        if (!this.$v.editedItem.content.prescription || !this.$v.editedItem.content.prescription.medicines.$each[index] || !this.$v.editedItem.content.prescription.medicines.$each[index].dawkowanie.$dirty) return errors
        !this.$v.editedItem.content.prescription.medicines.$each[index].dawkowanie.required && errors.push('Pole wymagane')
        return errors
      },
      submitPrescription(){
        this.$v.editedItem.$touch()
        if (this.$v.editedItem.$invalid) {
          if(this.$v.editedItem.content.prescription.patient.$invalid) this.editedItem.tab = 0;
          else if(this.$v.editedItem.content.prescription.author.$invalid) this.editedItem.tab = 1;
          else if(this.$v.editedItem.content.prescription.clinic.$invalid) this.editedItem.tab = 1;
          else if(this.$v.editedItem.content.prescription.medicines.$invalid) this.editedItem.tab = 2;
          else if(this.$v.editedItem.content.prescription.$invalid) this.editedItem.tab = 1;
        } else {
            this.itemChanged = false;
              this.$store.dispatch( 'getPrescriptionCreate', {
                slug: this.editedItem.id
              });
        }
      }
    },
  }
</script>