var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.tableItems,"loading":_vm.eventsLoadStatus===1,"options":_vm.tableOptions,"server-items-length":_vm.options.lineCount,"hide-default-footer":"","must-sort":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Harmonogram")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEvents($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[_c('span',[_vm._v("Wpisz min 3 znaki i naciśnij Enter")])]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date",attrs:{"hide-details":"","clearable":"","label":"Od","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date",attrs:{"hide-details":"","clearable":"","label":"Do","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.toDateMenu = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-select',{staticClass:"choose_type",attrs:{"clearable":"","items":_vm.lookups.event_type,"hide-details":"","label":"Rodzaj"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":"Pokaż wyłączone"},model:{value:(_vm.showDeleted),callback:function ($$v) {_vm.showDeleted=$$v},expression:"showDeleted"}}),(_vm.user.permissions['event'] >= 10)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.user.permissions['event'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{name:'harmonogram',params:{}},"fab":"","dark":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2503341291)},[_c('span',[_vm._v("Dodaj rekord")])]):_vm._e()],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.all_day?item.date_from+' - '+item.date_to:item.date_from.split(' ')[0])+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.all_day?'':item.date_from.split(' ')[1]+' - '+item.date_to.split(' ')[1])+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find(_vm.lookups.event_type,{'value':item.type}).text)+" ")]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.public),callback:function ($$v) {_vm.$set(item, "public", $$v)},expression:"item.public"}})]}},{key:"item.interval",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.interval?_vm._.find(_vm.lookups.interval,{'value':item.interval}).text:null)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status && _vm.user.permissions['event'] >= 10)?_c('v-btn',{attrs:{"to":{name:'harmonogram',params:{id:item.id}},"x-small":"","color":"secondary"}},[_vm._v("Detale")]):_vm._e(),(!item.status  && _vm.user.permissions['event'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.reactivateItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-restore")])],1)]}}],null,true)},[_c('span',[_vm._v("Przywróć")])]):_vm._e(),(!item.status && item.can_delete  && _vm.user.permissions['event'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.forceDeleteItem(item)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete-forever")])],1)]}}],null,true)},[_c('span',[_vm._v("Usuń na stałe")])]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}])}),_c('div',{staticClass:"text-center pt-2"},[(!_vm.options.itemAdded && _vm.options.lineCount-_vm.tableItems.length>0 && _vm.eventsLoadStatus===2)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadMore}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Załaduj więcej ("+_vm._s(_vm.options.lineCount-_vm.tableItems.length)+")")],1):(_vm.options.itemAdded && _vm.options.lineCount-_vm.tableItems.length>0)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadEvents}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Odśwież tabele aby móc załadować więcej ("+_vm._s(_vm.options.lineCount-_vm.tableItems.length)+")")],1):(_vm.options.itemSearched)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.loadEvents}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Anuluj wyszukiwanie")],1):_vm._e()],1)],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }