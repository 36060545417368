import { unset } from "lodash";
import TemplateAPI from "../api/template";

export const templates = {
    state: {
        templates: [],
        templateOptions:{
            selectedType:undefined,
        },
        templatesLoadStatus: 0,
        templateAddStatus: 0,
        templateAddText: "",

        templateEdit: {
            id: undefined,
            type:undefined,
            name: undefined,
            body:undefined,
            files:[],
            content:{},
        },
        templateEditDefault: {
            id: undefined,
            type:undefined,
            name: undefined,
            body:undefined,
            files:[],
            content:{},
        },
        templateEditLoadStatus: 0,
        templateEditStatus: 0,
        templateEditText: "",

        templateDeleteStatus: 0,
        templateDeleteText: "",
    },

    actions: {
        loadTemplates({ state, commit, dispatch }) {
            commit("setTemplates", []);
            commit("setTemplatesLoadStatus", 1);
                TemplateAPI.getTemplates({
                    selectedType:state.templateOptions.selectedType,
                })
                .then(function(response) {
                    commit("setTemplates", response.data);
                    commit("setTemplatesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setTemplates", []);
                    commit("setTemplatesLoadStatus", 3);
                });
         
        },
        setTemplateOptions({ state, commit, dispatch },data){
            commit("setTemplateOptions",data.options);
            dispatch("loadTemplates");
        },
       
        addTemplate({ commit, state, dispatch }, data) {
            commit("setTemplateAddStatus", 1);
            commit("setTemplateAddText", "");

            TemplateAPI.postAddNewTemplate(
                data.type,
                data.name,
                data.body,
                data.files,
                data.contens,

            )
                .then(function(response) {
                    commit("setTemplateAddStatus", 2);
                    commit("setAddedTemplate",response.data);
                })
                .catch(function(error) {
                    commit("setTemplateAddStatus", 3);
                    commit("setTemplateAddText", error.response.data.errors);
                });
        },

        // loadTemplateEdit({ commit }, data) {
        //     commit("setTemplateEditLoadStatus", 1);
        //     commit("setTemplateEditStatus", 0);
        //     commit("setTemplateEditText", "");

        //     TemplateAPI.getTemplateEdit(data.slug)
        //         .then(function(response) {
        //             commit("setReferenceEvents", response.data['references']);
        //             commit("setReferenceTemplates", response.data['referenceTemplates']);
        //             unset(response.data['references']);
        //             unset(response.data['referenceTemplates']);
        //             commit("setTemplateEdit", response.data);
        //             commit("setTemplateEditLoadStatus", 2);
        //         })
        //         .catch(function() {
        //             commit("setTemplateEdit", {});
        //             commit("setTemplateEditLoadStatus", 3);
        //         });
        // },
        loadTemplateEditDefault({ commit }) {
            commit("setTemplateEditDefault");
        },
        // loadTemplateAddDefault({ commit }) {
        //     commit("setTemplateAddStatus", 0);
        //     commit("setTemplateAddText", "");
        // },
        editTemplate({ commit, state, dispatch }, data) {
            commit("setTemplateEditStatus", 1);

            TemplateAPI.putEditTemplate(
                data.slug,
                data.body,
                data.files,
                data.content,
            )
                .then(function(response) {
                    commit("setTemplateEditStatus", 2);
                    commit("setEditedTemplate",response.data)
                })
                .catch(function(error) {
                    commit("setTemplateEditStatus", 3);
                    commit("setTemplateEditText", error.response.data.errors);
                });
        },
       
        forceDeleteTemplate({ commit, state, dispatch }, data) {
            commit("setTemplateDeleteStatus", 1);

            TemplateAPI.forceDeleteTemplate(data.slug)
                .then(function(response) {
                    commit("setTemplateDeleteStatus", 2);
                    commit("setDeletedTemplate",response.data)
                })
                .catch(function() {
                    commit("setTemplateDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setTemplateOptions(state,options){
            state.templateOptions = options;
        },
        setTemplatesLoadStatus(state, status) {
            state.templatesLoadStatus = status;
        },
        setTemplates(state, templates) {
            state.templates = templates;
        },
        setTemplateAddStatus(state, status) {
            state.templateAddStatus = status;
        },
        setTemplateAddText(state, text) {
            state.templateAddText = text;
        },
        setTemplateEdit(state, template) {
            state.templateEdit = template;
        },
        setEditedTemplate(state, template) {
            var index = _.findIndex(state.templates,{'id':template.id});
            state.templates[index] = template;
        },
        setAddedTemplate(state, template) {
            state.templates.push(template);
        },
        setDeletedTemplate(state, template) {
            var index = _.findIndex(state.templates,{'id':template.id});
            state.templates.splice(index,1);
        },
        setTemplateEditDefault(state) {
            state.templateEdit = { ...state.templateEditDefault };
        },
        setTemplateEditStatus(state, status) {
            state.templateEditStatus = status;
        },
        setTemplateEditText(state, text) {
            state.templateEditText = text;
        },
        setTemplateEditLoadStatus(state, status) {
            state.templateEditLoadStatus = status;
        },
        setTemplateDeleteStatus(state, status) {
            state.templateDeleteStatus = status;
        },
        setTemplateDeletedText(state, text) {
            state.templateDeleteText = text;
        }
    },

    getters: {
        getTemplateOptions(state){
            return state.templateOptions;
        },
        getTemplatesLoadStatus(state) {
            return function(){
                return state.templatesLoadStatus;
            }
        },
        getTemplates(state) {
            return state.templates;
        },
        getTemplateAddStatus(state) {
            return function(){
                return state.templateAddStatus;
            } 
        },
        getTemplateAddText(state) {
            return state.templateAddText;
        },
        getTemplateEdit(state) {
            return state.templateEdit;
        },
        getTemplateEditStatus(state) {
          return function(){
            return state.templateEditStatus;
          }
        },
        getTemplateEditText(state) {
            return state.templateEditText;
        },
        getTemplateEditLoadStatus(state) {
            return function(){
                return state.templateEditLoadStatus;
            }
        },
        getTemplateDeleteStatus(state) {
            return function(){
                return state.templateDeleteStatus;
            }
        },
        getTemplateDeleteText(state) {
            return state.templateDeleteText;
        }
    }
};
