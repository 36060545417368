<template>
  <v-row>
    <v-col class="col-12">
      <h2 class="mt-5">STREFA PACJENTA - REJESTRACJA KONSULTACJI</h2>
       <v-progress-linear :indeterminate="registrationAddStatus===1" color="#cc0839" class="mt-2 mb-5"></v-progress-linear> 
       <v-dialog v-model="dialogCode" max-width="500">
                  <v-card>
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                      Potwierdzenie rejestracji konsultacji
                    </v-card-title>

                    <v-card-text>
                      <v-row class="pl-1">
                        <v-col class="col-8">
                           <v-text-field v-model="code" :error-messages="codeErrors" required  label="Przepisz kod otrzymany w SMS"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="registrationAddStatus===5">
                                <v-col class="d-flex flex-column align-center">
                                      <v-alert dense outlined type="error">
                                          Nieprawidłowy kod
                                      </v-alert>
                                </v-col>
                          </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn color="gray" text @click="resendCode">Wyślij kod ponownie</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close">Anuluj</v-btn>
                      <v-btn @click="handleSubmit" dark color="#cc0839">Zatwierdź</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog> 
          <v-dialog v-model="dialogSuccess" max-width="500">
                  <v-card>
                    <v-card-title>
                      <img class="mr-3" height="50" src="/icon.png" />
                     Potwierdzenie rejestracji konsultacji
                    </v-card-title>

                    <v-card-text>
                      <v-row class="pl-1">
                        <v-col class="d-flex flex-column align-center">
                           <h3 class="mb-2">Dziękujemy za rejestracje</h3>
                           <p class="mb-0">Na podany adres e-mail została wysłana wiadomość z potwierdzeniem</p>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex flex-column align-center">
                      <v-btn @click="end" dark color="#cc0839">Zakończ</v-btn>
                    </v-card-actions>
                  </v-card>
          </v-dialog> 
      <v-row>
        <v-col v-if="!editedItem.event_id">
          <v-card height="500">
            <v-sheet height="64px">
              <v-toolbar flat>
                <v-btn
                  dense
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="prev"
                  >Poprzedni tydzień</v-btn
                >
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  dense
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="next"
                  >Kolejny tydzień</v-btn
                >
              </v-toolbar>
            </v-sheet>
            <v-sheet height="436">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="secondary"
                :events="events"
                type="week"
                @change="updateRange"
                @click:event="goToEvent"
                :weekdays="weekDays"
                :first-time="minTime"
                :interval-minutes="calIntervalMinutes"
                :interval-count="calIntervalCount"
                :short-intervals="false"
                :short-weekdays="false"
                event-overlap-mode="column"
              >
                <template v-slot:event="{  event, timed, eventSummary }">
                        <div class="d-flex flex-column align-center fill-height justify-center text-h6">
                          <strong>{{event.name}}</strong>
                        </div>
                        
                      </template>
              </v-calendar>
            </v-sheet>
          </v-card>
        </v-col>
        <v-col v-if="editedItem.event_id">
            <v-card height="400" >
              <v-row class="px-2">
                    <v-col class="col-12"><h4>Informacje o pacjencie</h4></v-col>
                    <v-col class="col-12"><v-text-field v-model="editedItem.name" :error-messages="nameErrors"  dense outlined  label="Imię" /></v-col>
                    <v-col class="col-12"><v-text-field v-model="editedItem.surname" dense :error-messages="surnameErrors" outlined  label="Nazwisko" /></v-col>
                    <v-col class="col-12"><v-text-field v-model="editedItem.email" dense :error-messages="emailErrors" outlined  label="Adres e-mail" /></v-col>
                    <v-col class="col-12"><v-text-field v-model="editedItem.phone" dense :error-messages="phoneErrors" outlined  label="Numer telefonu" /></v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col v-if="editedItem.event_id">
            <v-card height="400" >
              <v-row class="px-2">
                <v-col class="col-12"><h4>Podsumowanie rejestracji</h4></v-col>
                <v-col class="col-6 text-end"><h4>Data</h4></v-col><v-col class="col-6 text-end"><h3>{{moment(editedItem.date_from).format('LL')}}</h3></v-col>
                <v-col class="col-6 text-end"><h4>Godzina</h4></v-col><v-col class="col-6 text-end"><h3>{{moment(editedItem.date_from).format('HH:mm')}}</h3></v-col>
                <v-col class="col-12"><h4>Informacje dodatkowe</h4></v-col>
                <v-col class="col-12"><p>Link do wizyty zostanie wysłany na wskazany adres e-mail. Dodatkowo zostanie wysłane przypomnienie na telefon w dniu wizyty.</p></v-col>
             </v-row>
            </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="editedItem.event_id" class="col-6 text-start">
        <v-btn large @click="end" text>Przerwij</v-btn>
    </v-col>
    <v-col v-if="editedItem.event_id" class="col-6 text-end">
        <v-btn large @click="handleSubmit" dark color="#cc0839">Potwierdź termin</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment';
moment.locale('pl');

import required from 'vuelidate/lib/validators/required'
import email from 'vuelidate/lib/validators/email'
import numeric from 'vuelidate/lib/validators/numeric'
export default {
  data: () => ({
    dialogCode:false,
    dialogSuccess:false,
    focus:null,
    weekDays: [1, 2, 3, 4, 5, 6, 0],
    extendOriginal: null,
    calRangeStart: null,
    calRangeEnd: null,
    calIntervalMinutes:30,
    dialogCode:false,
    dialogSuccess:false,
    code:null,
    editedItem:{
        name:null,
        surname:null,
        email:null,
        phone:null,
        date_from:null,
        date_to:null,
        event_id:null
    }
  }),
  validations(){
    if(this.dialogCode){
      return {
        code:{required},
        editedItem:{
            name:{required},
            surname:{required},
            email:{required,email},
            phone:{required,numeric},
        },
    }
    }else{
      return {
        editedItem:{
            name:{required},
            surname:{required},
            email:{required,email},
            phone:{required,numeric},
        },
      }
    }
  },
  watch:{
    registrationAddStatus(val){
      if(val===2){//ok, dodano
        this.dialogCode = false;
        this.dialogSuccess = true;
      }else if(val===5){//nieprawidłowy PIN

      }else if(val===4){//PIN wymagany
        this.code = null;
        this.dialogCode = true;
      }
    },
  },
  computed: {
    lookups() {
      return this.$store.getters.getLookups;
    },
    registrationAddStatus(){
      return this.$store.getters.getReservationAddStatus();
    },
    events() {
      return this.referenceEvents;
      // if(this.mainEvent && this.mainEvent.event_ids.length==0)return _.concat(this.mainEvent,this.referenceEvents);
      // //else if(this.mainEvent && !this.itemEditing)return _.concat(this.mainEvent,this.referenceEvents);
      // else return _.concat(this.mainEvents,this.referenceEvents);
    },
    minTime(){
      if(this.events.length>0){
        return this.$store.getters.getPublicEventsMinTime;
      }else{
        return "8:00";
      }
    },
    calIntervalCount(){
      if(this.events.length>0){
        return moment.duration(
            moment('2020-01-01 ' + this.$store.getters.getPublicEventsMaxTime).diff(moment('2020-01-01 ' + this.$store.getters.getPublicEventsMinTime)))
            .as('minutes')/this.calIntervalMinutes;
      }else{//12h
        return 12/(this.calIntervalMinutes/60);
      }
    },
    referenceEvents() {
      var referenceEvents = [];
      this.$store.getters.getPublicEvents.forEach((event) => {
        referenceEvents.push({
          id: event.id,
          name: event.desc,
          color: 'green',
          start: moment(event.date_from).valueOf(),
          end: moment(event.date_to).valueOf(),
          timed:true
        });
      });
      return referenceEvents;
    },
    nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Imię jest wymagane')
        return errors
    },
    surnameErrors () {
        const errors = []
        if (!this.$v.editedItem.surname.$dirty) return errors
        !this.$v.editedItem.surname.required && errors.push('Nazwisko jest wymagane')
        return errors
    },
    emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.required && errors.push('Adres e-mail jest wymagane');
        !this.$v.editedItem.email.email && errors.push('Adres e-mail jest niepoprawny');
        return errors
    },
    phoneErrors () {
        const errors = []
        if (!this.$v.editedItem.phone.$dirty) return errors
        !this.$v.editedItem.phone.required && errors.push('Numer telefonu jest wymagany');
        !this.$v.editedItem.phone.numeric && errors.push('Może zawierać tylko cyfry');
        return errors
    },
    codeErrors () {
        const errors = []
        if (!this.dialogCode || !this.$v.code.$dirty) return errors
        !this.$v.code.required && errors.push('Kod jest wymagany')
        return errors
    },
  },
  methods: {
    goToEvent({ event }) {
      this.editedItem.date_from = moment(event.start).toISOString()
      this.editedItem.date_to = moment(event.end).toISOString()
      this.editedItem.event_id = event.id
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    moment: function (val) {
      return moment(val);
    },
    updateReferenceEvents() {
      this.$store.dispatch("loadPublicEvents", {
          date_from: this.calRangeStart.toISOString(),
          date_to: this.calRangeEnd.endOf("day").toISOString()
      });
    },
    updateRange({ start, end }) {
      this.calRangeStart = moment(start.date);
      this.calRangeEnd = moment(end.date);
      this.updateReferenceEvents();
    },
    close(){
      this.dialogCode = false;
      this.code = null;
      this.editedItem.date_from = null
      this.editedItem.date_to = null
      this.editedItem.event_id = null
    },
    end(){
      this.code = null;
      this.editedItem.date_from = null
      this.editedItem.date_to = null
      this.editedItem.event_id = null
      this.dialogSuccess = false
      this.$router.push({name:'portal.pulpit'})
    },
    resendCode(){
      this.code = null;
      this.dialogCode = false;
      this.handleSubmit();
    },
    handleSubmit () {
        this.$v.$touch()

        if (this.$v.$invalid) {

        } else {
              this.$store.dispatch( 'addReservation', {
                  name:  this.editedItem.name,
                  surname:  this.editedItem.surname,
                  email:this.editedItem.email,
                  phone:this.editedItem.phone,
                  date_from:this.editedItem.date_from,
                  date_to:this.editedItem.date_to,
                  event_id:this.editedItem.event_id,
                  code:  this.code,
              });
        }
      },
  },
};
</script>