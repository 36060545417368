import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/shared/lookups
    */
    getLookups: function(){
        return axios.get( HGO_CONFIG.API_URL + '/shared/lookups' );
    },

    /*
        GET     /api/shared/lookups/role
    */
    getRoleLookup: function(){
        return axios.get( HGO_CONFIG.API_URL + '/shared/lookups/role' );
    },

     /*
        GET     /api/shared/lookups/user
    */
    getUserLookup: function(){
        return axios.get( HGO_CONFIG.API_URL + '/shared/lookups/user' );
    },

/*
		POST 	/api/portal/results
	*/
    postNewResults: function( name,surname,email,phone,body,files,code){
        return axios.post( HGO_CONFIG.WEB_URL + '/portal/results',
            {
                name: name,
                surname:surname,
                email:email,
                phone:phone,
                body:body,
                my_files:files,
                code:code,
            }
        );
    },

    /*
		POST 	/api/portal/reservation
	*/
    postNewReservation: function( name,surname,email,phone,date_from,date_to,event_id,code){
        return axios.post( HGO_CONFIG.WEB_URL + '/portal/reservation',
            {
                name: name,
                surname:surname,
                email:email,
                phone:phone,
                date_from:date_from,
                date_to:date_to,
                event_id:event_id,
                code:code,
            }
        );
    },

    /*
        GET     /api/portal/events
    */
   getPublicEvents: function(data){
        return axios.get( HGO_CONFIG.WEB_URL + '/portal/events',{
            params:{
                date_from:data.date_from,
                date_to:data.date_to,
        }});
    },
}