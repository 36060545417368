<template>
         <v-data-table
            dense
            :key="componentKey"
            :headers="headers"
            :items="tableItems"
            hide-default-footer
            must-sort
          >
            <template v-slot:footer>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-dialog v-if="user.permissions['clinics'] >= 10" v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" x-small class="mb-2 mt-2"  color="secondary">
                        Dodaj
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                            <v-col cols="12">
                              <v-text-field v-model="selectedItemName" :error-messages="selectedItemNameErrors" required @input="$v.selectedItemName.$touch()"  @blur="$v.selectedItemName.$touch()" label="Nazwa"></v-text-field>                            
                            </v-col>
                            <v-col cols="12">
                               <v-text-field v-model="selectedItemPhone" label="Telefon"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                               <v-text-field v-model="selectedItemEmail" :error-messages="selectedItemEmailErrors" @input="$v.selectedItemEmail.$touch()"  @blur="$v.selectedItemEmail.$touch()" label="Email"></v-text-field>
                            </v-col>
                        
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close">Anuluj</v-btn>
                      <v-btn color="secondary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="user.permissions['clinics'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="user.permissions['clinics'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  </template>
                  <span>Usuń</span>
                </v-tooltip>
                <confirm ref="confirm"></confirm>
            </template>
            
          </v-data-table> 
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import email from 'vuelidate/lib/validators/email'

  export default {
    components:{
      confirm,
    },
    data: () => ({
      componentKey:0,
      dialog: false,
      itemEditing:false,
      headers: [
        { text: 'Nazwa', value: 'name'},
        { text: 'Telefon', value: 'phone' },
        { text: 'Adres e-mail', value: 'email'},
        { text: '', value: 'actions', sortable: false},
      ],
      selectedIndex:null,
      selectedItemId:null,
      selectedItemName:null,
      selectedItemPhone:null,
      selectedItemEmail:null,
    }),
    validations: {
        selectedItemName: {
          required,
        },
        selectedItemEmail:{
            email
        }
    },
    watch: {
      dialog (val) {
        this.$v.$reset()
        val || this.close()
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      tableItems(){
        return this.$store.getters.getClinicEdit.contacts;
      },
      formTitle () {
        return !this.itemEditing ? 'Nowy kontakt' : 'Edycja kontaktu'
      },
      selectedItemNameErrors () {
        const errors = []
        if (!this.$v.selectedItemName.$dirty) return errors
        !this.$v.selectedItemName.required && errors.push('Nazwa jest wymagana')
        return errors
      },
       selectedItemEmailErrors () {
        const errors = []
        if (!this.$v.selectedItemEmail.$dirty) return errors
        !this.$v.selectedItemEmail.email && errors.push('Adres e-mail jest niepoprawny')
        return errors
      },
    },
   
    methods: {
      editItem (item) {
        this.itemEditing = true
        this.selectedIndex = _.indexOf(this.tableItems,item)
        this.selectedItemId = item.id
        this.selectedItemName = item.name
        this.selectedItemPhone = item.phone
        this.selectedItemEmail = item.email
        this.dialog = true
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać kontakt?', { color: 'error' }).then((confirm) => {
            if(confirm){
               _.pull(this.tableItems,item);
               this.componentKey++;
               //this.$store.dispatch( 'forceDeleteClinic', {slug:  item.id});
            }
        })
      },
      close () {
        this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.selectedIndex = null
          this.selectedItemId = null
          this.selectedItemName = null
          this.selectedItemPhone = null
          this.selectedItemEmail = null
          this.itemEditing = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
                this.tableItems[this.selectedIndex] = {
                    name:this.selectedItemName,
                    phone:this.selectedItemPhone,
                    email:this.selectedItemEmail
                };
            } else {//new item
                this.tableItems.push({
                    name:this.selectedItemName,
                    phone:this.selectedItemPhone,
                    email:this.selectedItemEmail
                });
            }
                
            this.componentKey++;
            this.close();
        }
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>
