import { HGO_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/roles
    */
    getRoles: function(options){
        return axios.get( HGO_CONFIG.API_URL + '/roles',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/roles/search
    */
   searchRoles: function(options){
    return axios.get( HGO_CONFIG.API_URL + '/roles/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

    /*
		POST 	/api/roles
	*/
    postAddNewRole: function( name,description, permissions){
        return axios.post( HGO_CONFIG.API_URL + '/roles',
            {
                name: name,
                description:description,
                permissions:permissions
            }
        );
    },

    /*
		GET 	/api/roles/{slug}/edit
	*/
    getRoleEdit: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/roles/' + slug + '/edit' );
	},

    /*
		GET 	/api/roles/{slug}permission
	*/
    getPermissionList: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/roles/' + slug + '/permission' );
	},

    /*
		GET 	/api/roles/permission
	*/
    getEmptyPermissionList: function( slug ){
		return axios.get( HGO_CONFIG.API_URL + '/roles/permission' );
	},

    /*
		PUT 	/api/roles/{slug}
	*/
    putEditRole: function(slug, name,description,permissions){
        return axios.put( HGO_CONFIG.API_URL + '/roles/'+ slug,
            {
                name: name,
                description:description,
                permissions:permissions
            }
        );
    },

     /*
		GET 	/api/roles/reactivate/{slug}
	*/
    putReactivateRole: function(slug){
        return axios.get( HGO_CONFIG.API_URL + '/roles/reactivate/' + slug);
    },

    /*
		DELETE /api/roles/{slug}
	*/
    deleteRole: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/roles/' + slug );
    },
    
    /*
		DELETE /api/roles/{slug}/force
	*/
    forceDeleteRole: function( slug ){
		return axios.delete( HGO_CONFIG.API_URL + '/roles/' + slug + '/force' );
	}
}