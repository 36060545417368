<template>
      <v-col>
          <v-card flat>
         <v-data-table
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-2"
            :loading="clinicServicesLoadStatus===1"
            hide-default-footer
            must-sort
            disable-pagination
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Diagnostyka</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>

                <v-divider
                  v-if="user.permissions['clinics'] >= 10"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-dialog v-if="user.permissions['clinics'] >= 10" v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="newItem" small class="mb-2 mt-2" fab dark color="primary">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card  :loading="clinicServiceAddStatus===1 || clinicServiceEditLoadStatus===1 || clinicServiceEditStatus===1">
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                          <v-row v-if="clinicServiceAddStatus===3 && clinicServiceAddText=='clinic_service_exists'">
                                <v-col class="d-flex flex-column align-center">
                                      <v-alert dense outlined type="error">
                                          Badanie jest już przypisane do kliniki. (może być wyłączone)
                                      </v-alert>
                                </v-col>
                          </v-row>
                          <v-row v-if="!itemEditing">
                            <v-col cols="1" class="py-0">
                              <v-radio-group v-model="serviceRadio" >
                                <v-radio
                                  color="secondary"
                                  class="mt-1"
                                  value="choose"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>  
                            <v-col cols="11" class="py-0">
                                <service-live-search :disabled="serviceRadio=='new'" clearable :error-messages="service_idErrors" :selectedService="editedItem.service_id" @serviceSelected="editedItem.service_id = $event" />
                            </v-col>                 
                          </v-row>
                          <v-row v-if="!itemEditing" class="mt-0">
                            <v-col cols="1" class="py-0">
                              <v-radio-group v-model="serviceRadio" >
                                <v-radio
                                  color="secondary"
                                  class="mt-1"
                                  value="new"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>  
                            <v-col cols="11" class="py-0">
                              <v-text-field class="mt-2" :disabled="serviceRadio=='choose'" v-model="editedItem.service_name" :error-messages="service_nameErrors" required @input="$v.editedItem.service_name.$touch()"  @blur="$v.editedItem.service_name.$touch()" label="Nazwa diagnosyki"></v-text-field>
                              <v-row>
                                 <v-col cols="6">
                                    <v-text-field :disabled="serviceRadio=='choose'" dense v-model="editedItem.service_code" :error-messages="service_codeErrors" required @input="$v.editedItem.service_code.$touch()"  @blur="$v.editedItem.service_code.$touch()" label="Kod"></v-text-field>                            
                                  </v-col>
                                  <v-col cols="6">
                                    <v-select :disabled="serviceRadio=='choose'" dense  v-model="editedItem.service_category_id" :items="lookups.categories" :error-messages="service_category_idErrors" required @input="$v.editedItem.service_category_id.$touch()"  @blur="$v.editedItem.service_category_id.$touch()" label="Działanie"></v-select>
                                  </v-col>
                              </v-row>
                            </v-col>                 
                          </v-row>
                          <v-row class="mt-5">
                            <v-col cols="5">
                              <h3 class="mb-5">Cena (dla pacjenta)</h3>
                              <v-text-field v-for="(v,index) in editedItem.price" :key="'price_' + v.currency_id" :error-messages="priceAmountErrors(index)" dense v-model="v.amount" :label="_.find(lookups.currencies,{'value':v.currency_id}).text" />
                            </v-col>
                            <v-col cols=2 class="text-center">
                             <v-divider vertical></v-divider>
                            </v-col>
                            <v-col  cols="5">
                              <h3 class="mb-5">Koszt (dla kliniki)</h3>
                              <v-text-field v-for="(v,index) in editedItem.cost" :key="'cost_' + v.currency_id" :error-messages="costAmountErrors(index)" dense v-model="v.amount" :label="_.find(lookups.currencies,{'value':v.currency_id}).text" />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-select clearable dense v-model="editedItem.referral_type_id" :items="lookups.referral_type"  label="Skierowanie"></v-select>
                            </v-col>
                            <v-col cols="6">
                              <v-select clearable dense v-model="editedItem.interval" :items="lookups.interval"  label="Domyślny czas usługi"></v-select>
                            </v-col>
                            <v-col dense cols="12">
                                  <h3 class="mb-5">Informacje dla pacjenta</h3>
                                  <v-textarea hide-details solo v-model="editedItem.patient_info"  rows="5" />
                            </v-col>
                          </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.category="{ item }">
              <v-icon>{{item.service.category.icon}}</v-icon>
            </template>

            <template v-slot:item.price="{ item }">
              {{priceText(item.price)}}
            </template>
            <template v-slot:item.cost="{ item }">
              {{priceText(item.cost)}}
            </template>
            <template v-slot:item.inf="{ item }">
              <v-simple-checkbox v-model="item.patient_info" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.ref="{ item }">
              <v-simple-checkbox v-model="item.referral_type_id" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.interval="{ item }">
              {{item.interval?_.find(lookups.interval,{'value':item.interval}).text:null}}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="item.status && user.permissions['clinics'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && item.can_delete && user.permissions['clinics'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status  && user.permissions['clinics'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="reactivateItem(item)"
                  >
                    mdi-delete-restore
                  </v-icon>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['clinics'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    color="error"
                    @click="forceDeleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                
            </template>

            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>

          </v-data-table> 
          </v-card>
      <confirm ref="confirm"></confirm>
      </v-col>
</template>

<script>
  import confirm from '../components/Confirm'

  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import integer from 'vuelidate/lib/validators/integer'
  import ServiceLiveSearch from '../components/ServicesLiveSearch'

  export default {
    components:{
      confirm,
      ServiceLiveSearch
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      itemEditing:false,
      search:'',
      serviceRadio:'choose',
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp', filterable:false},
        { text: '', value: 'category', filterable:true },
        { text: 'Usługa', value: 'service.name', filterable:true },
        { text: 'Cena', value: 'price', filterable:false },
        { text: 'Koszt', value: 'cost', filterable:false },
        { text: 'Informacje', value: 'inf', filterable:false },
        { text: 'Skierowanie', value: 'ref', filterable:false },
        { text: 'Czas trwania', value: 'interval', filterable:false },
        { text: '', value: 'actions', sortable: false, filterable:false },
      ],
    }),
    validations(){
      if(this.serviceRadio=='choose'){
        return {
          editedItem:{
            service_id: {
              required
            },
            price:{
              $each: {
                amount: {
                  integer
                }
              }
            },
            cost:{
              $each: {
                amount: {
                  integer
                }
              }
            }
          }
        }
      }else{
        return {
          editedItem:{
            service_name: {
              required,
            },
            service_code: {
              required,
            },
            service_category_id: {
              required,
            },
            price:{
              $each: {
                amount: {
                  integer
                }
              }
            },
            cost:{
              $each: {
                amount: {
                  integer
                }
              }
            }
          }
        }
      }
    },
    watch: {
      dialog (val) {
        this.$v.$reset()
        val || this.close()
      },
      clinicServiceEditStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      clinicServiceAddStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      clinicServiceDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getClinicServiceOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setClinicServiceOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getClinicServiceOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      clinicId: {
        get () {
          return this.$store.getters.getClinicServiceOptions.clinicId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.clinicId = value;
          this.options = currentOptions;
        }
      },
     
      editedItem(){
        return this.$store.getters.getClinicServiceEdit;
      },
      tableItems(){
        return this.$store.getters.getClinicServices;
      },
      clinicServicesLoadStatus(){
        return this.$store.getters.getClinicServicesLoadStatus;
      },
      clinicServiceEditLoadStatus(){
        return this.$store.getters.getClinicServiceEditLoadStatus
      },
      clinicServiceEditStatus(){
        return this.$store.getters.getClinicServiceEditStatus();
      },
      clinicServiceEditText(){
        return this.$store.getters.getClinicServiceEditText
      },
      clinicServiceAddStatus(){
        return this.$store.getters.getClinicServiceAddStatus();
      },
      clinicServiceAddText(){
        return this.$store.getters.getClinicServiceAddText;
      },
      clinicServiceDeleteStatus(){
        return this.$store.getters.getClinicServiceDeleteStatus();
      },
      computedHeaders () {
        if(!this.showDeleted) return this.headers.filter(h => h.value!=='status')
        else return this.headers
      },
      formTitle () {
        return !this.itemEditing ? 'Nowa diagnostyka' : 'Edycja diagnostyki'
      },
       service_idErrors () {
        const errors = []
        if (this.serviceRadio=='new' || !this.$v.editedItem.service_id.$dirty) return errors
        !this.$v.editedItem.service_id.required && errors.push('Diagnostyka jest wymagana')
        return errors
      },
      service_nameErrors () {
        const errors = []
        if (this.serviceRadio=='choose' || !this.$v.editedItem.service_name.$dirty) return errors
        !this.$v.editedItem.service_name.required && errors.push('Nazwa jest wymagana')
        return errors
      },
      service_codeErrors () {
        const errors = []
        if (this.serviceRadio=='choose' || !this.$v.editedItem.service_code.$dirty) return errors
        !this.$v.editedItem.service_code.required && errors.push('Kod diagnostyki jest wymagany')
        return errors
      },
      service_category_idErrors () {
        const errors = []
        if (this.serviceRadio=='choose' || !this.$v.editedItem.service_category_id.$dirty) return errors
        !this.$v.editedItem.service_category_id.required && errors.push('Kategoria diagnostyki jest wymagana')
        return errors
      },
      
    },
    mounted(){
      this.clinicId = this.$route.params.id;
    },
    methods: {
      priceAmountErrors (i) {
        const errors = []
        if (!this.$v.editedItem.price.$each[i] || !this.$v.editedItem.price.$each[i].amount.$dirty) return errors
        !this.$v.editedItem.price.$each[i].amount.integer && errors.push('Kwota musi być liczbą')
        return errors
      },
      costAmountErrors (i) {
        const errors = []
        if (!this.$v.editedItem.cost.$each[i] || !this.$v.editedItem.cost.$each[i].amount.$dirty) return errors
        !this.$v.editedItem.cost.$each[i].amount.integer && errors.push('Kwota musi być liczbą')
        return errors
      },
      priceText(value){
        if(value){
          var output = [];
          this.lookups.currencies.forEach(c => {
            var val = _.find(value,{'currency_id':c.value});
            if(val)output.push(val['amount'] + ' ' + c.text );
          });
          return output.join(', ');

        }else return "BEZPŁATNE";
      },
      loadClinicServices(){
        this.search='';
        this.$store.dispatch( 'loadClinicServices' );
      },
      reactivateItem(item){
        this.$store.dispatch( 'editClinicServiceReactivate',{slug:item.id});
      },
      editItem (item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadClinicServiceEdit', {slug:  item.id});  
        this.dialog = true
      },
      newItem(){
        this.$store.dispatch("loadClinicServiceCurrencies");
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz wyłączyć diagnostykę?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteClinicService', {slug:  item.id});
            }
        })
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać diagnostykę na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteClinicService', {slug:  item.id});
            }
        })
      },
      close () {
        this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadClinicServiceEditDefault'); 
          this.$store.dispatch( 'loadClinicServiceAddDefault');
          this.$store.commit("setServicesLookup",[]);
          this.itemEditing = false
          this.serviceRadio = 'choose'
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editClinicService', {
                slug: this.editedItem.id,
                price: _.filter(this.editedItem.price, function(o) { return o.amount!=null && o.amount!=''; }),
                cost: _.filter(this.editedItem.cost, function(o) { return o.amount!=null && o.amount!='' }),
                referral_type_id: this.editedItem.referral_type_id,
                patient_info: this.editedItem.patient_info,
                interval: this.editedItem.interval,
              });
            } else {//new item
              this.$store.dispatch( 'addClinicService', {
                service_id: this.editedItem.service_id,
                service_name: this.editedItem.service_name,
                service_code: this.editedItem.service_code,
                service_category_id: this.editedItem.service_category_id,
                price: _.filter(this.editedItem.price, function(o) { return o.amount!=null && o.amount!='' }),
                cost: _.filter(this.editedItem.cost, function(o) { return o.amount!=null && o.amount!='' }),
                referral_type_id: this.editedItem.referral_type_id,
                patient_info: this.editedItem.patient_info,
                interval: this.editedItem.interval,
              });

            }
        }
      },
    },
  }
</script>


<style scoped>

</style>
